import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { Component, HostListener, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Socio } from 'src/app/@core/models/Socio';
import { AutenticaService } from 'src/app/@core/services/AutenticaService';
import { BaseDirectivoService } from 'src/app/@core/services/BaseDirectivoService';
import { SocioService } from 'src/app/@core/services/SocioService';
import { variablesGlobales } from 'src/app/Util/variableGlobal';
import { map, startWith } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { BaseDirectivo } from 'src/app/@core/models/BaseDirectivo';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { Functions } from 'src/app/@core/helpers/Functions';
import { DocumentoService } from 'src/app/@core/services/DocumentoService';
import { BuscarDocumentoComponent } from '../../../../controles/buscar-documento/buscar-documento.component';
import { Archivo } from 'src/app/@core/models/Archivo';
import { MatTableDataSource } from '@angular/material/table';
import { Entidad } from 'src/app/@core/models/Entidad';
import { EntidadService } from 'src/app/@core/services/EntidadService';
import { ArchivoService } from 'src/app/@core/services/ArchivoService';
import { DialogAdjuntarComponent } from 'src/app/completar-registro/dialog-adjuntar/dialog-adjuntar.component';
import { RegistrarEntidadComponent } from 'src/app/menu/controles/registrar-entidad/registrar-entidad.component';
import { BaseDirectivoRequisito } from 'src/app/@core/models/BaseDirectivoRequisito';
import { BaseDirectivoRequisitoService } from 'src/app/@core/services/BaseDirectivoRequisitoService';

export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};

@Component({
  selector: 'app-registrar-directivo',
  templateUrl: './registrar-directivo.component.html',
  styleUrls: ['./registrar-directivo.component.scss'],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }
  ]
})
export class RegistrarDirectivoComponent implements OnInit {

  FrmRegistrarDirectivo!: FormGroup;
  codBase:number=0;
  codigo:number=0;
  opc!:string;
  Socio: Socio[]=[];
  filteredSocio: any;
  Entidad: Entidad[]=[];
  filteredEntidad: any;
  ObjBaseDirectivo!: BaseDirectivo;
  txtTitulo!: string;
  isVisibleGuardar: boolean=true;
  admBase: number=0;
  codTemp: number=0;
  ObjBaseDirectivoRequisito!: BaseDirectivoRequisito;

  Archivo: Archivo[]=[];
  ArchivoQuitar: Archivo[]=[];
  displayedColumns: string[] = ['nU_ID_ARCHIVO','tX_NOMBRE','tX_REQUISITO','tX_DESCRIPCION'];
  dataSource = this.Archivo;

  ArchivoTable: Archivo[]=[];
  ArchivoTableDataSource = new MatTableDataSource(this.ArchivoTable);

  @ViewChild('autosize') autosize!: CdkTextareaAutosize;

  constructor(private _formBuilder: FormBuilder,
              private ObjAutenticaService: AutenticaService,
              private ObjSocioService: SocioService,
              private ObjBaseDirectivoService: BaseDirectivoService,
              private ObjDocumentoService: DocumentoService,
              private ObjEntidadService: EntidadService,
              private ObjArchivoService: ArchivoService,
              private ObjBaseDirectivoRequisitoService: BaseDirectivoRequisitoService,
              private ObjFunctions: Functions,
              public dialog: MatDialog,
              private dialogRef: MatDialogRef<RegistrarDirectivoComponent>,
              @Inject(MAT_DIALOG_DATA) data: any,) { 
                this.codBase=data.codBase;
                this.codigo=parseInt(data.codDirectivo);
                this.opc=data.op;
              }

  ngOnInit(): void {
    this.FrmRegistrarDirectivo=this._formBuilder.group({
      codsocio:[null, Validators.compose([Validators.required])],
      codentidad:[null, Validators.compose([Validators.required])],
      cbocargo:[null, Validators.compose([Validators.required])],
      FecInicio:[null, Validators.compose([Validators.required])],
      coddocumento_ingreso:[null],
      txtdocumento_ingreso:[null],
      FecTermino:[null, Validators.compose([Validators.required])],
      coddocumento_termino:[null],
      txtdocumento_termino:[null],
    }); 

    this.admBase=variablesGlobales.usuarioRol.admBase;

    if(this.opc==='MOD') this.Obtener();
    else this.txtTitulo="Registra Directivo";
  }

  @HostListener('window:keyup.esc') onKeyUp() {
    this.Close();
  }

  fnObtenerSocio(idsocio:number){
    this.ObjSocioService.Obtener(idsocio,2).pipe()
      .subscribe(data => {
        if (data.isSuccess){
          this.FrmRegistrarDirectivo.controls["codsocio"].setValue(data.socio);
        }
      });
  }

  fnObtenerEntidad(identidad:number){
    this.ObjEntidadService.Obtener(identidad,0).pipe()
      .subscribe(data => {
        if (data.isSuccess){
          this.FrmRegistrarDirectivo.controls["codentidad"].setValue(data.entidad);
        }
      });
  }

  fnObtenerDocumento(opc:number, iddocumento:number){
    this.ObjDocumentoService.Obtener(iddocumento).pipe()
      .subscribe(data => {
        if (data.isSuccess){
          if(opc==1){
            this.FrmRegistrarDirectivo.controls["coddocumento_ingreso"].setValue(data.documento.nU_ID_DOCUMENTO);
            this.FrmRegistrarDirectivo.controls["txtdocumento_ingreso"].setValue(data.documento.tX_SIGLA);
          } 
          else{
            this.FrmRegistrarDirectivo.controls["coddocumento_termino"].setValue(data.documento.nU_ID_DOCUMENTO);
            this.FrmRegistrarDirectivo.controls["txtdocumento_termino"].setValue(data.documento.tX_SIGLA);
          } 
        }
      });
  }

  Obtener(){ 
    this.ObjAutenticaService.ValidarApp()
      .subscribe(
        data => { 
          if(data.isSuccess){
            if(data.tx_token.length>0){
              variablesGlobales.token=data.tx_token;

              this.ObjBaseDirectivoService.Obtener(this.codigo).pipe()
                .subscribe(
                  data => {
                    if (data.isSuccess){
                      this.ObjBaseDirectivo=data.directivo;

                      //this.fnObtenerSocio(this.ObjBaseDirectivo.nU_ID_ASOCIADO!);
                      this.fnObtenerEntidad(this.ObjBaseDirectivo.nU_ID_ENTIDAD!);
                      this.FrmRegistrarDirectivo.controls["cbocargo"].setValue(this.ObjBaseDirectivo.nU_ID_CARGO?.toString());
                      this.FrmRegistrarDirectivo.controls["cbocargo"].disable();
                      this.FrmRegistrarDirectivo.controls["FecInicio"].setValue(this.convertFechaString(this.ObjBaseDirectivo.fE_INICIO));
                      this.fnObtenerDocumento(1,this.ObjBaseDirectivo.nU_ID_DOCUMENTO_INICIO!);

                      if(this.admBase && this.ObjBaseDirectivo.fE_TERMINO === null){
                        this.txtTitulo="Finaliza Cargo";
                      }
                      else{
                        this.txtTitulo="Detalle de Cargo";
                        this.isVisibleGuardar=false;
                      }

                      if(this.ObjBaseDirectivo.fE_TERMINO !== null){                          
                        this.FrmRegistrarDirectivo.controls["FecTermino"].setValue(this.convertFechaString(this.ObjBaseDirectivo.fE_TERMINO));
                      }

                      if(this.ObjBaseDirectivo.nU_ID_DOCUMENTO_TERMINO!>0){
                        this.fnObtenerDocumento(2,this.ObjBaseDirectivo.nU_ID_DOCUMENTO_TERMINO!);
                      }

                      for(let item of data.listaarchivosadjuntos){
                        let model={'nU_ID_ARCHIVO':item.nU_ID_ARCHIVO,
                                    'tX_NOMBRE_ARCHIVO':item.tX_NOMBRE_ARCHIVO,
                                    'nU_ID_REQUISITO':item.nU_ID_REQUISITO,
                                    'tX_REQUISITO':item.tX_REQUISITO,
                                    'tX_DESCRIPCION':item.tX_DESCRIPCION,
                                    'nU_ID_TABLA_ARCHIVO': item.nU_ID_BASEDIRECTIVOREQUISITO,                        
                                    'tX_EXTENSION':item.tX_EXTENSION};
  
                        this.Archivo.push(model);                      
                      }
  
                      this.ArchivoTableDataSource.data=this.Archivo;
                    }
                  },
                  error => {
                    //console.log(error);
                    Swal.fire({
                      title:'El servicio solicitado no se encuentra disponible',
                      icon: 'error',
                    });
                  }
                );  
            }
            else{
                //console.log("Autenticación inválida: "+data.tx_Mensaje);
            }
          }
        },
        error => {
          //console.log(error);
        }   
      );                              
  }

  fnListarSocio(){   
    this.ObjAutenticaService.ValidarApp()
      .subscribe(
        data => { 
          if(data.isSuccess){
            if(data.tx_token.length>0){
              variablesGlobales.token=data.tx_token;

              this.ObjSocioService.Listar(1).pipe()
                .subscribe(data => {
                  if(data.isSuccess){
                    this.Socio=data.data;
                    this.filteredSocio=this.FrmRegistrarDirectivo.controls["codsocio"].valueChanges
                        .pipe(
                          startWith(''),
                          map(value => typeof value === 'string' ? value : value.tX_NUMERO+' | '+value.tX_NOMBRE+' '+value.tX_APELLIDO_PATERNO+' '+value.tX_APELLIDO_MATERNO),            
                          map(nombre => nombre ? this._filterSocio(nombre) : this.Socio.slice()
                        )
                    );
                  }
                });
            }
            else{
                //console.log("Autenticación inválida: "+data.tx_Mensaje);
            }
          }
        },
        error => {
          //console.log(error);
        }   
      ); 
  }

  displayFnSocio(socio?: Socio): string{
    return socio ? socio.tX_NUMERO+' | '+socio.tX_NOMBRE+' '+socio.tX_APELLIDO_PATERNO+' '+socio.tX_APELLIDO_MATERNO: "";
  }

  private _filterSocio(nombre: string): Socio[] {
    const filterValue = nombre.toLowerCase();
    return this.Socio.filter(option => (option.tX_NUMERO+' | '+
                            option.tX_NOMBRE!.toLowerCase()+' '+
                            option.tX_APELLIDO_PATERNO!.toLowerCase()+' '+
                            option.tX_APELLIDO_MATERNO!.toLowerCase()).includes(filterValue));
  }

  fnListarEntidad(){   
    this.ObjAutenticaService.ValidarApp()
      .subscribe(
        data => { 
          if(data.isSuccess){
            if(data.tx_token.length>0){
              variablesGlobales.token=data.tx_token;

              this.ObjEntidadService.Listar(1).pipe()
                .subscribe(data => {
                  if(data.isSuccess){
                    this.Entidad=data.entidad;
                    this.filteredEntidad=this.FrmRegistrarDirectivo.controls["codentidad"].valueChanges
                        .pipe(
                          startWith(''),
                          map(value => typeof value === 'string' ? value : value.tX_NUMERO+' | '+value.tX_NOMBRE+' '+value.tX_APELLIDO_PATERNO+' '+value.tX_APELLIDO_MATERNO),            
                          map(nombre => nombre ? this._filterEntidad(nombre) : this.Entidad.slice()
                        )
                    );
                  }
                });
            }
            else{
                //console.log("Autenticación inválida: "+data.tx_Mensaje);
            }
          }
        },
        error => {
          //console.log(error);
        }   
      ); 
  }

  displayFnEntidad(entidad?: Entidad): string{
    return entidad ? entidad.tX_NUMERO+' | '+entidad.tX_NOMBRE+' '+entidad.tX_APELLIDO_PATERNO+' '+entidad.tX_APELLIDO_MATERNO: "";
  }

  private _filterEntidad(nombre: string): Entidad[] {
    const filterValue = nombre.toLowerCase();
    return this.Entidad.filter(option => (option.tX_NUMERO+' | '+
                            option.tX_NOMBRE!.toLowerCase()+' '+
                            option.tX_APELLIDO_PATERNO!.toLowerCase()+' '+
                            option.tX_APELLIDO_MATERNO!.toLowerCase()).includes(filterValue));
  }

  alertMsg(msg:string){
    Swal.fire({
      html:msg,
      icon:'info'
    })
  }

  ValidarCampos(){
    var band=0;

    /*if(this.FrmRegistrarDirectivo.controls["codsocio"].value!==null &&
       this.FrmRegistrarDirectivo.controls["codsocio"].value.nU_ID_ASOCIADO!==undefined){
        band=1;
    }
    if(band===0){
      this.alertMsg('Seleccione Socio');
      return false;
    }*/

    if(this.FrmRegistrarDirectivo.controls["codentidad"].value!==null &&
       this.FrmRegistrarDirectivo.controls["codentidad"].value.nU_ID_ENTIDAD!==undefined){
        band=1;
    }
    if(band===0){
      this.alertMsg('Seleccione Persona');
      return false;
    }

    if (this.FrmRegistrarDirectivo.controls["cbocargo"].value === null || 
      this.FrmRegistrarDirectivo.controls["cbocargo"].value.length === 0) {
        this.alertMsg('Seleccione Cargo');
        return false;
    }

    if (this.FrmRegistrarDirectivo.controls["FecInicio"].value === null || 
      this.FrmRegistrarDirectivo.controls["FecInicio"].value.length === 0 || 
      /^\s+$/.test(this.FrmRegistrarDirectivo.controls["FecInicio"].value)) {
        this.alertMsg('Ingrese Fecha de Inicio o establezca el formato correcto');
        return false;
    }

    if(this.FrmRegistrarDirectivo.controls["txtdocumento_ingreso"].value === null ||
      this.FrmRegistrarDirectivo.controls["txtdocumento_ingreso"].value.length === 0 || 
      /^\s+$/.test(this.FrmRegistrarDirectivo.controls["txtdocumento_ingreso"].value)){
        this.alertMsg('Ingrese Documento Designatorio');
        return false;
    }

    if(this.opc==='MOD'){
      /*if (this.FrmRegistrarDirectivo.controls["FecTermino"].value === null || 
       this.FrmRegistrarDirectivo.controls["FecTermino"].value.length === 0 || 
       /^\s+$/.test(this.FrmRegistrarDirectivo.controls["FecTermino"].value)) {
        this.alertMsg('Ingrese Fecha de Término o establezca el formato correcto');
        return false;
      }

      var valfechaini = new Date(this.FrmRegistrarDirectivo.controls["FecInicio"].value);
      var valfechafin = new Date(this.FrmRegistrarDirectivo.controls["FecTermino"].value);
      if (!this.ObjFunctions.ValidateDateRange(valfechaini,valfechafin)) {
        this.alertMsg('Fecha de Término debe ser mayor que Fecha de Inicio.');
        return false;
      }

      if(this.FrmRegistrarDirectivo.controls["txtdocumento_termino"].value === null ||
        this.FrmRegistrarDirectivo.controls["txtdocumento_termino"].value.length === 0 || 
        /^\s+$/.test(this.FrmRegistrarDirectivo.controls["txtdocumento_termino"].value)){
          this.alertMsg('Ingrese Documento de Término');
          return false;
      }*/

      if (this.FrmRegistrarDirectivo.controls["FecTermino"].value !== null) {

        var valfechaini = new Date(this.FrmRegistrarDirectivo.controls["FecInicio"].value);
        var valfechafin = new Date(this.FrmRegistrarDirectivo.controls["FecTermino"].value);
        if (!this.ObjFunctions.ValidateDateRange(valfechaini,valfechafin)) {
          this.alertMsg('Fecha de Término debe ser mayor que Fecha de Inicio.');
          return false;
        }

        if(this.FrmRegistrarDirectivo.controls["txtdocumento_termino"].value === null ||
          this.FrmRegistrarDirectivo.controls["txtdocumento_termino"].value.length === 0 || 
          /^\s+$/.test(this.FrmRegistrarDirectivo.controls["txtdocumento_termino"].value)){
            this.alertMsg('Ingrese Documento de Término');
            return false;
        }
      }

      if (this.FrmRegistrarDirectivo.controls["txtdocumento_termino"].value !== null && 
       this.FrmRegistrarDirectivo.controls["txtdocumento_termino"].value.trim().length > 0) {
        
        if (this.FrmRegistrarDirectivo.controls["FecTermino"].value === null || 
         this.FrmRegistrarDirectivo.controls["FecTermino"].value.length === 0 || 
          /^\s+$/.test(this.FrmRegistrarDirectivo.controls["FecTermino"].value)) {
            this.alertMsg('Ingrese Fecha de Término o establezca el formato correcto');
            return false;
          }
      }
    }

    return true;
  }

  Guardar(){
    if(this.ValidarCampos()){
      this.ObjBaseDirectivo={nU_ID_BASEDIRECTIVO:0};
      this.ObjBaseDirectivo.nU_ID_BASEDIRECTIVO=this.codigo;
      this.ObjBaseDirectivo.nU_ID_BASE=this.codBase;   
      //this.ObjBaseDirectivo.nU_ID_ENTIDAD=this.FrmRegistrarDirectivo.controls["codsocio"].value.nU_ID_ENTIDAD;
      //this.ObjBaseDirectivo.nU_ID_ASOCIADO=this.FrmRegistrarDirectivo.controls["codsocio"].value.nU_ID_ASOCIADO;
      this.ObjBaseDirectivo.nU_ID_ENTIDAD=this.FrmRegistrarDirectivo.controls["codentidad"].value.nU_ID_ENTIDAD;
      this.ObjBaseDirectivo.nU_ID_ASOCIADO=-1;
      this.ObjBaseDirectivo.nU_ID_CARGO=parseInt(this.FrmRegistrarDirectivo.controls["cbocargo"].value);
      this.ObjBaseDirectivo.fE_INICIO=new Date(this.FrmRegistrarDirectivo.controls["FecInicio"].value);
      this.ObjBaseDirectivo.nU_ID_DOCUMENTO_INICIO=this.FrmRegistrarDirectivo.controls["coddocumento_ingreso"].value;
      this.ObjBaseDirectivo.lISTAARCHIVOQUITAR=this.ArchivoQuitar;

      if(this.opc==='MOD'){
        if(this.FrmRegistrarDirectivo.controls["FecTermino"].value !== null){
            this.ObjBaseDirectivo.fE_TERMINO=new Date(this.FrmRegistrarDirectivo.controls["FecTermino"].value);
        }

        if (this.FrmRegistrarDirectivo.controls["txtdocumento_termino"].value !== null && 
          this.FrmRegistrarDirectivo.controls["txtdocumento_termino"].value.trim().length > 0) {
            this.ObjBaseDirectivo.nU_ID_DOCUMENTO_TERMINO=this.FrmRegistrarDirectivo.controls["coddocumento_termino"].value;
        }

        this.ObjBaseDirectivo.nU_ID_ESTADO=2;
      }

      this.ObjAutenticaService.ValidarApp()
        .subscribe(
          data => { 
            if(data.isSuccess){
              if(data.tx_token.length>0){
                variablesGlobales.token=data.tx_token;

                this.ObjBaseDirectivoService.Actualizar(this.ObjBaseDirectivo).pipe()
                  .subscribe(
                    data => {
                      if (data.isSuccess){
                        for(let archivoSave of this.Archivo){
                          let oFormData = new FormData(); 
                          if (archivoSave.nU_ID_TABLA_ARCHIVO==0){
                            if (archivoSave.archivo!=null){
                              oFormData.append("nU_RELACIONADO", "4"); 
                              oFormData.append("nU_ID_RELACIONADO", data.nU_ID_CODIGO.trim()); 
                              oFormData.append("nU_ID_ARCHIVO", archivoSave.nU_ID_ARCHIVO!.toString());                        
                              oFormData.append("tX_NOMBRE", archivoSave.tX_NOMBRE!);
                              oFormData.append("nU_ID_REQUISITO", archivoSave.nU_ID_REQUISITO!.toString());
                              oFormData.append("tX_DESCRIPCION", archivoSave.tX_DESCRIPCION!);
                              oFormData.append("tX_EXTENSION", archivoSave.tX_EXTENSION!);
                              oFormData.append("nU_ID_USUARIO", variablesGlobales.usuario.codusu.toString());
                              oFormData.append("archivo", archivoSave.archivo);
                              this.ObjArchivoService.UpLoadFileRelacionado(oFormData).subscribe(
                                data1=>{
                                }
                              );
                            }
                            else{
                              //Nuevo vinculo
                              this.ObjBaseDirectivoRequisito={
                                nU_ID_BASEDIRECTIVOREQUISITO:0,
                                nU_ID_BASEDIRECTIVO:parseInt(data.nU_ID_CODIGO),
                                nU_ID_REQUISITO:archivoSave.nU_ID_REQUISITO,
                                nU_ID_ARCHIVO:archivoSave.nU_ID_ARCHIVO
                              };

                              this.ObjBaseDirectivoRequisitoService.Actualizar(this.ObjBaseDirectivoRequisito).pipe()
                                .subscribe(
                                  data => {
                                    if (data.isSuccess){
                                    }
                                    else{
                                      Swal.fire({
                                        title: data.tx_Mensaje,
                                        icon: 'warning'}); 
                                    } 
                                  },
                                  error => {
                                    //console.log(error);
                                    Swal.fire({
                                      title:'El servicio solicitado no se encuentra disponible',
                                      icon: 'error',
                                    });
                                  }
                                );
                            } 
                          }
                        };

                        Swal.fire({
                          title: data.tx_Mensaje,
                          icon: 'success'}); 
                        
                        this.dialogRef.close(data.isSuccess);
                      }
                      else{
                        Swal.fire({
                          title: data.tx_Mensaje,
                          icon: 'warning'}); 
                      } 
                    },
                    error => {
                      //console.log(error);
                      Swal.fire({
                        title:'El servicio solicitado no se encuentra disponible',
                        icon: 'error',
                      });
                    }
                  );
              }
              else{
                  //console.log("Autenticación inválida: "+data.tx_Mensaje);
              }
            }
          },
          error => {
            //console.log(error);
          }   
        );
    }
  }

  fnBuscarDocumento(opc: number){
    const dialogRef = this.dialog.open(BuscarDocumentoComponent, {
      data: {
        op:opc
      },
      disableClose: true 
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if(result.opc==1){
          this.FrmRegistrarDirectivo.controls["coddocumento_ingreso"].setValue(result.obj.nU_ID_DOCUMENTO);
          this.FrmRegistrarDirectivo.controls["txtdocumento_ingreso"].setValue(result.obj.tX_SIGLA);
        }
        else if(result.opc==2){
          this.FrmRegistrarDirectivo.controls["coddocumento_termino"].setValue(result.obj.nU_ID_DOCUMENTO);
          this.FrmRegistrarDirectivo.controls["txtdocumento_termino"].setValue(result.obj.tX_SIGLA);
        }
      }
    });
  }

  Close() {
    this.dialogRef.close();
  }

  pulsar(event:any) {
    if (event.which === 13 && !event.shiftKey) {
      event.preventDefault();
      return false;
    }
    return true;
  }

  convertFechaString(fecha: any){
    var parts=fecha.split("/");
    var dia =parts[1];
    var mes =parts[0];
    var anio =parts[2].substring(0,4);
    var mydate =new Date(anio,mes-1,dia)

    return mydate.toISOString();
  }

  fnLimpiar(){
    if(this.FrmRegistrarDirectivo.controls["codentidad"].value===null ||
       this.FrmRegistrarDirectivo.controls["codentidad"].value.nU_ID_ENTIDAD===undefined){
        this.Archivo=[];
        this.ArchivoTableDataSource.data=this.Archivo;
        this.codTemp=0;
    }
  }

  fnObtenerArchivos(obj: Entidad){
    this.ObjAutenticaService.ValidarApp()
      .subscribe(
        data => { 
          if(data.isSuccess){
            if(data.tx_token.length>0){
              variablesGlobales.token=data.tx_token;

              this.ObjEntidadService.Obtener(obj.nU_ID_ENTIDAD,3).pipe()
                .subscribe(data => {
                  if (data.isSuccess){
                    for(let item of data.listaarchivosadjuntos){
                      let model={'nU_ID_ARCHIVO':item.nU_ID_ARCHIVO,
                                  'tX_NOMBRE_ARCHIVO':item.tX_NOMBRE_ARCHIVO,
                                  'nU_ID_REQUISITO':item.nU_ID_REQUISITO,
                                  'tX_REQUISITO':item.tX_REQUISITO,
                                  'tX_DESCRIPCION':item.tX_DESCRIPCION,   
                                  'nU_ID_TABLA_ARCHIVO':0,
                                  'tX_EXTENSION':item.tX_EXTENSION,};

                      this.Archivo.push(model);                      
                    }

                    this.ArchivoTableDataSource.data=this.Archivo;
                  }
                });
            }
            else{
              //console.log("Autenticación inválida: "+data.tx_Mensaje);
            }
          }
        },
        error => {
          //console.log(error);
        }   
      );
  }

  fnQuitar(obj:Archivo){
    Swal.fire({
      text: "¿Está seguro de eliminar el registro?",
      icon: 'question',
      showCancelButton: true,
      cancelButtonText:'No',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí',
      reverseButtons: true
    }).then((result) => {
      if (result.value){
        if(obj.nU_ID_ARCHIVO===0){
          this.Archivo=this.Archivo.filter((value,key)=>{
            return value.codTemp != obj.codTemp;
          });     
        }
        else{
          this.Archivo=this.Archivo.filter((value,key)=>{
            return value.nU_ID_ARCHIVO != obj.nU_ID_ARCHIVO;
          });
          this.ArchivoQuitar.push(obj);
        }
        this.ArchivoTableDataSource.data=this.Archivo;
      }
    });
  }

  fnDescargar(obj: Archivo){
    if(obj.nU_ID_ARCHIVO===0){
      this.fnObtenerArchivo(obj);
    }
    else{
      this.ObjAutenticaService.ValidarApp()
        .subscribe(
          data => { 
            if(data.isSuccess){
              if(data.tx_token.length>0){
                variablesGlobales.token=data.tx_token;
  
                this.ObjArchivoService.ObtenerTrama(obj.tX_NOMBRE_ARCHIVO!).pipe()    
                  .subscribe(
                    data => {
                      if(data.isSuccess){
                        obj.trama=data.tx_Mensaje;
                        this.fnObtenerArchivo(obj);
                      } 
                      else{
                        Swal.fire({
                          title:'Archivo no encontrado',
                          icon: 'info',
                        });
                      }                                  
                    },
                    error => {
                      //console.log(error);
                      Swal.fire({
                        title:'El servicio solicitado no se encuentra disponible',
                        icon: 'error',
                      });
                    }
                  );
              }
              else{
                  //console.log("Autenticación inválida: "+data.tx_Mensaje);
              }
            }
          },
          error => {
            //console.log(error);
          }   
        );
    }
  }

  fnObtenerArchivo(obj: Archivo){
    const byteCharacters= atob(obj.trama);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);
  
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
  
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    var nombre=(obj.nU_ID_ARCHIVO==0)?obj.tX_NOMBRE:obj.tX_NOMBRE_ARCHIVO;

    const blob = new Blob(byteArrays, {type: 'application/'+nombre!.split('.').pop()});
    const blobUrl = URL.createObjectURL(blob);

    const link:any = document.createElement('a');
    link.href = blobUrl;
    link.download = nombre;
    link.click();
  }

  fnAgregar(){
    const dialogRef = this.dialog.open(DialogAdjuntarComponent, {
      data: {
        origen:"FrmRegistrarDirectivo",
        listaArchivo: this.Archivo,
      },
      disableClose: true 
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.codTemp++;

        let model={'nU_ID_ARCHIVO':0,
                    'tX_NOMBRE':result.nomarchivo,
                    'nU_ID_TABLA_ARCHIVO':0,
                    'nU_ID_REQUISITO':result.codrequisito,
                    'tX_REQUISITO':result.requisito,
                    'tX_DESCRIPCION':result.descripcion,                        
                    'tX_EXTENSION':result.extension,                       
                    'nU_ID_ESTADO':3,
                    'archivo':result.file,     
                    'trama':result.trama,              
                    'codTemp':this.codTemp};

        this.Archivo.push(model);          
        this.ArchivoTableDataSource.data=this.Archivo;
      }
    });
  }

  fnRegistrarEntidad(opc:string,identidad:number){
    const dialogRef = this.dialog.open(RegistrarEntidadComponent, {
      data: {
        codEntidad:identidad,
        op: opc
      },
      disableClose: true 
    });
  }
}
