
<div *ngIf="principal" fxLayout="column" fxLayoutAlign="center">
    <form [formGroup]="FrmGestBase">
        <div class="opcion">
            <mat-label class="Titulo">Bases Organizacionales</mat-label>
        </div>
        <mat-card fxLayout="column">
            <mat-card-content fxLayout="column" style="margin-top: 18px;margin-bottom: 20px;" *ngIf="admBase">
                <button mat-mini-fab color="btnStyle" class="tooltips_fab" (click)="fnAgregar()">
                    <mat-icon>add</mat-icon>
                    <span>Añadir</span>
                </button>
            </mat-card-content>
            <mat-card-content fxLayout="column">
                <h3 class="SubTitulo">Relación de Bases Organizacionales</h3>
                <div class="example-container">
                    <table mat-table [dataSource]="BaseTableDataSource">
                        <ng-container matColumnDef="nU_ID_BASE">
                            <th mat-header-cell *matHeaderCellDef class="priority-1"></th>
                            <td mat-cell *matCellDef="let element" class="priority-1">
                                <button mat-icon-button class="tooltips_cls" [matMenuTriggerFor]="menu">
                                    <mat-icon>menu</mat-icon>
                                </button>
                                <mat-menu #menu="matMenu">
                                    <button mat-menu-item (click)="fnGestDirectivo(element)">
                                        <mat-icon fontSet="material-icons-outlined">groups</mat-icon>
                                        <span>Directivos</span>
                                    </button>
                                    <button mat-menu-item (click)="fnGestAsociado(element)">
                                        <mat-icon fontSet="material-icons-outlined">groups</mat-icon>
                                        <span>Asociado</span>
                                    </button>
                                </mat-menu>                           
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="nU_ANIO_PROMOCION">
                            <th mat-header-cell *matHeaderCellDef class="priority-2">Año de Promoción</th>
                            <td mat-cell *matCellDef="let element" class="priority-2">{{element.nU_ANIO_PROMOCION}}</td>
                        </ng-container>
                        <ng-container matColumnDef="tX_TURNO">
                            <th mat-header-cell *matHeaderCellDef class="priority-3">Turno</th>
                            <td mat-cell *matCellDef="let element" class="priority-3">{{element.tX_TURNO}}</td>
                        </ng-container>
                        <ng-container matColumnDef="fE_REGISTRO">
                            <th mat-header-cell *matHeaderCellDef class="priority-4">F. Registro</th>
                            <td mat-cell *matCellDef="let element" class="priority-4">{{convertFecha(element.fE_REGISTRO)}}</td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                </div>  
            </mat-card-content>
        </mat-card> 
    </form>      
</div>
<mat-paginator id="paginadorBase" class="stylePaginator" [pageSizeOptions]="[10, 20, 50]" showFirstLastButtons></mat-paginator>
<div *ngIf="detalle">
    <app-gestiona-directivo
        [ObjBase]="ObjBase"    
        (salida)="fnRetornoGestDirectivo($event)"
        >
    </app-gestiona-directivo>
</div>
<div *ngIf="asociado">
    <app-gestiona-asociado
        [ObjBase]="ObjBase"    
        (salida)="fnRetornoGestAsociado($event)"
        >
    </app-gestiona-asociado>
</div>