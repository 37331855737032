import { Injectable } from '@angular/core';
import { ImageToBase64 } from '../helpers/ImageToBase64';
import { Integrante } from '../models/Integrante';

import * as pdfMake from "pdfmake/build/pdfmake.min.js";
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { Evento } from '../models/Evento';
import { variablesGlobales } from 'src/app/Util/variableGlobal';
import { Equipo } from '../models/Equipo';
(<any>pdfMake).vfs = pdfFonts.pdfMake.vfs;

export interface data{
    index_row: number,
    index_column: number,
    rowSpan: number,
    count: number,
    maxheight: number
}

@Injectable({
    providedIn: 'root'
})

export class RptPDFFichaInscripcion {

    isVisibleData: number=0;

    constructor(private canvas: ImageToBase64) { }

    convertFechaString(fecha: any){
        var parts=fecha.split("/");
        var dia =parts[1];
        var mes =parts[0];
        var anio =parts[2].substring(0,4);
        var mydate =new Date(anio,mes-1,dia)
    
        return mydate.toISOString();
    }

    convertFecha(fecha: string): string{
        var parts=fecha.split("-");
        var anio =parts[0];
        var mes =parts[1];
        var dia =parts[2].substring(0,2);
    
        return dia+"/"+mes+"/"+anio;
    }

    applyVerticalAlignment(node: any, index: number, align: 'center' | 'bottom') {
        const allCellHeights = node.table.body[index].map((innerNode: any) => {
            const findInlineHeight = this.findInlineHeight(innerNode, node.table.widths[index]._calcWidth);
            const lines = Math.ceil(findInlineHeight.width / node.table.widths[index]._calcWidth);
            return findInlineHeight.height * lines;
        });

        const maxRowHeight = Math.max(...allCellHeights)
        node.table.body[index].forEach((cell: any, ci: number) => {
            if (allCellHeights[ci] && maxRowHeight > allCellHeights[ci]) {
                let topMargin;
                if (align ==='bottom') {
                    topMargin = (maxRowHeight - allCellHeights[ci]);
                } else if (align === 'center') {
                    topMargin = (maxRowHeight - allCellHeights[ci]) / 2;
                }
                if (cell._margin) {
                    cell._margin[1] += topMargin
                } else {
                    cell._margin = [0, topMargin, 0, 0]
                }
            }
        })
    }

    findInlineHeight(cell: any, maxWidth: number, usedWidth: number = 0) {
        if (cell._offsets) {
            usedWidth += cell._offsets.total;
        }

        if (cell._inlines && cell._inlines.length) {
            let currentMaxHeight = 0;
            for (const currentNode of cell._inlines) {
                usedWidth += currentNode.width;
                if (usedWidth > maxWidth) {
                    currentMaxHeight += currentNode.height;
                } else {
                    currentMaxHeight = Math.max(currentNode.height, currentMaxHeight);
                }
            }
            return {
                height: currentMaxHeight,
                width: usedWidth
            }
        } else if (cell.table) {
            let currentMaxHeight = 0;
            for (const currentTableBodies of cell.table.body) {
                const innerTableHeights = currentTableBodies.map((innerTableCell: any) => {
                    const findInlineHeight = this.findInlineHeight(innerTableCell, maxWidth, usedWidth);

                    usedWidth = findInlineHeight.width;
                    return findInlineHeight.height
                });
                currentMaxHeight = Math.max(...innerTableHeights, currentMaxHeight);
            }
            return {
                height: currentMaxHeight,
                width: usedWidth
            }
        } else if (cell._height) {
            usedWidth += cell._width;
            return {
                height: cell._height,
                width: usedWidth
            };
        }

        return {
            height: null,
            width: usedWidth
        };
    }

    setTopMarginOfCellForVerticalCentering(ri: number, node: any){
        const cellHeights = node.table.body[ri].map((cell: any) => {
          if(cell._inlines && cell._inlines.length) {
            return cell._inlines[0].height;
          } else if(cell.stack) {
            return cell.stack[0]._inlines[0].height * cell.stack.length;
          }
          return null;
        });
      
        const maxRowHeight = Math.max(...cellHeights);
        node.table.body[ri].forEach((cell: any, ci: number) => {
          if(cellHeights[ci] && maxRowHeight > cellHeights[ci]) {
            //console.log(maxRowHeight);
            let topMargin = (maxRowHeight - cellHeights[ci]) / 2;
            if(cell._margin) {
              cell._margin[1] = topMargin;
            } else {
              cell._margin = [0, topMargin, 0, 0];
            }
          }
        });

        return 2;
    }

    ShowFoto(foto: string): any{
        let element={};

        if(foto!=""){
            element={
                image: foto,
                width: 101.32,
                height: 120,
                absolutePosition: {x: 438, y: 122}
            };
        }
        return element;
    }

    /** Definición del documento PDF **/
    getDocDefinition(logo: any, objE: Evento, objI: Integrante, txtcabecera: string, foto: string, 
        departamento: string, provincia: string, distrito: string, objEq: Equipo, txtdisciplina: string,
        orientation: string, pageSize: string): any {
        this.isVisibleData=variablesGlobales.usuarioRol.isVisibleData;

        //Margen de la cabecera [left,top,right,bottom]
        let pageMargin=[60, 30, 60, 55];

        let fontSizeTitle=22;
        let fontSizeCabecera=10;

        //Dimensiones del logo
        let widthLogo=54.62;
        let heightLogo=70;
    
        const docDefinition = {
            pageOrientation: orientation,
            pageSize,
            pageMargins: pageMargin,
            content: [
                {
                    table:{
                        widths:['12%','*'],
                        body:[
                            [
                                //logo de la empresa
                                {
                                    image: logo,
                                    width: widthLogo,
                                    height: heightLogo,
                                    rowSpan:5
                                },
                                //Título del documento
                                {
                                    text: txtcabecera, 
                                    fontSize: fontSizeTitle, 
                                    bold: true,
                                    margin: [10,0,0,0]
                                }
                            ],
                            [
                                {},
                                { 
                                    text: 'INSCRITO EN EL REGISTRO DE PERSONAS JURÍDICAS CON',
                                    fontSize: 10, 
                                    bold: true,
                                    margin: [35,0,0,0]
                                },
                            ],
                            /*[
                                {},
                                { 
                                    text: 'PARTIDA REGISTRAL N° 11109575',
                                    fontSize: 10, 
                                    bold: true,
                                    margin: [90,0,0,0]
                                },
                            ],*/
                            [
                                {},
                                { 
                                    //text: 'XXV CAMPEONATO DE FÚTBOL EXACEMBA 2022',
                                    text: objE.tX_DENOMINACION,
                                    fontSize: 13, 
                                    bold: true,
                                    //margin: [25,8,0,0]
                                    margin: [75,8,0,0]
                                },
                            ],
                            [
                                {},
                                { 
                                    //text: '“Bodas de Plata Promoción 1997 “ – “Javier Bardales Garate”',
                                    text: '“'+objE.tX_NOMBRE+'”',
                                    fontSize: 10, 
                                    bold: true,
                                    //margin: [34,0,0,0]
                                    margin: [18,0,0,0]
                                },
                            ],
                            [
                                {},
                                { 
                                    table:{
                                        widths:['76%','*'],
                                        body:[
                                            [
                                                {
                                                    text: 'FICHA DE INSCRIPCIÓN DE JUGADOR', 
                                                    fontSize: 13, 
                                                    bold: true,
                                                    alignment : 'center',
                                                    margin: [0,8,0,0]
                                                },
                                                {
                                                    canvas: [
                                                        {
                                                            type: 'rect',
                                                            x: 0,
                                                            y: 0,
                                                            w: 100,
                                                            h: 119,
                                                            r: 5,
                                                            lineColor: 'black',
                                                        }
                                                    ],
                                                    rowSpan:3
                                                }
                                            ],
                                            [
                                                { 
                                                    text: "EQUIPO: "+objEq.nU_ANIO_PROMOCION+' '+objEq.tX_TURNO+' - '+objEq.tX_DENOMINACION,
                                                    fontSize: 13, 
                                                    bold: true,
                                                    alignment : 'center'
                                                },
                                                {}
                                            ],
                                            [
                                                { 
                                                    text: "DISCIPLINA: "+txtdisciplina,
                                                    fontSize: 13, 
                                                    bold: true,
                                                    alignment : 'center'
                                                },
                                                {}
                                            ]
                                        ]
                                    },
                                    layout:'noBorders'
                                },
                            ]
                        ],
                    },
                    layout:'noBorders'
                },
                {
                    text: [
                        {text: 'Pegue aquí\n\n'},
                        {text: 'foto del\n\n'},
                        {text: 'jugador'},
                    ], 
                    fontSize: 10, 
                    alignment : 'center',
                    absolutePosition: {x: 440, y: 168}
                },
                this.ShowFoto(foto),
                {
                    text: 'DATOS GENERALES',
                    style: 'Cabecera',
                    margin: [0,10,0,0]
                },
                {
                    margin:[0,10,0,0],
                    table:{
                        widths:['15%','*','18%','15%'],
                        body:[
                            [
                                {
                                    text: 'Documento de Identidad', 
                                    style: 'Cabecera'
                                },
                                {
                                    text: 'DNI', 
                                    fontSize: fontSizeCabecera
                                },
                                {
                                    text: 'N° de Documento', 
                                    style: 'Cabecera'
                                },
                                {
                                    text: objI.tX_NUMERO, 
                                    fontSize: fontSizeCabecera
                                }
                            ],
                            [
                                {
                                    text: 'Nombre', 
                                    style: 'Cabecera'
                                },
                                {
                                    text: objI.tX_NOMBRE, 
                                    fontSize: fontSizeCabecera,
                                    colSpan: 3
                                }
                            ],
                            [
                                {
                                    text: 'Apellido Paterno', 
                                    style: 'Cabecera'
                                },
                                {
                                    text: objI.tX_APELLIDO_PATERNO, 
                                    fontSize: fontSizeCabecera,
                                    colSpan: 3
                                }
                            ],
                            [
                                {
                                    text: 'Apellido Materno', 
                                    style: 'Cabecera'
                                },
                                {
                                    text: objI.tX_APELLIDO_MATERNO, 
                                    fontSize: fontSizeCabecera,
                                    colSpan: 3
                                }
                            ],
                            [
                                {
                                    text: 'Correo Electrónico', 
                                    style: 'Cabecera'
                                },
                                {
                                    text: (this.isVisibleData)?objI.tX_CORREO:'', 
                                    fontSize: fontSizeCabecera,
                                    colSpan: 3
                                }
                            ],
                            [
                                {
                                    text: 'Fecha de Nacimiento', 
                                    style: 'Cabecera'
                                },
                                {
                                    text: (this.isVisibleData && objI.fE_NACIMIENTO!=null)?this.convertFecha(this.convertFechaString(objI.fE_NACIMIENTO)):'', 
                                    fontSize: fontSizeCabecera
                                },
                                {
                                    text: 'Año de Egreso', 
                                    style: 'Cabecera'
                                },
                                {
                                    text: (objI.nU_ANIO_EGRESO!>0)?objI.nU_ANIO_EGRESO:'', 
                                    fontSize: fontSizeCabecera
                                }
                            ],
                            [
                                {
                                    text: 'Turno', 
                                    style: 'Cabecera'
                                },
                                {
                                    text: (objI.tX_TURNO_ASOCIADO!=null)?objI.tX_TURNO_ASOCIADO:'', 
                                    fontSize: fontSizeCabecera
                                },
                                {
                                    text: 'Sexo', 
                                    style: 'Cabecera'
                                },
                                {
                                    text: (objI.tX_SEXO=="M")?"Masculino":"Femenino", 
                                    fontSize: fontSizeCabecera
                                }
                            ],
                            [
                                {
                                    text: 'Dirección', 
                                    style: 'Cabecera'
                                },
                                {
                                    text: (this.isVisibleData)?objI.tX_DIRECCION:'', 
                                    fontSize: fontSizeCabecera,
                                    colSpan: 3
                                }
                            ],
                            [
                                {
                                    text: 'Distrito', 
                                    style: 'Cabecera'
                                },
                                {
                                    text: (this.isVisibleData)?distrito:'', 
                                    fontSize: fontSizeCabecera
                                },
                                {
                                    text: 'Provincia', 
                                    style: 'Cabecera'
                                },
                                {
                                    text: (this.isVisibleData)?provincia:'', 
                                    fontSize: fontSizeCabecera
                                }
                            ],
                            [
                                {
                                    text: 'Departamento', 
                                    style: 'Cabecera'
                                },
                                {
                                    text: (this.isVisibleData)?departamento:'', 
                                    fontSize: fontSizeCabecera
                                },
                                {
                                    text: 'Teléfono/Celular', 
                                    style: 'Cabecera'
                                },
                                {
                                    text: (this.isVisibleData)?objI.tX_TELEFONO:'',
                                    fontSize: fontSizeCabecera
                                }
                            ]
                        ],
                    },
                    //layout: { paddingTop: this.setTopMarginOfCellForVerticalCentering }
                },
                {
                    text: [
                        {text:'DATOS ADICIONALES ', style: 'Cabecera'},
                        {text:'(marcar con “X”)', fontSize:9},
                    ],
                    margin: [0,10,0,0]
                },
                {
                    margin:[0,10,0,0],
                    table:{
                        //widths:['25%','8%','8%','10%','10%','*'],
                        widths:['25%','8%','8%','*'],
                        body:[
                            [
                                {
                                    text: 'Asimilado', 
                                    style: 'Cabecera',
                                    alignment : 'center',
                                    rowSpan: 2
                                },
                                {
                                    text: 'Sí', 
                                    style: 'Cabecera',
                                    alignment : 'center'
                                },
                                {
                                    text: 'No', 
                                    style: 'Cabecera',
                                    alignment : 'center'
                                },
                                /*{
                                    text: 'Externo', 
                                    style: 'Cabecera',
                                    alignment : 'center'
                                },
                                {
                                    text: 'Interno', 
                                    style: 'Cabecera',
                                    alignment : 'center'
                                },*/
                                {
                                    text: 'Procedencia', 
                                    style: 'Cabecera',
                                    alignment : 'center'
                                }
                            ],
                            [
                                {},
                                {
                                    text: (objI.nU_ASIMILADO==1)?'X':'', 
                                    style: 'Cabecera',
                                    alignment : 'center'
                                },
                                {
                                    text: (objI.nU_ASIMILADO==0)?'X':'', 
                                    style: 'Cabecera',
                                    alignment : 'center'
                                },
                                /*{
                                    text: (objI.nU_TIPOASIMILADO==1)?'X':'', 
                                    style: 'Cabecera',
                                    alignment : 'center'
                                },
                                {
                                    text: (objI.nU_TIPOASIMILADO==0)?'X':'',
                                    style: 'Cabecera',
                                    alignment : 'center'
                                },*/
                                {
                                    text: (objI.tX_PROCEDENCIA!=null)?objI.tX_PROCEDENCIA:'', 
                                    style: 'Cabecera',
                                    alignment : 'center'
                                }
                            ],
                        ],
                    }
                },
                /*{
                    text:'Externo: jugador asimilado nuevo que viene de otra Asociación.', 
                    fontSize:9
                },
                {
                    text:'Interno: jugador asimilado de nuestra Asociación que está cambiando de equipo (promoción).', 
                    fontSize:9
                },
                {
                    text:'Procedencia: indicar la Asociación al que perteneció o promoción que jugó el año pasado.', 
                    fontSize:9
                },*/
                {
                    margin: [0, 10, 0, 0],
                    text:'Registro digital', 
                    fontSize:9
                },
                /*{
                    margin: [0, 10, 0, 0],
                    table: {
                        widths: ['*', '*'],
                        body: [
                            [
                                {
                                    columns: [
                                        { width: '*', text: '' },
                                        {
                                            margin: [0, 70, 0, 0],
                                            width: 'auto',
                                            table: {
                                                body: [
                                                    [{
                                                        canvas: [
                                                            {
                                                                type: 'line',
                                                                x1: 0, y1: 0,
                                                                x2: 150, y2: 0,
                                                                lineWidth: 1,
                                                                dash: {length: 2, space: 2}
                                                            }
                                                        ]
                                                    }],
                                                    [{text: 'Firma del Jugador', style: 'Cabecera', alignment: 'center'}]
                                                ]
                                            },
                                            layout: 'noBorders'
                                        },
                                        { width: '*', text: '' },
                                    ]
                                },
                                {
                                    columns: [
                                        { width: '*', text: '' },
                                        {
                                            width: 'auto',
                                            table: {
                                                body: [
                                                    [{
                                                        canvas: [
                                                            {
                                                                type: 'rect',
                                                                x: 6,
                                                                y: 0,
                                                                w: 60,
                                                                h: 60,
                                                                r: 5,
                                                                lineColor: 'black',
                                                            }
                                                        ],
                                                    }],
                                                    [{text: 'Huella', style: 'Cabecera', alignment: 'center'}],
                                                    [{text: '(Índice derecho)', style: 'Cabecera', alignment: 'center'}]
                                                ]
                                            },
                                            layout: 'noBorders'
                                        },
                                        { width: '*', text: '' },
                                    ]
                                }                    
                            ]
                        ]
                    },
                    layout: 'noBorders'
                },*/
            ],
            styles: {
                Cabecera: {
                    fontSize: fontSizeCabecera,
                    bold: true
                },
            }
        };

        return docDefinition;
    }

    generatePdf(docDefinition:any): void {
        const pdfObject = pdfMake.createPdf(docDefinition);
        pdfObject.open();
    }

    getPdfData(objE: Evento, objI: Integrante, txtcabecera: string, foto: string, departamento: string, provincia: string, distrito: string,
        objEq: Equipo, txtdisciplina: string): void {
        //Se obtiene la trama de la imagen en assets que se va a incorporar en el documento
        let ruta="./assets/img/";

        if(variablesGlobales.codsistema==1) ruta+="exasadj/exasadj.jpg";
        if(variablesGlobales.codsistema==2) ruta+="exacemba/exacemba.jpg";

        this.canvas.getBase64Image(ruta)
        .then(base64Img => {
            const logo = base64Img;
            this.generatePdf(this.getDocDefinition(logo, objE, objI, txtcabecera, foto, departamento, provincia, distrito, objEq, txtdisciplina, 'portrait', 'A4'));
        });
    }
}