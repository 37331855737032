import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { AutenticaService } from '../@core/services/AutenticaService';
import { Configuracion } from '../@core/services/config';
import { SolicitudPadronService } from '../@core/services/SolicitudPadronService';
import { variablesGlobales } from '../Util/variableGlobal';

@Component({
  selector: 'app-completar-registro',
  templateUrl: './completar-registro.component.html',
  styleUrls: ['./completar-registro.component.scss']
})
export class CompletarRegistroComponent implements OnInit {

  token: string;
  continuar: number=0;
  fmrLink: number=0;

  constructor(private route: ActivatedRoute,
    private ObjAutenticaService: AutenticaService,
    private ObjSolicitudPadronService: SolicitudPadronService,
    ) { 
    this.token=this.route.snapshot.paramMap.get("token")!;
    this.ObjAutenticaService.ValidarApp()
      .subscribe(
        data => {
          //console.log(data);
          if(data.isSuccess){
            if(data.tx_token.length>0){
              variablesGlobales.token=data.tx_token;
              this.ObjSolicitudPadronService.ValidaToken(this.token)
                .subscribe(
                  data1=>{
                    //console.log(data1);
                    if (data1.estadoToken==1){
                      this.continuar= data1.estadoToken; //Token valido
                    }
                    else{
                      this.fmrLink=1;
                      Swal.fire({
                        icon: 'info',
                        title: 'Oops...?',
                        text: data1.tx_Mensaje,//'Something went wrong!',
                      });     
                    }
                  }
                );
            }
          }
        }
      ); 
  }

  ngOnInit(): void {
    //validar token
    //console.log(this.token);
  }

  /*FrmValidaCodigo!: FormGroup;
  principal:number=1;
  marco_legal:number=0;

  constructor(private _formBuilder: FormBuilder,) { }

  ngOnInit(): void {
    this.FrmValidaCodigo=this._formBuilder.group({
      codigo: [null, Validators.required]
    });
  }

  alertMsg(msg:string){
    Swal.fire({
      html:msg,
      icon:'info'
    })
  }

  ValidarCampos(){
    if (this.FrmValidaCodigo.controls["codigo"].value === null || 
      this.FrmValidaCodigo.controls["codigo"].value.length === 0 || 
      /^\s+$/.test(this.FrmValidaCodigo.controls["codigo"].value)) {
        this.alertMsg('Ingrese código de verificación');
        return false;
    }

    return true;
  }

  fnValidar(){
    if(this.ValidarCampos()){
      this.principal=0;
      this.marco_legal=1;
    }
  }*/

}
