<div *ngIf="partido" fxLayout="column" fxLayoutAlign="center">
    <form [formGroup]="FrmGestPartido">
        <div class="opcion">
            <mat-label class="Titulo">{{txtTitulo}}</mat-label>
        </div>
        <mat-card fxLayout="column">
            <div class="opcion1">
                <mat-label class="titulo">
                    Fecha: {{ObjFecha.nU_SECUENCIA+' ['+getFormatFecha(0,ObjFecha.fE_PROGRAMADO!)+'] | '+txtDisciplina}}
                </mat-label>
            </div>
            <mat-card-content fxLayout="column">
                <div style="margin-top: 18px;margin-bottom: 20px;">
                    <button mat-mini-fab color="btnStyle" class="tooltips_fab" (click)="Retorno()">
                        <mat-icon>arrow_back</mat-icon>
                        <span>Regresar</span>
                    </button>
                    <button mat-mini-fab color="btnStyle" class="tooltips_fab" style="margin-left: 18px;" *ngIf="isBotonAgregar" (click)="fnAgregarPartido(0)">
                        <mat-icon>add</mat-icon>
                        <span>Añadir</span>
                    </button>
                    <button mat-mini-fab color="btnStyle" class="tooltips_fab" style="margin-left: 18px;" *ngIf="isBotonAgregar" (click)="fnListarPartido()">
                        <mat-icon>autorenew</mat-icon>
                        <span>Actualizar lista</span>
                    </button>
                </div>
                <h3 class="SubTitulo">Relación de Partidos</h3>
                <div class="example-container">
                    <table mat-table [dataSource]="PartidoTableDataSource">
                        <ng-container matColumnDef="nU_ID_PARTIDO">
                            <th mat-header-cell *matHeaderCellDef class="priority-1"></th>
                            <td mat-cell *matCellDef="let element" class="priority-1">
                                <button mat-icon-button class="tooltips_cls" [matMenuTriggerFor]="menu">
                                    <mat-icon>menu</mat-icon>
                                </button>
                                <mat-menu #menu="matMenu">
                                    <button mat-menu-item 
                                        *ngIf="isBotonAgregar && (element.nU_ID_ESTADO!=0)"
                                        (click)="fnImprimirPlanillaJuego(element)">
                                            <mat-icon fontSet="material-symbols-outlined">print</mat-icon>
                                            <span>Planilla de Deportistas</span>
                                    </button>
                                    <button mat-menu-item 
                                        *ngIf="isBotonAgregar && 
                                            element.fE_INICIO==null &&
                                            (element.nU_ID_ESTADO==1 || element.nU_ID_ESTADO==16)" 
                                        (click)="fnAgregarPartido(element.nU_ID_PARTIDO)">
                                            <mat-icon fontSet="material-symbols-outlined">edit</mat-icon>
                                            <span>Editar</span>
                                    </button>
                                    <button mat-menu-item (click)="fnGestPlanilla(element)">
                                        <mat-icon fontSet="material-icons-outlined">groups</mat-icon>
                                        <span>Deportistas</span>
                                    </button>
                                    <button mat-menu-item (click)="fnGestPartidoSet(element)" *ngIf="coddisciplina==2 || coddisciplina==3">
                                        <mat-icon fontSet="material-symbols-outlined">scoreboard</mat-icon>
                                        <span>Resultados</span>
                                    </button>
                                    <button mat-menu-item 
                                        *ngIf="isBotonAgregar && 
                                            element.fE_INICIO==null &&
                                            (element.nU_ID_ESTADO==1 || element.nU_ID_ESTADO==3 || element.nU_ID_ESTADO==16)" 
                                        (click)="fnIniciarPartido(element.nU_ID_PARTIDO)">
                                            <mat-icon fontSet="material-symbols-outlined">play_circle</mat-icon>
                                            <span>Iniciar</span>
                                    </button>
                                    <button mat-menu-item 
                                        *ngIf="isBotonAgregar && 
                                            element.nU_ID_ESTADO==15" 
                                        (click)="fnFinalizarPartido(element.nU_ID_PARTIDO)">
                                            <mat-icon fontSet="material-symbols-outlined">check_small</mat-icon>
                                            <span>Finalizar</span>
                                    </button>
                                    <button mat-menu-item 
                                        *ngIf="isBotonAgregar && 
                                            element.nU_ID_ESTADO==17" 
                                        (click)="fnConcluirRegistro(element.nU_ID_PARTIDO)">
                                            <mat-icon fontSet="material-symbols-outlined">data_check</mat-icon>
                                            <span>Concluir Registro</span>
                                    </button>
                                    <button mat-menu-item
                                        *ngIf="element.fE_INICIO!=null"
                                        (click)="fnGestNota(element)">
                                            <mat-icon fontSet="material-icons-outlined">description</mat-icon>
                                            <span>Notas del partido</span>
                                    </button>
                                    <button mat-menu-item
                                        *ngIf="isBotonAgregar &&
                                            (element.nU_ID_ESTADO==1 || element.nU_ID_ESTADO==3 || element.nU_ID_ESTADO==16)" 
                                        (click)="fnSuspenderPartido(element.nU_ID_PARTIDO,1)">
                                            <mat-icon fontSet="material-icons-outlined">do_not_disturb_on</mat-icon>
                                            <span>Suspender</span>
                                    </button>
                                    <button mat-menu-item 
                                        *ngIf="isBotonAgregar &&
                                            element.nU_ID_ESTADO==18"
                                        (click)="fnRegReclamo(element)">
                                        <mat-icon fontSet="material-icons-outlined">campaign</mat-icon>
                                            <span>Registrar reclamo</span>
                                    </button>
                                    <button mat-menu-item 
                                        *ngIf="isBotonAgregar &&
                                            element.fE_INICIO==null" 
                                        (click)="fnSuspenderPartido(element.nU_ID_PARTIDO,2)">
                                            <mat-icon fontSet="material-icons-outlined">delete</mat-icon>
                                            <span>Quitar</span>
                                    </button> 
                                </mat-menu>                       
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="nU_SECUENCIA">
                            <th mat-header-cell *matHeaderCellDef class="priority-2">Nro.</th>
                            <td mat-cell *matCellDef="let element" class="priority-2">{{element.nU_SECUENCIA}}</td>
                        </ng-container>
                        <ng-container matColumnDef="tX_SERIE">
                            <th mat-header-cell *matHeaderCellDef class="priority-3">Serie</th>
                            <td mat-cell *matCellDef="let element" class="priority-3">{{element.tX_SERIE}}</td>
                        </ng-container>
                        <ng-container matColumnDef="fE_PROGRAMADO">
                            <th mat-header-cell *matHeaderCellDef class="priority-4">Estimado</th>
                            <td mat-cell *matCellDef="let element" class="priority-4">{{getFormatFecha(1,element.fE_PROGRAMADO)}}</td>
                        </ng-container>
                        <ng-container matColumnDef="nU_ID_EQUIPO_LOCAL">
                            <th mat-header-cell *matHeaderCellDef class="priority-5">Partidos</th>
                            <td mat-cell *matCellDef="let element" class="priority-5">{{element.tX_PARTIDO}}</td>
                        </ng-container>
                        <ng-container matColumnDef="fE_INICIO">
                            <th mat-header-cell *matHeaderCellDef class="priority-6">Inicio</th>
                            <td mat-cell *matCellDef="let element" class="priority-6">{{(element.fE_INICIO!=null)?getFormatFecha(1,element.fE_INICIO):'—'}}</td>
                        </ng-container> 
                        <ng-container matColumnDef="tX_RESULTADO">
                            <th mat-header-cell *matHeaderCellDef class="priority-7">Resultado</th>
                            <td mat-cell *matCellDef="let element" class="priority-7">
                                {{(element.tX_RESULTADO!=null && element.tX_RESULTADO!='-')?element.tX_RESULTADO:'—'}}
                            </td>
                        </ng-container> 
                        <ng-container matColumnDef="nU_ID_EQUIPO_GANADOR">
                            <th mat-header-cell *matHeaderCellDef class="priority-8">Ganador</th>
                            <td mat-cell *matCellDef="let element" class="priority-8">{{(element.nU_ID_EQUIPO_GANADOR!=null)?element.tX_GANADOR:'—'}}</td>
                        </ng-container> 
                        <ng-container matColumnDef="nU_ID_ESTADO">
                            <th mat-header-cell *matHeaderCellDef class="priority-9">Estado</th>
                            <td mat-cell *matCellDef="let element" class="priority-9">{{element.tX_ESTADO}}</td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                </div> 
                <mat-paginator 
                    [pageIndex]="pageIndex"
                    [pageSize]="pageSize"
                    [pageSizeOptions]="pageSizeOptions" 
                    (page)="handleEvent($event)"
                    showFirstLastButtons></mat-paginator>
            </mat-card-content>
        </mat-card> 
    </form>      
</div>
<div *ngIf="integrante_partido">
    <app-gestiona-planilla-juego
        [ObjEvento]="ObjEvento"  
        [ObjFecha]="ObjFecha"   
        [ObjPartido]="ObjPartido"  
        [coddisciplina]="coddisciplina" 
        [txtDisciplina]="txtDisciplina"  
        (salida)="fnRetornoGestPlanilla($event)"
        >
    </app-gestiona-planilla-juego>
</div>
<div *ngIf="partido_set">
    <app-gestiona-partido-set
        [ObjEvento]="ObjEvento"  
        [ObjFecha]="ObjFecha"   
        [ObjPartido]="ObjPartido"  
        [coddisciplina]="coddisciplina" 
        [txtDisciplina]="txtDisciplina"  
        (salida)="fnRetornoGestPartidoSet($event)"
        >
    </app-gestiona-partido-set>
</div>
<div *ngIf="nota">
    <app-gestiona-nota-partido
        [ObjEvento]="ObjEvento"  
        [ObjFecha]="ObjFecha"   
        [ObjPartido]="ObjPartido"  
        [coddisciplina]="coddisciplina" 
        [txtDisciplina]="txtDisciplina"  
        (salida)="fnRetornoGestNota($event)"
        >
    </app-gestiona-nota-partido>
</div>

