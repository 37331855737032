<div *ngIf="principal" fxLayout="column" fxLayoutAlign="center">
    <form [formGroup]="FrmGestDocumento">
        <div class="opcion">
            <mat-label class="Titulo">Gestiona Documento</mat-label>
        </div>
        <mat-card fxLayout="column">
            <!--<mat-card-content fxLayout="column">
                <mat-card-content fxLayout="row">
                    <fieldset class="borderFieldset">
                        <mat-form-field class="Fila1">
                            <input matInput aria-label="string" placeholder="Expediente" formControlName="txtbuscar">
                        </mat-form-field>
                        <button mat-icon-button class="tooltips_cls" (click)="Buscar()">
                            <mat-icon>search</mat-icon>
                            <span>Buscar</span>
                        </button>
                    </fieldset>            
                </mat-card-content>
            </mat-card-content>-->
            <mat-card-content fxLayout="column">
                <h3 class="SubTitulo">Lista de Documentos</h3>
                <table mat-table [dataSource]="DocumentoTableDataSource">
                    <ng-container matColumnDef="nU_ID_DOCUMENTO">
                        <th mat-header-cell *matHeaderCellDef class="priority-1"></th>
                        <td mat-cell *matCellDef="let element" class="priority-1">
                            <button mat-icon-button class="tooltips_cls" [matMenuTriggerFor]="menu">
                                <mat-icon>menu</mat-icon>
                            </button>
                            <mat-menu #menu="matMenu">
                                <button mat-menu-item *ngIf="element.nU_ID_TIPODOCUMENTO==19" (click)="fnDetalle(element.nU_ID_DOCUMENTO,0)">
                                    <mat-icon fontSet="material-icons-outlined">visibility</mat-icon>
                                    <span>Ver</span>
                                </button>
                               <!--<button mat-menu-item (click)="fnRecibir(element)">
                                    <mat-icon fontSet="material-icons-outlined">receipt</mat-icon>
                                    <span>Recibir</span>
                                </button>-->
                                <!-- <button mat-menu-item *ngIf="element.nU_ID_TIPODOCUMENTO==19" (click)="fnAtender(element.nU_ID_DOCUMENTO)"> -->
                                <button mat-menu-item *ngIf="element.nU_ID_TIPODOCUMENTO==19" (click)="fnDetalle(element.nU_ID_DOCUMENTO,1)">
                                    <mat-icon fontSet="material-icons-outlined">fact_check</mat-icon>
                                    <span>Atender</span>
                                </button>
                            </mat-menu>                            
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="fE_EMISION">
                        <th mat-header-cell *matHeaderCellDef class="priority-2">Fecha Emisión</th>
                        <td mat-cell *matCellDef="let element" class="priority-2">{{convertFormatDate(element.fE_EMISION)}}</td>
                    </ng-container>
                    <ng-container matColumnDef="tX_SIGLA">
                        <th mat-header-cell *matHeaderCellDef class="priority-3">Documento</th>
                        <td mat-cell *matCellDef="let element" class="priority-3">{{element.tX_SIGLA}}</td>
                    </ng-container>
                    <ng-container matColumnDef="tX_DESCRIPCION">
                        <th mat-header-cell *matHeaderCellDef class="priority-4">Asunto</th>
                        <td mat-cell *matCellDef="let element" class="priority-4">{{element.tX_DESCRIPCION}}</td>
                    </ng-container>
                    <ng-container matColumnDef="tX_NOTA">
                        <th mat-header-cell *matHeaderCellDef class="priority-5">Nota</th>
                        <td mat-cell *matCellDef="let element" class="priority-5">{{element.tX_NOTA}}</td>
                    </ng-container>
                    <ng-container matColumnDef="tX_ACCION">
                        <th mat-header-cell *matHeaderCellDef class="priority-6">Acción</th>
                        <td mat-cell *matCellDef="let element" class="priority-6">{{element.tX_ACCION}}</td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>  
            </mat-card-content>
        </mat-card> 
    </form>      
</div>
<mat-paginator id="paginadorDocumento" class="stylePaginator" [pageSizeOptions]="[10, 20, 50]" showFirstLastButtons></mat-paginator>
<div *ngIf="detalle">
    <app-detalle-documento
        [codigo]="codigo"       
        [tipo]="tipo"
        (salida)="fnRetornoDetalle($event)"
        >
    </app-detalle-documento>
</div>
