import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { Component, HostListener, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Equipo } from 'src/app/@core/models/Equipo';
import { Socio } from 'src/app/@core/models/Socio';
import { AutenticaService } from 'src/app/@core/services/AutenticaService';
import { EquipoService } from 'src/app/@core/services/EquipoService';
import { SocioService } from 'src/app/@core/services/SocioService';
import { variablesGlobales } from 'src/app/Util/variableGlobal';
import { map, startWith } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { Entidad } from 'src/app/@core/models/Entidad';
import { EntidadService } from 'src/app/@core/services/EntidadService';
import { MatRadioChange } from '@angular/material/radio';
import { Integrante } from 'src/app/@core/models/Integrante';
import { IntegranteService } from 'src/app/@core/services/IntegranteService';
import { RegistrarEntidadComponent } from '../../../../controles/registrar-entidad/registrar-entidad.component';
import { Functions } from 'src/app/@core/helpers/Functions';
import { DocumentoService } from 'src/app/@core/services/DocumentoService';
import { BuscarDocumentoComponent } from 'src/app/menu/controles/buscar-documento/buscar-documento.component';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { IntegranteRequisito } from 'src/app/@core/models/IntegranteRequisito';
import { Archivo } from 'src/app/@core/models/Archivo';
import { MatTableDataSource } from '@angular/material/table';
import { DialogAdjuntarComponent } from 'src/app/completar-registro/dialog-adjuntar/dialog-adjuntar.component';
import { ArchivoService } from 'src/app/@core/services/ArchivoService';
import { IntegranteRequisitoService } from 'src/app/@core/services/IntegranteRequisitoService';
import { BaseService } from 'src/app/@core/services/BaseService';
import { Base } from 'src/app/@core/models/Base';

export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};

@Component({
  selector: 'app-registrar-integrante',
  templateUrl: './registrar-integrante.component.html',
  styleUrls: ['./registrar-integrante.component.scss'],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }
  ]
})
export class RegistrarIntegranteComponent implements OnInit {

  FrmRegistrarIntegrante!: FormGroup;
  codigo:number=0;
  opc!:string;
  ObjEquipo!: Equipo;
  ListaIntegrante:Integrante[]=[];
  opcDetalle:number=0;
  coddisciplina:number=0;
  isVisibleSocio:boolean=false;
  isVisibleEntidad:boolean=false;
  isVisibleProcedencia:boolean=false;
  Socio: Socio[]=[];
  filteredSocio: any;
  Entidad: Entidad[]=[];
  filteredEntidad: any;
  Equipo: Equipo[]=[];
  filteredEquipo: any;
  asimilado: number=0;
  tipoasimilado: number=-1;
  ObjIntegrante!: Integrante;
  txtTitulo!: string;
  isVisibleGuardar: boolean=true;
  admDelegado: number=0;
  admDeportista: number=0;
  isVisibleObjeto: number=0;
  codTemp: number=0;
  ObjIntegranteRequisito!: IntegranteRequisito;
  estado: number=0;
  isVisibleEquipo: boolean=false;
  isVisi: boolean=false;

  //coddisciplina:number=0;
  Base: Base[]=[];
  filteredBase: any;
  ListaEquipo:Equipo[]=[];
  //codigo:number=0;
  //ObjEquipo!: Equipo;
  isVisibleData: number=0;

  Archivo: Archivo[]=[];
  ArchivoQuitar: Archivo[]=[];
  displayedColumns: string[] = ['nU_ID_ARCHIVO','tX_NOMBRE','tX_REQUISITO','tX_DESCRIPCION'];
  dataSource = this.Archivo;

  ArchivoTable: Archivo[]=[];
  ArchivoTableDataSource = new MatTableDataSource(this.ArchivoTable);
  
  @ViewChild('autosize') autosize!: CdkTextareaAutosize;

  constructor(private _formBuilder: FormBuilder,
              private ObjAutenticaService: AutenticaService,
              private ObjSocioService: SocioService,
              private ObjEntidadService: EntidadService,
              private ObjEquipoService: EquipoService,
              private ObjIntegranteService: IntegranteService,
              private ObjDocumentoService: DocumentoService,
              private ObjArchivoService: ArchivoService,
              private ObjIntegranteRequisitoService: IntegranteRequisitoService,
              private ObjFunctions: Functions,
              private objBaseService: BaseService,
              public dialog: MatDialog,
              private dialogRef: MatDialogRef<RegistrarIntegranteComponent>,
              @Inject(MAT_DIALOG_DATA) data: any,
              ) {
                this.ObjEquipo=data.objEquipo;
                this.ListaIntegrante=data.listaIntegrante;
                this.opcDetalle=data.opcdetalle;
                this.coddisciplina=data.coddisciplina;
                this.codigo=parseInt(data.codIntegrante);
                this.opc=data.op;
              }

  ngOnInit(): void {
    this.FrmRegistrarIntegrante=this._formBuilder.group({
      tipo_deportista:[null, Validators.compose([Validators.required])],
      codsocio:[null, Validators.compose([Validators.required])],
      codentidad:[null, Validators.compose([Validators.required])],
      rdoParticipacion:[null, Validators.compose([Validators.required])],
      codequipo_procedencia:[null, Validators.compose([Validators.required])],
      tipo_delegado:[null, Validators.compose([Validators.required])],
      FecInicio:[null, Validators.compose([Validators.required])],
      coddocumento_ingreso:[null],
      txtdocumento_ingreso:[null],
      FecTermino:[null, Validators.compose([Validators.required])],
      coddocumento_termino:[null],
      txtdocumento_termino:[null],
      cboAsimilado:[null, Validators.compose([Validators.required])],
      codequipo:[null, Validators.compose([Validators.required])],
    }); 

    this.admDelegado=variablesGlobales.usuarioRol.admDelegado;
    this.admDeportista=variablesGlobales.usuarioRol.admDeportista;

    this.isVisibleData=variablesGlobales.usuarioRol.isVisibleData;

    if(this.opc==='MOD') this.Obtener();
    else{
      if(this.opcDetalle===1){
        this.txtTitulo="Registrar Delegado";
      }
      else{
        this.txtTitulo="Registrar Deportista";
        this.FrmRegistrarIntegrante.controls["tipo_deportista"].setValue("2");
        this.fnMostrarCampos(this.FrmRegistrarIntegrante.controls["tipo_deportista"].value);
      }
    } 

    if(this.opcDetalle===1){
      //this.isVisibleSocio=true;
      this.isVisibleEntidad=true;
    }
  }

  @HostListener('window:keyup.esc') onKeyUp() {
    this.Close();
  }

  fnObtenerSocio(idsocio:number){
    this.ObjSocioService.Obtener(idsocio,2).pipe()
      .subscribe(data => {
        if (data.isSuccess){
          this.FrmRegistrarIntegrante.controls["codsocio"].setValue(data.socio);
        }
      });
  }

  fnObtenerEntidad(identidad:number){
    this.ObjEntidadService.Obtener(identidad,0).pipe()
      .subscribe(data => {
        if (data.isSuccess){
          this.FrmRegistrarIntegrante.controls["codentidad"].setValue(data.entidad);
        }
      });
  }

  fnObtenerEquipo(idequipo:number){
    this.ObjEquipoService.Obtener(idequipo).pipe()
      .subscribe(data => {
        //console.log(data);
        if (data.isSuccess){
          this.FrmRegistrarIntegrante.controls["codequipo"].setValue(data.equipo);
        }
      });
  }

  fnObtenerDocumento(opc:number, iddocumento:number){
    this.ObjDocumentoService.Obtener(iddocumento).pipe()
      .subscribe(data => {
        if (data.isSuccess){
          if(opc==1){
            this.FrmRegistrarIntegrante.controls["coddocumento_ingreso"].setValue(data.documento.nU_ID_DOCUMENTO);
            this.FrmRegistrarIntegrante.controls["txtdocumento_ingreso"].setValue(data.documento.tX_SIGLA);
          } 
          else{
            this.FrmRegistrarIntegrante.controls["coddocumento_termino"].setValue(data.documento.nU_ID_DOCUMENTO);
            this.FrmRegistrarIntegrante.controls["txtdocumento_termino"].setValue(data.documento.tX_SIGLA);
          } 
        }
      });
  }

  Obtener(){ 
    this.ObjAutenticaService.ValidarApp()
      .subscribe(
        data => { 
          if(data.isSuccess){
            if(data.tx_token.length>0){
              variablesGlobales.token=data.tx_token;

              this.ObjIntegranteService.Obtener(this.codigo).pipe()
                .subscribe(
                  data => {
                    //console.log(data);
                    if (data.isSuccess){
                      this.ObjIntegrante=data.integrante;

                      if(this.opcDetalle===1){
                        if(this.admDelegado){
                          this.isVisibleObjeto=1;
                        }

                        if(this.isVisibleEntidad){
                          this.fnObtenerEntidad(this.ObjIntegrante.nU_ID_ENTIDAD!);
                        }

                        this.FrmRegistrarIntegrante.controls["tipo_delegado"].setValue(this.ObjIntegrante.nU_ID_CARGO?.toString());
                        this.FrmRegistrarIntegrante.controls["tipo_delegado"].disable();
                        this.FrmRegistrarIntegrante.controls["FecInicio"].setValue(this.convertFechaString(this.ObjIntegrante.fE_INICIO));
                        this.fnObtenerDocumento(1,this.ObjIntegrante.nU_ID_DOCUMENTO_INICIO!);

                        if(this.isVisibleObjeto && this.ObjIntegrante.fE_TERMINO === null){
                          this.txtTitulo="Finaliza Cargo";
                        }
                        else{
                          this.txtTitulo="Detalle de Cargo";
                          this.isVisibleGuardar=false;
                        }

                        if(this.ObjIntegrante.fE_TERMINO !== null){
                          this.FrmRegistrarIntegrante.controls["FecTermino"].setValue(this.convertFechaString(this.ObjIntegrante.fE_TERMINO));
                        }
  
                        if(this.ObjIntegrante.nU_ID_DOCUMENTO_TERMINO!>0){
                          this.fnObtenerDocumento(2,this.ObjIntegrante.nU_ID_DOCUMENTO_TERMINO!);
                        }
                      }
                      else{
                        if(this.admDeportista){
                          this.isVisibleObjeto=1;
                        }

                        this.txtTitulo="Editar Deportista";
                        //this.FrmRegistrarIntegrante.controls["tipo_deportista"].setValue((this.ObjIntegrante.nU_ID_ASOCIADO!>0)?"1":"2");
                        this.FrmRegistrarIntegrante.controls["tipo_deportista"].setValue("2");
                        this.FrmRegistrarIntegrante.controls["tipo_deportista"].disable();
                        this.fnMostrarCampos(this.FrmRegistrarIntegrante.controls["tipo_deportista"].value);
                        //this.FrmRegistrarIntegrante.controls["rdoParticipacion"].setValue((this.ObjIntegrante.tX_PROCEDENCIA!=null)?"1":"0");
                      
                        if(this.isVisibleEntidad){
                          this.fnObtenerEntidad(this.ObjIntegrante.nU_ID_ENTIDAD!);
                        }

                        /*if(this.ObjIntegrante.tX_PROCEDENCIA!=null){
                          this.isVisibleProcedencia=true;
                          //this.FrmRegistrarIntegrante.controls["codequipo_procedencia"].setValue("");
                        }*/

                        this.FrmRegistrarIntegrante.controls["cboAsimilado"].setValue(this.ObjIntegrante.nU_TIPOASIMILADO?.toString());
                        if(this.ObjIntegrante.nU_TIPOASIMILADO===1){
                          this.isVisibleEquipo=true;
                          this.fnObtenerEquipo(this.ObjIntegrante.nU_ID_EQUIPO_PROCEDENCIA!);
                        }
                      }

                      /*if(this.isVisibleSocio){
                        this.fnObtenerSocio(this.ObjIntegrante.nU_ID_ASOCIADO!);
                      }*/

                      for(let item of data.listaarchivosadjuntos){
                        let model={'nU_ID_ARCHIVO':item.nU_ID_ARCHIVO,
                                    'tX_NOMBRE_ARCHIVO':item.nU_ID_ARCHIVO+'.'+item.tX_EXTENSION,
                                    'nU_ID_REQUISITO':item.nU_ID_REQUISITO,
                                    'tX_REQUISITO':item.tX_REQUISITO,
                                    'tX_DESCRIPCION':item.tX_DESCRIPCION,
                                    'nU_ID_TABLA_ARCHIVO': item.nU_ID_INTEGRANTEREQUISITO,                        
                                    'tX_EXTENSION':item.tX_EXTENSION};
  
                        this.Archivo.push(model);                      
                      }
  
                      this.ArchivoTableDataSource.data=this.Archivo;
                    }
                  },
                  error => {
                    //console.log(error);
                    Swal.fire({
                      title:'El servicio solicitado no se encuentra disponible',
                      icon: 'error',
                    });
                  }
                );  
            }
            else{
                //console.log("Autenticación inválida: "+data.tx_Mensaje);
            }
          }
        },
        error => {
          //console.log(error);
        }   
      );                              
  }

  fnMostrarCampos(opc: string){
    if(opc=="1"){
      this.isVisibleSocio=true;
      this.isVisibleEntidad=false;
      this.FrmRegistrarIntegrante.controls["codentidad"].setValue("");
    }
    else{
      this.isVisibleSocio=false;
      this.isVisibleEntidad=true;
      this.FrmRegistrarIntegrante.controls["codsocio"].setValue("");
    }

    this.FrmRegistrarIntegrante.controls["rdoParticipacion"].setValue(null);
    this.isVisibleProcedencia=false;
    this.FrmRegistrarIntegrante.controls["codequipo_procedencia"].setValue("");
  }

  fnListarSocio(){   
    this.ObjAutenticaService.ValidarApp()
      .subscribe(
        data => { 
          if(data.isSuccess){
            if(data.tx_token.length>0){
              variablesGlobales.token=data.tx_token;

              this.ObjSocioService.Listar(1).pipe()
                .subscribe(data => {
                  if(data.isSuccess){
                    this.Socio=data.data;
                    this.filteredSocio=this.FrmRegistrarIntegrante.controls["codsocio"].valueChanges
                        .pipe(
                          startWith(''),
                          map(value => typeof value === 'string' ? value : value.tX_NUMERO+' | '+value.tX_NOMBRE+' '+value.tX_APELLIDO_PATERNO+' '+value.tX_APELLIDO_MATERNO),            
                          map(nombre => nombre ? this._filterSocio(nombre) : this.Socio.slice()
                        )
                    );
                  }
                });
            }
            else{
                //console.log("Autenticación inválida: "+data.tx_Mensaje);
            }
          }
        },
        error => {
          //console.log(error);
        }   
      ); 
  }

  displayFnSocio(socio?: Socio): string{
    return socio ? socio.tX_NUMERO+' | '+socio.tX_NOMBRE+' '+socio.tX_APELLIDO_PATERNO+' '+socio.tX_APELLIDO_MATERNO: "";
  }

  private _filterSocio(nombre: string): Socio[] {
    const filterValue = nombre.toLowerCase();
    return this.Socio.filter(option => (option.tX_NUMERO+' | '+
                            option.tX_NOMBRE!.toLowerCase()+' '+
                            option.tX_APELLIDO_PATERNO!.toLowerCase()+' '+
                            option.tX_APELLIDO_MATERNO!.toLowerCase()).includes(filterValue));
  }

  fnListarEntidad(){   
    this.ObjAutenticaService.ValidarApp()
      .subscribe(
        data => { 
          if(data.isSuccess){
            if(data.tx_token.length>0){
              variablesGlobales.token=data.tx_token;

              this.ObjEntidadService.Listar(1).pipe()
                .subscribe(data => {
                  if(data.isSuccess){
                    this.Entidad=data.entidad;
                    this.filteredEntidad=this.FrmRegistrarIntegrante.controls["codentidad"].valueChanges
                        .pipe(
                          startWith(''),
                          map(value => typeof value === 'string' ? value : value.tX_NUMERO+' | '+value.tX_NOMBRE+' '+value.tX_APELLIDO_PATERNO+' '+value.tX_APELLIDO_MATERNO),            
                          map(nombre => nombre ? this._filterEntidad(nombre) : this.Entidad.slice()
                        )
                    );
                  }
                });
            }
            else{
                //console.log("Autenticación inválida: "+data.tx_Mensaje);
            }
          }
        },
        error => {
          //console.log(error);
        }   
      ); 
  }

  displayFnEntidad(entidad?: Entidad): string{
    return entidad ? entidad.tX_NUMERO+' | '+entidad.tX_NOMBRE+' '+entidad.tX_APELLIDO_PATERNO+' '+entidad.tX_APELLIDO_MATERNO: "";
  }

  private _filterEntidad(nombre: string): Entidad[] {
    const filterValue = nombre.toLowerCase();
    return this.Entidad.filter(option => (option.tX_NUMERO+' | '+
                            option.tX_NOMBRE!.toLowerCase()+' '+
                            option.tX_APELLIDO_PATERNO!.toLowerCase()+' '+
                            option.tX_APELLIDO_MATERNO!.toLowerCase()).includes(filterValue));
  }

  fnMostrarProcedencia(event: MatRadioChange){
    if(event.value=="1"){
      this.isVisibleProcedencia=true;
    }
    else{
      this.isVisibleProcedencia=false;
      this.FrmRegistrarIntegrante.controls["codequipo_procedencia"].setValue("");
    }
  }

  
  fnListarEquipo(){   
    this.ObjAutenticaService.ValidarApp()
      .subscribe(
        data => { 
          if(data.isSuccess){
            if(data.tx_token.length>0){
              variablesGlobales.token=data.tx_token;

              this.ObjEquipoService.Listar(1,variablesGlobales.codevento,this.coddisciplina).pipe()
                .subscribe(data => {
                  if(data.isSuccess){
                    this.Equipo=data.equipo;
                    this.filteredEquipo=this.FrmRegistrarIntegrante.controls["codequipo_procedencia"].valueChanges
                        .pipe(
                          startWith(''),
                          map(value => typeof value === 'string' ? value : value.nU_ANIO_PROMOCION+' '+value.tX_TURNO+' - '+value.tX_DENOMINACION),            
                          map(descripcion => descripcion ? this._filterEquipo(descripcion) : this.Equipo.slice()
                        )
                    );
                  }
                });
            }
            else{
                //console.log("Autenticación inválida: "+data.tx_Mensaje);
            }
          }
        },
        error => {
          //console.log(error);
        }   
      ); 
  }

  displayFnEquipo(equipo?: Equipo): string{
    return equipo ? equipo.nU_ANIO_PROMOCION+' '+equipo.tX_TURNO+' - '+equipo.tX_DENOMINACION: "";
  }

  private _filterEquipo(descripcion: string): Equipo[] {
    const filterValue = descripcion.toLowerCase();
    return this.Equipo.filter(option => (option.nU_ANIO_PROMOCION+' '+
                              option.tX_TURNO!.toLowerCase()+' - '+
                              option.tX_DENOMINACION!.toLowerCase()).includes(filterValue));
  }

  alertMsg(msg:string){
    Swal.fire({
      html:msg,
      icon:'info'
    })
  }

  ValidarCampos(){
    var band=0;

    if(this.opcDetalle===1){
      /*if(this.FrmRegistrarIntegrante.controls["codsocio"].value!==null &&
       this.FrmRegistrarIntegrante.controls["codsocio"].value.nU_ID_ASOCIADO!==undefined){
        band=1;
      }*/
      if(this.FrmRegistrarIntegrante.controls["codentidad"].value!==null &&
       this.FrmRegistrarIntegrante.controls["codentidad"].value.nU_ID_ENTIDAD!==undefined){
        band=1;
      }
      if(band===0){
        this.alertMsg('Seleccione Persona');
        return false;
      }
      else{
        band=0;
      }

      if (this.FrmRegistrarIntegrante.controls["tipo_delegado"].value === null || 
        this.FrmRegistrarIntegrante.controls["tipo_delegado"].value.length === 0) {
          this.alertMsg('Seleccione el Tipo de Delegado');
          return false;
      }

      if (this.FrmRegistrarIntegrante.controls["FecInicio"].value === null || 
        this.FrmRegistrarIntegrante.controls["FecInicio"].value.length === 0 || 
        /^\s+$/.test(this.FrmRegistrarIntegrante.controls["FecInicio"].value)) {
          this.alertMsg('Ingrese Fecha de Inicio o establezca el formato correcto');
          return false;
      }

      if(this.FrmRegistrarIntegrante.controls["txtdocumento_ingreso"].value === null ||
        this.FrmRegistrarIntegrante.controls["txtdocumento_ingreso"].value.length === 0 || 
        /^\s+$/.test(this.FrmRegistrarIntegrante.controls["txtdocumento_ingreso"].value)){
          this.alertMsg('Ingrese Documento Designatorio');
          return false;
      }

      if(this.opc==='MOD'){
        /*if (this.FrmRegistrarIntegrante.controls["FecTermino"].value === null || 
        this.FrmRegistrarIntegrante.controls["FecTermino"].value.length === 0 || 
        /^\s+$/.test(this.FrmRegistrarIntegrante.controls["FecTermino"].value)) {
          this.alertMsg('Ingrese Fecha de Término o establezca el formato correcto');
          return false;
        }

        var valfechaini = new Date(this.FrmRegistrarIntegrante.controls["FecInicio"].value);
        var valfechafin = new Date(this.FrmRegistrarIntegrante.controls["FecTermino"].value);
        if (!this.ObjFunctions.ValidateDateRange(valfechaini,valfechafin)) {
          this.alertMsg('Fecha de Término debe ser mayor que Fecha de Inicio.');
          return false;
        }

        if(this.FrmRegistrarIntegrante.controls["txtdocumento_termino"].value === null ||
          this.FrmRegistrarIntegrante.controls["txtdocumento_termino"].value.length === 0 || 
          /^\s+$/.test(this.FrmRegistrarIntegrante.controls["txtdocumento_termino"].value)){
            this.alertMsg('Ingrese Documento de Término');
            return false;
        }*/

        if (this.FrmRegistrarIntegrante.controls["FecTermino"].value !== null) {

          var valfechaini = new Date(this.FrmRegistrarIntegrante.controls["FecInicio"].value);
          var valfechafin = new Date(this.FrmRegistrarIntegrante.controls["FecTermino"].value);
          if (!this.ObjFunctions.ValidateDateRange(valfechaini,valfechafin)) {
            this.alertMsg('Fecha de Término debe ser mayor que Fecha de Inicio.');
            return false;
          }
  
          if(this.FrmRegistrarIntegrante.controls["txtdocumento_termino"].value === null ||
            this.FrmRegistrarIntegrante.controls["txtdocumento_termino"].value.length === 0 || 
            /^\s+$/.test(this.FrmRegistrarIntegrante.controls["txtdocumento_termino"].value)){
              this.alertMsg('Ingrese Documento de Término');
              return false;
          }
        }
  
        if (this.FrmRegistrarIntegrante.controls["txtdocumento_termino"].value !== null && 
         this.FrmRegistrarIntegrante.controls["txtdocumento_termino"].value.trim().length > 0) {
          
          if (this.FrmRegistrarIntegrante.controls["FecTermino"].value === null || 
           this.FrmRegistrarIntegrante.controls["FecTermino"].value.length === 0 || 
            /^\s+$/.test(this.FrmRegistrarIntegrante.controls["FecTermino"].value)) {
              this.alertMsg('Ingrese Fecha de Término o establezca el formato correcto');
              return false;
            }
        }
      }
    }
    else{
      if (this.FrmRegistrarIntegrante.controls["tipo_deportista"].value === null || 
        this.FrmRegistrarIntegrante.controls["tipo_deportista"].value.length === 0) {
          this.alertMsg('Seleccione el Tipo de Deportista');
          return false;
      }
      else{
        if(this.FrmRegistrarIntegrante.controls["tipo_deportista"].value==="1"){
          if(this.FrmRegistrarIntegrante.controls["codsocio"].value!==null &&
          this.FrmRegistrarIntegrante.controls["codsocio"].value.nU_ID_ASOCIADO!==undefined){
            band=1;
          }
          if(band===0){
            this.alertMsg('Seleccione Socio');
            return false;
          }
          else{
            band=0;
          }
        }
        else{
          if(this.FrmRegistrarIntegrante.controls["codentidad"].value!==null &&
          this.FrmRegistrarIntegrante.controls["codentidad"].value.nU_ID_ENTIDAD!==undefined){
            band=1;
          }
          if(band===0){
            this.alertMsg('Seleccione Persona');
            return false;
          }
          else{
            band=0;
          }
        }
      }

      /*if (this.FrmRegistrarIntegrante.controls["rdoParticipacion"].value === null) {
        this.alertMsg('Seleccione Opción');
        return false;
      }
      else{
        if(this.FrmRegistrarIntegrante.controls["rdoParticipacion"].value === "1"){
          if(this.FrmRegistrarIntegrante.controls["codequipo_procedencia"].value!==null &&
            this.FrmRegistrarIntegrante.controls["codequipo_procedencia"].value.nU_ID_EQUIPO!==undefined){
            band=1;
          }
          if(band===0){
            this.alertMsg('Seleccione Equipo de Procedencia');
            return false;
          }
          else{
            band=0;
          }
        }
      }*/

      if (this.FrmRegistrarIntegrante.controls["cboAsimilado"].value === null || 
        this.FrmRegistrarIntegrante.controls["cboAsimilado"].value.length === 0) {
          this.alertMsg('Seleccione Tipo Asimilado');
          return false;
      }
      else{
        if(parseInt(this.FrmRegistrarIntegrante.controls["cboAsimilado"].value) === 1){
          if(this.FrmRegistrarIntegrante.controls["codequipo"].value!==null &&
            this.FrmRegistrarIntegrante.controls["codequipo"].value.nU_ID_EQUIPO!==undefined){
            if(this.FrmRegistrarIntegrante.controls["codequipo"].value.nU_ID_EQUIPO===this.ObjEquipo.nU_ID_EQUIPO){
                this.alertMsg('El equipo de procedencia no puede ser igual al equipo en el que se encuentra');
                return false;
            }
            band=1;
          }
          if(band===0){
            this.alertMsg('Seleccione Equipo de Procedencia');
            return false;
          }
          else{
            band=0;
          }
        }
      }
    }

    return true;
  }

  fnAsignaAsimilado(){
    if(this.FrmRegistrarIntegrante.controls["tipo_deportista"].value==="1"){
      if(this.FrmRegistrarIntegrante.controls["rdoParticipacion"].value === "1"){
        var procedencia=this.FrmRegistrarIntegrante.controls["codequipo_procedencia"].value.nU_ANIO_PROMOCION+' '+
                        this.FrmRegistrarIntegrante.controls["codequipo_procedencia"].value.tX_TURNO+' - '+
                        this.FrmRegistrarIntegrante.controls["codequipo_procedencia"].value.tX_DENOMINACION;

        if(procedencia!==this.ObjEquipo.nU_ANIO_PROMOCION+' '+this.ObjEquipo.tX_TURNO+' - '+this.ObjEquipo.tX_DENOMINACION){
          this.asimilado=1;
          this.tipoasimilado=0;
        }
        else{
          this.asimilado=0;
          this.tipoasimilado=-1;
        }
      }
      else{
        this.asimilado=0;
        this.tipoasimilado=-1;
      }
    }
    else{
      this.asimilado=1;
      this.tipoasimilado=1;
    } 
  }

  Guardar(){
    if(this.ValidarCampos()){
      /*if(this.opcDetalle===2){
        this.fnAsignaAsimilado();
      }*/
      
      /*let band=0;

      if(this.ListaIntegrante.length>0){
        for(let item of this.ListaIntegrante){
          if(this.opcDetalle===1){
            if(item.nU_ID_ENTIDAD===this.FrmRegistrarIntegrante.controls["codsocio"].value.nU_ID_ENTIDAD ||
              item.nU_ID_CARGO===parseInt(this.FrmRegistrarIntegrante.controls["tipo_delegado"].value)){
                band=1; 
                break; 
            }
          }
          else{
            if(this.FrmRegistrarIntegrante.controls["tipo_deportista"].value==="1"){
              if(item.nU_ID_ENTIDAD===this.FrmRegistrarIntegrante.controls["codsocio"].value.nU_ID_ENTIDAD){
                band=1;  
                break;  
              }
            }
            else{
              if(item.nU_ID_ENTIDAD===this.FrmRegistrarIntegrante.controls["codentidad"].value.nU_ID_ENTIDAD){
                band=1;  
                break;  
              }
            }
          } 
        }
      }

      if(band===1){
        Swal.fire({
          html:'El registro ya existe en la lista',
          icon:'info' 
        })
      }
      else{ */
        this.ObjIntegrante={nU_ID_INTEGRANTE:0};
        this.ObjIntegrante.nU_ID_INTEGRANTE=this.codigo;
        this.ObjIntegrante.nU_ID_EQUIPO=this.ObjEquipo.nU_ID_EQUIPO;
        //this.ObjIntegrante.nU_ASIMILADO=this.asimilado;      
        //this.ObjIntegrante.nU_TIPOASIMILADO=this.tipoasimilado;
        this.ObjIntegrante.lISTAARCHIVOQUITAR=this.ArchivoQuitar;
        this.ObjIntegrante.nU_OPCION=1;

        if(this.opcDetalle===1){
          //this.ObjIntegrante.nU_ID_ENTIDAD=this.FrmRegistrarIntegrante.controls["codsocio"].value.nU_ID_ENTIDAD;
          this.ObjIntegrante.nU_ID_ENTIDAD=this.FrmRegistrarIntegrante.controls["codentidad"].value.nU_ID_ENTIDAD;
          this.ObjIntegrante.nU_ID_CARGO=parseInt(this.FrmRegistrarIntegrante.controls["tipo_delegado"].value);
          //this.ObjIntegrante.nU_ID_ASOCIADO=this.FrmRegistrarIntegrante.controls["codsocio"].value.nU_ID_ASOCIADO;
          this.ObjIntegrante.nU_ID_ASOCIADO=-1;
          this.ObjIntegrante.fE_INICIO=new Date(this.FrmRegistrarIntegrante.controls["FecInicio"].value);
          this.ObjIntegrante.nU_ID_DOCUMENTO_INICIO=this.FrmRegistrarIntegrante.controls["coddocumento_ingreso"].value;
          this.ObjIntegrante.nU_ASIMILADO=this.asimilado;      
          this.ObjIntegrante.nU_TIPOASIMILADO=this.tipoasimilado;

          if(this.opc==='MOD'){
            if(this.FrmRegistrarIntegrante.controls["FecTermino"].value !== null){
              this.ObjIntegrante.fE_TERMINO=new Date(this.FrmRegistrarIntegrante.controls["FecTermino"].value);
            }
    
            if (this.FrmRegistrarIntegrante.controls["txtdocumento_termino"].value !== null && 
              this.FrmRegistrarIntegrante.controls["txtdocumento_termino"].value.trim().length > 0) {
                this.ObjIntegrante.nU_ID_DOCUMENTO_TERMINO=this.FrmRegistrarIntegrante.controls["coddocumento_termino"].value;
            }

            if(this.FrmRegistrarIntegrante.controls["FecTermino"].value !== null && this.FrmRegistrarIntegrante.controls["txtdocumento_termino"].value !== null && 
            this.FrmRegistrarIntegrante.controls["txtdocumento_termino"].value.trim().length > 0){
              this.ObjIntegrante.nU_ID_ESTADO=2;
              this.ObjIntegrante.nU_OPCION=4;
            }
          }
        }
        else{
          this.ObjIntegrante.nU_ID_ENTIDAD=(this.FrmRegistrarIntegrante.controls["tipo_deportista"].value==="1")?
                                          this.FrmRegistrarIntegrante.controls["codsocio"].value.nU_ID_ENTIDAD:
                                          this.FrmRegistrarIntegrante.controls["codentidad"].value.nU_ID_ENTIDAD;
          this.ObjIntegrante.nU_ID_CARGO=17;
          this.ObjIntegrante.nU_ID_ASOCIADO=(this.FrmRegistrarIntegrante.controls["tipo_deportista"].value==="1")?
                                            this.FrmRegistrarIntegrante.controls["codsocio"].value.nU_ID_ASOCIADO:-1;
          
          /*if(this.FrmRegistrarIntegrante.controls["codequipo_procedencia"].value!==null &&
            this.FrmRegistrarIntegrante.controls["codequipo_procedencia"].value.nU_ID_EQUIPO!==undefined){
              this.ObjIntegrante.tX_PROCEDENCIA=this.FrmRegistrarIntegrante.controls["codequipo_procedencia"].value.nU_ANIO_PROMOCION+' '+
                                                this.FrmRegistrarIntegrante.controls["codequipo_procedencia"].value.tX_TURNO+' - '+
                                                this.FrmRegistrarIntegrante.controls["codequipo_procedencia"].value.tX_DENOMINACION;
              
          }*/

          this.ObjIntegrante.nU_ASIMILADO=(parseInt(this.FrmRegistrarIntegrante.controls["cboAsimilado"].value)===0)?0:1;      
          this.ObjIntegrante.nU_TIPOASIMILADO=parseInt(this.FrmRegistrarIntegrante.controls["cboAsimilado"].value);

          if(this.FrmRegistrarIntegrante.controls["codequipo"].value!==null &&
            this.FrmRegistrarIntegrante.controls["codequipo"].value.nU_ID_EQUIPO!==undefined){
              this.ObjIntegrante.nU_ID_EQUIPO_PROCEDENCIA=this.FrmRegistrarIntegrante.controls["codequipo"].value.nU_ID_EQUIPO;
              this.ObjIntegrante.tX_PROCEDENCIA=this.FrmRegistrarIntegrante.controls["codequipo"].value.nU_ANIO_PROMOCION+' '+
                                                this.FrmRegistrarIntegrante.controls["codequipo"].value.tX_TURNO+' - '+
                                                this.FrmRegistrarIntegrante.controls["codequipo"].value.tX_DENOMINACION; 
          }
        }

        this.ObjAutenticaService.ValidarApp()
          .subscribe(
            data => { 
              if(data.isSuccess){
                if(data.tx_token.length>0){
                  variablesGlobales.token=data.tx_token;
                  //console.log(this.ObjIntegrante);
                  this.ObjIntegranteService.Actualizar(this.ObjIntegrante).pipe()
                    .subscribe(
                      data => {
                        if (data.isSuccess){
                          for(let archivoSave of this.Archivo){
                            let oFormData = new FormData(); 
                            if (archivoSave.nU_ID_TABLA_ARCHIVO==0){
                              if (archivoSave.archivo!=null){
                                oFormData.append("nU_RELACIONADO", "3"); 
                                oFormData.append("nU_ID_RELACIONADO", data.nU_ID_CODIGO.trim()); 
                                oFormData.append("nU_ID_ARCHIVO", archivoSave.nU_ID_ARCHIVO!.toString());                        
                                oFormData.append("tX_NOMBRE", archivoSave.tX_NOMBRE!);
                                oFormData.append("nU_ID_REQUISITO", archivoSave.nU_ID_REQUISITO!.toString());
                                oFormData.append("tX_DESCRIPCION", archivoSave.tX_DESCRIPCION!);
                                oFormData.append("tX_EXTENSION", archivoSave.tX_EXTENSION!);
                                oFormData.append("nU_ID_USUARIO", variablesGlobales.usuario.codusu.toString());
                                oFormData.append("archivo", archivoSave.archivo);
                                this.ObjArchivoService.UpLoadFileRelacionado(oFormData).subscribe(
                                  data1=>{
                                  }
                                );
                              }
                              else{
                                //Nuevo vinculo
                                this.ObjIntegranteRequisito={
                                  nU_ID_INTEGRANTEREQUISITO:0,
                                  nU_ID_INTEGRANTE:parseInt(data.nU_ID_CODIGO),
                                  nU_ID_REQUISITO:archivoSave.nU_ID_REQUISITO,
                                  nU_ID_ARCHIVO:archivoSave.nU_ID_ARCHIVO
                                };

                                //console.log(this.ObjIntegranteRequisito);
                                this.ObjIntegranteRequisitoService.Actualizar(this.ObjIntegranteRequisito).pipe()
                                  .subscribe(
                                    data => {
                                      if (data.isSuccess){
                                      }
                                      else{
                                        Swal.fire({
                                          title: data.tx_Mensaje,
                                          icon: 'warning'}); 
                                      } 
                                    },
                                    error => {
                                      //console.log(error);
                                      Swal.fire({
                                        title:'El servicio solicitado no se encuentra disponible',
                                        icon: 'error',
                                      });
                                    }
                                  );
                              } 
                            }
                          };

                          Swal.fire({
                            title: data.tx_Mensaje,
                            icon: 'success'}); 
                          
                          this.dialogRef.close(data.isSuccess);
                        }
                        else{
                          Swal.fire({
                            title: data.tx_Mensaje,
                            icon: 'warning'}); 
                        } 
                      },
                      error => {
                        //console.log(error);
                        Swal.fire({
                          title:'El servicio solicitado no se encuentra disponible',
                          icon: 'error',
                        });
                      }
                    );
                }
                else{
                    //console.log("Autenticación inválida: "+data.tx_Mensaje);
                }
              }
            },
            error => {
              //console.log(error);
            }   
          );
      //}
    }
  }

  fnRegistrarEntidad(opc:string,identidad:number){
    const dialogRef = this.dialog.open(RegistrarEntidadComponent, {
      data: {
        codEntidad:identidad,
        op: opc
      },
      disableClose: true 
    });
  }

  Close() {
    this.dialogRef.close();
  }

  pulsar(event:any) {
    if (event.which === 13 && !event.shiftKey) {
      event.preventDefault();
      return false;
    }
    return true;
  }

  convertFechaString(fecha: any){
    var parts=fecha.split("/");
    var dia =parts[1];
    var mes =parts[0];
    var anio =parts[2].substring(0,4);
    var mydate =new Date(anio,mes-1,dia)

    return mydate.toISOString();
  }

  fnBuscarDocumento(opc: number){
    const dialogRef = this.dialog.open(BuscarDocumentoComponent, {
      data: {
        op:opc
      },
      disableClose: true 
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if(result.opc==1){
          this.FrmRegistrarIntegrante.controls["coddocumento_ingreso"].setValue(result.obj.nU_ID_DOCUMENTO);
          this.FrmRegistrarIntegrante.controls["txtdocumento_ingreso"].setValue(result.obj.tX_SIGLA);
        }
        else if(result.opc==2){
          this.FrmRegistrarIntegrante.controls["coddocumento_termino"].setValue(result.obj.nU_ID_DOCUMENTO);
          this.FrmRegistrarIntegrante.controls["txtdocumento_termino"].setValue(result.obj.tX_SIGLA);
        }
      }
    });
  }

  fnLimpiar(){
    if(this.FrmRegistrarIntegrante.controls["codentidad"].value===null ||
       this.FrmRegistrarIntegrante.controls["codentidad"].value.nU_ID_ENTIDAD===undefined){
        this.Archivo=[];
        this.ArchivoTableDataSource.data=this.Archivo;
        this.codTemp=0;
    }
  }

  fnObtenerArchivos(obj: Entidad){
    this.ObjAutenticaService.ValidarApp()
      .subscribe(
        data => { 
          if(data.isSuccess){
            if(data.tx_token.length>0){
              variablesGlobales.token=data.tx_token;

              let tipo_cargo=0;
              
              if(this.opcDetalle==1){
                if (this.FrmRegistrarIntegrante.controls["tipo_delegado"].value == null || 
                  this.FrmRegistrarIntegrante.controls["tipo_delegado"].value.length == 0) {
                    tipo_cargo=15;
                }
                else{
                  tipo_cargo=parseInt(this.FrmRegistrarIntegrante.controls["tipo_delegado"].value);
                }
              }
              else{
                tipo_cargo=17;
              }

              this.ObjArchivoService.Listar(1,obj.nU_ID_ENTIDAD,tipo_cargo).pipe()
                .subscribe(data => {
                  if (data.isSuccess){
                    for(let item of data.listaarchivo){
                      this.codTemp++;

                      let model={'nU_ID_ARCHIVO':item.nU_ID_ARCHIVO,
                                  'tX_NOMBRE_ARCHIVO':item.tX_NOMBRE_ARCHIVO,
                                  'nU_ID_REQUISITO':item.nU_ID_REQUISITO,
                                  'tX_REQUISITO':item.tX_REQUISITO,
                                  'tX_DESCRIPCION':item.tX_DESCRIPCION,   
                                  'nU_ID_TABLA_ARCHIVO':0,
                                  'tX_EXTENSION':item.tX_EXTENSION,
                                  'codTemp':this.codTemp};

                      this.Archivo.push(model);                      
                    }

                    this.ArchivoTableDataSource.data=this.Archivo;
                  }
                });
            }
            else{
              //console.log("Autenticación inválida: "+data.tx_Mensaje);
            }
          }
        },
        error => {
          //console.log(error);
        }   
      );
  }

  fnQuitar(obj:Archivo){
    Swal.fire({
      text: "¿Está seguro de eliminar el registro?",
      icon: 'question',
      showCancelButton: true,
      cancelButtonText:'No',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí',
      reverseButtons: true
    }).then((result) => {
      if (result.value){
        if(obj.nU_ID_ARCHIVO===0){
          this.Archivo=this.Archivo.filter((value,key)=>{
            return value.codTemp != obj.codTemp;
          });     
        }
        else{
          this.Archivo=this.Archivo.filter((value,key)=>{
            return value.nU_ID_ARCHIVO != obj.nU_ID_ARCHIVO;
          });

          if(obj.codTemp==undefined){
            this.ArchivoQuitar.push(obj);
          }
        }
        this.ArchivoTableDataSource.data=this.Archivo;
      }
    });
  }

  fnDescargar(obj: Archivo){
    if(obj.nU_ID_ARCHIVO===0){
      this.fnObtenerArchivo(obj);
    }
    else{
      this.ObjAutenticaService.ValidarApp()
        .subscribe(
          data => { 
            if(data.isSuccess){
              if(data.tx_token.length>0){
                variablesGlobales.token=data.tx_token;
  
                this.ObjArchivoService.ObtenerTrama(obj.tX_NOMBRE_ARCHIVO!).pipe()    
                  .subscribe(
                    data => {
                      if(data.isSuccess){
                        obj.trama=data.tx_Mensaje;
                        this.fnObtenerArchivo(obj);
                      } 
                      else{
                        Swal.fire({
                          title:'Archivo no encontrado',
                          icon: 'info',
                        });
                      }                                   
                    },
                    error => {
                      //console.log(error);
                      Swal.fire({
                        title:'El servicio solicitado no se encuentra disponible',
                        icon: 'error',
                      });
                    }
                  );
              }
              else{
                  //console.log("Autenticación inválida: "+data.tx_Mensaje);
              }
            }
          },
          error => {
            //console.log(error);
          }   
        );
    }
  }

  fnObtenerArchivo(obj: Archivo){
    const byteCharacters= atob(obj.trama);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);
  
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
  
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    var nombre=(obj.nU_ID_ARCHIVO==0)?obj.tX_NOMBRE:obj.tX_NOMBRE_ARCHIVO;

    const blob = new Blob(byteArrays, {type: 'application/'+nombre!.split('.').pop()});
    const blobUrl = URL.createObjectURL(blob);

    const link:any = document.createElement('a');
    link.href = blobUrl;
    link.download = nombre;
    link.click();
  }

  fnAgregar(){
    if(this.FrmRegistrarIntegrante.controls["codentidad"].value==null ||
      this.FrmRegistrarIntegrante.controls["codentidad"].value.nU_ID_ENTIDAD==undefined){
        this.alertMsg("No se ha seleccionado persona");
        return;
    }

    const dialogRef = this.dialog.open(DialogAdjuntarComponent, {
      data: {
        origen:"FrmRegistrarIntegrante",
        codentidad: this.FrmRegistrarIntegrante.controls["codentidad"].value.nU_ID_ENTIDAD,
        listaArchivo: this.Archivo,
      },
      disableClose: true 
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.codTemp++;

        let model: any = {'nU_ID_ARCHIVO':result.codarchivo,
                          'nU_ID_TABLA_ARCHIVO':0,
                          'nU_ID_REQUISITO':result.codrequisito,
                          'tX_REQUISITO':result.requisito,
                          'tX_DESCRIPCION':result.descripcion,                        
                          'tX_EXTENSION':result.extension,             
                          'codTemp':this.codTemp};

        if(result.codarchivo>0){
          model['tX_NOMBRE_ARCHIVO']=result.nomarchivo;
        }
        else{
          model['tX_NOMBRE']=result.nomarchivo;
          model['nU_ID_ESTADO']=3;
          model['archivo']=result.file;
          model['trama']=result.trama;
        }

        this.Archivo.push(model);          
        this.ArchivoTableDataSource.data=this.Archivo;
      }
    });
  }

  fnLimpiarEquipo(){
    if(this.FrmRegistrarIntegrante.controls["codbase"].value===null ||
      this.FrmRegistrarIntegrante.controls["codbase"].value.nU_ID_BASE===undefined){
        this.FrmRegistrarIntegrante.controls["txtdenominacion"].setValue(null);
        this.FrmRegistrarIntegrante.controls["cboserie"].setValue(null);
    }
  }

  fnListarBase(){   
    this.ObjAutenticaService.ValidarApp()
      .subscribe(
        data => { 
          if(data.isSuccess){
            if(data.tx_token.length>0){
              variablesGlobales.token=data.tx_token;

              this.objBaseService.Listar(1).pipe()
                .subscribe(data => {
                  if(data.isSuccess){
                    this.Base=data.listabase;
                    this.filteredBase=this.FrmRegistrarIntegrante.controls["codbase"].valueChanges
                        .pipe(
                          startWith(''),
                          map(value => typeof value === 'string' ? value : value.nU_ANIO_PROMOCION+' - '+value.tX_TURNO),            
                          map(descripcion => descripcion ? this._filterBase(descripcion) : this.Base.slice()
                        )
                    );
                  }
                });
            }
            else{
                //console.log("Autenticación inválida: "+data.tx_Mensaje);
            }
          }
        },
        error => {
          //console.log(error);
        }   
      ); 
  }

  displayFnBase(base?: Base): string{
    return base ? base.nU_ANIO_PROMOCION+' - '+base.tX_TURNO: "";
  }

  private _filterBase(descripcion: string): Base[] {
    const filterValue = descripcion.toLowerCase();
    return this.Base.filter(option => (option.nU_ANIO_PROMOCION+' - '+option.tX_TURNO!.toLowerCase()).includes(filterValue));
  }

  fnMostrarEquipo(tipoasimilado: string){
    if(parseInt(tipoasimilado)===0 || parseInt(tipoasimilado)===2){
      this.isVisibleEquipo=false;
      this.FrmRegistrarIntegrante.controls["codequipo"].setValue("");
    }
    else{
      this.isVisibleEquipo=true;
    }
  }
}
