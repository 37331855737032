import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { Component, HostListener, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Departamento } from 'src/app/@core/models/Departamento';
import { Distrito } from 'src/app/@core/models/Distrito';
import { Provincia } from 'src/app/@core/models/Provincia';
import { AutenticaService } from 'src/app/@core/services/AutenticaService';
import { DepartamentoService } from 'src/app/@core/services/DepartamentoService';
import { DistritoService } from 'src/app/@core/services/DistritoService';
import { ProvinciaService } from 'src/app/@core/services/ProvinciaService';
import { variablesGlobales } from 'src/app/Util/variableGlobal';
import Swal from 'sweetalert2';
import * as EmailValidator from 'email-validator';
import { Entidad } from 'src/app/@core/models/Entidad';
import { EntidadService } from 'src/app/@core/services/EntidadService';
import { Archivo } from 'src/app/@core/models/Archivo';
import { ArchivoService } from 'src/app/@core/services/ArchivoService';
import { DialogAdjuntarComponent } from 'src/app/completar-registro/dialog-adjuntar/dialog-adjuntar.component';
import { CiudadanoService } from 'src/app/@core/services/CiudadanoService';
import { Router } from '@angular/router';
import { stringToKeyValue } from '@angular/flex-layout/extended/typings/style/style-transforms';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};

@Component({
  selector: 'app-registrar-entidad',
  templateUrl: './registrar-entidad.component.html',
  styleUrls: ['./registrar-entidad.component.scss'],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }
  ]
})
export class RegistrarEntidadComponent implements OnInit {

  FrmRegistrarEntidad!: FormGroup;

  codigo:number=0;
  opc!:string;
  Departamento!: Departamento[];
  Provincia!: Provincia[];
  Distrito!: Distrito[];
  isVisibleButton: boolean=true;
  isVisibleCabecera: boolean=false;
  isVisibleFNacimiento: boolean=false;
  isVisibleTelefono: boolean=false;
  isVisibleDomicilio: boolean=false;
  isVisibleArchivo: boolean=false;
  //isVisibleButtonBusqueda: boolean=false;
  isVisibleButtonBusqueda: boolean=true;
  ObjEntidad!: Entidad;
  maxLength: number=0;
  codTemp: number=0;
  txtTitulo!: string;
  isReadOnly: boolean=false;

  Archivo: Archivo[]=[];
  ArchivoQuitar: Archivo[]=[];
  displayedColumns: string[] = ['nU_ID_ARCHIVO','tX_NOMBRE','tX_REQUISITO','tX_DESCRIPCION'];
  dataSource = this.Archivo;

  ArchivoTable: Archivo[]=[];
  ArchivoTableDataSource = new MatTableDataSource(this.ArchivoTable);

  @ViewChild('autosize') autosize!: CdkTextareaAutosize;

  constructor(private _formBuilder: FormBuilder,
              private router: Router,
              private ObjAutenticaService: AutenticaService,
              private ObjDepartamentoService: DepartamentoService,
              private ObjProvinciaService: ProvinciaService,
              private ObjDistritoService: DistritoService,
              private ObjEntidadService: EntidadService,
              private ObjArchivoService: ArchivoService,
              private ObjCiudadanoService: CiudadanoService,
              public dialog: MatDialog,
              private dialogRef: MatDialogRef<RegistrarEntidadComponent>,
              @Inject(MAT_DIALOG_DATA) data: any,) { 
                this.codigo=data.codEntidad;
                this.opc=data.op;
              }

  ngOnInit(): void {
    
    //if (variablesGlobales.usuario.codusu===3)
      //this.isVisibleButtonBusqueda=true;
    this.FrmRegistrarEntidad=this._formBuilder.group({
      cbotipodocumento: ["1"],
      txtnumero: [null,Validators.compose([Validators.required,Validators.maxLength(15)])],
      txtnombre: [null, Validators.required],
      txtape_paterno: [null, Validators.required],
      txtape_materno: [null, Validators.required],
      txtemail: [null, Validators.required],
      cbosexo: [null, Validators.required],
      FecNacimiento:[null,Validators.compose([Validators.required,Validators.maxLength(10)])],
      txttelefono: [null, Validators.required],
      coddepartamento: [null, Validators.required],
      codprovincia: [null, Validators.required],
      coddistrito: [null, Validators.required],
      txtdireccion: [null, Validators.required],
    });

    if(this.opc==='MOD'){
      this.txtTitulo="Actualiza Información";
      this.Obtener();
    } 
    else{
      this.txtTitulo="Registrar Persona";
    }

    this.maxLength=8;

    this.fnListarDepartamento();
  }

  @HostListener('window:keyup.esc') onKeyUp() {
    this.Close();
  }

  Obtener(){ 
    this.ObjAutenticaService.ValidarApp()
      .subscribe(
        data => { 
          if(data.isSuccess){
            if(data.tx_token.length>0){
              variablesGlobales.token=data.tx_token;

              this.ObjEntidadService.Obtener(this.codigo,2).pipe()
                .subscribe(
                  data => {
                    ////console.log(data);
                    if (data.isSuccess){
                      this.ObjEntidad=data.entidad;

                      this.FrmRegistrarEntidad.controls["cbotipodocumento"].setValue(this.ObjEntidad.tX_ID_TIPODOCUMENTOIDENTIDAD);
                      this.FrmRegistrarEntidad.controls["cbotipodocumento"].disable();
                      this.isReadOnly=true;
                      this.FrmRegistrarEntidad.controls["txtnumero"].setValue(this.ObjEntidad.tX_NUMERO);
                      this.FrmRegistrarEntidad.controls["txtnombre"].setValue(this.ObjEntidad.tX_NOMBRE);
                      this.FrmRegistrarEntidad.controls["txtape_paterno"].setValue(this.ObjEntidad.tX_APELLIDO_PATERNO);
                      this.FrmRegistrarEntidad.controls["txtape_materno"].setValue(this.ObjEntidad.tX_APELLIDO_MATERNO);
                      this.FrmRegistrarEntidad.controls["txtemail"].setValue(this.ObjEntidad.tX_CORREO);
                      this.FrmRegistrarEntidad.controls["cbosexo"].setValue(this.ObjEntidad.tX_SEXO);
                      this.FrmRegistrarEntidad.controls["cbosexo"].disable();

                      if(this.ObjEntidad.fE_NACIMIENTO!==null){
                        this.isVisibleFNacimiento=true;
                        this.FrmRegistrarEntidad.controls["FecNacimiento"].setValue(this.convertFechatoStringData(this.ObjEntidad.fE_NACIMIENTO));
                      }

                      if(this.ObjEntidad.nU_ID_TELEFONO!>0){
                        this.isVisibleTelefono=true;
                        this.FrmRegistrarEntidad.controls["txttelefono"].setValue(this.ObjEntidad.tX_TELEFONO);
                      }

                      if(this.ObjEntidad.nU_ID_ESTABLECIMIENTO!>0){
                        this.isVisibleDomicilio=true;
                        this.FrmRegistrarEntidad.controls["txtdireccion"].setValue(this.ObjEntidad.tX_DIRECCION);
                        
                        this.ObjDepartamentoService.Listar(0,'').pipe()    
                          .subscribe(
                            data => {
                              if(data.isSuccess){
                                this.Departamento= data.data;
                                if(this.Departamento.length>0){
                                  this.FrmRegistrarEntidad.controls["coddepartamento"].setValue(this.ObjEntidad.tX_UBIGEO!.substring(0,2));

                                  this.ObjProvinciaService.Listar(1,this.ObjEntidad.tX_UBIGEO!.substring(0,2)).pipe()    
                                    .subscribe(
                                      data => {
                                        if(data.isSuccess){
                                          this.Provincia= data.data;
                                          if(this.Provincia.length>0){
                                            this.FrmRegistrarEntidad.controls["codprovincia"].setValue(this.ObjEntidad.tX_UBIGEO!.substring(0,4));

                                            this.ObjDistritoService.Listar(0,this.ObjEntidad.tX_UBIGEO!.substring(0,4)).pipe()    
                                              .subscribe(
                                                data => {
                                                  if(data.isSuccess){
                                                    this.Distrito= data.data;
                                                    if(this.Distrito.length>0){
                                                      this.FrmRegistrarEntidad.controls["coddistrito"].setValue(this.ObjEntidad.tX_UBIGEO);
                                                    } 
                                                  }                                  
                                                },
                                                error => {
                                                  //console.log(error);
                                                }
                                              );
                                          } 
                                        }                                  
                                      },
                                      error => {
                                        //console.log(error);
                                      }
                                    );
                                }
                              }                                  
                            },
                            error => {
                              //console.log(error);
                            }
                          );
                      }

                      if(this.isVisibleFNacimiento || this.isVisibleTelefono || this.isVisibleDomicilio){
                        this.isVisibleCabecera=true; 
                      }

                      /*if(data.listaarchivosadjuntos.length>0){
                        this.isVisibleArchivo=true;
                        this.Archivo=data.listaarchivosadjuntos;
                        this.ArchivoTableDataSource.data=this.Archivo;
                      }*/

                      if(this.isVisibleFNacimiento && this.isVisibleTelefono && this.isVisibleDomicilio){
                        this.isVisibleButton=false;
                      }
                    }
                  },
                  error => {
                    //console.log(error);
                    Swal.fire({
                      title:'El servicio solicitado no se encuentra disponible',
                      icon: 'error',
                    });
                  }
                );  
            }
            else{
                //console.log("Autenticación inválida: "+data.tx_Mensaje);
            }
          }
        },
        error => {
          //console.log(error);
        }   
      );                              
  }

  Limpiar(){
    this.FrmRegistrarEntidad.patchValue({
      txtnumero: null,
    });
  }

  fnListarDepartamento(){
    this.ObjAutenticaService.ValidarApp()
      .subscribe(
        data => { 
          if(data.isSuccess){
            if(data.tx_token.length>0){
              variablesGlobales.token=data.tx_token;

              this.ObjDepartamentoService.Listar(0,'').pipe()    
                .subscribe(
                  data => {
                    if(data.isSuccess){
                      this.Departamento= data.data;
                    }                                  
                  },
                  error => {
                    //console.log(error);
                    Swal.fire({
                      title:'El servicio solicitado no se encuentra disponible',
                      icon: 'error',
                    });
                  }
                );
            }
            else{
                //console.log("Autenticación inválida: "+data.tx_Mensaje);
            }
          }
        },
        error => {
          //console.log(error);
        }   
      );
  }

  fnListarProvincia(coddepartamento:string){
    this.FrmRegistrarEntidad.patchValue({
      codprovincia: null,
      coddistrito: null,
    });

    this.ObjAutenticaService.ValidarApp()
      .subscribe(
        data => { 
          if(data.isSuccess){
            if(data.tx_token.length>0){
              variablesGlobales.token=data.tx_token;

              this.ObjProvinciaService.Listar(1,coddepartamento).pipe()    
                .subscribe(
                  data => {
                    if(data.isSuccess){
                      this.Provincia= data.data;
                      if(this.Provincia.length>0) this.fnListarDistrito(this.Provincia[0].tX_ID_PROVINCIA);
                    }                                  
                  },
                  error => {
                    //console.log(error);
                    Swal.fire({
                      title:'El servicio solicitado no se encuentra disponible',
                      icon: 'error',
                    });
                  }
                );
            }
            else{
                //console.log("Autenticación inválida: "+data.tx_Mensaje);
            }
          }
        },
        error => {
          //console.log(error);
        }   
      );
  }

  fnListarDistrito(codprovincia:string){
    this.FrmRegistrarEntidad.patchValue({
      coddistrito: null,
    });

    this.ObjAutenticaService.ValidarApp()
      .subscribe(
        data => { 
          if(data.isSuccess){
            if(data.tx_token.length>0){
              variablesGlobales.token=data.tx_token;

              this.ObjDistritoService.Listar(0,codprovincia).pipe()    
                .subscribe(
                  data => {
                    if(data.isSuccess){
                      this.Distrito= data.data;
                    }                                  
                  },
                  error => {
                    //console.log(error);
                    Swal.fire({
                      title:'El servicio solicitado no se encuentra disponible',
                      icon: 'error',
                    });
                  }
                );
            }
            else{
                //console.log("Autenticación inválida: "+data.tx_Mensaje);
            }
          }
        },
        error => {
          //console.log(error);
        }   
      );
  }

  fnIngNumDocumento(event:any){
    if(this.FrmRegistrarEntidad.controls["cbotipodocumento"].value==="1"){
      this.maxLength=8;
      return this.fnSoloNumeros(event);
    } 
    else if(this.FrmRegistrarEntidad.controls["cbotipodocumento"].value==="4" || 
    this.FrmRegistrarEntidad.controls["cbotipodocumento"].value==="7")
        this.maxLength=12;
    else if(this.FrmRegistrarEntidad.controls["cbotipodocumento"].value==="0" ||
    this.FrmRegistrarEntidad.controls["cbotipodocumento"].value==="A")
        this.maxLength=15;
    return true;
  }

  fnSoloNumeros(event:any) {
    event.target.value = event.target.value.replace(/[^\d]/g,'');
    return false;
  }

  fnSoloLetras(event:any) {
    var patron =/[A-Za-z\s\á\é\í\ó\ú\Á\É\Í\Ó\Ú\Ñ\ñ]/;

    if(!patron.test(event.key)){
      event.target.value = event.target.value.replace(event.key,'');
    }
    event.target.value = event.target.value.replace(/[\/\\\\]/g,'');//Permite espaciado
    return false;
  }

  fnMostrarComponente(opc: number){
    switch(opc){
      case 1:
        this.isVisibleFNacimiento=true;
        break;
      case 2: 
        this.isVisibleTelefono=true;
        break;
      case 3: 
        this.isVisibleDomicilio=true;
        break;
      case 4: 
        this.isVisibleArchivo=true;
        break;
    }

    if(this.isVisibleFNacimiento && this.isVisibleTelefono && this.isVisibleDomicilio && this.isVisibleArchivo){
    //if(this.isVisibleFNacimiento && this.isVisibleTelefono && this.isVisibleDomicilio){
      this.isVisibleButton=false;
    }
    
    if(this.isVisibleFNacimiento || this.isVisibleTelefono || this.isVisibleDomicilio){
      this.isVisibleCabecera=true; 
    }
  }

  fnOcultarComponente(opc: number){
    switch(opc){
      case 1:
        this.isVisibleFNacimiento=false;
        this.FrmRegistrarEntidad.controls["FecNacimiento"].setValue(null);
        break;
      case 2: 
        this.isVisibleTelefono=false;
        this.FrmRegistrarEntidad.controls["txttelefono"].setValue(null);
        break;
      case 3: 
        this.isVisibleDomicilio=false;
        this.FrmRegistrarEntidad.controls["coddepartamento"].setValue(null);
        this.FrmRegistrarEntidad.controls["codprovincia"].setValue(null);
        this.FrmRegistrarEntidad.controls["coddistrito"].setValue(null);
        this.FrmRegistrarEntidad.controls["txtdireccion"].setValue(null);
        break;
      case 4:
        if(this.Archivo.length>0){
          Swal.fire({
            text: "Existen archivos en la lista ¿está seguro de quitarlos?",
            icon: 'question',
            showCancelButton: true,
            cancelButtonText:'No',
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí',
            reverseButtons: true
          }).then((result) => {
            if (result.value){
              for(let item of this.Archivo){
                if(item.nU_ID_ARCHIVO>0){
                  this.ArchivoQuitar.push(item);     
                }
              }

              this.Archivo=[];
              this.ArchivoTableDataSource.data=this.Archivo;
              this.isVisibleArchivo=false;
            }
          });
        }
        else{
          this.isVisibleArchivo=false;
        }
        break;
    }

    if(!this.isVisibleFNacimiento && !this.isVisibleTelefono && !this.isVisibleDomicilio){
      this.isVisibleCabecera=false;
    }
    
    if(!this.isVisibleFNacimiento || !this.isVisibleTelefono || !this.isVisibleDomicilio || !this.isVisibleArchivo){
    //if(!this.isVisibleFNacimiento || !this.isVisibleTelefono || !this.isVisibleDomicilio){
      this.isVisibleButton=true;
    }
  }

  alertMsg(msg:string){
    Swal.fire({
      html:msg,
      icon:'info'
    })
  }

  ValidarCampos(){
    if (this.FrmRegistrarEntidad.controls["txtnumero"].value === null || 
      this.FrmRegistrarEntidad.controls["txtnumero"].value.length === 0 || 
      /^\s+$/.test(this.FrmRegistrarEntidad.controls["txtnumero"].value)) {
        this.alertMsg('Ingrese Número de Documento de Identidad');
        return false;
    }
    else{
      if(this.FrmRegistrarEntidad.controls["cbotipodocumento"].value==="1"){
        if(this.FrmRegistrarEntidad.controls["txtnumero"].value.length!==8){
          this.alertMsg('Debe ingresar 8 dígitos');
          return false;
        }
      }
    }

    if (this.FrmRegistrarEntidad.controls["txtnombre"].value === null || 
      this.FrmRegistrarEntidad.controls["txtnombre"].value.length === 0 || 
      /^\s+$/.test(this.FrmRegistrarEntidad.controls["txtnombre"].value)) {
        this.alertMsg('Ingrese Nombres');
        return false;
    }

    if (this.FrmRegistrarEntidad.controls["txtape_paterno"].value === null || 
      this.FrmRegistrarEntidad.controls["txtape_paterno"].value.length === 0 || 
      /^\s+$/.test(this.FrmRegistrarEntidad.controls["txtape_paterno"].value)) {
        this.alertMsg('Ingrese Apellido Paterno');
        return false;
    }

    if (this.FrmRegistrarEntidad.controls["txtape_materno"].value === null || 
      this.FrmRegistrarEntidad.controls["txtape_materno"].value.length === 0 || 
      /^\s+$/.test(this.FrmRegistrarEntidad.controls["txtape_materno"].value)) {
        this.alertMsg('Ingrese Apellido Materno');
        return false;
    }

    if (this.FrmRegistrarEntidad.controls["txtemail"].value === null)
      //console.log('hola');

    // if (this.FrmRegistrarEntidad.controls["txtemail"].value === null || 
    //   this.FrmRegistrarEntidad.controls["txtemail"].value.length === 0 || 
    //   /^\s+$/.test(this.FrmRegistrarEntidad.controls["txtemail"].value)) {
    //     this.alertMsg('Ingrese Correo Electrónico');
    //     return false;
    // }
    // else{
    //   if(!EmailValidator.validate(this.FrmRegistrarEntidad.controls["txtemail"].value.trim())){
    //     this.alertMsg('Formato de correo incorrecto');
    //     return false;
    //   }       
    // }

    if (this.FrmRegistrarEntidad.controls["cbosexo"].value === null || 
      this.FrmRegistrarEntidad.controls["cbosexo"].value.length === 0) {
        this.alertMsg('Ingrese Sexo');
        return false;
    }

    if(this.isVisibleFNacimiento){
      if (this.FrmRegistrarEntidad.controls["FecNacimiento"].value === null || 
        this.FrmRegistrarEntidad.controls["FecNacimiento"].value.length === 0 || 
        /^\s+$/.test(this.FrmRegistrarEntidad.controls["FecNacimiento"].value)) {
          this.alertMsg('Ingrese Fecha de Nacimiento o establezca el formato correcto');
          return false;
      }
    }

    if(this.isVisibleTelefono){
      if (this.FrmRegistrarEntidad.controls["txttelefono"].value === null || 
        this.FrmRegistrarEntidad.controls["txttelefono"].value.length === 0 || 
        /^\s+$/.test(this.FrmRegistrarEntidad.controls["txttelefono"].value)) {
          this.alertMsg('Ingrese Teléfono');
          return false;
      }
    }

    if(this.isVisibleDomicilio){
      if (this.FrmRegistrarEntidad.controls["coddepartamento"].value === null || 
        this.FrmRegistrarEntidad.controls["coddepartamento"].value.length === 0) {
          this.alertMsg('Ingrese Departamento');
          return false;
      }

      if (this.FrmRegistrarEntidad.controls["codprovincia"].value === null || 
        this.FrmRegistrarEntidad.controls["codprovincia"].value.length === 0) {
          this.alertMsg('Ingrese Provincia');
          return false;
      }

      if (this.FrmRegistrarEntidad.controls["coddistrito"].value === null || 
        this.FrmRegistrarEntidad.controls["coddistrito"].value.length === 0) {
          this.alertMsg('Ingrese Distrito');
          return false;
      }

      if (this.FrmRegistrarEntidad.controls["txtdireccion"].value === null || 
        this.FrmRegistrarEntidad.controls["txtdireccion"].value.length === 0 || 
        /^\s+$/.test(this.FrmRegistrarEntidad.controls["txtdireccion"].value)) {
          this.alertMsg('Ingrese Dirección');
          return false;
      }
    }

    if(this.isVisibleArchivo){
      if(this.Archivo.length === 0){
        this.alertMsg('Adjunte archivo');
        return false;
      }
    }

    return true;
  }

  Guardar(){
    if(this.ValidarCampos()){
      this.ObjEntidad={nU_ID_ENTIDAD:0};
      this.ObjEntidad.nU_ID_ENTIDAD=this.codigo;
      this.ObjEntidad.tX_ID_TIPODOCUMENTOIDENTIDAD=this.FrmRegistrarEntidad.controls["cbotipodocumento"].value;
      this.ObjEntidad.tX_NUMERO=this.FrmRegistrarEntidad.controls["txtnumero"].value;
      this.ObjEntidad.tX_NOMBRE=this.FrmRegistrarEntidad.controls["txtnombre"].value.toUpperCase().trim();
      this.ObjEntidad.tX_APELLIDO_PATERNO=this.FrmRegistrarEntidad.controls["txtape_paterno"].value.toUpperCase().trim();
      this.ObjEntidad.tX_APELLIDO_MATERNO=this.FrmRegistrarEntidad.controls["txtape_materno"].value.toUpperCase().trim();
      if (this.FrmRegistrarEntidad.controls["txtemail"].value === null)
          this.ObjEntidad.tX_CORREO="";
        else
          this.ObjEntidad.tX_CORREO=this.FrmRegistrarEntidad.controls["txtemail"].value.trim();

      this.ObjEntidad.tX_SEXO=this.FrmRegistrarEntidad.controls["cbosexo"].value;  
      this.ObjEntidad.lISTAARCHIVOQUITAR=this.ArchivoQuitar;

      if(this.isVisibleFNacimiento){
        this.ObjEntidad.fE_NACIMIENTO=new Date(this.FrmRegistrarEntidad.controls["FecNacimiento"].value);
      }

      if(this.isVisibleDomicilio){
        this.ObjEntidad.tX_UBIGEO=this.FrmRegistrarEntidad.controls["coddistrito"].value;
        this.ObjEntidad.tX_DIRECCION=this.FrmRegistrarEntidad.controls["txtdireccion"].value.trim();
      }

      if(this.isVisibleTelefono){
        this.ObjEntidad.tX_TELEFONO=this.FrmRegistrarEntidad.controls["txttelefono"].value;
      }

      this.ObjAutenticaService.ValidarApp()
        .subscribe(
          data => { 
            if(data.isSuccess){
              if(data.tx_token.length>0){
                variablesGlobales.token=data.tx_token;

                this.ObjEntidadService.Actualizar(this.ObjEntidad).pipe()
                  .subscribe(
                    data => {
                      if (data.isSuccess){
                        for(let archivoSave of this.Archivo){
                          let oFormData = new FormData(); 
                          oFormData.append("nU_RELACIONADO", "1"); 
                          oFormData.append("nU_ID_RELACIONADO", data.nU_ID_CODIGO.trim()); 
                          oFormData.append("nU_ID_ARCHIVO", archivoSave.nU_ID_ARCHIVO!.toString());                        
                          oFormData.append("tX_NOMBRE", archivoSave.tX_NOMBRE!);
                          oFormData.append("nU_ID_REQUISITO", archivoSave.nU_ID_REQUISITO!.toString());
                          oFormData.append("tX_DESCRIPCION", archivoSave.tX_DESCRIPCION!);
                          oFormData.append("tX_EXTENSION", archivoSave.tX_EXTENSION!);
                          oFormData.append("nU_ID_USUARIO", variablesGlobales.usuario.codusu.toString());
                          oFormData.append("archivo", archivoSave.archivo);

                          this.ObjArchivoService.UpLoadFileRelacionado(oFormData).subscribe(
                            data1=>{
                            }
                          );
                        };

                        Swal.fire({
                          title: data.tx_Mensaje,
                          icon: 'success'}); 
                        
                        this.dialogRef.close(data.isSuccess);
                      }
                      else{
                        Swal.fire({
                          title: data.tx_Mensaje,
                          icon: 'warning'}); 
                      } 
                    },
                    error => {
                      //console.log(error);
                      Swal.fire({
                        title:'El servicio solicitado no se encuentra disponible',
                        icon: 'error',
                      });
                    }
                  );
              }
              else{
                  //console.log("Autenticación inválida: "+data.tx_Mensaje);
              }
            }
          },
          error => {
            //console.log(error);
          }   
        );
    }
  }

  Close() {
    this.dialogRef.close();
  }

  pulsar(event:any) {
    if (event.which === 13 && !event.shiftKey) {
      event.preventDefault();
      return false;
    }
    return true;
  }

  fnQuitar(obj:Archivo){
    Swal.fire({
      text: "¿Está seguro de eliminar el registro?",
      icon: 'question',
      showCancelButton: true,
      cancelButtonText:'No',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí',
      reverseButtons: true
    }).then((result) => {
      if (result.value){
        if(obj.nU_ID_ARCHIVO===0){
          this.Archivo=this.Archivo.filter((value,key)=>{
            return value.codTemp != obj.codTemp;
          });     
        }
        else{
          this.Archivo=this.Archivo.filter((value,key)=>{
            return value.nU_ID_ARCHIVO != obj.nU_ID_ARCHIVO;
          });
          this.ArchivoQuitar.push(obj);
        }
        this.ArchivoTableDataSource.data=this.Archivo;
      }
    });
  }

  fnDescargar(obj: Archivo){
    if(obj.nU_ID_ARCHIVO===0){
      this.fnObtenerArchivo(obj);
    }
    else{
      this.ObjAutenticaService.ValidarApp()
        .subscribe(
          data => { 
            if(data.isSuccess){
              if(data.tx_token.length>0){
                variablesGlobales.token=data.tx_token;
  
                this.ObjArchivoService.ObtenerTrama(obj.tX_NOMBRE_ARCHIVO!).pipe()    
                  .subscribe(
                    data => {
                      if(data.isSuccess){
                        obj.trama=data.tx_Mensaje;
                        this.fnObtenerArchivo(obj);
                      }  
                      else{
                        Swal.fire({
                          title:'Archivo no encontrado',
                          icon: 'info',
                        });
                      }                                
                    },
                    error => {
                      //console.log(error);
                      Swal.fire({
                        title:'El servicio solicitado no se encuentra disponible',
                        icon: 'error',
                      });
                    }
                  );
              }
              else{
                  //console.log("Autenticación inválida: "+data.tx_Mensaje);
              }
            }
          },
          error => {
            //console.log(error);
          }   
        );
    }
  }

  fnObtenerArchivo(obj: Archivo){
    const byteCharacters= atob(obj.trama);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);
  
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
  
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    var nombre=(obj.nU_ID_ARCHIVO==0)?obj.tX_NOMBRE:obj.tX_NOMBRE_ARCHIVO;

    const blob = new Blob(byteArrays, {type: 'application/'+nombre!.split('.').pop()});
    const blobUrl = URL.createObjectURL(blob);

    const link:any = document.createElement('a');
    link.href = blobUrl;
    link.download = nombre;
    link.click();
  }

  fnAgregar(){
    const dialogRef = this.dialog.open(DialogAdjuntarComponent, {
      data: {
        origen:"FrmRegistrarEntidad",
        listaArchivo: this.Archivo,
      },
      disableClose: true 
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.codTemp++;

        let model={'nU_ID_ARCHIVO':0,
                    'tX_NOMBRE':result.nomarchivo,
                    'nU_ID_REQUISITO':result.codrequisito,
                    'tX_REQUISITO':result.requisito,
                    'tX_DESCRIPCION':result.descripcion,                        
                    'tX_EXTENSION':result.extension,                       
                    'nU_ID_ESTADO':3,
                    'archivo':result.file,     
                    'trama':result.trama,              
                    'codTemp':this.codTemp};

        this.Archivo.push(model);          
        this.ArchivoTableDataSource.data=this.Archivo;
      }
    });
  }

  convertFechatoStringOnpe(fecha: any){
    var parts=fecha.split("-");
    var dia =parts[2];
    var mes =parts[1];
    var anio =parts[0];
    var mydate =new Date(anio,mes-1,dia)

    return mydate.toISOString();
  }

  convertFechatoStringData(fecha: any){
    var parts=fecha.split("/");
    var dia =parts[1];
    var mes =parts[0];
    var anio =parts[2].substring(0,4);
    var mydate =new Date(anio,mes-1,dia)

    return mydate.toISOString();
  }

  Buscar(){
    //console.log(this.FrmRegistrarEntidad.controls["cbotipodocumento"].value);
    if (this.FrmRegistrarEntidad.controls["cbotipodocumento"].value.toString()!="1")
    {
      Swal.fire({
        title:'Sólo es posible consultar números de DNI.',
        icon: 'error',
      });
      return;
    }
    
    if (this.FrmRegistrarEntidad.controls["txtnumero"].value === null || 
      this.FrmRegistrarEntidad.controls["txtnumero"].value.length === 0 || 
      /^\s+$/.test(this.FrmRegistrarEntidad.controls["txtnumero"].value)) {
        this.alertMsg('Ingrese Número de Documento de Identidad');
        return;
    }
    else{
      if(this.FrmRegistrarEntidad.controls["cbotipodocumento"].value==="1"){
        if(this.FrmRegistrarEntidad.controls["txtnumero"].value.length!==8){
          this.alertMsg('Debe ingresar 8 dígitos');
          return;
        }
      }
    }
    
    this.ObjAutenticaService.ValidarApp()
      .subscribe(
        data => { 
          if(data.isSuccess){
            if(data.tx_token.length>0){
              variablesGlobales.token=data.tx_token;
              let nrodni =this.FrmRegistrarEntidad.controls["txtnumero"].value.toString();
              this.ObjCiudadanoService.ConsultaLibre(nrodni)
                .subscribe(data => {
                  if (data.isSuccess){
                    //console.log(data);
                    if (data.data === null){
                      this.alertMsg('El dni no se encuentra en nuestros registros.');
                    }
                    else{
                      this.FrmRegistrarEntidad.controls["txtnombre"].setValue(data.data.tX_NOMBRES.toString());
                      this.FrmRegistrarEntidad.controls["txtape_paterno"].setValue(data.data.tX_PATERNO);
                      this.FrmRegistrarEntidad.controls["txtape_materno"].setValue(data.data.tX_MATERNO);
                      this.FrmRegistrarEntidad.controls["cbosexo"].setValue(data.data.tX_SEXO);
                      this.isVisibleFNacimiento=true;
                      this.FrmRegistrarEntidad.controls["FecNacimiento"].setValue(this.convertFechatoStringOnpe(data.data.fE_NACIMIENTO));
                      this.isVisibleDomicilio=true;
                      this.FrmRegistrarEntidad.controls["coddepartamento"].setValue(data.data.tX_UBIGEO.substring(0,2));
                      this.ObjProvinciaService.Listar(1,data.data.tX_UBIGEO!.substring(0,2)).pipe()    
                        .subscribe(
                          datap => {
                            if(datap.isSuccess){
                              this.Provincia= datap.data;
                              if(this.Provincia.length>0){
                                this.FrmRegistrarEntidad.controls["codprovincia"].setValue(data.data.tX_UBIGEO!.substring(0,4));

                                this.ObjDistritoService.Listar(0,data.data.tX_UBIGEO!.substring(0,4)).pipe()    
                                  .subscribe(
                                    datad => {
                                      if(datad.isSuccess){
                                        this.Distrito= datad.data;
                                        if(this.Distrito.length>0){
                                          this.FrmRegistrarEntidad.controls["coddistrito"].setValue(data.data.tX_UBIGEO);
                                        } 
                                      }                                  
                                    },
                                    error => {
                                      //console.log(error);
                                    }
                                  );
                              } 
                            }                                  
                          },
                          error => {
                            //console.log(error);
                          }
                        );
                    }
                  }                                         
                });
            }
            else{
              //this.router.navigate(['login']);
            }
          }
        },
        error => {
          //console.log(error);
          //this.router.navigate(['login']);
        }   
      ); 
  }
}
