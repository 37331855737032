import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { Component, HostListener, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { map, startWith } from 'rxjs/operators';
import { Entidad } from 'src/app/@core/models/Entidad';
import { Equipo } from 'src/app/@core/models/Equipo';
import { Integrante } from 'src/app/@core/models/Integrante';
import { NotaPartido } from 'src/app/@core/models/NotaPartido';
import { Partido } from 'src/app/@core/models/Partido';
import { AutenticaService } from 'src/app/@core/services/AutenticaService';
import { EntidadService } from 'src/app/@core/services/EntidadService';
import { EquipoService } from 'src/app/@core/services/EquipoService';
import { IntegranteService } from 'src/app/@core/services/IntegranteService';
import { NotaPartidoService } from 'src/app/@core/services/NotaPartidoService';
import { variablesGlobales } from 'src/app/Util/variableGlobal';
import { RegistrarEntidadComponent } from 'src/app/menu/controles/registrar-entidad/registrar-entidad.component';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-dialog-agregar-notapartido',
  templateUrl: './dialog-agregar-notapartido.component.html',
  styleUrls: ['./dialog-agregar-notapartido.component.scss']
})
export class DialogAgregarNotapartidoComponent implements OnInit {

  FrmAgregarNotaPartido!: FormGroup;
  coddisciplina:number=0;
  codfecha:number=0;
  ObjPartido!:Partido;
  codnotapartido:number=0;
  titulo!: string;
  Equipo: Equipo[]=[];
  Entidad: Entidad[]=[];
  filteredEntidad: any;
  Integrante: Integrante[]=[];
  ObjNotaPartido!:NotaPartido;
  estado: number=1;
  isVisibleEquipo: boolean=false;
  isVisibleEntidad: boolean=false;

  @ViewChild('autosize') autosize!: CdkTextareaAutosize;

  constructor(private _formBuilder: FormBuilder,
              private ObjAutenticaService: AutenticaService,
              private router: Router,
              private ObjEquipoService: EquipoService,
              private ObjEntidadService: EntidadService,
              private ObjIntegranteService: IntegranteService,
              private ObjNotaPartidoService: NotaPartidoService,
              public dialog: MatDialog,
              private dialogRef: MatDialogRef<DialogAgregarNotapartidoComponent>,
              @Inject(MAT_DIALOG_DATA) data: any) {
                this.coddisciplina=data.coddisciplina;
                this.codfecha=data.codfecha;
                this.ObjPartido=data.objPartido;
                this.codnotapartido=data.codnotapartido;
              }

  ngOnInit(): void {
    this.FrmAgregarNotaPartido=this._formBuilder.group({
      cbotipo:[null,Validators.required],
      cboequipo:[null,Validators.required],
      codentidad:[null,Validators.required],
      cbodelegado:[null,Validators.required],
      txtobservacion:["",Validators.required]
    });

    this.titulo="Registrar Nota";

    this.cargaDatos();
  }

  @HostListener('window:keyup.esc') onKeyUp() {
    this.Close();
  }

  cargaDatos(){
    this.ObjAutenticaService.ValidarApp()
      .subscribe(
        data => { 
          if(data.isSuccess){
            if(data.tx_token.length>0){
              variablesGlobales.token=data.tx_token;

              this.ObjEquipoService.Obtener(this.ObjPartido.nU_ID_EQUIPO_LOCAL!).pipe()
                .subscribe(data => {
                  if(data.isSuccess){
                    this.Equipo.push(data.equipo);
                  }
                });
              
              this.ObjEquipoService.Obtener(this.ObjPartido.nU_ID_EQUIPO_VISITA!).pipe()
                .subscribe(data => {
                  if(data.isSuccess){
                    this.Equipo.push(data.equipo);
                  }
                });
            }
            else{
                variablesGlobales.token='';
                variablesGlobales.usuario.codusu=0;
                this.router.navigate(['login']);
            }
          }
        },
        error => {
          console.log(error);
          variablesGlobales.token='';
          variablesGlobales.usuario.codusu=0;
          this.router.navigate(['login']);
        }   
      );
  }

  fnListarEntidad(){   
    this.ObjAutenticaService.ValidarApp()
      .subscribe(
        data => { 
          if(data.isSuccess){
            if(data.tx_token.length>0){
              variablesGlobales.token=data.tx_token;

              this.ObjEntidadService.Listar(1).pipe()
                .subscribe(data => {
                  if(data.isSuccess){
                    this.Entidad=data.entidad;
                    this.filteredEntidad=this.FrmAgregarNotaPartido.controls["codentidad"].valueChanges
                        .pipe(
                          startWith(''),
                          map(value => typeof value === 'string' ? value : value.tX_NUMERO+' | '+value.tX_NOMBRE+' '+value.tX_APELLIDO_PATERNO+' '+value.tX_APELLIDO_MATERNO),            
                          map(nombre => nombre ? this._filterEntidad(nombre) : this.Entidad.slice()
                        )
                    );
                  }
                });
            }
            else{
                variablesGlobales.token='';
                variablesGlobales.usuario.codusu=0;
                this.router.navigate(['login']);
            }
          }
        },
        error => {
          console.log(error);
          variablesGlobales.token='';
          variablesGlobales.usuario.codusu=0;
          this.router.navigate(['login']);
        }   
      );
  }

  displayFnEntidad(entidad?: Entidad): string{
    return entidad ? entidad.tX_NUMERO+' | '+entidad.tX_NOMBRE+' '+entidad.tX_APELLIDO_PATERNO+' '+entidad.tX_APELLIDO_MATERNO: "";
  }

  private _filterEntidad(nombre: string): Entidad[] {
    const filterValue = nombre.toLowerCase();
    return this.Entidad.filter(option => (option.tX_NUMERO+' | '+
                            option.tX_NOMBRE!.toLowerCase()+' '+
                            option.tX_APELLIDO_PATERNO!.toLowerCase()+' '+
                            option.tX_APELLIDO_MATERNO!.toLowerCase()).includes(filterValue));
  }

  fnListarIntegrante(idequipo: number){
    this.Integrante=[];
    this.FrmAgregarNotaPartido.controls["cbodelegado"].setValue(null);

    this.ObjAutenticaService.ValidarApp()
      .subscribe(
        data => { 
          if(data.isSuccess){
            if(data.tx_token.length>0){
              variablesGlobales.token=data.tx_token;

              this.ObjIntegranteService.Listar(1,idequipo)
                .subscribe(data => {
                  if (data.isSuccess){
                    this.Integrante= data.integrante;

                    if(this.Integrante.length==1){
                      this.FrmAgregarNotaPartido.controls["cbodelegado"].setValue(this.Integrante[0].nU_ID_ENTIDAD);
                    }
                  }                                         
                }); 
            }
            else{
                variablesGlobales.token='';
                variablesGlobales.usuario.codusu=0;
                this.router.navigate(['login']);
            }
          }
        },
        error => {
          console.log(error);
          variablesGlobales.token='';
          variablesGlobales.usuario.codusu=0;
          this.router.navigate(['login']);
        }   
      );
  }

  fnHabilitarEquipo(tipo: string){
    switch(tipo){
      case "2": 
        this.isVisibleEquipo=false;
        this.isVisibleEntidad=true;
        this.FrmAgregarNotaPartido.controls["cboequipo"].setValue(null);
        this.FrmAgregarNotaPartido.controls["cbodelegado"].setValue(null);
        break;
      case "3":
        this.isVisibleEquipo=true;
        this.isVisibleEntidad=false;
        this.FrmAgregarNotaPartido.controls["codentidad"].setValue("");
        break;
      default:
        this.isVisibleEquipo=false;
        this.isVisibleEntidad=false;
        this.FrmAgregarNotaPartido.controls["cboequipo"].setValue(null);
        this.FrmAgregarNotaPartido.controls["cbodelegado"].setValue(null);
        this.FrmAgregarNotaPartido.controls["codentidad"].setValue("");
    }
  }

  fnRegistrarEntidad(opc:string,identidad:number){
    const dialogRef = this.dialog.open(RegistrarEntidadComponent, {
      data: {
        codEntidad:identidad,
        op: opc
      },
      disableClose: true 
    });
  }

  Guardar(){
    if(this.ValidarCampos()){
      this.ObjNotaPartido={nU_ID_NOTAPARTIDO: this.codnotapartido};
      this.ObjNotaPartido.nU_ID_FECHA=this.codfecha;
      this.ObjNotaPartido.nU_ID_EVENTO=variablesGlobales.codevento;
      this.ObjNotaPartido.nU_ID_DISCIPLINA=this.coddisciplina;
      this.ObjNotaPartido.nU_ID_PARTIDO=this.ObjPartido.nU_ID_PARTIDO;
      this.ObjNotaPartido.nU_ID_TIPO=parseInt(this.FrmAgregarNotaPartido.controls["cbotipo"].value);
      this.ObjNotaPartido.tX_OBSERVACION=this.FrmAgregarNotaPartido.controls["txtobservacion"].value;
      this.ObjNotaPartido.nU_ID_ESTADO=this.estado;

      if(!this.isVisibleEquipo && !this.isVisibleEntidad){
        this.ObjNotaPartido.nU_ID_ENTIDAD=variablesGlobales.usuario.codentidad;
      }
      else{
        if(this.isVisibleEquipo){
          this.ObjNotaPartido.nU_ID_EQUIPO=this.FrmAgregarNotaPartido.controls["cboequipo"].value;
          this.ObjNotaPartido.nU_ID_ENTIDAD=this.FrmAgregarNotaPartido.controls["cbodelegado"].value;
        }
  
        if(this.isVisibleEntidad){
          this.ObjNotaPartido.nU_ID_ENTIDAD=this.FrmAgregarNotaPartido.controls["codentidad"].value.nU_ID_ENTIDAD;
        }
      }

      this.ObjAutenticaService.ValidarApp()
        .subscribe(
          data => { 
            if(data.isSuccess){
              if(data.tx_token.length>0){
                variablesGlobales.token=data.tx_token;

                this.ObjNotaPartidoService.Actualizar(this.ObjNotaPartido).pipe()
                  .subscribe(
                    data => {
                      if (data.isSuccess){
                        Swal.fire({
                          title: data.tx_Mensaje,
                          icon: 'success'}); 
                        
                        this.dialogRef.close(data.isSuccess);
                      }
                      else{
                        console.log(data.tx_mensaje);
                        Swal.fire(
                          'Advertencia',
                          'Atención: Error al procesar',
                          'warning'
                        );
                      } 
                    },
                    error => {
                      console.log(error);
                      Swal.fire(
                        'Error',
                        'El servicio solicitado no se encuentra disponible',
                        'error'
                      );
                    }
                  );
              }
              else{
                variablesGlobales.token='';
                variablesGlobales.usuario.codusu=0;
                this.router.navigate(['login']);
              }
            }
          },
          error => {
            console.log(error);
            variablesGlobales.token='';
            variablesGlobales.usuario.codusu=0;
            this.router.navigate(['login']);
          }   
        );
    }
  }

  ValidarCampos(){
    if (this.FrmAgregarNotaPartido.controls["cbotipo"].value == null || 
      this.FrmAgregarNotaPartido.controls["cbotipo"].value.length == 0) {
        this.alertMsg('Seleccione Quién');
        return false;
    }

    if(this.isVisibleEquipo){
      if (this.FrmAgregarNotaPartido.controls["cboequipo"].value == null || 
        this.FrmAgregarNotaPartido.controls["cboequipo"].value.length == 0) {
          this.alertMsg('Seleccione Equipo');
          return false;
      }

      if (this.FrmAgregarNotaPartido.controls["cbodelegado"].value == null || 
        this.FrmAgregarNotaPartido.controls["cbodelegado"].value.length == 0) {
          this.alertMsg('Seleccione Delegado');
          return false;
      }
    }

    if(this.isVisibleEntidad){
      if(this.FrmAgregarNotaPartido.controls["codentidad"].value==null ||
        this.FrmAgregarNotaPartido.controls["codentidad"].value.nU_ID_ENTIDAD==undefined){
          this.alertMsg('Seleccione Persona');
          return false;
      }
    }

    if (this.FrmAgregarNotaPartido.controls["txtobservacion"].value == null || 
      this.FrmAgregarNotaPartido.controls["txtobservacion"].value.length == 0 || 
      /^\s+$/.test(this.FrmAgregarNotaPartido.controls["txtobservacion"].value)) {
        this.alertMsg('Ingrese Nota u observación');
        return false;
    }
    else{
      if(this.FrmAgregarNotaPartido.controls["txtobservacion"].value.trim().length<=20){
        this.alertMsg('Las observaciones deben considerar más de 20 caracteres');
        return false;
      }
    }

    return true;
  }

  alertMsg(msg:string){
    Swal.fire({
      html:msg,
      icon:'info'
    })
  }

  pulsar(event:any) {
    if (event.which === 13 && !event.shiftKey) {
      event.preventDefault();
      return false;
    }
    return true;
  }

  Close() {
    this.dialogRef.close();
  }
}
