import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DetallePlanillaJuego } from 'src/app/@core/models/DetallePlanillaJuego';
import { AutenticaService } from 'src/app/@core/services/AutenticaService';
import { DetallePlanillaJuegoService } from 'src/app/@core/services/DetallePlanillaJuegoService';
import { variablesGlobales } from 'src/app/Util/variableGlobal';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-dialog-quitar-detalleplanillajuego',
  templateUrl: './dialog-quitar-detalleplanillajuego.component.html',
  styleUrls: ['./dialog-quitar-detalleplanillajuego.component.scss']
})
export class DialogQuitarDetalleplanillajuegoComponent implements OnInit {

  FrmQuitarDetallePlanillaJuego!: FormGroup;
  coddetalleplanillajuego:number=0;
  ObjDetallePlanillaJuego!: DetallePlanillaJuego;
  opc: number=0;
  titulo!: string;

  constructor(private _formBuilder: FormBuilder,
              private ObjAutenticaService: AutenticaService,
              private router: Router,
              private ObjDetallePlanillaJuegoService: DetallePlanillaJuegoService,
              private dialogRef: MatDialogRef<DialogQuitarDetalleplanillajuegoComponent>,
              @Inject(MAT_DIALOG_DATA) data: any) {
                this.coddetalleplanillajuego=data.coddetalleplanillajuego;
                this.opc=data.opc;
              }

  ngOnInit(): void {
    this.FrmQuitarDetallePlanillaJuego=this._formBuilder.group({
      txtmotivo:[null,Validators.required]
    });

    switch(this.opc){
      case 0: 
        this.titulo="Quitar TA";
        break;
      case 1: 
        this.titulo="Quitar TR";
        break;
      case 2: 
        this.titulo="Quitar Gol";
        break;
    }
  }

  @HostListener('window:keyup.esc') onKeyUp() {
    this.Close();
  }

  Guardar(){
    if (this.FrmQuitarDetallePlanillaJuego.controls["txtmotivo"].value == null || 
      this.FrmQuitarDetallePlanillaJuego.controls["txtmotivo"].value.length == 0 || 
      /^\s+$/.test(this.FrmQuitarDetallePlanillaJuego.controls["txtmotivo"].value)) {
        this.alertMsg('Ingrese Motivo');
    }
    else{
      this.ObjDetallePlanillaJuego={nU_ID: this.coddetalleplanillajuego};
      this.ObjDetallePlanillaJuego.tX_OBSERVACION=this.FrmQuitarDetallePlanillaJuego.controls["txtmotivo"].value;

      this.ObjAutenticaService.ValidarApp()
        .subscribe(
          data => { 
            if(data.isSuccess){
              if(data.tx_token.length>0){
                variablesGlobales.token=data.tx_token;

                this.ObjDetallePlanillaJuegoService.Quitar(this.ObjDetallePlanillaJuego).pipe()
                  .subscribe(
                    data => {
                      if (data.isSuccess){
                        Swal.fire({
                          title: data.tx_Mensaje,
                          icon: 'success'}); 
                        
                        this.dialogRef.close(data.isSuccess);
                      }
                      else{
                        console.log(data.tx_mensaje);
                        Swal.fire(
                          'Advertencia',
                          'Atención: Error al procesar',
                          'warning'
                        );
                      } 
                    },
                    error => {
                      console.log(error);
                      Swal.fire(
                        'Error',
                        'El servicio solicitado no se encuentra disponible',
                        'error'
                      );
                    }
                  );
              }
              else{
                variablesGlobales.token='';
                variablesGlobales.usuario.codusu=0;
                this.router.navigate(['login']);
              }
            }
          },
          error => {
            console.log(error);
            variablesGlobales.token='';
            variablesGlobales.usuario.codusu=0;
            this.router.navigate(['login']);
          }   
        );
    }
  }

  alertMsg(msg:string){
    Swal.fire({
      html:msg,
      icon:'info'
    })
  }

  Close() {
    this.dialogRef.close();
  }

}
