import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels, QrcodeComponent } from '@techiediaries/ngx-qrcode';
import { Archivo } from 'src/app/@core/models/Archivo';
import { Base } from 'src/app/@core/models/Base';
import { Carnet } from 'src/app/@core/models/Carnet';
import { ArchivoService } from 'src/app/@core/services/ArchivoService';
import { AsociadoService } from 'src/app/@core/services/AsociadoService';
import { AutenticaService } from 'src/app/@core/services/AutenticaService';
import { DocumentoService } from 'src/app/@core/services/DocumentoService';
import { variablesGlobales } from 'src/app/Util/variableGlobal';
import { RegistrarEntidadComponent } from 'src/app/menu/controles/registrar-entidad/registrar-entidad.component';
import { RptCarnet2023Component } from 'src/app/menu/eventos/gestiona-equipo/gestiona-integrante/rpt-carnet2023/rpt-carnet2023.component';
import Swal from 'sweetalert2';
import { RegistrarAsociadoComponent } from './registrar-asociado/registrar-asociado.component';
import { Asociado } from 'src/app/@core/models/Asociado';
import { AsociadoRequisito } from 'src/app/@core/models/AsociadoRequisito';

@Component({
  selector: 'app-gestiona-asociado',
  templateUrl: './gestiona-asociado.component.html',
  styleUrls: ['./gestiona-asociado.component.scss']
})
export class GestionaAsociadoComponent implements OnInit {

  @Output() salida:any= new EventEmitter();

  FrmGestAsociado!: FormGroup;
  @Input() ObjBase!:Base;
  admBase: number=0;
  principal:number=1;
  codigo:number=0;
  AsociadoRequisito: AsociadoRequisito[]=[];
  foto:string="";
  ObjArchivo!: Archivo;
  isVisibleData: number=0;
  isVisibleObjeto: number=0;
  isBotonEmitirCarnet: number=0;
  isBotonImprimirCarnet: number=0;
  ObjCarnet!: Carnet;

  Asociado: Asociado[]=[];
  columns:any[]=[];
  displayedColumns: string[] = [];

  AsociadoTableDataSource = new MatTableDataSource(this.Asociado);

  elementType = NgxQrcodeElementTypes.URL;
  errorCorrectionLevel = NgxQrcodeErrorCorrectionLevels.HIGH;
  value: any;
  srcCarnet: any;
  srcFoto: any;
  srcQR: any;
  lblevento!: string;
  lblsigla!: string;
  lblNombre!: string;
  lblEquipo!: string;
  condicion!: string;

  lblEmision!: string;
  lblnumero!: string;
  lblCargo!: string;
  lblDisciplina!: string;

  parametros: any = {
    srcCarnet:null,
    srcFoto:null,
    srcQR: null,
    condicion:null,
    lblevento:null,
    lblsigla:null,
    lblNombre:null,
    lblEquipo:null
  };

  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  @ViewChild(QrcodeComponent, { static : false }) parent!: QrcodeComponent;

  constructor(private _formBuilder: FormBuilder,
              private router: Router,
              private ObjAutenticaService: AutenticaService,
              private ObjAsociadoService: AsociadoService,
              private ObjDocumentoService: DocumentoService,
              private ObjArchivoService: ArchivoService,
              private domSanitizer: DomSanitizer,
              public dialog: MatDialog) { }

  ngOnInit(): void {
    this.FrmGestAsociado=this._formBuilder.group({
    });

    this.admBase=variablesGlobales.usuarioRol.admBase;

    this.showEmitirCarnet();
    this.fnListarFiltro();
  }

  showEmitirCarnet(){
    if(variablesGlobales.usuarioOficinaCargo.codOficina>0){
      this.isBotonEmitirCarnet=1;
      this.isBotonImprimirCarnet=1;
    }
    else{
      this.isBotonEmitirCarnet=0;
      this.isBotonImprimirCarnet=0;
    }
  }

  fnListarFiltro(){
    var data:any=[
      {idcolumna: "nU_ID_ASOCIADO", cabecera: "", class: "dp_nU_ID_ASOCIADO"},
      {idcolumna: "tX_NUMERO", cabecera: "DNI", class: "dp_tX_NUMERO"},
      {idcolumna: "tX_NOMBRE_COMPLETO", cabecera: "Nombre Completo", class: "dp_tX_NOMBRE_COMPLETO"},
      {idcolumna: "tX_CONDICION", cabecera: "Condición", class: "dp_tX_CONDICION"},
      {idcolumna: "tX_TELEFONO", cabecera: "Teléfono", class: "dp_tX_TELEFONO"},
      {idcolumna: "fE_REGISTRO", cabecera: "F. Creación", class: "dp_fE_REGISTRO"}
    ];

    this.columns = data.map((obj:any) => {
      return { 
        columnDef: obj.idcolumna,
        header: obj.cabecera,
        class: obj.class, 
        cell: (element: any) => {
          if(obj.idcolumna==="fE_REGISTRO"){
            return `${element[obj.idcolumna] ? this.convertFecha(element[obj.idcolumna]) : ``}`
          }
          else{
            return `${element[obj.idcolumna] ? element[obj.idcolumna] : ``}`
          }
        }
      }
    });

    this.displayedColumns = data.map((obj:any) => obj.idcolumna);

    this.Asociado= [];
    this.AsociadoTableDataSource.data=this.Asociado; 
    this.AsociadoTableDataSource.paginator=this.paginator;

    this.LlenaGrilla();
  }

  LlenaGrilla(){
    this.ObjAutenticaService.ValidarApp()
      .subscribe(
        data => {
          if(data.isSuccess){
            if(data.tx_token.length>0){
              variablesGlobales.token=data.tx_token;

              this.ObjAsociadoService.Listar(1, this.ObjBase.nU_ID_BASE)
                .subscribe(data => {
                  if (data.isSuccess){
                    this.Asociado= data.asociado;
                    this.AsociadoTableDataSource.data=this.Asociado;
                    this.AsociadoTableDataSource.paginator=this.paginator;
                  }                                         
                });
            }
            else{
                variablesGlobales.token='';
                variablesGlobales.usuario.codusu=0;
                this.router.navigate(['login']);
            }
          }
        },
        error => {
          //////console.log(error);
          variablesGlobales.token='';
          variablesGlobales.usuario.codusu=0;
          this.router.navigate(['login']);
        }   
      ); 
  }

  convertFecha(fecha: string): string{
    var parts=fecha.split("-");
    var anio =parts[0];
    var mes =parts[1];
    var dia =parts[2].substring(0,2);
    var hora =parts[2].substring(3,11);

    return dia+"/"+mes+"/"+anio+" "+hora;
  }

  fnNuevo(opc:string, idasociado:number){
    const dialogRef = this.dialog.open(RegistrarAsociadoComponent, {
      data: {
        objBase: this.ObjBase,
        listaAsociado: this.Asociado,
        codAsociado:idasociado,
        op: opc
      },
      disableClose: true 
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.LlenaGrilla();
      }
    });
  }

  fnEditarEntidad(opc:string,identidad:number){
    const dialogRef = this.dialog.open(RegistrarEntidadComponent, {
      data: {
        codEntidad:identidad,
        op: opc
      },
      disableClose: true 
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.LlenaGrilla();
      }
    });
  }

  fnEmitirCarnet(obj: Asociado){
    this.ObjAutenticaService.ValidarApp()
      .subscribe(
        data => { 
          if(data.isSuccess){
            if(data.tx_token.length>0){
              variablesGlobales.token=data.tx_token;

              this.ObjAsociadoService.GeneraCarnet(4, obj.nU_ID_ASOCIADO!).pipe()
                .subscribe(
                  data => {
                    if (data.isSuccess){
                      Swal.fire({
                        title: data.tx_Mensaje,
                        icon: 'success'}); 
                    }
                    else{
                      Swal.fire({
                        title: data.tx_Mensaje,
                        icon: 'warning'}); 
                    } 
                  },
                  error => {
                    //console.log(error);
                    Swal.fire({
                      title:'El servicio solicitado no se encuentra disponible',
                      icon: 'error',
                    });
                  }
                );
            }
            else{
                //////console.log("Autenticación inválida: "+data.tx_Mensaje);
            }
          }
        },
        error => {
          //////console.log(error);
        }   
      );
  }


  fnImprimirCarnet(obj: Asociado){
    this.ObjAutenticaService.ValidarApp()
      .subscribe(
        data => { 
          if(data.isSuccess){
            if(data.tx_token.length>0){
              variablesGlobales.token=data.tx_token;

              this.ObjDocumentoService.ObtenerCarnet(obj.nU_ID_ENTIDAD!, 4).pipe()
                .subscribe(
                  data => {
                    //console.log(data);
                    if (data.isSuccess){
                      this.ObjCarnet=data.carnet;

                      this.AsociadoRequisito=data.listaarchivosadjuntos;
                      this.srcFoto="";
                      this.value=this.ObjCarnet.tX_LINK;
                      this.lblevento=this.ObjCarnet.tX_DENOMINACION_EVENTO!;
                      this.lblsigla=this.ObjCarnet.tX_SIGLA!;
                      this.lblNombre=this.ObjCarnet.tX_NOMBRE!+"<br>"+this.ObjCarnet.tX_APELLIDO_PATERNO!+" "+this.ObjCarnet.tX_APELLIDO_MATERNO!;
                      this.lblEquipo=this.ObjCarnet.tX_EQUIPO!;
                      this.condicion=this.ObjCarnet.tX_CONDICION!;
                      this.lblEmision=this.ObjCarnet.tX_EMISION!;
                      this.lblnumero=this.ObjCarnet.tX_NUMERO!;
                      this.lblCargo=this.ObjCarnet.tX_CARGO!;
                      this.lblDisciplina=this.ObjCarnet.tX_DISCIPLINA!;

                      let ruta="./assets/img/";

                      if(variablesGlobales.codsistema==1) ruta+="exasadj/";
                      if(variablesGlobales.codsistema==2) ruta+="exacemba/";

                      this.srcCarnet=ruta+
                                    (this.ObjCarnet.tX_CONDICION=="ASIMILADO"?
                                      "carnet_asimilado_2":
                                      "carnet_asociado_2"
                                    )+".jpeg";

                      var band=0;

                      for(let item of this.AsociadoRequisito){
                        if(item.nU_ID_REQUISITO===3){
                          this.ObjArchivoService.ObtenerTrama(item.nU_ID_ARCHIVO+"."+item.tX_EXTENSION).pipe()      
                            .subscribe(
                              data => {
                                if(data.isSuccess){
                                  const byteCharacters= atob(data.tx_Mensaje);
                                  const byteArrays = [];

                                  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
                                    const slice = byteCharacters.slice(offset, offset + 512);

                                    const byteNumbers = new Array(slice.length);
                                    for (let i = 0; i < slice.length; i++) {
                                      byteNumbers[i] = slice.charCodeAt(i);
                                    }

                                    const byteArray = new Uint8Array(byteNumbers);
                                    byteArrays.push(byteArray);
                                  }

                                  const blob = new Blob(byteArrays, {type: 'image/png'});
                                  const reader = new FileReader();
                                  reader.onload = () => {
                                    this.srcFoto = this.domSanitizer.bypassSecurityTrustUrl(reader.result!.toString());
                                  };

                                  reader.readAsDataURL(new File([blob],"imagen_qr",
                                    {type: 'image/png'})); 
                                }  
                                
                                this.fnObtenerCarnetPDF();
                              },
                              error => {
                                //////console.log(error);
                                Swal.fire({
                                  title:'El servicio solicitado no se encuentra disponible',
                                  icon: 'error',
                                });
                              }
                            );
                          
                          band=1;
                          break;
                        }
                      }

                      if(band==0){
                        this.fnObtenerCarnetPDF();
                      }
                    }
                    else{
                      Swal.fire({
                        title:'Carnet no encontrado',
                        icon: 'info',
                      });
                    }
                  },
                  error => {
                    //////console.log(error);
                    Swal.fire({
                      title:'El servicio solicitado no se encuentra disponible',
                      icon: 'error',
                    });
                  }
                );
            }
            else{
                //////console.log("Autenticación inválida: "+data.tx_Mensaje);
            }
          }
        },
        error => {
          //////console.log(error);
        }   
      );
  }

  fnObtenerCarnetPDF(){
    setTimeout(() => {
      const myBase64 = this.parent.qrcElement.nativeElement.querySelector("img").src;
      const byteCharacters= atob(myBase64.substring(22,myBase64.length));
      const byteArrays = [];

      for (let offset = 0; offset < byteCharacters.length; offset += 512) {
        const slice = byteCharacters.slice(offset, offset + 512);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }

      const blob = new Blob(byteArrays, {type: 'image/png'});
      const reader = new FileReader();
      reader.onload = () => {
        this.srcQR = this.domSanitizer.bypassSecurityTrustUrl(reader.result!.toString());
      };

      reader.readAsDataURL(new File([blob],"imagen_qr",
        {type: 'image/png'}));
    }, 0);

    setTimeout(() => {
      this.parametros = {
        srcCarnet:this.srcCarnet,
        srcFoto:this.srcFoto,
        srcQR: this.srcQR,
        condicion:this.condicion,
        lblevento:this.lblevento,
        lblsigla:this.lblsigla,
        lblNombre:this.lblNombre,
        lblEquipo:this.lblEquipo,
        lblEmision:this.lblEmision,
        lblnumero:this.lblnumero,
        lblCargo:this.lblCargo,
        lblDisciplina:this.lblDisciplina
      };

      const dialogRef = this.dialog.open(RptCarnet2023Component, {
        data: {
          parametros:this.parametros
        },
        disableClose: true 
      });
    }, 1000);
  }

  Retorno(){
    this.salida.emit(1);
  }

}
