<div fxLayout="column" fxLayoutAlign="center">
    <form [formGroup]="FrmConsultaFicha">
        <div class="opcion">
            <mat-label class="Titulo">Ficha de Inscripción</mat-label>
        </div>
        <mat-card fxLayout="column">
            <mat-card-content fxLayout="column">
                <h3 class="SubTitulo">Lista de Asociados</h3>
                <table mat-table [dataSource]="SocioTableDataSource">
                    <ng-container matColumnDef="nU_ID_ASOCIADO">
                        <th mat-header-cell *matHeaderCellDef class="priority-1"></th>
                        <td mat-cell *matCellDef="let element" class="priority-1">
                            <button mat-icon-button class="tooltips_cls" (click)="Imprimir(element.nU_ID_ASOCIADO)">
                                <mat-icon fontSet="material-icons-outlined">print</mat-icon>
                                <span>Imprimir</span>
                            </button>                          
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="tX_NOMBRE">
                        <th mat-header-cell *matHeaderCellDef class="priority-2">Nombres y Apellidos</th>
                        <td mat-cell *matCellDef="let element" class="priority-2">{{element.tX_NOMBRE+' '+element.tX_APELLIDO_PATERNO+' '+element.tX_APELLIDO_MATERNO}}</td>
                    </ng-container>
                    <ng-container matColumnDef="nU_ANIO_EGRESO">
                        <th mat-header-cell *matHeaderCellDef class="priority-3">Año de Egreso</th>
                        <td mat-cell *matCellDef="let element" class="priority-3">{{element.nU_ANIO_EGRESO}}</td>
                    </ng-container>
                    <ng-container matColumnDef="tX_TURNO">
                        <th mat-header-cell *matHeaderCellDef class="priority-4">Turno</th>
                        <td mat-cell *matCellDef="let element" class="priority-4">{{element.tX_TURNO}}</td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>  
            </mat-card-content>
        </mat-card> 
    </form>      
</div>
<mat-paginator id="paginadorSocio" class="stylePaginator" [pageSizeOptions]="[10, 20, 50]" showFirstLastButtons></mat-paginator>

