import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Archivo } from 'src/app/@core/models/Archivo';
import { Aux_Solicitud } from 'src/app/@core/models/Aux_Solicitud';
import { Departamento } from 'src/app/@core/models/Departamento';
import { Distrito } from 'src/app/@core/models/Distrito';
import { Provincia } from 'src/app/@core/models/Provincia';
import { Solicitud } from 'src/app/@core/models/Solicitud';
import { ArchivoService } from 'src/app/@core/services/ArchivoService';
import { AutenticaService } from 'src/app/@core/services/AutenticaService';
import { DepartamentoService } from 'src/app/@core/services/DepartamentoService';
import { DistritoService } from 'src/app/@core/services/DistritoService';
import { ProvinciaService } from 'src/app/@core/services/ProvinciaService';
import { SolicitudPadronService } from 'src/app/@core/services/SolicitudPadronService';
import { SolicitudService } from 'src/app/@core/services/SolicitudService';
import { variablesGlobales } from 'src/app/Util/variableGlobal';
import Swal from 'sweetalert2';
import { DialogAdjuntarComponent } from '../dialog-adjuntar/dialog-adjuntar.component';
import { ProgessSpinnerDialogComponent } from 'src/app/progess-spinner-dialog/progess-spinner-dialog.component';
import { DocumentoService } from 'src/app/@core/services/DocumentoService';
import { Documento } from 'src/app/@core/models/Documento';

export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};

@Component({
  selector: 'app-completar-solicitud',
  templateUrl: './completar-solicitud.component.html',
  styleUrls: ['./completar-solicitud.component.scss'],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }
  ]
})
export class CompletarSolicitudComponent implements OnInit {

  FrmRegistrarSolicitud!: FormGroup;

  @Input() token!:string;
  ObjAux_Solicitud!: Aux_Solicitud;
  ObjDocumento!: Documento;
  DocumentoAdjunto!: Documento[];
  Departamento!: Departamento[];
  Provincia!: Provincia[];
  Distrito!: Distrito[];
  codTemp: number=0;
  ObjSolicitud: Solicitud={};


  Archivo: Archivo[]=[];
  ArchivoQuitar: Archivo[]=[];
  displayedColumns: string[] = ['nU_ID_ARCHIVO','tX_NOMBRE','tX_REQUISITO','tX_DESCRIPCION'];
  dataSource = this.Archivo;

  ArchivoTable: Archivo[]=[];
  ArchivoTableDataSource = new MatTableDataSource(this.ArchivoTable);

  @ViewChild('autosize') autosize!: CdkTextareaAutosize;

  constructor(private _formBuilder: FormBuilder,
              private ObjAutenticaService: AutenticaService,
              private ObjSolicitudPadronService: SolicitudPadronService,
              private ObjDocumentoService: DocumentoService,
              private ObjDepartamentoService: DepartamentoService,
              private ObjProvinciaService: ProvinciaService,
              private ObjDistritoService: DistritoService,
              private ObjArchivoService: ArchivoService,
              public dialog: MatDialog) { }

  ngOnInit(): void {
    this.FrmRegistrarSolicitud=this._formBuilder.group({
      coddepartamento: [null, Validators.required],
      codprovincia: [null, Validators.required],
      coddistrito: [null, Validators.required],
      txtdireccion: [null, Validators.required],
      txttipodocumento: [{value:null, disabled:true}],
      txtnumero: [null],
      txtnombre: [null],
      txtape_paterno: [null],
      txtape_materno: [null],
      txtemail: [null],
      txttelefono: [null, Validators.required],
      FecNacimiento:[null,Validators.compose([Validators.required,Validators.maxLength(10)])],
      txtanio:[null,Validators.compose([Validators.required,Validators.maxLength(4)])],
      turno: [null, Validators.required],
      sexo: [null, Validators.required],
      txtnota: [null]
    });

    this.ObjAutenticaService.ValidarApp()
      .subscribe(
        data => { 
          if(data.isSuccess){
            if(data.tx_token.length>0){
              variablesGlobales.token=data.tx_token;

              this.fnListarDepartamento();

              this.ObjSolicitudPadronService.Obtener(this.token).pipe()
                .subscribe(
                  data => {
                    ////console.log(data);
                    if (data.isSuccess){
                      if(data.data!=null){
                        this.ObjAux_Solicitud=data.data;

                        this.FrmRegistrarSolicitud.controls["txttipodocumento"].setValue(this.ObjAux_Solicitud.tX_ID_TIPODOCUMENTOIDENTIDAD);
                        this.FrmRegistrarSolicitud.controls["txtnumero"].setValue(this.ObjAux_Solicitud.tX_NUMERO);
                        this.FrmRegistrarSolicitud.controls["txtnombre"].setValue(this.ObjAux_Solicitud.tX_NOMBRE);
                        this.FrmRegistrarSolicitud.controls["txtape_paterno"].setValue(this.ObjAux_Solicitud.tX_APELLIDO_PATERNO);
                        this.FrmRegistrarSolicitud.controls["txtape_materno"].setValue(this.ObjAux_Solicitud.tX_APELLIDO_MATERNO);
                        this.FrmRegistrarSolicitud.controls["txtemail"].setValue(this.ObjAux_Solicitud.tX_CORREO);

                        if(this.ObjAux_Solicitud.nU_ID_DOCUMENTO!>0){
                          this.ObjDocumentoService.Obtener(this.ObjAux_Solicitud.nU_ID_DOCUMENTO!).pipe()
                            .subscribe(
                              data => {
                                ////console.log(data);
                                if (data.isSuccess){
                                  this.ObjDocumento=data.documento;
                                  this.ObjAux_Solicitud=data.aux_solicitud;
                                  this.DocumentoAdjunto=data.listadocumentoadjuntos;
                                  this.Archivo=data.listaarchivo;
                                  this.ArchivoTableDataSource.data=this.Archivo;

                                  this.FrmRegistrarSolicitud.controls["txttelefono"].setValue(this.ObjAux_Solicitud.tX_NUMERO_TELEFONO);
                                  this.FrmRegistrarSolicitud.controls["FecNacimiento"].setValue(this.convertFechaString(this.ObjAux_Solicitud.fE_NACIMIENTO));
                                  this.FrmRegistrarSolicitud.controls["txtanio"].setValue(this.ObjAux_Solicitud.nU_ANIO_EGRESO);
                                  this.FrmRegistrarSolicitud.controls["turno"].setValue(this.ObjAux_Solicitud.tX_TURNO);
                                  this.FrmRegistrarSolicitud.controls["sexo"].setValue(this.ObjAux_Solicitud.tX_SEXO);
                                  this.FrmRegistrarSolicitud.controls["txtdireccion"].setValue(this.ObjAux_Solicitud.tX_DIRECCION);
                                  this.FrmRegistrarSolicitud.controls["txtnota"].setValue(this.ObjDocumento.tX_OBSERVACION);

                                  this.ObjDepartamentoService.Listar(0,'').pipe()    
                                    .subscribe(
                                      data => {
                                        if(data.isSuccess){
                                          this.Departamento= data.data;
                                          if(this.Departamento.length>0){
                                            this.FrmRegistrarSolicitud.controls["coddepartamento"].setValue(this.ObjAux_Solicitud.tX_UBIGEO!.substring(0,2));

                                            this.ObjProvinciaService.Listar(1,this.ObjAux_Solicitud.tX_UBIGEO!.substring(0,2)).pipe()    
                                              .subscribe(
                                                data => {
                                                  if(data.isSuccess){
                                                    this.Provincia= data.data;
                                                    if(this.Provincia.length>0){
                                                      this.FrmRegistrarSolicitud.controls["codprovincia"].setValue(this.ObjAux_Solicitud.tX_UBIGEO!.substring(0,4));

                                                      this.ObjDistritoService.Listar(0,this.ObjAux_Solicitud.tX_UBIGEO!.substring(0,4)).pipe()    
                                                        .subscribe(
                                                          data => {
                                                            if(data.isSuccess){
                                                              this.Distrito= data.data;
                                                              if(this.Distrito.length>0){
                                                                this.FrmRegistrarSolicitud.controls["coddistrito"].setValue(this.ObjAux_Solicitud.tX_UBIGEO);
                                                              } 
                                                            }                                  
                                                          },
                                                          error => {
                                                            ////console.log(error);
                                                          }
                                                        );
                                                    } 
                                                  }                                  
                                                },
                                                error => {
                                                  ////console.log(error);
                                                }
                                              );
                                          }
                                        }                                  
                                      },
                                      error => {
                                       // //console.log(error);
                                      }
                                    );
                                }
                              },
                              error => {
                                ////console.log(error);
                                Swal.fire({
                                  title:'El servicio solicitado no se encuentra disponible',
                                  icon: 'error',
                                });
                              }
                            );
                        }
                      }
                    }
                  },
                  error => {
                    ////console.log(error);
                    Swal.fire({
                      title:'El servicio solicitado no se encuentra disponible',
                      icon: 'error',
                    });
                  }
                );
            }
            else{
                //.log("Autenticación inválida: "+data.tx_Mensaje);
            }
          }
        },
        error => {
         ////console.log(error);
        }   
      );
  }

  fnListarDepartamento(){
    this.ObjDepartamentoService.Listar(0,'').pipe()    
      .subscribe(
        data => {
          if(data.isSuccess){
            this.Departamento= data.data;
          }                                  
        },
        error => {
          //console.log(error);
          Swal.fire({
            title:'El servicio solicitado no se encuentra disponible',
            icon: 'error',
          });
        }
      );
  }

  fnListarProvincia(coddepartamento:string){
    this.FrmRegistrarSolicitud.patchValue({
      codprovincia: null,
      coddistrito: null,
    });

    this.ObjAutenticaService.ValidarApp()
      .subscribe(
        data => { 
          if(data.isSuccess){
            if(data.tx_token.length>0){
              variablesGlobales.token=data.tx_token;

              this.ObjProvinciaService.Listar(1,coddepartamento).pipe()    
                .subscribe(
                  data => {
                    if(data.isSuccess){
                      this.Provincia= data.data;
                      if(this.Provincia.length>0) this.fnListarDistrito(this.Provincia[0].tX_ID_PROVINCIA);
                    }                                  
                  },
                  error => {
                    //console.log(error);
                    Swal.fire({
                      title:'El servicio solicitado no se encuentra disponible',
                      icon: 'error',
                    });
                  }
                );
            }
            else{
                //console.log("Autenticación inválida: "+data.tx_Mensaje);
            }
          }
        },
        error => {
          //console.log(error);
        }   
      );
  }

  fnListarDistrito(codprovincia:string){
    this.FrmRegistrarSolicitud.patchValue({
      coddistrito: null,
    });

    this.ObjAutenticaService.ValidarApp()
      .subscribe(
        data => { 
          if(data.isSuccess){
            if(data.tx_token.length>0){
              variablesGlobales.token=data.tx_token;

              this.ObjDistritoService.Listar(0,codprovincia).pipe()    
                .subscribe(
                  data => {
                    if(data.isSuccess){
                      this.Distrito= data.data;
                    }                                  
                  },
                  error => {
                    //console.log(error);
                    Swal.fire({
                      title:'El servicio solicitado no se encuentra disponible',
                      icon: 'error',
                    });
                  }
                );
            }
            else{
                //console.log("Autenticación inválida: "+data.tx_Mensaje);
            }
          }
        },
        error => {
          //console.log(error);
        }   
      );
  }

  convertFechaString(fecha: any){
    var parts=fecha.split("/");
    var dia =parts[1];
    var mes =parts[0];
    var anio =parts[2].substring(0,4);
    var mydate =new Date(anio,mes-1,dia)

    return mydate.toISOString();
  }

  pulsar(event:any) {
    if (event.which === 13 && !event.shiftKey) {
      event.preventDefault();
      return false;
    }
    return true;
  }

  fnSoloNumeros(event:any) {
    event.target.value = event.target.value.replace(/[^\d]/g,'');
    return false;
  }

  fnAgregar(){
    const dialogRef = this.dialog.open(DialogAdjuntarComponent, {
      data: {
        origen:"FrmCompletarSolicitud",
        listaArchivo: this.Archivo,
      },
      disableClose: true 
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.codTemp++;

        let model={'nU_ID_ARCHIVO':0,
                    'tX_NOMBRE':result.nomarchivo,
                    'nU_ID_DOC_REFERENCIA':19,
                    'nU_ID_REQUISITO':result.codrequisito,
                    'tX_REQUISITO':result.requisito,
                    'tX_DESCRIPCION':result.descripcion,                        
                    'tX_EXTENSION':result.extension,                       
                    'nU_ID_ESTADO':3,
                    'archivo':result.file,     
                    'trama':result.trama,              
                    'codTemp':this.codTemp};

        this.Archivo.push(model);          
        this.ArchivoTableDataSource.data=this.Archivo;
      }
    });
  }

  fnQuitar(obj:Archivo){
    Swal.fire({
      text: "¿Está seguro de eliminar el registro?",
      icon: 'question',
      showCancelButton: true,
      cancelButtonText:'No',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí',
      reverseButtons: true
    }).then((result) => {
      if (result.value){
        if(obj.nU_ID_ARCHIVO===0){
          this.Archivo=this.Archivo.filter((value,key)=>{
            return value.codTemp != obj.codTemp;
          });     
        }
        else{
          this.Archivo=this.Archivo.filter((value,key)=>{
            return value.nU_ID_ARCHIVO != obj.nU_ID_ARCHIVO;
          });
          this.ArchivoQuitar.push(obj);
        }
        this.ArchivoTableDataSource.data=this.Archivo;
      }
    });
  }

  fnDescargar(obj: Archivo){
    if(obj.nU_ID_ARCHIVO===0){
      this.fnObtenerArchivo(obj);
    }
    else{
      this.ObjAutenticaService.ValidarApp()
        .subscribe(
          data => { 
            if(data.isSuccess){
              if(data.tx_token.length>0){
                variablesGlobales.token=data.tx_token;
  
                this.ObjArchivoService.ObtenerTrama(obj.tX_NOMBRE_ARCHIVO!).pipe()    
                  .subscribe(
                    data => {
                      if(data.isSuccess){
                        obj.trama=data.tx_Mensaje;
                        this.fnObtenerArchivo(obj);
                      } 
                      else{
                        Swal.fire({
                          title:'Archivo no encontrado',
                          icon: 'info',
                        });
                      }                                  
                    },
                    error => {
                      //console.log(error);
                      Swal.fire({
                        title:'El servicio solicitado no se encuentra disponible',
                        icon: 'error',
                      });
                    }
                  );
              }
              else{
                  //console.log("Autenticación inválida: "+data.tx_Mensaje);
              }
            }
          },
          error => {
            //console.log(error);
          }   
        );
    }
  }

  fnObtenerArchivo(obj: Archivo){
    const byteCharacters= atob(obj.trama);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);
  
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
  
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    var nombre=(obj.nU_ID_ARCHIVO==0)?obj.tX_NOMBRE:obj.tX_NOMBRE_ARCHIVO;

    const blob = new Blob(byteArrays, {type: 'application/'+nombre!.split('.').pop()});
    const blobUrl = URL.createObjectURL(blob);

    const link:any = document.createElement('a');
    link.href = blobUrl;
    link.download = nombre;
    link.click();
  }


  alertMsg(msg:string){
    Swal.fire({
      html:msg,
      icon:'info'
    })
  }

  ValidarCampos(){
    if (this.FrmRegistrarSolicitud.controls["txttelefono"].value === null || 
      this.FrmRegistrarSolicitud.controls["txttelefono"].value.length === 0 || 
      /^\s+$/.test(this.FrmRegistrarSolicitud.controls["txttelefono"].value)) {
        this.alertMsg('Ingrese Teléfono');
        return false;
    }

    if (this.FrmRegistrarSolicitud.controls["FecNacimiento"].value === null || 
      this.FrmRegistrarSolicitud.controls["FecNacimiento"].value.length === 0 || 
      /^\s+$/.test(this.FrmRegistrarSolicitud.controls["FecNacimiento"].value)) {
        this.alertMsg('Ingrese Fecha de Nacimiento');
        return false;
    }

    if (this.FrmRegistrarSolicitud.controls["txtanio"].value === null || 
      this.FrmRegistrarSolicitud.controls["txtanio"].value.length === 0 || 
      /^\s+$/.test(this.FrmRegistrarSolicitud.controls["txtanio"].value)) {
        this.alertMsg('Ingrese Año de Egreso');
        return false;
    }

    if (this.FrmRegistrarSolicitud.controls["turno"].value === null || 
      this.FrmRegistrarSolicitud.controls["turno"].value.length === 0) {
        this.alertMsg('Ingrese Turno');
        return false;
    }

    if (this.FrmRegistrarSolicitud.controls["sexo"].value === null || 
      this.FrmRegistrarSolicitud.controls["sexo"].value.length === 0) {
        this.alertMsg('Ingrese Sexo');
        return false;
    }

    if (this.FrmRegistrarSolicitud.controls["coddepartamento"].value === null || 
      this.FrmRegistrarSolicitud.controls["coddepartamento"].value.length === 0) {
        this.alertMsg('Ingrese Departamento');
        return false;
    }

    if (this.FrmRegistrarSolicitud.controls["codprovincia"].value === null || 
      this.FrmRegistrarSolicitud.controls["codprovincia"].value.length === 0) {
        this.alertMsg('Ingrese Provincia');
        return false;
    }

    if (this.FrmRegistrarSolicitud.controls["coddistrito"].value === null || 
      this.FrmRegistrarSolicitud.controls["coddistrito"].value.length === 0) {
        this.alertMsg('Ingrese Distrito');
        return false;
    }

    if (this.FrmRegistrarSolicitud.controls["txtdireccion"].value === null || 
      this.FrmRegistrarSolicitud.controls["txtdireccion"].value.length === 0 || 
      /^\s+$/.test(this.FrmRegistrarSolicitud.controls["txtdireccion"].value)) {
        this.alertMsg('Ingrese Dirección');
        return false;
    }

    if(this.Archivo.length===0){
      this.alertMsg('Adjunte requisito');
      return false;
    }
    
    return true;
  }

  convertFechaDate(fecha: Date) {
    const dia: string = fecha.getDate().toString().padStart(2, '0');
    const mes: string = (fecha.getMonth() + 1).toString().padStart(2, '0');
    const anio: string = fecha.getFullYear().toString();
    return dia + '/' + mes + '/' + anio; 
  }

  Guardar(){
    
    if(this.ValidarCampos()){
      ////console.log(this.FrmRegistrarSolicitud.controls["FecNacimiento"].value);
      //this.ObjAux_Solicitud.fE_NACIMIENTO=this.convertFechaDate(new Date(this.FrmRegistrarSolicitud.controls["FecNacimiento"].value));
      this.ObjAux_Solicitud.fE_NACIMIENTO=new Date(this.FrmRegistrarSolicitud.controls["FecNacimiento"].value);
      this.ObjAux_Solicitud.nU_ANIO_EGRESO=parseInt(this.FrmRegistrarSolicitud.controls["txtanio"].value);
      this.ObjAux_Solicitud.tX_TURNO=this.FrmRegistrarSolicitud.controls["turno"].value;
      this.ObjAux_Solicitud.tX_SEXO=this.FrmRegistrarSolicitud.controls["sexo"].value;

      this.ObjSolicitud.objAux_Solicitud=this.ObjAux_Solicitud;
      this.ObjSolicitud.objEntidad={nU_ID_ENTIDAD:0};
      this.ObjSolicitud.objEntidad.tX_ID_TIPODOCUMENTOIDENTIDAD=this.ObjAux_Solicitud.tX_ID_TIPODOCUMENTOIDENTIDAD;
      this.ObjSolicitud.objEntidad.tX_NUMERO=this.ObjAux_Solicitud.tX_NUMERO;
      this.ObjSolicitud.objEntidad.tX_DENOMINACION=this.ObjAux_Solicitud.tX_NOMBRE+" "+this.ObjAux_Solicitud.tX_APELLIDO_PATERNO+" "+this.ObjAux_Solicitud.tX_APELLIDO_MATERNO;
      this.ObjSolicitud.objEntidad.tX_NOMBRE=this.ObjAux_Solicitud.tX_NOMBRE;
      this.ObjSolicitud.objEntidad.tX_APELLIDO_PATERNO=this.ObjAux_Solicitud.tX_APELLIDO_PATERNO;
      this.ObjSolicitud.objEntidad.tX_APELLIDO_MATERNO=this.ObjAux_Solicitud.tX_APELLIDO_MATERNO;
      this.ObjSolicitud.objEntidad.objEstablecimiento={nU_ID_ESTABLECIMIENTO:0};
      this.ObjSolicitud.objEntidad.objEstablecimiento.tX_DEPARTAMENTO=this.FrmRegistrarSolicitud.controls["coddepartamento"].value;
      this.ObjSolicitud.objEntidad.objEstablecimiento.tX_PROVINCIA=this.FrmRegistrarSolicitud.controls["codprovincia"].value;
      this.ObjSolicitud.objEntidad.objEstablecimiento.tX_UBIGEO=this.FrmRegistrarSolicitud.controls["coddistrito"].value;
      this.ObjSolicitud.objEntidad.objEstablecimiento.tX_DIRECCION=this.FrmRegistrarSolicitud.controls["txtdireccion"].value;
      this.ObjSolicitud.objEntidad.objCorreo={nU_ID_CORREO:0};
      this.ObjSolicitud.objEntidad.objCorreo.tX_CORREO=this.ObjAux_Solicitud.tX_CORREO;
      this.ObjSolicitud.objEntidad.objTelefono={nU_ID_TELEFONO:0};
      this.ObjSolicitud.objEntidad.objTelefono.tX_NUMERO=this.FrmRegistrarSolicitud.controls["txttelefono"].value;
      this.ObjSolicitud.listaArchivo=this.Archivo;
      this.ObjSolicitud.tX_OBSERVACION=this.FrmRegistrarSolicitud.controls["txtnota"].value;

      this.ObjAutenticaService.ValidarApp()
        .subscribe(
          data => { 
            if(data.isSuccess){
              if(data.tx_token.length>0){
                variablesGlobales.token=data.tx_token;
                //Para la carga de archivo va soblre el Id de documento y el tipo de documento 19
                
                let dialogRef = this.dialog.open(ProgessSpinnerDialogComponent, { 
                  panelClass: 'transparent',
                  disableClose: true
                });

                this.ObjSolicitudPadronService.Presentar(this.ObjSolicitud).pipe()
                  .subscribe(
                    data => {
                      ////console.log(data);
                      if (data.isSuccess){
                        for(let archivoSave of this.Archivo){
                          let oFormData = new FormData();
                          ////console.log(oFormData);        
                          ////console.log(archivoSave);  
                          oFormData.append("nU_ID_ARCHIVO", archivoSave.nU_ID_ARCHIVO!.toString());                        
                          oFormData.append("tX_NOMBRE", archivoSave.tX_NOMBRE!);
                          oFormData.append("nU_ID_DOC_REFERENCIA", archivoSave.nU_ID_DOC_REFERENCIA!.toString());
                          oFormData.append("nU_ID_REFERENCIA", data.nU_ID_CODIGO!.trim());
                          oFormData.append("nU_ID_REQUISITO", archivoSave.nU_ID_REQUISITO!.toString());
                          oFormData.append("tX_DESCRIPCION", archivoSave.tX_DESCRIPCION!);
                          oFormData.append("tX_EXTENSION", archivoSave.tX_EXTENSION!);
                          //oFormData.append("nU_ID_USUARIO", variablesGlobales.usuario.codusu!.toString());
                          oFormData.append("nU_ID_USUARIO", "1");
                          oFormData.append("archivo", archivoSave.archivo);
                          ////console.log(variablesGlobales.usuario.codusu);
                          this.ObjArchivoService.EnviarArchivo(oFormData).subscribe(
                            data1=>{
                              ////console.log(data1);
                            }
                          );
                        };

                        dialogRef.close();
                        
                        Swal.fire({
                          title: data.tx_Mensaje,
                          icon: 'success'}); 
                        // Limpia valores obtenidos
                        this.FrmRegistrarSolicitud.patchValue({
                          coddepartamento: null,
                          codprovincia: null,
                          coddistrito: null,
                          txtdireccion: null,
                          txttipodocumento: null,
                          txtnumero: null,
                          txtnombre: null,
                          txtape_paterno: null,
                          txtape_materno: null,
                          txtemail: null,
                          txttelefono: null,
                          FecNacimiento: null,
                          txtanio: null,
                          turno: null,
                          sexo: null,
                          txtnota: null
                        });
                        this.Archivo=[];
                        this.ArchivoTableDataSource.data=this.Archivo; 
                      }
                      else{
                        dialogRef.close();
                        Swal.fire({
                          title: data.tx_Mensaje,
                          icon: 'warning'}); 
                      } 
                    },
                    error => {
                      dialogRef.close();
                      //console.log(error);
                      Swal.fire({
                        title:'El servicio solicitado no se encuentra disponible',
                        icon: 'error',
                      });
                    }
                  );
              }
              else{
                  //console.log("Autenticación inválida: "+data.tx_Mensaje);
              }
            }
          },
          error => {
            //console.log(error);
          }   
        );
    }
  }
}
