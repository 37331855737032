import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Partido } from 'src/app/@core/models/Partido';
import { AutenticaService } from 'src/app/@core/services/AutenticaService';
import { PartidoService } from 'src/app/@core/services/PartidoService';
import { variablesGlobales } from 'src/app/Util/variableGlobal';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-dialog-suspender-partido',
  templateUrl: './dialog-suspender-partido.component.html',
  styleUrls: ['./dialog-suspender-partido.component.scss']
})
export class DialogSuspenderPartidoComponent implements OnInit {

  FrmSuspenderPartido!: FormGroup;
  codpartido:number=0;
  opc: number=0;
  titulo!: string;
  ObjPartido!: Partido;

  constructor(private _formBuilder: FormBuilder,
              private ObjAutenticaService: AutenticaService,
              private router: Router,
              private ObjPartidoService: PartidoService,
              private dialogRef: MatDialogRef<DialogSuspenderPartidoComponent>,
              @Inject(MAT_DIALOG_DATA) data: any) {
                this.codpartido=data.codpartido;
                this.opc=data.opc;

                if(this.opc==1){
                  this.titulo="Registro de Suspensión";
                }
                else{
                  this.titulo="Quitar Partido";
                }
              }

  ngOnInit(): void {
    this.FrmSuspenderPartido=this._formBuilder.group({
      txtmotivo:[null,Validators.required]
    });
  }

  @HostListener('window:keyup.esc') onKeyUp() {
    this.Close();
  }

  Guardar(){
    if (this.FrmSuspenderPartido.controls["txtmotivo"].value == null || 
      this.FrmSuspenderPartido.controls["txtmotivo"].value.length == 0 || 
      /^\s+$/.test(this.FrmSuspenderPartido.controls["txtmotivo"].value)) {
        this.alertMsg('Ingrese Motivo');
    }
    else{
      this.ObjPartido={nU_ID_PARTIDO: this.codpartido};
      this.ObjPartido.tX_OBSERVACION=this.FrmSuspenderPartido.controls["txtmotivo"].value;

      this.Procesar(this.ObjPartido);
    }
  }

  alertMsg(msg:string){
    Swal.fire({
      html:msg,
      icon:'info'
    })
  }

  Procesar(obj: Partido){
    this.ObjAutenticaService.ValidarApp()
      .subscribe(
        data => { 
          if(data.isSuccess){
            if(data.tx_token.length>0){
              variablesGlobales.token=data.tx_token;

              if(this.opc==1){
                this.ObjPartidoService.Suspender(obj).pipe()
                  .subscribe(
                    data => {
                      if (data.isSuccess){
                        Swal.fire({
                          title: data.tx_Mensaje,
                          icon: 'success'}); 
                        
                        this.dialogRef.close(data.isSuccess);
                      }
                      else{
                        console.log(data.tx_mensaje);
                        Swal.fire(
                          'Advertencia',
                          'Atención: Error al procesar',
                          'warning'
                        );
                      } 
                    },
                    error => {
                      console.log(error);
                      Swal.fire(
                        'Error',
                        'El servicio solicitado no se encuentra disponible',
                        'error'
                      );
                    }
                  );
              }
              else{
                this.ObjPartidoService.Quitar(obj).pipe()
                  .subscribe(
                    data => {
                      if (data.isSuccess){
                        Swal.fire({
                          title: data.tx_Mensaje,
                          icon: 'success'}); 
                        
                        this.dialogRef.close(data.isSuccess);
                      }
                      else{
                        console.log(data.tx_mensaje);
                        Swal.fire(
                          'Advertencia',
                          'Atención: Error al procesar',
                          'warning'
                        );
                      } 
                    },
                    error => {
                      console.log(error);
                      Swal.fire(
                        'Error',
                        'El servicio solicitado no se encuentra disponible',
                        'error'
                      );
                    }
                  );
              } 
            }
            else{
              variablesGlobales.token='';
              variablesGlobales.usuario.codusu=0;
              this.router.navigate(['login']);
            }
          }
        },
        error => {
          console.log(error);
          variablesGlobales.token='';
          variablesGlobales.usuario.codusu=0;
          this.router.navigate(['login']);
        }   
      );
  }

  Close() {
    this.dialogRef.close();
  }

}
