import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { Component, HostListener, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { map, startWith } from 'rxjs/operators';
import { Archivo } from 'src/app/@core/models/Archivo';
import { Asociado } from 'src/app/@core/models/Asociado';
import { AsociadoRequisito } from 'src/app/@core/models/AsociadoRequisito';
import { Base } from 'src/app/@core/models/Base';
import { Entidad } from 'src/app/@core/models/Entidad';
import { ArchivoService } from 'src/app/@core/services/ArchivoService';
import { AsociadoRequisitoService } from 'src/app/@core/services/AsociadoRequisitoService';
import { AsociadoService } from 'src/app/@core/services/AsociadoService';
import { AutenticaService } from 'src/app/@core/services/AutenticaService';
import { EntidadService } from 'src/app/@core/services/EntidadService';
import { variablesGlobales } from 'src/app/Util/variableGlobal';
import { DialogAdjuntarComponent } from 'src/app/completar-registro/dialog-adjuntar/dialog-adjuntar.component';
import { RegistrarEntidadComponent } from 'src/app/menu/controles/registrar-entidad/registrar-entidad.component';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-registrar-asociado',
  templateUrl: './registrar-asociado.component.html',
  styleUrls: ['./registrar-asociado.component.scss']
})
export class RegistrarAsociadoComponent implements OnInit {

  FrmRegistrarAsociado!: FormGroup;
  codigo:number=0;
  opc!:string;
  ObjBase!: Base;
  ListaAsociado:Asociado[]=[];
  Entidad: Entidad[]=[];
  filteredEntidad: any;
  ObjAsociado!: Asociado;
  txtTitulo!: string;
  admBase: number=0;
  codTemp: number=0;
  ObjAsociadoRequisito!: AsociadoRequisito;
  estado: number=1;

  Archivo: Archivo[]=[];
  ArchivoQuitar: Archivo[]=[];
  displayedColumns: string[] = ['nU_ID_ARCHIVO','tX_NOMBRE','tX_REQUISITO','tX_DESCRIPCION'];
  dataSource = this.Archivo;

  ArchivoTable: Archivo[]=[];
  ArchivoTableDataSource = new MatTableDataSource(this.ArchivoTable);
  
  @ViewChild('autosize') autosize!: CdkTextareaAutosize;

  constructor(private _formBuilder: FormBuilder,
              private ObjAutenticaService: AutenticaService,
              private ObjEntidadService: EntidadService,
              private ObjAsociadoService: AsociadoService,
              private ObjArchivoService: ArchivoService,
              private ObjAsociadoRequisitoService: AsociadoRequisitoService,
              public dialog: MatDialog,
              private dialogRef: MatDialogRef<RegistrarAsociadoComponent>,
              @Inject(MAT_DIALOG_DATA) data: any) {
                this.ObjBase=data.objBase;
                this.ListaAsociado=data.listaAsociado;
                this.codigo=parseInt(data.codAsociado);
                this.opc=data.op;
              }

  ngOnInit(): void {
    this.FrmRegistrarAsociado=this._formBuilder.group({
      codentidad:[null, Validators.compose([Validators.required])],
      cbocondicion:[null, Validators.compose([Validators.required])]
    }); 

    this.admBase=variablesGlobales.usuarioRol.admBase;

    if(this.opc==='MOD'){
      this.txtTitulo="Editar Asociado";
      this.Obtener();
    } 
    else{
      this.txtTitulo="Registrar Asociado";
      this.FrmRegistrarAsociado.controls["cbocondicion"].setValue("HABIL");
      this.FrmRegistrarAsociado.controls["cbocondicion"].disable();
    } 
  }

  @HostListener('window:keyup.esc') onKeyUp() {
    this.Close();
  }

  Obtener(){ 
    this.ObjAutenticaService.ValidarApp()
      .subscribe(
        data => { 
          if(data.isSuccess){
            if(data.tx_token.length>0){
              variablesGlobales.token=data.tx_token;

              this.ObjAsociadoService.Obtener(this.codigo).pipe()
                .subscribe(
                  data => {
                    if (data.isSuccess){
                      this.ObjAsociado=data.asociado;

                      this.fnObtenerEntidad(this.ObjAsociado.nU_ID_ENTIDAD!);
                      this.FrmRegistrarAsociado.controls["cbocondicion"].setValue(this.ObjAsociado.tX_CONDICION);
                      this.estado=this.ObjAsociado.nU_ID_ESTADO!;

                      for(let item of data.listaarchivosadjuntos){
                        let model={'nU_ID_ARCHIVO':item.nU_ID_ARCHIVO,
                                    'tX_NOMBRE_ARCHIVO':item.nU_ID_ARCHIVO+'.'+item.tX_EXTENSION,
                                    'nU_ID_REQUISITO':item.nU_ID_REQUISITO,
                                    'tX_REQUISITO':item.tX_REQUISITO,
                                    'tX_DESCRIPCION':item.tX_DESCRIPCION,
                                    'nU_ID_TABLA_ARCHIVO': item.nU_ID_INTEGRANTEREQUISITO,                        
                                    'tX_EXTENSION':item.tX_EXTENSION};
  
                        this.Archivo.push(model);                      
                      }
  
                      this.ArchivoTableDataSource.data=this.Archivo;
                    }
                  },
                  error => {
                    //console.log(error);
                    Swal.fire({
                      title:'El servicio solicitado no se encuentra disponible',
                      icon: 'error',
                    });
                  }
                );  
            }
            else{
                //console.log("Autenticación inválida: "+data.tx_Mensaje);
            }
          }
        },
        error => {
          //console.log(error);
        }   
      );                              
  }

  fnObtenerEntidad(identidad:number){
    this.ObjEntidadService.Obtener(identidad,0).pipe()
      .subscribe(data => {
        if (data.isSuccess){
          this.FrmRegistrarAsociado.controls["codentidad"].setValue(data.entidad);
        }
      });
  }

  fnListarEntidad(){   
    this.ObjAutenticaService.ValidarApp()
      .subscribe(
        data => { 
          if(data.isSuccess){
            if(data.tx_token.length>0){
              variablesGlobales.token=data.tx_token;

              this.ObjEntidadService.Listar(1).pipe()
                .subscribe(data => {
                  if(data.isSuccess){
                    this.Entidad=data.entidad;
                    this.filteredEntidad=this.FrmRegistrarAsociado.controls["codentidad"].valueChanges
                        .pipe(
                          startWith(''),
                          map(value => typeof value === 'string' ? value : value.tX_NUMERO+' | '+value.tX_NOMBRE+' '+value.tX_APELLIDO_PATERNO+' '+value.tX_APELLIDO_MATERNO),            
                          map(nombre => nombre ? this._filterEntidad(nombre) : this.Entidad.slice()
                        )
                    );
                  }
                });
            }
            else{
                //console.log("Autenticación inválida: "+data.tx_Mensaje);
            }
          }
        },
        error => {
          //console.log(error);
        }   
      ); 
  }

  displayFnEntidad(entidad?: Entidad): string{
    return entidad ? entidad.tX_NUMERO+' | '+entidad.tX_NOMBRE+' '+entidad.tX_APELLIDO_PATERNO+' '+entidad.tX_APELLIDO_MATERNO: "";
  }

  private _filterEntidad(nombre: string): Entidad[] {
    const filterValue = nombre.toLowerCase();
    return this.Entidad.filter(option => (option.tX_NUMERO+' | '+
                            option.tX_NOMBRE!.toLowerCase()+' '+
                            option.tX_APELLIDO_PATERNO!.toLowerCase()+' '+
                            option.tX_APELLIDO_MATERNO!.toLowerCase()).includes(filterValue));
  }

  fnLimpiar(){
    if(this.FrmRegistrarAsociado.controls["codentidad"].value==null ||
       this.FrmRegistrarAsociado.controls["codentidad"].value.nU_ID_ENTIDAD==undefined){
        this.Archivo=[];
        this.ArchivoTableDataSource.data=this.Archivo;
        this.codTemp=0;
    }
  }

  fnObtenerArchivos(obj: Entidad){
    this.ObjAutenticaService.ValidarApp()
      .subscribe(
        data => { 
          if(data.isSuccess){
            if(data.tx_token.length>0){
              variablesGlobales.token=data.tx_token;

              this.ObjArchivoService.Listar(3,obj.nU_ID_ENTIDAD).pipe()
                .subscribe(data => {
                  if (data.isSuccess){
                    for(let item of data.listaarchivo){
                      if(item.nU_ID_REQUISITO==1 || item.nU_ID_REQUISITO==3){
                        this.codTemp++;

                        let model={'nU_ID_ARCHIVO':item.nU_ID_ARCHIVO,
                                    'tX_NOMBRE_ARCHIVO':item.tX_NOMBRE_ARCHIVO,
                                    'nU_ID_REQUISITO':item.nU_ID_REQUISITO,
                                    'tX_REQUISITO':item.tX_REQUISITO,
                                    'tX_DESCRIPCION':item.tX_DESCRIPCION,   
                                    'nU_ID_TABLA_ARCHIVO':0,
                                    'tX_EXTENSION':item.tX_EXTENSION,
                                    'codTemp':this.codTemp};

                        this.Archivo.push(model); 
                      }                     
                    }

                    this.ArchivoTableDataSource.data=this.Archivo;
                  }
                });
            }
            else{
              //console.log("Autenticación inválida: "+data.tx_Mensaje);
            }
          }
        },
        error => {
          //console.log(error);
        }   
      );
  }

  fnQuitar(obj:Archivo){
    Swal.fire({
      text: "¿Está seguro de eliminar el registro?",
      icon: 'question',
      showCancelButton: true,
      cancelButtonText:'No',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí',
      reverseButtons: true
    }).then((result) => {
      if (result.value){
        if(obj.nU_ID_ARCHIVO===0){
          this.Archivo=this.Archivo.filter((value,key)=>{
            return value.codTemp != obj.codTemp;
          });     
        }
        else{
          this.Archivo=this.Archivo.filter((value,key)=>{
            return value.nU_ID_ARCHIVO != obj.nU_ID_ARCHIVO;
          });

          if(obj.codTemp==undefined){
            this.ArchivoQuitar.push(obj);
          }
        }
        this.ArchivoTableDataSource.data=this.Archivo;
      }
    });
  }

  fnDescargar(obj: Archivo){
    if(obj.nU_ID_ARCHIVO===0){
      this.fnObtenerArchivo(obj);
    }
    else{
      this.ObjAutenticaService.ValidarApp()
        .subscribe(
          data => { 
            if(data.isSuccess){
              if(data.tx_token.length>0){
                variablesGlobales.token=data.tx_token;
  
                this.ObjArchivoService.ObtenerTrama(obj.tX_NOMBRE_ARCHIVO!).pipe()    
                  .subscribe(
                    data => {
                      if(data.isSuccess){
                        obj.trama=data.tx_Mensaje;
                        this.fnObtenerArchivo(obj);
                      } 
                      else{
                        Swal.fire({
                          title:'Archivo no encontrado',
                          icon: 'info',
                        });
                      }                                   
                    },
                    error => {
                      //console.log(error);
                      Swal.fire({
                        title:'El servicio solicitado no se encuentra disponible',
                        icon: 'error',
                      });
                    }
                  );
              }
              else{
                  //console.log("Autenticación inválida: "+data.tx_Mensaje);
              }
            }
          },
          error => {
            //console.log(error);
          }   
        );
    }
  }

  fnObtenerArchivo(obj: Archivo){
    const byteCharacters= atob(obj.trama);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);
  
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
  
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    var nombre=(obj.nU_ID_ARCHIVO==0)?obj.tX_NOMBRE:obj.tX_NOMBRE_ARCHIVO;

    const blob = new Blob(byteArrays, {type: 'application/'+nombre!.split('.').pop()});
    const blobUrl = URL.createObjectURL(blob);

    const link:any = document.createElement('a');
    link.href = blobUrl;
    link.download = nombre;
    link.click();
  }

  fnRegistrarEntidad(opc:string,identidad:number){
    const dialogRef = this.dialog.open(RegistrarEntidadComponent, {
      data: {
        codEntidad:identidad,
        op: opc
      },
      disableClose: true 
    });
  }

  fnAgregar(){
    if(this.FrmRegistrarAsociado.controls["codentidad"].value==null ||
      this.FrmRegistrarAsociado.controls["codentidad"].value.nU_ID_ENTIDAD==undefined){
        this.alertMsg("No se ha seleccionado persona");
        return;
    }

    const dialogRef = this.dialog.open(DialogAdjuntarComponent, {
      data: {
        origen:"FrmRegistrarAsociado",
        codentidad: this.FrmRegistrarAsociado.controls["codentidad"].value.nU_ID_ENTIDAD,
        listaArchivo: this.Archivo,
      },
      disableClose: true 
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.codTemp++;

        let model: any = {'nU_ID_ARCHIVO':result.codarchivo,
                          'nU_ID_TABLA_ARCHIVO':0,
                          'nU_ID_REQUISITO':result.codrequisito,
                          'tX_REQUISITO':result.requisito,
                          'tX_DESCRIPCION':result.descripcion,                        
                          'tX_EXTENSION':result.extension,             
                          'codTemp':this.codTemp};

        if(result.codarchivo>0){
          model['tX_NOMBRE_ARCHIVO']=result.nomarchivo;
        }
        else{
          model['tX_NOMBRE']=result.nomarchivo;
          model['nU_ID_ESTADO']=3;
          model['archivo']=result.file;
          model['trama']=result.trama;
        }

        this.Archivo.push(model);          
        this.ArchivoTableDataSource.data=this.Archivo;
      }
    });
  }

  Guardar(){
    if(this.ValidarCampos()){
      this.ObjAsociado={nU_ID_ASOCIADO:this.codigo};
      this.ObjAsociado.nU_ID_ENTIDAD=this.FrmRegistrarAsociado.controls["codentidad"].value.nU_ID_ENTIDAD
      this.ObjAsociado.nU_ANIO_EGRESO=this.ObjBase.nU_ANIO_PROMOCION;
      this.ObjAsociado.tX_TURNO=this.ObjBase.tX_TURNO;
      this.ObjAsociado.nU_ID_BASE=this.ObjBase.nU_ID_BASE;
      this.ObjAsociado.nU_DATOS_PERSONALES=0;
      this.ObjAsociado.nU_ESTATUTOS=0;
      this.ObjAsociado.nU_ID_ESTADO=this.estado;
      this.ObjAsociado.tX_CONDICION=this.FrmRegistrarAsociado.controls["cbocondicion"].value;
      this.ObjAsociado.tX_SEXO=this.FrmRegistrarAsociado.controls["codentidad"].value.tX_SEXO;

      if(this.FrmRegistrarAsociado.controls["codentidad"].value.fE_NACIMIENTO!=null){
        this.ObjAsociado.fE_NACIMIENTO=this.FrmRegistrarAsociado.controls["codentidad"].value.fE_NACIMIENTO;
      }

      this.ObjAutenticaService.ValidarApp()
        .subscribe(
          data => { 
            if(data.isSuccess){
              if(data.tx_token.length>0){
                variablesGlobales.token=data.tx_token;

                this.ObjAsociadoService.Actualizar(this.ObjAsociado).pipe()
                  .subscribe(
                    data => {
                      if (data.isSuccess){
                        if(parseInt(data.nU_ID_CODIGO.trim())>0){
                          for(let archivoSave of this.Archivo){
                            let oFormData = new FormData(); 
                            if (archivoSave.nU_ID_TABLA_ARCHIVO==0){
                              if (archivoSave.archivo!=null){
                                oFormData.append("nU_RELACIONADO", "5"); 
                                oFormData.append("nU_ID_RELACIONADO", data.nU_ID_CODIGO.trim()); 
                                oFormData.append("nU_ID_ARCHIVO", archivoSave.nU_ID_ARCHIVO!.toString());                        
                                oFormData.append("tX_NOMBRE", archivoSave.tX_NOMBRE!);
                                oFormData.append("nU_ID_REQUISITO", archivoSave.nU_ID_REQUISITO!.toString());
                                oFormData.append("tX_DESCRIPCION", archivoSave.tX_DESCRIPCION!);
                                oFormData.append("tX_EXTENSION", archivoSave.tX_EXTENSION!);
                                oFormData.append("nU_ID_USUARIO", variablesGlobales.usuario.codusu.toString());
                                oFormData.append("archivo", archivoSave.archivo);
                                this.ObjArchivoService.UpLoadFileRelacionado(oFormData).subscribe(
                                  data1=>{
                                  }
                                );
                              }
                              else{
                                //Nuevo vinculo
                                this.ObjAsociadoRequisito={
                                  nU_ID_ASOCIADOREQUISITO:0,
                                  nU_ID_ASOCIADO:parseInt(data.nU_ID_CODIGO),
                                  nU_ID_REQUISITO:archivoSave.nU_ID_REQUISITO,
                                  nU_ID_ARCHIVO:archivoSave.nU_ID_ARCHIVO
                                };
  
                                this.ObjAsociadoRequisitoService.Actualizar(this.ObjAsociadoRequisito).pipe()
                                  .subscribe(
                                    data => {
                                      if (data.isSuccess){
                                      }
                                      else{
                                        Swal.fire({
                                          title: data.tx_Mensaje,
                                          icon: 'warning'}); 
                                      } 
                                    },
                                    error => {
                                      //console.log(error);
                                      Swal.fire({
                                        title:'El servicio solicitado no se encuentra disponible',
                                        icon: 'error',
                                      });
                                    }
                                  );
                              } 
                            }
                          };
  
                          Swal.fire({
                            title: data.tx_Mensaje,
                            icon: 'success'}); 
                          
                          this.dialogRef.close(data.isSuccess);
                        } 
                        else{
                          Swal.fire({
                            title: data.tx_Mensaje,
                            icon: 'warning'}); 
                        } 
                      }
                      else{
                        Swal.fire({
                          title: data.tx_Mensaje,
                          icon: 'warning'}); 
                      } 
                    },
                    error => {
                      //console.log(error);
                      Swal.fire({
                        title:'El servicio solicitado no se encuentra disponible',
                        icon: 'error',
                      });
                    }
                  );
              }
              else{
                  //console.log("Autenticación inválida: "+data.tx_Mensaje);
              }
            }
          },
          error => {
            //console.log(error);
          }   
        );
    }
  }

  ValidarCampos(){
    if(this.FrmRegistrarAsociado.controls["codentidad"].value==null ||
      this.FrmRegistrarAsociado.controls["codentidad"].value.nU_ID_ENTIDAD==undefined){
        this.alertMsg('Seleccione Persona');
        return false;
    }

    if (this.FrmRegistrarAsociado.controls["cbocondicion"].value == null || 
      this.FrmRegistrarAsociado.controls["cbocondicion"].value.length == 0) {
        this.alertMsg('Seleccione Condición');
        return false;
    }

    if(this.Archivo.length>0){
      let cont=0;

      for(let item of this.Archivo){
        if(item.nU_ID_REQUISITO==1 || item.nU_ID_REQUISITO==3){
          cont++; 
        } 
      }

      if(cont<2){
        this.alertMsg('Se requiere adjuntar archivos con los requisitos de: DNI y FOTO TAMAÑO CARNET');
        return false;
      }
    }
    else{
      this.alertMsg('No se han añadido archivos');
      return false;
    }
  
    return true;
  }

  alertMsg(msg:string){
    Swal.fire({
      html:msg,
      icon:'info'
    })
  }

  pulsar(event:any) {
    if (event.which === 13 && !event.shiftKey) {
      event.preventDefault();
      return false;
    }
    return true;
  }

  Close() {
    this.dialogRef.close();
  }

}
