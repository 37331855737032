import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Archivo } from 'src/app/@core/models/Archivo';
import { Base } from 'src/app/@core/models/Base';
import { BaseDirectivo } from 'src/app/@core/models/BaseDirectivo';
import { ArchivoService } from 'src/app/@core/services/ArchivoService';
import { AutenticaService } from 'src/app/@core/services/AutenticaService';
import { BaseDirectivoService } from 'src/app/@core/services/BaseDirectivoService';
import { DocumentoService } from 'src/app/@core/services/DocumentoService';
import { RegistrarEntidadComponent } from 'src/app/menu/controles/registrar-entidad/registrar-entidad.component';
import { variablesGlobales } from 'src/app/Util/variableGlobal';
import Swal from 'sweetalert2';
import { RegistrarDirectivoComponent } from './registrar-directivo/registrar-directivo.component';

@Component({
  selector: 'app-gestiona-directivo',
  templateUrl: './gestiona-directivo.component.html',
  styleUrls: ['./gestiona-directivo.component.scss']
})
export class GestionaDirectivoComponent implements OnInit {

  @Output() salida:any= new EventEmitter();

  FrmGestDirectivo!: FormGroup;
  @Input() ObjBase!: Base;
  ObjArchivo!: Archivo;
  admBase: number=0;

  BaseDirectivo: BaseDirectivo[]=[];
  columns:any[]=[];
  displayedColumns: string[] = [];

  BaseDirectivoTableDataSource = new MatTableDataSource(this.BaseDirectivo);

  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;

  constructor(private _formBuilder: FormBuilder,
              private router: Router,
              private ObjAutenticaService: AutenticaService,
              private ObjBaseDirectivoService: BaseDirectivoService,
              private ObjDocumentoService: DocumentoService,
              private ObjArchivoService: ArchivoService,
              public dialog: MatDialog,
              ) { }

  ngOnInit(): void {
    this.FrmGestDirectivo=this._formBuilder.group({
      cboFiltro:["1"],
    });

    this.admBase=variablesGlobales.usuarioRol.admBase;

    this.fnListarFiltro(this.FrmGestDirectivo.controls["cboFiltro"].value);
  }

  fnListarFiltro(opc:string){
    var data:any=[];
    if(opc==="1"){
      data=[
        {idcolumna: "nU_ID_BASEDIRECTIVO", cabecera: "", class: "act_nU_ID_BASEDIRECTIVO"},
        {idcolumna: "tX_NUMERO", cabecera: "DNI", class: "act_tX_NUMERO"},
        {idcolumna: "tX_NOMBRE_COMPLETO", cabecera: "Nombre Completo", class: "act_tX_NOMBRE_COMPLETO"},
        {idcolumna: "tX_CARGO", cabecera: "Cargo", class: "act_tX_CARGO"},
        {idcolumna: "fE_INICIO", cabecera: "Inicio", class: "act_fE_INICIO"},
        {idcolumna: "tX_DOCUMENTO_INICIO", cabecera: "Documento de Designación", class: "act_tX_DOCUMENTO_INICIO"},
        {idcolumna: "fE_REGISTRO", cabecera: "F. Creación", class: "act_fE_REGISTRO"}
      ];
    }
    else {
      data=[
        {idcolumna: "nU_ID_BASEDIRECTIVO", cabecera: "", class: "inact_nU_ID_BASEDIRECTIVO"},
        {idcolumna: "tX_NUMERO", cabecera: "DNI", class: "inact_tX_NUMERO"},
        {idcolumna: "tX_NOMBRE_COMPLETO", cabecera: "Nombre Completo", class: "inact_tX_NOMBRE_COMPLETO"},
        {idcolumna: "tX_CARGO", cabecera: "Cargo", class: "inact_tX_CARGO"},
        {idcolumna: "fE_INICIO", cabecera: "Inicio", class: "inact_fE_INICIO"},
        {idcolumna: "tX_DOCUMENTO_INICIO", cabecera: "Documento de Designación", class: "inact_tX_DOCUMENTO_INICIO"},
        {idcolumna: "fE_TERMINO", cabecera: "Término", class: "inact_fE_TERMINO"},
        {idcolumna: "tX_DOCUMENTO_TERMINO", cabecera: "Documento de Término", class: "inact_tX_DOCUMENTO_TERMINO"},
        {idcolumna: "fE_REGISTRO", cabecera: "F. Creación", class: "inact_fE_REGISTRO"},
        {idcolumna: "fE_MODIFICA", cabecera: "Última Actualización", class: "act_fE_MODIFICA"}
      ];
    }

    this.columns = data.map((obj:any) => {
      return { 
        columnDef: obj.idcolumna,
        header: obj.cabecera,
        class: obj.class,
        cell: (element: any) => {
          if(obj.idcolumna==="fE_INICIO" || obj.idcolumna==="fE_TERMINO"){
            return `${element[obj.idcolumna] ? element[obj.idcolumna].substring(0,10) : ``}`
          }
          else if(obj.idcolumna==="fE_REGISTRO" || obj.idcolumna==="fE_MODIFICA"){
            return `${element[obj.idcolumna] ? this.convertFecha(element[obj.idcolumna]) : ``}`
          }
          else{
            return `${element[obj.idcolumna] ? element[obj.idcolumna] : ``}`
          }
        } 
      }
    });

    this.displayedColumns = data.map((obj:any) => obj.idcolumna);

    this.BaseDirectivo= [];
    this.BaseDirectivoTableDataSource.data=this.BaseDirectivo; 
    this.BaseDirectivoTableDataSource.paginator=this.paginator;

    this.LlenaGrilla();
  }

  LlenaGrilla(){
    this.ObjAutenticaService.ValidarApp()
      .subscribe(
        data => { 
          if(data.isSuccess){
            if(data.tx_token.length>0){
              variablesGlobales.token=data.tx_token;

              this.ObjBaseDirectivoService.Listar(parseInt(this.FrmGestDirectivo.controls["cboFiltro"].value),this.ObjBase.nU_ID_BASE)
                .subscribe(data => {
                  if (data.isSuccess){
                    this.BaseDirectivo= data.directivo;
                    this.BaseDirectivoTableDataSource.data=this.BaseDirectivo; 
                  }                                         
                }); 

              this.BaseDirectivoTableDataSource.paginator=this.paginator;
            }
            else{
              variablesGlobales.token='';
              variablesGlobales.usuario.codusu=0;
              variablesGlobales.usuarioOficinaCargo.codOficina=0;
              variablesGlobales.usuarioOficinaCargo.codCargo=0;
              variablesGlobales.usuarioRol.admBase=0;
              variablesGlobales.usuarioRol.admEquipo=0;
              variablesGlobales.usuarioRol.admDelegado=0;
              variablesGlobales.usuarioRol.admDeportista=0;
              variablesGlobales.usuarioRol.isVisibleData=0;
              variablesGlobales.menu={};
              variablesGlobales.codevento=0;
              this.router.navigate(['login']);
            }
          }
        },
        error => {
          //console.log(error);
          variablesGlobales.token='';
          variablesGlobales.usuario.codusu=0;
          variablesGlobales.usuarioOficinaCargo.codOficina=0;
          variablesGlobales.usuarioOficinaCargo.codCargo=0;
          variablesGlobales.usuarioRol.admBase=0;
          variablesGlobales.usuarioRol.admEquipo=0;
          variablesGlobales.usuarioRol.admDelegado=0;
          variablesGlobales.usuarioRol.admDeportista=0;
          variablesGlobales.usuarioRol.isVisibleData=0;
          variablesGlobales.menu={};
          variablesGlobales.codevento=0;
          this.router.navigate(['login']);
        }   
      ); 
  }

  fnNuevo(opc:string,iddirectivo:number){
    const dialogRef = this.dialog.open(RegistrarDirectivoComponent, {
      data: {
        codBase: this.ObjBase.nU_ID_BASE,
        codDirectivo:iddirectivo,
        op: opc
      },
      disableClose: true 
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.LlenaGrilla();
      }
    });
  }

  convertFecha(fecha: string): string{
    var parts=fecha.split("-");
    var anio =parts[0];
    var mes =parts[1];
    var dia =parts[2].substring(0,2);
    var hora =parts[2].substring(3,11);

    return dia+"/"+mes+"/"+anio+" "+hora;
  }

  fnQuitar(obj:BaseDirectivo){
    Swal.fire({
      text: "¿Está seguro de eliminar el registro?",
      icon: 'question',
      showCancelButton: true,
      cancelButtonText:'No',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí',
      reverseButtons: true
    }).then((result) => {
      if (result.value){
        this.ObjAutenticaService.ValidarApp()
          .subscribe(
            data => { 
              if(data.isSuccess){
                if(data.tx_token.length>0){
                  variablesGlobales.token=data.tx_token;              
                  obj.nU_ID_ESTADO=0;

                  this.ObjBaseDirectivoService.Actualizar(obj).pipe()
                    .subscribe(
                      data => {
                        if (data.isSuccess){
                          Swal.fire({
                            title: data.tx_Mensaje,
                            icon: 'success'}); 

                          this.LlenaGrilla();
                        }
                        else{
                          Swal.fire({
                            title: data.tx_Mensaje,
                            icon: 'warning'}); 
                        } 
                      },
                      error => {
                        //console.log(error);
                        Swal.fire({
                          title:'El servicio solicitado no se encuentra disponible',
                          icon: 'error',
                        });
                      },
                    );
                }
                else{
                  variablesGlobales.token='';
                  variablesGlobales.usuario.codusu=0;
                  variablesGlobales.usuarioOficinaCargo.codOficina=0;
                  variablesGlobales.usuarioOficinaCargo.codCargo=0;
                  variablesGlobales.usuarioRol.admBase=0;
                  variablesGlobales.usuarioRol.admEquipo=0;
                  variablesGlobales.usuarioRol.admDelegado=0;
                  variablesGlobales.usuarioRol.admDeportista=0;
                  variablesGlobales.usuarioRol.isVisibleData=0;
                  variablesGlobales.menu={};
                  variablesGlobales.codevento=0;
                  this.router.navigate(['login']);
                }
              }
            },
            error => {
              //console.log(error);
              variablesGlobales.token='';
              variablesGlobales.usuario.codusu=0;
              variablesGlobales.usuarioOficinaCargo.codOficina=0;
              variablesGlobales.usuarioOficinaCargo.codCargo=0;
              variablesGlobales.usuarioRol.admBase=0;
              variablesGlobales.usuarioRol.admEquipo=0;
              variablesGlobales.usuarioRol.admDelegado=0;
              variablesGlobales.usuarioRol.admDeportista=0;
              variablesGlobales.usuarioRol.isVisibleData=0;
              variablesGlobales.menu={};
              variablesGlobales.codevento=0;
              this.router.navigate(['login']);
            }   
          );
      }
    });
  }

  fnDescargarDocumento(opc:number,obj: BaseDirectivo){
    this.ObjAutenticaService.ValidarApp()
      .subscribe(
        data => { 
          if(data.isSuccess){
            if(data.tx_token.length>0){
              variablesGlobales.token=data.tx_token;

              this.ObjDocumentoService.Obtener((opc==1)?obj.nU_ID_DOCUMENTO_INICIO!:obj.nU_ID_DOCUMENTO_TERMINO!).pipe()
                .subscribe(
                  data => {
                    if (data.isSuccess){
                      this.ObjArchivo={nU_ID_ARCHIVO:0};

                      for(let item of data.listaarchivo){
                        if(item.nU_ID_REQUISITO==2){
                          this.ObjArchivo=item;
                          break;
                        }
                      }

                      if(this.ObjArchivo.nU_ID_ARCHIVO>0){
                        this.ObjArchivoService.ObtenerTrama(this.ObjArchivo.tX_NOMBRE_ARCHIVO!).pipe()    
                          .subscribe(
                            data1 => {
                              if(data1.isSuccess){
                                this.ObjArchivo.trama=data1.tx_Mensaje;
                                this.fnObtenerArchivo(this.ObjArchivo);
                              }  
                              else{
                                Swal.fire({
                                  title:'Archivo no encontrado',
                                  icon: 'info',
                                });
                              }                                 
                            },
                            error => {
                              //console.log(error);
                              Swal.fire({
                                title:'El servicio solicitado no se encuentra disponible',
                                icon: 'error',
                              });
                            }
                          );
                      }
                      else{
                        Swal.fire({
                          title:'No existe archivo disponible para descargar',
                          icon: 'info',
                        });
                      }
                    }
                  },
                  error => {
                    //console.log(error);
                    Swal.fire({
                      title:'El servicio solicitado no se encuentra disponible',
                      icon: 'error',
                    });
                  }
                );
            }
            else{
                //console.log("Autenticación inválida: "+data.tx_Mensaje);
            }
          }
        },
        error => {
          //console.log(error);
        }   
      ); 
  }

  fnObtenerArchivo(obj: Archivo){
    const byteCharacters= atob(obj.trama);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);
  
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
  
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    var nombre=(obj.nU_ID_ARCHIVO==0)?obj.tX_NOMBRE:obj.tX_NOMBRE_ARCHIVO;

    const blob = new Blob(byteArrays, {type: 'application/'+nombre!.split('.').pop()});
    const blobUrl = URL.createObjectURL(blob);

    const link:any = document.createElement('a');
    link.href = blobUrl;
    link.download = nombre;
    link.click();
  }

  Retorno(){
    this.salida.emit(1);
  }

  fnEditarEntidad(opc:string,identidad:number){
    const dialogRef = this.dialog.open(RegistrarEntidadComponent, {
      data: {
        codEntidad:identidad,
        op: opc
      },
      disableClose: true 
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.LlenaGrilla();
      }
    });
  }
}
