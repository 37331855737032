<mat-card-title class="Titulo" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>Agregar Requisito</mat-card-title>
<div mat-dialog-content>
    <form [formGroup]="FrmAgregarRequisito"> 
        <mat-card fxLayout="column">  
            <mat-card-content fxLayout="column">
                <div *ngIf="isVisibleOpcion">
                    <mat-form-field class="fila1">
                        <mat-select class="valueSelect" placeholder="Seleccione opción" formControlName="codopcion" 
                            (selectionChange)="fnHabilitarCampos($event.value)">
                                <mat-option class="opcionSelect" value="1">Agregar archivo nuevo</mat-option>
                                <mat-option class="opcionSelect" value="2">Seleccionar archivo existente</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div *ngIf="isVisibleAgregarArchivo"> 
                    <div>
                        <mat-form-field class="fila1">
                            <mat-select class="valueSelect" placeholder="Requisito" formControlName="codrequisito" required>
                                <mat-option class="opcionSelect" *ngFor="let elem of Requisito" [value]="elem.nU_ID_REQUISITO"
                                    (click)="txtrequisito=elem.tX_DESCRIPCION!">
                                    {{elem.tX_DESCRIPCION}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div>
                        <mat-form-field class="fila2">
                            <textarea matInput cdkTextareaAutosize
                                #autosize="cdkTextareaAutosize" placeholder="Descripción" formControlName="txtdescripcion" class="CampoTextArea" 
                                (keydown)="pulsar($event)" autocomplete="off" required></textarea>
                        </mat-form-field>
                    </div>
                    <div>
                        <input type="file" formControlName="archivo" style="font-size: 11.5px; color: #09C;" 
                            (change)="ngChangeFila($event)" accept=".pdf, .jpg, .jpeg, .png, .PDF, .JPG, .JPEG, .PNG">
                    </div>
                </div>
                <div *ngIf="isVisibleArchivoExistente">
                    <div class="example-container">
                        <table mat-table [dataSource]="ArchivoTableDataSource">
                            <ng-container matColumnDef="nU_ID_ARCHIVO">
                                <th mat-header-cell *matHeaderCellDef></th>
                                <td mat-cell *matCellDef="let element">
                                    <mat-radio-button [value]="true" (change)="radioSelected($event, element)"></mat-radio-button>
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="tX_NOMBRE_ARCHIVO">
                                <th mat-header-cell *matHeaderCellDef>NOMBRE</th>
                                <td mat-cell *matCellDef="let element">{{element.tX_NOMBRE_ARCHIVO}}</td>
                            </ng-container>
                            <ng-container matColumnDef="tX_REQUISITO">
                                <th mat-header-cell *matHeaderCellDef>REQUISITO</th>
                                <td mat-cell *matCellDef="let element">{{element.tX_REQUISITO}}</td>
                            </ng-container>
                            <ng-container matColumnDef="tX_DESCRIPCION">
                                <th mat-header-cell *matHeaderCellDef>DESCRIPCIÓN</th>
                                <td mat-cell *matCellDef="let element">{{element.tX_DESCRIPCION}}</td>
                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        </table>  
                    </div>
                </div>
            </mat-card-content> 
        </mat-card>
    </form>
</div>
<div mat-dialog-actions style="text-align: right;margin-top: 20px;">
    <button mat-button class="btnOpcion" (click)="Close()">
        <mat-icon fontSet="material-icons-outlined">cancel</mat-icon>
        <span>Cancelar</span>
    </button>
    <button mat-button class="btnOpcion" (click)="Agregar()">
        <mat-icon fontSet="material-icons-outlined">add_box</mat-icon>
        <span>Agregar</span>
    </button>
</div>











