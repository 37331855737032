<mat-card-title class="Titulo" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>{{txtTitulo}}</mat-card-title>
<div mat-dialog-content>
    <form [formGroup]="FrmRegistrarAsociado"> 
        <mat-card fxLayout="column">  
            <mat-card-content fxLayout="column">
                <div >
                    <mat-form-field class="fila0">
                        <textarea matInput cdkTextareaAutosize
                            #autosize="cdkTextareaAutosize" placeholder="Seleccione Persona" formControlName="codentidad" class="CampoTextArea"
                            [matAutocomplete]="auto" (focus)="fnListarEntidad()" (keydown)="pulsar($event)" (keyup)="fnLimpiar()" [readonly]="opc=='MOD'" required></textarea>
                        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFnEntidad" (optionSelected)="fnObtenerArchivos($event.option.value)">
                            <mat-option class="opcionSelect" *ngFor="let entidad of filteredEntidad | async" [value]="entidad">                            
                                {{entidad.tX_NUMERO+' | '+entidad.tX_NOMBRE+' '+entidad.tX_APELLIDO_PATERNO+' '+entidad.tX_APELLIDO_MATERNO}}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                    <button mat-icon-button (click)="fnRegistrarEntidad('NUE',0)" [disabled]="opc=='MOD'">
                        <mat-icon>add_circle_outline</mat-icon>
                    </button>
                </div>
                <div>
                    <mat-form-field>
                        <mat-select placeholder="Condición" formControlName="cbocondicion" required>
                            <mat-option value="HABIL">HABIL</mat-option>
                            <mat-option value="INHABILITADO">INHABILITADO</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div><br><br>
                <fieldset class="borderFieldset"><legend class="estiloLegend">Archivos</legend>
                    <div style="margin-top: 10px;margin-bottom: 20px;">
                        <button mat-icon-button class="tooltips_fab" (click)="fnAgregar()">
                            <mat-icon fontSet="material-icons-outlined">attach_file</mat-icon>
                            <span>Añadir</span>
                        </button>
                    </div>
                    <div class="example-container">
                        <table mat-table [dataSource]="ArchivoTableDataSource">
                            <ng-container matColumnDef="nU_ID_ARCHIVO">
                                <th mat-header-cell *matHeaderCellDef></th>
                                <td mat-cell *matCellDef="let element">
                                    <button mat-icon-button class="tooltips_cls" [matMenuTriggerFor]="menu">
                                        <mat-icon>menu</mat-icon>
                                    </button>
                                    <mat-menu #menu="matMenu">
                                        <button mat-menu-item (click)="fnQuitar(element)">
                                            <mat-icon fontSet="material-icons-outlined">delete</mat-icon>
                                            <span>Quitar</span>
                                        </button>
                                        <button mat-menu-item (click)="fnDescargar(element)">
                                            <mat-icon fontSet="material-icons-outlined">get_app</mat-icon>
                                            <span>Descargar</span>
                                        </button>
                                    </mat-menu>                            
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="tX_NOMBRE">
                                <th mat-header-cell *matHeaderCellDef>NOMBRE</th>
                                <td mat-cell *matCellDef="let element">
                                    {{(element.nU_ID_ARCHIVO==0)?element.tX_NOMBRE:element.tX_NOMBRE_ARCHIVO}}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="tX_REQUISITO">
                                <th mat-header-cell *matHeaderCellDef>REQUISITO</th>
                                <td mat-cell *matCellDef="let element">{{element.tX_REQUISITO}}</td>
                            </ng-container>
                            <ng-container matColumnDef="tX_DESCRIPCION">
                                <th mat-header-cell *matHeaderCellDef>DESCRIPCIÓN</th>
                                <td mat-cell *matCellDef="let element">{{element.tX_DESCRIPCION}}</td>
                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        </table>
                    </div>
                </fieldset>
            </mat-card-content> 
        </mat-card>
    </form>
</div>
<div mat-dialog-actions style="text-align: right;">
    <button mat-button class="btnOpcion" (click)="Close()">
        <mat-icon fontSet="material-icons-outlined">cancel</mat-icon>
        <span>Cancelar</span>
    </button>
    <button mat-button class="btnOpcion" (click)="Guardar()">
        <mat-icon fontSet="material-icons-outlined">save</mat-icon>
        <span>Grabar</span>
    </button>
</div>
