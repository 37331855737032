import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Configuracion } from './config';
import { variablesGlobales } from 'src/app/Util/variableGlobal';
import { Reporte } from '../models/Reporte';

@Injectable({
    providedIn: 'root'
})

export class EstadisticaService {
    public url:string = Configuracion.url2 + 'Estadistica/';
    
    constructor(private _http: HttpClient) { }

    Listar(ObjReporte: Reporte){
        ObjReporte.nU_CODIGO_USUARIO=variablesGlobales.usuario.codusu;
        ObjReporte.tX_IP= "";
        ObjReporte.tX_NOMBRE_EQUIPO= "";
        ObjReporte.tX_APLICACION= variablesGlobales.aplicacion;

        return this._http.post<any>(this.url + 'Listar', ObjReporte);
    }
}