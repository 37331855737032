<div class="div_centrado">
    <div style="display:flex; justify-content: center;">
        <mat-card fxLayout="column" style="width: 350px;">  
            <mat-card-title>Que le gustaría hacer?</mat-card-title>  
                <a href={{solicitud}}>1.- Desea iniciar una nueva solicitud de inscripción de socio?</a>
                <br>
                <a href={{portalprincipal}}>2.- Desea iniciar al portal principal?</a>
        </mat-card>
    </div>
</div>
