import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { AutenticaService } from 'src/app/@core/services/AutenticaService';
import { SocioService } from 'src/app/@core/services/SocioService';
import { variablesGlobales } from 'src/app/Util/variableGlobal';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-consultar-asociado',
  templateUrl: './consultar-asociado.component.html',
  styleUrls: ['./consultar-asociado.component.scss']
})
export class ConsultarAsociadoComponent implements OnInit, AfterViewInit, OnDestroy {

  FrmConsultarAsociado!: FormGroup;
  maxLength: number=0;
  principal:number=1;
  registro:number=0;
  isVisibleAsociado:boolean=false;
  resultado!:string;

  constructor(private _formBuilder: FormBuilder,
              private router: Router,
              private ObjSocioService: SocioService,
              private ObjAutenticaService: AutenticaService,
              private recaptchaV3Service: ReCaptchaV3Service) { }

  ngOnInit(): void {
    this.FrmConsultarAsociado=this._formBuilder.group({
      tipo_documento:["1"],
      numero:[null,Validators.compose([Validators.required,Validators.maxLength(15)])]
    });

    this.maxLength=8;
  }

  ngAfterViewInit() {
    const element = document.getElementsByClassName('grecaptcha-badge')[0] as HTMLElement;
    if (element) {
      element.style.visibility = 'visible';
    }
  }

  ngOnDestroy() {
    const element = document.getElementsByClassName('grecaptcha-badge')[0] as HTMLElement;
    if (element) {
      element.style.visibility = 'hidden';
    }
  }

  alertMsg(msg:string){
    Swal.fire({
      html:msg,
      icon:'info'
    })
  }

  ValidarCampos(){
    if (this.FrmConsultarAsociado.controls["numero"].value === null || 
      this.FrmConsultarAsociado.controls["numero"].value.length === 0 || 
      /^\s+$/.test(this.FrmConsultarAsociado.controls["numero"].value)) {
        this.alertMsg('Ingrese Número de Documento de Identidad');
        return false;
    }
    else{
      if(this.FrmConsultarAsociado.controls["numero"].value.length!==8){
        this.alertMsg('Debe ingresar 8 dígitos');
        return false;
      }
    }

    return true;
  }

  Consultar(){
    if(this.ValidarCampos()){
      this.isVisibleAsociado=false;

      this.recaptchaV3Service.execute('consulta_asociado')
        .subscribe(token => {
          this.ObjAutenticaService.ValidarApp()
          .subscribe(
            data => { 
              if(data.isSuccess){
                if(data.tx_token.length>0){
                  variablesGlobales.token=data.tx_token;
                  
                  this.ObjSocioService.ConsultaLibre(this.FrmConsultarAsociado.controls["tipo_documento"].value,this.FrmConsultarAsociado.controls["numero"].value) 
                    .subscribe(
                      data => {
                        if (data.procesoSwitch==0){
                          this.isVisibleAsociado=true;
                          this.resultado=data.resultProceso;
                        }
                        else{
                          Swal.fire({
                            title: data.mensaje,
                            icon: 'warning'}); 
                        } 
                      },
                      error => {
                        //console.log(error);
                        Swal.fire({
                          title:'El servicio solicitado no se encuentra disponible',
                          icon: 'error',
                        });
                      }
                    );
                }
                else{
                  //console.log("Autenticación inválida: "+data.resultProceso);
                }
              }
            },
            error => {
              //console.log(error);
            }
          );
        });
    }
  }

  Limpiar(){
    this.FrmConsultarAsociado.patchValue({
      numero: null,
    });
  }

  fnIngNumDocumento(event:any){
    if(this.FrmConsultarAsociado.controls["tipo_documento"].value==="1"){
      this.maxLength=8;
      return this.fnSoloNumeros(event);
    } 
    else if(this.FrmConsultarAsociado.controls["tipo_documento"].value==="4" || 
    this.FrmConsultarAsociado.controls["tipo_documento"].value==="7")
        this.maxLength=12;
    else if(this.FrmConsultarAsociado.controls["tipo_documento"].value==="0" ||
    this.FrmConsultarAsociado.controls["tipo_documento"].value==="A")
        this.maxLength=15;
    return true;
  }

  fnSoloNumeros(event:any) {
    event.target.value = event.target.value.replace(/[^\d]/g,'');
    return false;
  }

  fnSoloLetras(event:any) {
    var patron =/[A-Za-z\s\á\é\í\ó\ú\Á\É\Í\Ó\Ú\Ñ\ñ]/;

    if(!patron.test(event.key)){
      event.target.value = event.target.value.replace(event.key,'');
    }
    event.target.value = event.target.value.replace(/[\/\\\\\s]/g,'');//No permite espaciado
    return false;
  }

  fnRegistrar(){
    this.principal=0;
    this.registro=1;
  }

  fnRetornoRegistro(obj:any){
    this.principal=1;
    this.registro=0;

    const element = document.getElementsByClassName('grecaptcha-badge')[0] as HTMLElement;
    if (element) {
      element.style.visibility = 'visible';
    }
  }

  fnIniciaSesion(){
    this.router.navigate(['/login']);
  }
}
