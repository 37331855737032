<div *ngIf="principal" class="div_centrado">
    <form [formGroup]="FrmConsultarAsociado"> 
        <div style="display:flex; justify-content: center;">
            <mat-card fxLayout="column" style="width: 335px;">  
                <p>Consulta de Asociado</p>
                <mat-card-content fxLayout="column">
                    <div>
                        <mat-form-field class="fila">
                            <mat-select placeholder="Tipo de Documento" formControlName="tipo_documento" required (selectionChange)="Limpiar()">
                                <mat-option value="1">DNI - DOCUMENTO NACIONAL DE IDENTIDAD</mat-option>
                                <!--<mat-option value="4">Carnet de Extranjería</mat-option>
                                <mat-option value="7">Pasaporte</mat-option>-->
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div>
                        <mat-form-field class="fila">
                            <input matInput aria-label="string" placeholder="Número" formControlName="numero" autocomplete="off" required (keyup)="fnIngNumDocumento($event)" [maxLength]="maxLength">
                        </mat-form-field> 
                    </div>
                </mat-card-content>
                <mat-card-actions style="text-align: center;margin-bottom: 5px;">
                    <button mat-button mat-raised-button class="btnConsultar" (click)="Consultar()">Consultar</button>
                </mat-card-actions>
            </mat-card>
        </div>
        <div style="margin-top: 40px;" *ngIf="isVisibleAsociado">
            <p>{{resultado}}</p>
        </div>
        <!-- <div style="margin-top: 20px;">
            <mat-label>El Asociado identificado con DNI Nro. 00098123 se encuentra Activo Habilitado.</mat-label>
        </div>
        <div style="margin-top: 20px;">
            <mat-label>Consulta realizada 01/02/2022 14:30h UTM -5</mat-label>
        </div> -->
        <div style="margin-top: 20px;">
            <button mat-icon-button style="color: #1973b8;" (click)="fnRegistrar()">
                <mat-icon fontSet="material-icons" style="margin-right: 10px;">how_to_reg</mat-icon>
                <span>¿Desea formar parte de nuestra asociación?</span>
            </button>           
        </div>
        <!--<div style="text-align: center;">
            <button mat-button (click)="fnIniciaSesion()">
                <span style="color: #1973b8;"><u>Iniciar Sesión</u></span>
            </button>
        </div>-->
    </form>
</div>
<div>
    <app-solicitar-registro 
        *ngIf="registro"  
        (salida)="fnRetornoRegistro($event)"
    >
    </app-solicitar-registro>
</div>