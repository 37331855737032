
<mat-card-title class="Titulo" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>{{titulo}}</mat-card-title>
<div mat-dialog-content>
    <form [formGroup]="FrmAgregarNotaPartido"> 
        <mat-card fxLayout="column">  
            <mat-card-content fxLayout="column">
                <div>
                    <mat-form-field class="Fila0">
                        <mat-select style="width: 150px;" placeholder="¿Quién?" formControlName="cbotipo" required (selectionChange)="fnHabilitarEquipo($event.value)">
                            <mat-option value="1">MESA DE CONTROL</mat-option>
                            <mat-option value="2">ARBITRO</mat-option>
                            <mat-option value="3">DELEGADO</mat-option>
                            <mat-option value="4">VEEDOR</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field class="Fila1" *ngIf="isVisibleEquipo"> 
                        <mat-select style="width: 100px;" placeholder="Equipo" formControlName="cboequipo" required (selectionChange)="fnListarIntegrante($event.value)">
                            <mat-option *ngFor="let item of Equipo" [value]="item.nU_ID_EQUIPO">
                                {{item.tX_DENOMINACION}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div *ngIf="isVisibleEntidad">
                    <mat-form-field class="Fila2">
                        <textarea matInput cdkTextareaAutosize
                            #autosize="cdkTextareaAutosize" placeholder="Árbitro" formControlName="codentidad" class="CampoTextArea"
                            [matAutocomplete]="auto" (focus)="fnListarEntidad()" (keydown)="pulsar($event)" required></textarea>
                        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFnEntidad">
                            <mat-option class="opcionSelect" *ngFor="let entidad of filteredEntidad | async" [value]="entidad">                            
                                {{entidad.tX_NUMERO+' | '+entidad.tX_NOMBRE+' '+entidad.tX_APELLIDO_PATERNO+' '+entidad.tX_APELLIDO_MATERNO}}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                    <button mat-icon-button (click)="fnRegistrarEntidad('NUE',0)">
                        <mat-icon>add_circle_outline</mat-icon>
                    </button>
                </div>
                <div *ngIf="isVisibleEquipo">
                    <mat-form-field class="Fila3"> 
                        <mat-select class="valueSelect" placeholder="Delegado" formControlName="cbodelegado" required>
                            <mat-option class="opcionSelect" *ngFor="let item of Integrante" [value]="item.nU_ID_ENTIDAD">
                                {{item.tX_NOMBRE_COMPLETO}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field class="Fila4">
                        <textarea matInput placeholder="Nota u observación" formControlName="txtobservacion" class="CampoTextArea"
                            autocomplete="off" rows="2" required></textarea>
                        <mat-hint>Debe contener más de 20 caracteres</mat-hint>
                    </mat-form-field> 
                </div>
            </mat-card-content> 
        </mat-card>
    </form>
</div>
<div mat-dialog-actions style="text-align: right;">
    <button mat-button class="btnOpcion" (click)="Close()">
        <mat-icon fontSet="material-icons-outlined">cancel</mat-icon>
        <span>Cancelar</span>
    </button>
    <button mat-button class="btnOpcion" (click)="Guardar()">
        <mat-icon fontSet="material-icons-outlined">save</mat-icon>
        <span>Grabar</span>
    </button>
</div>