import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Configuracion } from './config';
import { variablesGlobales } from 'src/app/Util/variableGlobal';

@Injectable({
    providedIn: 'root'
})

export class EventoService {
    public url:string = Configuracion.url2 + 'Evento/';
    
    constructor(private _http: HttpClient) { }

    Obtener(codigo: number){
        let bodyServicio = {"NU_ID_EVENTO":codigo}; 
        return this._http.post<any>(this.url + 'Obtener', bodyServicio);
    }

    Listar(){
        let bodyServicio = {}; 
        return this._http.post<any>(this.url + 'Listar', bodyServicio);
    }

    Disciplina(opc: number){
        let bodyServicio = {"NU_OPCION_BUSQUEDA":opc,
                            "NU_CODIGO_USUARIO": variablesGlobales.usuario.codusu}; 
        return this._http.post<any>(this.url + 'ListarDisciplina', bodyServicio);
    }

    Serie(evento: number,disciplina: number){
        let bodyServicio = {"NU_OPCION_BUSQUEDA":evento,
                            "NU_CODIGO_USUARIO": variablesGlobales.usuario.codusu,
                            "NU_CODIGO_BUSQUEDA":disciplina};         
        return this._http.post<any>(this.url + 'ListarSerie', bodyServicio);
    }
}