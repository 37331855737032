import { Injectable } from '@angular/core';
import { ImageToBase64 } from '../helpers/ImageToBase64';

import * as pdfMake from "pdfmake/build/pdfmake.min.js";
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { Integrante } from '../models/Integrante';
import { Evento } from '../models/Evento';
import { Equipo } from '../models/Equipo';
import { variablesGlobales } from 'src/app/Util/variableGlobal';
(<any>pdfMake).vfs = pdfFonts.pdfMake.vfs;

@Injectable({
    providedIn: 'root'
})

export class RptPDFListaIntegrantexEquipo {

    constructor(private canvas: ImageToBase64) { }

    convertFecha(fecha: string): string{
        var parts=fecha.split("-");
        var anio =parts[0];
        var mes =parts[1];
        var dia =parts[2].substring(0,2);
        var hora =parts[2].substring(3,11);
    
        return dia+"/"+mes+"/"+anio+" "+hora;
    }

    /** Diseño de cabecera **/
    setTitle(logo: any, objEvento: Evento): any {
        //Margen de la cabecera [left,top,right,bottom]
        let margin = [25, 8, 25, 15];

        let fontSizeTitle=14;
        let fontSizeSubTitle=12;

        //Dimensiones del logo
        let widthLogo=46.81;
        let heightLogo=60;

        return [
            {   margin:margin,
                table: {
                    widths:['*'],
                    headerRows: 1,
                    body:[
                        [
                            {
                                table:{
                                    widths:['10%','*','10%'],
                                    body:[
                                        [
                                            //logo de la empresa
                                            {
                                                image: logo,
                                                width: widthLogo,
                                                height: heightLogo,
                                                alignment : 'center',
                                                //rowSpan: 2
                                            },
                                            //Título del documento
                                            {
                                                text: 'NOMINA DE JUGADORES',
                                                fontSize: fontSizeTitle+6,
                                                bold: true,
                                                alignment : 'center',
                                                margin:[0,6,0,6],
                                                //rowSpan: 2
                                            },
                                            {
                                                text: variablesGlobales.usuario.nombre+' '+this.GetDate(),
                                                fontSize: 8,
                                                bold: true,
                                                alignment : 'right',
                                                margin:[0,6,0,6]
                                            }
                                        ]//,
                                        // [
                                        //     {}, {},
                                        //     {
                                        //         text: this.GetDate(),
                                        //         fontSize: 7,
                                        //         bold: true,
                                        //         alignment : 'right',
                                        //         margin:[0,6,0,6],
                                        //     }
                                        // ]
                                    ]
                                },
                                layout:'noBorders'
                            }
                        ],
                        [{}]
                    ]
                },
                layout: 'headerLineOnly'
            }
        ];
    }

    GetDate() {
        const fecha: Date=new Date();
        const dia: string = fecha.getDate().toString().padStart(2, '0');
        const mes: string = (fecha.getMonth() + 1).toString().padStart(2, '0');
        const anio: string = fecha.getFullYear().toString();

        var hora=fecha.getHours();
        var min=fecha.getMinutes();
        var seg=fecha.getSeconds();
        var ampm=(hora>=12)?'p.m.':'a.m.';
        hora=(hora>12)?hora-12:hora;

        return dia + '/' + mes + '/' + anio + ' ' +
            ((hora<10)?'0'+hora:hora) + ':' + ((min<10)?'0'+min:min) + ':' + ((seg<10)?'0'+seg:seg) + ' ' + ampm; 
    }


    ShowTable(opc: number, data: Integrante[]): any{
        let tbintegrante={};

        if(opc==1){
            tbintegrante={
                margin: [0,10,0,0],
                table: {
                    widths: ['5%', '12%', '30%', '15%', '25%', '*'],
                    headerRows: 1,
                    body: this.createTableBody(this.createTableHeader(opc), this.mapData(opc, data))
                }
            };
        }
        else{
            tbintegrante={
                margin: [0,10,0,0],
                table: {
                    widths: ['5%', '12%', '30%', '15%', '10%', '*'],
                    headerRows: 1,
                    body: this.createTableBody(this.createTableHeader(opc), this.mapData(opc, data))
                }
            };
        }
        
        return tbintegrante;
    }

    /** Método para crear el encabezado de la tabla**/
    createTableHeader(opc: number): any {
        let header =[];
        if(opc==1){
            header = ['Ítem', 'DNI', 'Nombre Completo', 'Tipo Delegado', 'Correo', 'Teléfono'];
        }
        else{
            header = ['Ítem', 'DNI', 'Nombre Completo', 'Año Nacimiento', 'Asimilado', 'F. Registro'];
        }

        let pageHeader:any = { fila_0: {} };
        header.forEach((attribute, i) => {
            if(i==0){
                pageHeader.fila_0['col_' + (+i + 1)] = { text: attribute, style: 'tableHeader', border: [false,false,false,true],alignment : 'center'};
            }
            else{
                pageHeader.fila_0['col_' + (+i + 1)] = { text: attribute, style: 'tableHeader', border: [false,false,false,true]};
            } 
        });
        return pageHeader;
    }

    /** Método para crear el contenido de la tabla **/
    createTableBody(headers: any, data: any[]): any {
        const body = [];
        let count = 0;

        for (const key in headers) {
            const row = [];

            for (const headerKey in headers[key]) {
                row.push(headers[key][headerKey]);

                if(key=="fila_0"){
                    count++;
                }
            }

            body.push(row);
        }

        if(data.length>0){
            data.forEach((record:any, i:number) => {
                const row = [];
    
                for (const key in record) {
                    if(key=="index"){
                        row.push({ text: record[key], style: 'tableContent', border: [false,false,false,(i==data.length-1)?true:false], alignment : 'center'});
                    }
                    else{
                        row.push({ text: record[key], style: 'tableContent', border: [false,false,false,(i==data.length-1)?true:false]});
                    }  
                    
                }
    
                body.push(row);
            });
        }
        else{
            const rows:any = { fila_0: {} };

            for(let i=0; i<count; i++){
                rows.fila_0['col_' + (+i + 1)] = { text: ' ', style: 'tableContent', border: [false, false, false, true] };
            }

            for (var key in rows) {
                if (rows.hasOwnProperty(key))
                {
                    var element = rows[key];
                    var row = new Array();

                    for(let i=0; i<count; i++){
                       row.push(element['col_' + (+i + 1)]) ;
                    }

                    body.push(row);
                }
            }
        }

        return body;
    }

    /** Definición del documento PDF **/
    getDocDefinition(logo: any, objEvento: Evento, objEquipo: Equipo, opc: number, listaIntegrante: Integrante[], txtdisciplina: string,
        orientation: string, pageSize: string): any {

        let pageMargin=[25, 80, 25, 55];
        let fontSizeBody=10;
        let headerFontTable=9;
        let bodyFontTable=9;
    
        const docDefinition = {
            pageOrientation: orientation,
            pageSize,
            pageMargins: pageMargin,
            header: this.setTitle(logo, objEvento),
            footer: (currentPage: number, pageCount: number) => {
                return { text: 'Página ' + currentPage.toString() + ' de ' + pageCount, alignment: 'center', margin: [0, 30, 0, 0], fontSize: 8 };
            },
            content: [
                {
                    margin: [0,10,0,0],
                    table: {
                        widths: ['10%', '*'],
                        body: [
                            [
                                {text:'Evento:', fontSize: fontSizeBody, bold:true},
                                {text:objEvento.tX_DENOMINACION, fontSize: fontSizeBody}
                            ],
                            [
                                {text:'Nombre:', fontSize: fontSizeBody, bold:true},
                                {text:objEvento.tX_NOMBRE, fontSize: fontSizeBody}
                            ],
                            [
                                {text:'Promoción:', fontSize: fontSizeBody, bold:true},
                                {text:objEquipo.nU_ANIO_PROMOCION+' '+objEquipo.tX_TURNO, fontSize: fontSizeBody}
                            ],
                            [
                                {text:'Equipo:', fontSize: fontSizeBody, bold:true},
                                {text:objEquipo.tX_DENOMINACION, fontSize: fontSizeBody}
                            ],
                            [
                                {text:'Disciplina:', fontSize: fontSizeBody, bold:true},
                                {text:txtdisciplina, fontSize: fontSizeBody}
                            ]
                        ]
                    },
                    layout: 'noBorders'
                },
                {
                    margin: [0,20,0,0],
                    text: 'Relación de '+((opc==1)?'Delegados':'Deportistas'), 
                    fontSize: fontSizeBody, 
                    bold:true
                },
                this.ShowTable(opc, listaIntegrante)
            ],
            styles: {
                tableHeader: {
                    fontSize: headerFontTable,
                    bold: true
                },
                tableContent: {
                    fontSize: bodyFontTable
                }
            }
        };

        return docDefinition;
    }

    generatePdf(docDefinition:any): void {
        const pdfObject = pdfMake.createPdf(docDefinition);
        pdfObject.open();
    }

    getPdfData(objEvento: Evento, objEquipo: Equipo, opc: number, listaIntegrante: Integrante[], txtdisciplina: string): void {
        //Se obtiene la trama de la imagen en assets que se va a incorporar en el documento
        let ruta="./assets/img/";

        if(variablesGlobales.codsistema==1) ruta+="exasadj/exasadj.jpg";
        if(variablesGlobales.codsistema==2) ruta+="exacemba/exacemba.jpg";

        this.canvas.getBase64Image(ruta)
        .then(base64Img => {
            const logo = base64Img;
            this.generatePdf(this.getDocDefinition(logo, objEvento, objEquipo, opc, listaIntegrante, txtdisciplina, 'portrait', 'A4'));
        });
    }

    mapData(opc: number, data: Integrante[]): any {
        return data.map((item, i) => {
            if(opc==1){
                return {
                    index:i+1,
                    dni:item.tX_NUMERO,
                    nombre:item.tX_NOMBRE_COMPLETO,
                    cargo:item.tX_CARGO,
                    correo:item.tX_CORREO,
                    telefono:item.tX_TELEFONO
                };
            }
            else{
                return {
                    index: i+1,
                    dni:item.tX_NUMERO,
                    nombre:item.tX_NOMBRE_COMPLETO,
                    anio_nac: (item.aniO_NACIMIENTO!>0)?item.aniO_NACIMIENTO:"",
                    asimilado:item.tX_ASIMILADO,
                    fechareg:this.convertFecha(item.fE_REGISTRO!)
                };
            }
        });
    }
}