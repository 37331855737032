import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Documento } from 'src/app/@core/models/Documento';
import { TipoDocumento } from 'src/app/@core/models/TipoDocumento';
import { ArchivoService } from 'src/app/@core/services/ArchivoService';
import { AutenticaService } from 'src/app/@core/services/AutenticaService';
import { DocumentoService } from 'src/app/@core/services/DocumentoService';
import { TipoDocumentoService } from 'src/app/@core/services/TipoDocumentoService';
import { variablesGlobales } from 'src/app/Util/variableGlobal';
import Swal from 'sweetalert2';

export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};

@Component({
  selector: 'app-agregar-documento',
  templateUrl: './agregar-documento.component.html',
  styleUrls: ['./agregar-documento.component.scss'],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }
  ]
})
export class AgregarDocumentoComponent implements OnInit {

  FrmAgregarDocumento!: FormGroup;
  codigo:number=0;
  ObjDocumento!: Documento;
  oFileCurrent: any;
  TipoDocumento!: TipoDocumento[];

  archivo:any={
    codarchivo:0,
    nomarchivo: null,
    extension: null,
    file:null
  };

  constructor(private _formBuilder: FormBuilder,
              private ObjAutenticaService: AutenticaService,
              private ObjDocumentoService: DocumentoService,
              private ObjArchivoService: ArchivoService,
              private ObjTipoDocumentoService: TipoDocumentoService,
              private dialogRef: MatDialogRef<AgregarDocumentoComponent>) { }

  ngOnInit(): void {
    this.FrmAgregarDocumento=this._formBuilder.group({
      cbotipodoc:[null,Validators.required],
      FecEmision:[null,Validators.required],
      txtasunto:[null,Validators.required],
      archivo:[null]
    }); 

    this.fnListarTipoDocumento();
  }

  @HostListener('window:keyup.esc') onKeyUp() {
    this.Close();
  }

  fnListarTipoDocumento(){
    this.ObjAutenticaService.ValidarApp()
      .subscribe(
        data => { 
          if(data.isSuccess){
            if(data.tx_token.length>0){
              variablesGlobales.token=data.tx_token;

              this.ObjTipoDocumentoService.Listar(1)
                .subscribe(data => {
                  if (data.isSuccess){
                    this.TipoDocumento= data.data;

                    if(this.TipoDocumento.length>0){
                      this.FrmAgregarDocumento.controls["cbotipodoc"].setValue(this.TipoDocumento[0].nU_ID_TIPODOCUMENTO);
                    }
                  }                                         
                });
            }
            else{
              ////console.log("Autenticación inválida: "+data.tx_Mensaje);
            }
          }
        },
        error => {
          ////console.log(error);
        }    
      ); 
  }

  alertMsg(msg:string){
    Swal.fire({
      html:msg,
      icon:'info'
    })
  }

  ngChangeFila(event: any){
    const validExts = new Array(".pdf", ".jpg", ".jpeg", ".png", ".PDF", ".JPG", ".JPEG", ".PNG");
    const oFile = event.target.files[0];
    if(oFile == undefined){
      this.oFileCurrent=null;
      return;
    }
      
    const fileExt = oFile.name.substring(oFile.name.lastIndexOf('.'));
    if (validExts.indexOf(fileExt) < 0) {
      alert("No permitido, Solo se permiten archivos con extensión " + validExts.toString());
      this.FrmAgregarDocumento.controls["archivo"].setValue(null);
      this.oFileCurrent=null;
      return;
    }
    var converted = oFile.size/(1024*1024);
    if(converted>5){
      alert("El archivo no debe superar los 5 MB");
      this.FrmAgregarDocumento.controls["archivo"].setValue(null);
      this.oFileCurrent=null;
      return;
    }

    this.oFileCurrent = oFile;
    this.archivo.nomarchivo=this.oFileCurrent.name;
    this.archivo.extension=this.oFileCurrent.name.split('.').pop();
    this.archivo.file=this.oFileCurrent;
  }

  ValidarCampos(){
    if (this.FrmAgregarDocumento.controls["cbotipodoc"].value === null || 
      this.FrmAgregarDocumento.controls["cbotipodoc"].value.length === 0) {
        this.alertMsg('Seleccione Tipo de Documento');
        return false;
    }

    if (this.FrmAgregarDocumento.controls["FecEmision"].value === null || 
      this.FrmAgregarDocumento.controls["FecEmision"].value.length === 0 || 
      /^\s+$/.test(this.FrmAgregarDocumento.controls["FecEmision"].value)) {
        this.alertMsg('Ingrese Fecha de Emisión o establezca el formato correcto');
        return false;
    }

    if (this.FrmAgregarDocumento.controls["txtasunto"].value === null || 
      this.FrmAgregarDocumento.controls["txtasunto"].value.length === 0 || 
      /^\s+$/.test(this.FrmAgregarDocumento.controls["txtasunto"].value)) {
        this.alertMsg('Ingrese Asunto');
        return false;
    }

    if(this.oFileCurrent==null){
      this.alertMsg('Seleccione archivo');
      return false;
    }

    return true;
  }

  Guardar(){
    if(this.ValidarCampos()){
      this.ObjDocumento={nU_ID_DOCUMENTO:0};
      this.ObjDocumento.nU_ID_DOCUMENTO=this.codigo;
      this.ObjDocumento.nU_ID_EXTERNO=0;
      this.ObjDocumento.nU_ID_TIPODOCUMENTO=parseInt(this.FrmAgregarDocumento.controls["cbotipodoc"].value);  
      this.ObjDocumento.fE_EMISION=new Date(this.FrmAgregarDocumento.controls["FecEmision"].value);
      this.ObjDocumento.tX_DESCRIPCION=this.FrmAgregarDocumento.controls["txtasunto"].value;

      this.ObjAutenticaService.ValidarApp()
        .subscribe(
          data => { 
            if(data.isSuccess){
              if(data.tx_token.length>0){
                variablesGlobales.token=data.tx_token;

                this.ObjDocumentoService.Actualizar(this.ObjDocumento).pipe()
                  .subscribe(
                    data => {
                      if (data.isSuccess){
                        let oFormData = new FormData();
                        oFormData.append("nU_ID_ARCHIVO", this.archivo.codarchivo.toString());                        
                        oFormData.append("tX_NOMBRE", this.archivo.nomarchivo);
                        oFormData.append("nU_ID_DOC_REFERENCIA", this.FrmAgregarDocumento.controls["cbotipodoc"].value);
                        oFormData.append("nU_ID_REFERENCIA", data.nU_ID_CODIGO.trim());
                        oFormData.append("nU_ID_REQUISITO", "2");
                        oFormData.append("tX_DESCRIPCION", "DOCUMENTO");
                        oFormData.append("tX_EXTENSION", this.archivo.extension);
                        oFormData.append("nU_ID_USUARIO", variablesGlobales.usuario.codusu.toString());
                        oFormData.append("archivo", this.archivo.file);
                        this.ObjArchivoService.EnviarArchivo(oFormData).subscribe(
                          data1=>{
                          }
                        );

                        Swal.fire({
                          title: data.tx_Mensaje,
                          icon: 'success'}); 
                        
                        this.dialogRef.close(data.isSuccess);
                      }
                      else{
                        Swal.fire({
                          title: data.tx_Mensaje,
                          icon: 'warning'}); 
                      } 
                    },
                    error => {
                      ////console.log(error);
                      Swal.fire({
                        title:'El servicio solicitado no se encuentra disponible',
                        icon: 'error',
                      });
                    }
                  );
              }
              else{
                  ////console.log("Autenticación inválida: "+data.tx_Mensaje);
              }
            }
          },
          error => {
            ////console.log(error);
          }   
        );
    }
  }

  Close() {
    this.dialogRef.close();
  }

  pulsar(event:any) {
    if (event.which === 13 && !event.shiftKey) {
      event.preventDefault();
      return false;
    }
    return true;
  }
}
