
<mat-card-title class="Titulo" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>{{titulo}}</mat-card-title>
<div mat-dialog-content>
    <form [formGroup]="FrmAgregarInfraccion"> 
        <mat-card fxLayout="column">  
            <mat-card-content fxLayout="column">
                <div>
                    <mat-label class="Info">Deportista: {{txtentidad}}</mat-label>
                </div>
                <div style="margin-top: 20px;">
                    <mat-form-field class="Fila0">
                        <mat-select style="width: 150px;" placeholder="Tiempo" formControlName="cbotiempo" required>
                            <mat-option value="1">1er</mat-option>
                            <mat-option value="2">2do</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </mat-card-content> 
        </mat-card>
    </form>
</div>
<div mat-dialog-actions style="text-align: right;">
    <button mat-button class="btnOpcion" (click)="Close()">
        <mat-icon fontSet="material-icons-outlined">cancel</mat-icon>
        <span>Cancelar</span>
    </button>
    <button mat-button class="btnOpcion" (click)="Guardar()">
        <mat-icon fontSet="material-icons-outlined">save</mat-icon>
        <span>Grabar</span>
    </button>
</div>