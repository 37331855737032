import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Configuracion } from './config';
import { variablesGlobales } from 'src/app/Util/variableGlobal';

@Injectable({
    providedIn: 'root'
})

export class AutenticaService {
    public url:string = Configuracion.url1 + 'Autentica/';
    public url2:string = Configuracion.url2 + 'Autentica/';
    
    constructor(private _http: HttpClient) { }

    ValidarApp(){
        return this._http.post<any>(this.url + 'Usuario', variablesGlobales.autentica);
    }

    ValidarUsuario(tX_LOGIN: string,tX_CLAVE: string ){
        let bodyServicio = {"tX_LOGIN":tX_LOGIN,
                            "tX_CLAVE":tX_CLAVE}; 
        return this._http.post<any>(this.url2 + 'Usuario', bodyServicio);
    }
}