import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Configuracion } from './config';
import { variablesGlobales } from 'src/app/Util/variableGlobal';
import { Usuario } from '../models/Usuario';

@Injectable({
    providedIn: 'root'
})

export class UsuarioService {
    public url:string = Configuracion.url2 + 'Usuario/';
    
    constructor(private _http: HttpClient) { }

    ObtenerId(id: number){
        let bodyServicio = {"NU_CODIGO_BUSQUEDA":id}; 
        return this._http.post<any>(this.url + 'Obtener', bodyServicio);
    }

    ObtenerLogin(login: string){
        let bodyServicio = {"TX_CODIGO_BUSQUEDA":login}; 
        return this._http.post<any>(this.url + 'Obtener', bodyServicio);
    }

    Listar(opc: number, dato: string){
        let bodyServicio = {"NU_OPCION_BUSQUEDA":opc,
                            "TX_DATO_BUSQUEDA":dato}; 
        return this._http.post<any>(this.url + 'Listar', bodyServicio);
    }

    Actualizar(variable: Usuario){
        variable.nU_CODIGO_USUARIO=variablesGlobales.usuario.codusu;
        
        return this._http.post<any>(this.url + 'Actualizar', variable);
    }

    ReseteaClave(id: number, clave?: string){
        let bodyServicio = {"NU_ID_USUARIO":id,
                            "TX_CLAVE":clave,
                            "NU_CODIGO_USUARIO":variablesGlobales.usuario.codusu}; 
        return this._http.post<any>(this.url + 'ReseteaClave', bodyServicio);
    }
}