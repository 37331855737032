import { Injectable } from '@angular/core';

import * as pdfMake from "pdfmake/build/pdfmake.min.js";
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
(<any>pdfMake).vfs = pdfFonts.pdfMake.vfs;

@Injectable({
    providedIn: 'root'
})

export class RptPDFCarnet2023 {

    getDocDefinition(base64:any, orientation: string, pageSize: string): any {
        let pageMargin=[25, 30, 25, 55];
    
        const docDefinition = {
            pageOrientation: orientation,
            pageSize,
            pageMargins: pageMargin,
            content: [
                {
                    image: base64,
                    width: 150,
                    height: 240,
                    alignment : 'center'
                }
            ]
        };

        return docDefinition;
    }

    generatePdf(docDefinition: any): void {
        const pdfObject = pdfMake.createPdf(docDefinition);
        pdfObject.open();
    }

    getPdfData(base64:any): void {
        this.generatePdf(this.getDocDefinition(base64, 'portrait', 'A7'));
    }
    
}