import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Configuracion } from './config';
import { variablesGlobales } from 'src/app/Util/variableGlobal';
import { DetallePlanillaJuego } from '../models/DetallePlanillaJuego';

@Injectable({
    providedIn: 'root'
})

export class DetallePlanillaJuegoService {
    public url:string = Configuracion.url2 + 'DetallePlanillaJuego/';
    
    constructor(private _http: HttpClient) { }

    Listar(opc: number, codigo: number){
        let bodyServicio = {"NU_OPCION_BUSQUEDA":opc,
                            "NU_CODIGO_BUSQUEDA":codigo,
                            "NU_CODIGO_USUARIO":variablesGlobales.usuario.codusu}; 
        return this._http.post<any>(this.url + 'Listar', bodyServicio);
    }

    Actualizar(ObjDetallePlanillaJuego: DetallePlanillaJuego){
        let bodyServicio: any = ObjDetallePlanillaJuego;
        bodyServicio["NU_ID_USUARIO"] = variablesGlobales.usuario.codusu;
        
        return this._http.post<any>(this.url + 'Actualizar', bodyServicio);
    }

    Quitar(ObjDetallePlanillaJuego: DetallePlanillaJuego){
        let bodyServicio: any = ObjDetallePlanillaJuego;
        bodyServicio["NU_ID_USUARIO"] = variablesGlobales.usuario.codusu;
        
        return this._http.post<any>(this.url + 'Quitar', bodyServicio);
    }
}