import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import * as EmailValidator from 'email-validator';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { Aux_Solicitud } from 'src/app/@core/models/Aux_Solicitud';
import { AutenticaService } from 'src/app/@core/services/AutenticaService';
import { variablesGlobales } from 'src/app/Util/variableGlobal';
import { SolicitudPadronService } from 'src/app/@core/services/SolicitudPadronService';
import { ProgessSpinnerDialogComponent } from 'src/app/progess-spinner-dialog/progess-spinner-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-solicitar-registro',
  templateUrl: './solicitar-registro.component.html',
  styleUrls: ['./solicitar-registro.component.scss']
})
export class SolicitarRegistroComponent implements OnInit {

  @Output() salida:any= new EventEmitter();

  FrmRegistrarSolictud!: FormGroup;
  maxLength: number=0;
  ObjAux_Solicitud!: Aux_Solicitud;

  @ViewChild('autosize') autosize!: CdkTextareaAutosize;

  constructor(private _formBuilder: FormBuilder,
              private ObjAutenticaService: AutenticaService,
              private ObjSolicitudPadronService: SolicitudPadronService,
              public dialog: MatDialog) { }

  ngOnInit(): void {
    this.FrmRegistrarSolictud=this._formBuilder.group({
      tipo_documento:["1"],
      numero:[null,Validators.compose([Validators.required,Validators.maxLength(15)])],
      nombre: [null, Validators.required],
      ape_paterno: [null, Validators.required],
      ape_materno: [null, Validators.required],
      email: [null, Validators.required]
    });

    this.maxLength=8;

    const element = document.getElementsByClassName('grecaptcha-badge')[0] as HTMLElement;
    if (element) {
      element.style.visibility = 'hidden';
    }
  }

  Limpiar(){
    this.FrmRegistrarSolictud.patchValue({
      numero: null,
    });
  }

  fnIngNumDocumento(event:any){
    if(this.FrmRegistrarSolictud.controls["tipo_documento"].value==="1"){
      this.maxLength=8;
      return this.fnSoloNumeros(event);
    } 
    else if(this.FrmRegistrarSolictud.controls["tipo_documento"].value==="4" || 
    this.FrmRegistrarSolictud.controls["tipo_documento"].value==="7")
        this.maxLength=12;
    else if(this.FrmRegistrarSolictud.controls["tipo_documento"].value==="0" ||
    this.FrmRegistrarSolictud.controls["tipo_documento"].value==="A")
        this.maxLength=15;
    return true;
  }

  fnSoloNumeros(event:any) {
    event.target.value = event.target.value.replace(/[^\d]/g,'');
    return false;
  }

  fnSoloLetras(event:any) {
    var patron =/[A-Za-z\s\á\é\í\ó\ú\Á\É\Í\Ó\Ú\Ñ\ñ]/;

    if(!patron.test(event.key)){
      event.target.value = event.target.value.replace(event.key,'');
    }
    event.target.value = event.target.value.replace(/[\/\\\\]/g,'');//Permite espaciado
    return false;
  }
  

  alertMsg(msg:string){
    Swal.fire({
      html:msg,
      icon:'info'
    })
  }

  ValidarCampos(){
    if (this.FrmRegistrarSolictud.controls["numero"].value === null || 
      this.FrmRegistrarSolictud.controls["numero"].value.length === 0 || 
      /^\s+$/.test(this.FrmRegistrarSolictud.controls["numero"].value)) {
        this.alertMsg('Ingrese Número de Documento de Identidad');
        return false;
    }
    else{
      if(this.FrmRegistrarSolictud.value.tipo_documento==="1"){
        if(this.FrmRegistrarSolictud.controls["numero"].value.length!==8){
          this.alertMsg('Debe ingresar 8 dígitos');
          return false;
        }
      }
    }

    if (this.FrmRegistrarSolictud.controls["nombre"].value === null || 
      this.FrmRegistrarSolictud.controls["nombre"].value.length === 0 || 
      /^\s+$/.test(this.FrmRegistrarSolictud.controls["nombre"].value)) {
        this.alertMsg('Ingrese Nombres');
        return false;
    }

    if (this.FrmRegistrarSolictud.controls["ape_paterno"].value === null || 
      this.FrmRegistrarSolictud.controls["ape_paterno"].value.length === 0 || 
      /^\s+$/.test(this.FrmRegistrarSolictud.controls["ape_paterno"].value)) {
        this.alertMsg('Ingrese Apellido Paterno');
        return false;
    }

    if (this.FrmRegistrarSolictud.controls["ape_materno"].value === null || 
      this.FrmRegistrarSolictud.controls["ape_materno"].value.length === 0 || 
      /^\s+$/.test(this.FrmRegistrarSolictud.controls["ape_materno"].value)) {
        this.alertMsg('Ingrese Apellido Materno');
        return false;
    }

    if (this.FrmRegistrarSolictud.controls["email"].value === null || 
      this.FrmRegistrarSolictud.controls["email"].value.length === 0 || 
      /^\s+$/.test(this.FrmRegistrarSolictud.controls["email"].value)) {
        this.alertMsg('Ingrese Correo Electrónico');
        return false;
    }
    else{
      if(!EmailValidator.validate(this.FrmRegistrarSolictud.controls["email"].value.trim())){
        this.alertMsg('Formato de correo incorrecto');
        return false;
      }       
    }

    return true;
  }

  Guardar(){
    if(this.ValidarCampos()){
      this.ObjAux_Solicitud={nU_ID_CODIGO:0};
      this.ObjAux_Solicitud.tX_ID_TIPODOCUMENTOIDENTIDAD=this.FrmRegistrarSolictud.controls["tipo_documento"].value;
      this.ObjAux_Solicitud.tX_NUMERO=this.FrmRegistrarSolictud.controls["numero"].value;
      this.ObjAux_Solicitud.tX_NOMBRE=this.FrmRegistrarSolictud.controls["nombre"].value.toUpperCase().trim();
      this.ObjAux_Solicitud.tX_APELLIDO_PATERNO=this.FrmRegistrarSolictud.controls["ape_paterno"].value.toUpperCase().trim();
      this.ObjAux_Solicitud.tX_APELLIDO_MATERNO=this.FrmRegistrarSolictud.controls["ape_materno"].value.toUpperCase().trim();
      this.ObjAux_Solicitud.tX_CORREO=this.FrmRegistrarSolictud.controls["email"].value.trim();
      this.ObjAux_Solicitud.nU_DNI_VALIDADO=1;

      this.ObjAutenticaService.ValidarApp()
        .subscribe(
          data => { 
            if(data.isSuccess){
              if(data.tx_token.length>0){
                variablesGlobales.token=data.tx_token;

                let dialogRef = this.dialog.open(ProgessSpinnerDialogComponent, { 
                  panelClass: 'transparent',
                  disableClose: true
                });

                this.ObjSolicitudPadronService.Actualizar(this.ObjAux_Solicitud).pipe()
                  .subscribe(
                    data => {
                      dialogRef.close();
                      if (data.isSuccess){
                        Swal.fire({
                          title: data.tx_Mensaje,
                          icon: 'success'}); 

                        this.FrmRegistrarSolictud.patchValue({
                          tipo_documento: "1",
                          numero: null,
                          nombre: null,
                          ape_paterno: null,
                          ape_materno: null,
                          email: null
                        });
                      }
                      else{
                        Swal.fire({
                          title: data.tx_Mensaje,
                          icon: 'warning'}); 
                      } 
                    },
                    error => {
                      dialogRef.close();
                      //console.log(error);
                      Swal.fire({
                        title:'El servicio solicitado no se encuentra disponible',
                        icon: 'error',
                      });
                    }
                  );
              }
              else{
                  //console.log("Autenticación inválida: "+data.tx_Mensaje);
              }
            }
          },
          error => {
            //console.log(error);
          }   
        );
    }
  }

  pulsar(event:any) {
    if (event.which === 13 && !event.shiftKey) {
      event.preventDefault();
      return false;
    }
    return true;
  }

  Retorno(){  
    this.salida.emit(1);
  }
}
