import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Configuracion } from './config';
import { Entidad } from '../models/Entidad';
import { variablesGlobales } from 'src/app/Util/variableGlobal';

@Injectable({
    providedIn: 'root'
})

export class CiudadanoService {
    public url:string = Configuracion.url4 + 'Ciudadano/';
    
    constructor(private _http: HttpClient) { }

    ConsultaLibre(tX_NUMERO:string){
        let bodyServicio = {"tX_NUMERO":tX_NUMERO, 
                            "tX_TIPODOCUMENTO":"1"}; 
        return this._http.post<any>(Configuracion.url4 + 'ciudadano/obtener', bodyServicio);
    }

   
}