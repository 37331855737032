import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Configuracion } from './config';
import { variablesGlobales } from 'src/app/Util/variableGlobal';
import { Socio } from '../models/Socio';

@Injectable({
    providedIn: 'root'
})

export class SocioService {
    public url:string = Configuracion.url2 + 'Socio/';
    
    constructor(private _http: HttpClient) { }

    ConsultaLibre(tX_ID_TIPODOCUMENTOIDENTIDAD: string,tX_NUMERO:string){
        let bodyServicio = {"tX_ID_TIPODOCUMENTOIDENTIDAD":tX_ID_TIPODOCUMENTOIDENTIDAD, 
                            "tX_NUMERO":tX_NUMERO}; 
        return this._http.post<any>(this.url + 'ConsultaLibre', bodyServicio);
    }
    
    Obtener(codigo: number, opc: number){
        let bodyServicio = {"NU_CODIGO_BUSQUEDA":codigo,
                            "NU_OPCION_BUSQUEDA":opc}; 
        return this._http.post<any>(this.url + 'Obtener', bodyServicio);
    }

    Actualizar(variable: Socio){
        let bodyServicio = {"NU_ID_ESTABLECIMIENTO":variable.nU_ID_ESTABLECIMIENTO,
                            "TX_UBIGEO":variable.tX_UBIGEO,
                            "TX_DIRECCION":variable.tX_DIRECCION,
                            "NU_ID_CORREO":variable.nU_ID_CORREO,
                            "TX_CORREO":variable.tX_CORREO,
                            "NU_ID_TELEFONO":variable.nU_ID_TELEFONO,
                            "TX_TELEFONO":variable.tX_TELEFONO,
                            "NU_ID_USUARIO":variablesGlobales.usuario.codusu
                            }; 
        return this._http.post<any>(this.url + 'Actualizar', bodyServicio);
    } 

    Listar(opc: number){
        let bodyServicio = {"NU_OPCION_BUSQUEDA":opc}; 
        return this._http.post<any>(this.url + 'Listar', bodyServicio);
    }
}