import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import Swal from 'sweetalert2';
import { AutenticaService } from '../@core/services/AutenticaService';
import { EntidadService } from '../@core/services/EntidadService';
import { variablesGlobales } from '../Util/variableGlobal';

@Component({
  selector: 'app-consultar-dni',
  templateUrl: './consultar-dni.component.html',
  styleUrls: ['./consultar-dni.component.scss']
})
export class ConsultarDniComponent implements OnInit {

  FrmConsultarONPE!: FormGroup;
  maxLength: number=0;
  isVisibleTexto:boolean=false;
  resultado!:string;
  txtTitulo!: string;
  
  constructor(private _formBuilder: FormBuilder,
              private ObjEntidadService: EntidadService,
              private ObjAutenticaService: AutenticaService,
              private recaptchaV3Service: ReCaptchaV3Service) { 
                this.ObjAutenticaService.ValidarApp()
                .subscribe(
                  data => { 
                    if(data.isSuccess){
                      if(data.tx_token.length>0){
                        variablesGlobales.token=data.tx_token;

                        this.ObjEntidadService.Obtener(1,0).pipe()
                          .subscribe(
                            data => {
                              if(data.isSuccess){
                                this.txtTitulo=data.entidad.tX_DENOMINACION;
                              }
                            },
                            error => {
                              console.log(error);
                              Swal.fire({
                                title:'El servicio solicitado no se encuentra disponible',
                                icon: 'error',
                              });
                            }
                          );
                      }
                      else{
                          console.log("Autenticación inválida: "+data.tx_Mensaje);
                      }
                    }
                  },
                  error => {
                    console.log(error);
                  }   
                );
              }

  ngOnInit(): void {
    this.FrmConsultarONPE=this._formBuilder.group({
      tipo_documento:["1"],
      numero:[null,Validators.compose([Validators.required,Validators.maxLength(15)])]
    });

    this.maxLength=8;
  }

  ngAfterViewInit() {
    const element = document.getElementsByClassName('grecaptcha-badge')[0] as HTMLElement;
    if (element) {
      element.style.visibility = 'visible';
    }
  }

  ngOnDestroy() {
    const element = document.getElementsByClassName('grecaptcha-badge')[0] as HTMLElement;
    if (element) {
      element.style.visibility = 'hidden';
    }
  }

  alertMsg(msg:string){
    Swal.fire({
      html:msg,
      icon:'info'
    })
  }

  ValidarCampos(){
    if (this.FrmConsultarONPE.controls["numero"].value === null || 
      this.FrmConsultarONPE.controls["numero"].value.length === 0 || 
      /^\s+$/.test(this.FrmConsultarONPE.controls["numero"].value)) {
        this.alertMsg('Ingrese Número de Documento de Identidad');
        return false;
    }
    else{
      if(this.FrmConsultarONPE.controls["numero"].value.length!==8){
        this.alertMsg('Debe ingresar 8 dígitos');
        return false;
      }
    }

    return true;
  }

  Consultar(){
    if(this.ValidarCampos()){
      this.isVisibleTexto=false;

      this.recaptchaV3Service.execute('consulta_asociado')
        .subscribe(token => {
          this.ObjAutenticaService.ValidarApp()
          .subscribe(            
            data => {
              //console.log(data); 
              if(data.isSuccess){
                if(data.tx_token.length>0){
                  variablesGlobales.token=data.tx_token;
                  
                  this.ObjEntidadService.ConsultaLibre(this.FrmConsultarONPE.controls["numero"].value) 
                    .subscribe(
                      data => {
                        //console.log(data);
                        if (data.isSuccess){
                          this.isVisibleTexto=true;
                          //console.log(data.data.tX_NOMBRECOMPLETO);
                          this.resultado="Nombre: "+data.data.tX_NOMBRECOMPLETO+", Fecha Nac.: "+this.getFechaNacimiento(data.data.fE_NACIMIENTO);
                        }
                        else{
                          Swal.fire({
                            title: 'No se encontro DNI.',
                            icon: 'warning'}); 
                        } 
                      },
                      error => {
                        //console.log(error);
                        Swal.fire({
                          title:'El servicio solicitado no se encuentra disponible',
                          icon: 'error',
                        });
                      }
                    );
                }
                else{
                  //console.log("Autenticación inválida: "+data.resultProceso);
                }
              }
            },
            error => {
              //console.log(error);
            }
          );
        });
    }
  }

  getFechaNacimiento(fecha: any){
    var parts=fecha.split("-");
    var dia =parts[2];
    var mes =parts[1];
    var anio =parts[0];

    return dia+'/'+mes+'/'+anio;
  }

  Limpiar(){
    this.FrmConsultarONPE.patchValue({
      numero: null,
    });
  }

  fnIngNumDocumento(event:any){
    if(this.FrmConsultarONPE.controls["tipo_documento"].value==="1"){
      this.maxLength=8;
      return this.fnSoloNumeros(event);
    } 
    else if(this.FrmConsultarONPE.controls["tipo_documento"].value==="4" || 
    this.FrmConsultarONPE.controls["tipo_documento"].value==="7")
        this.maxLength=12;
    else if(this.FrmConsultarONPE.controls["tipo_documento"].value==="0" ||
    this.FrmConsultarONPE.controls["tipo_documento"].value==="A")
        this.maxLength=15;
    return true;
  }

  fnSoloNumeros(event:any) {
    event.target.value = event.target.value.replace(/[^\d]/g,'');
    return false;
  }

  fnSoloLetras(event:any) {
    var patron =/[A-Za-z\s\á\é\í\ó\ú\Á\É\Í\Ó\Ú\Ñ\ñ]/;

    if(!patron.test(event.key)){
      event.target.value = event.target.value.replace(event.key,'');
    }
    event.target.value = event.target.value.replace(/[\/\\\\\s]/g,'');//No permite espaciado
    return false;
  }

}
