
<mat-card-title class="Titulo" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>Agregar Base</mat-card-title>
<div mat-dialog-content>
    <form [formGroup]="FrmAgregarBase"> 
        <mat-card fxLayout="column">  
            <mat-card-content fxLayout="column">
                <div>
                    <mat-form-field>
                        <input matInput placeholder="Año de Promoción" formControlName="txtanio" autocomplete="off" required
                            (keyup)="fnSoloNumeros($event)" maxLength="4">
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field> 
                        <mat-select placeholder="Turno" formControlName="cboturno" required>
                            <mat-option value="Diurna">Diurna</mat-option>
                            <mat-option value="Nocturna">Nocturna</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </mat-card-content> 
        </mat-card>
    </form>
</div>
<div mat-dialog-actions style="text-align: right;">
    <button mat-button class="btnOpcion" (click)="Close()">
        <mat-icon fontSet="material-icons-outlined">cancel</mat-icon>
        <span>Cancelar</span>
    </button>
    <button mat-button class="btnOpcion" (click)="Guardar()">
        <mat-icon fontSet="material-icons-outlined">save</mat-icon>
        <span>Grabar</span>
    </button>
</div>











