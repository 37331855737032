<mat-card-title class="Titulo" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>Asigna Rol</mat-card-title>
<div mat-dialog-content>
    <form [formGroup]="FrmAsignaRol"> 
        <mat-card fxLayout="column">  
            <mat-card-content fxLayout="column">
                <mat-list>
                    <mat-list-item class="formatoTexto">
                      <mat-checkbox [checked]="IsChecked" (change)="fnMarcarTodos($event)"><b>Marcar / Desmarcar todo</b></mat-checkbox>
                    </mat-list-item>
                    <mat-list-item *ngFor="let item of Rol; index as i" class="formatoTexto">
                      <mat-checkbox [checked]="chkArr[i]" (change)="fnMarcar($event,i,item.nU_ID_ROL)">{{item.tX_DESCRIPCION}}</mat-checkbox>
                    </mat-list-item>
                </mat-list>
            </mat-card-content> 
        </mat-card>
    </form>
</div>
<div mat-dialog-actions style="text-align: right;">
    <button mat-button class="btnOpcion" (click)="Close()">
        <mat-icon fontSet="material-icons-outlined">cancel</mat-icon>
        <span>Cancelar</span>
    </button>
    <button mat-button class="btnOpcion" (click)="Guardar()">
        <mat-icon fontSet="material-icons-outlined">save</mat-icon>
        <span>Grabar</span>
    </button>
</div>
