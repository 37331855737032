import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PortalComponent } from './portal/portal.component';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { MenuModule } from './menu/menu.module';
import { APP_BASE_HREF } from '@angular/common';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { ConsultarAsociadoComponent } from './portal/consultar-asociado/consultar-asociado.component';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatCardModule } from '@angular/material/card';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { SolicitarRegistroComponent } from './portal/solicitar-registro/solicitar-registro.component';
import { LoginComponent } from './login/login.component';
import { CompletarRegistroComponent } from './completar-registro/completar-registro.component';
import { CompletarSolicitudComponent } from './completar-registro/completar-solicitud/completar-solicitud.component';
import { MarcoLegalComponent } from './completar-registro/marco-legal/marco-legal.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableModule } from '@angular/material/table';
import { DialogAdjuntarComponent } from './completar-registro/dialog-adjuntar/dialog-adjuntar.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AuthInterceptorService } from './@core/services/AuthInterceptorService';
import { RecaptchaV3Module, RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';
import { environment } from 'src/environments/environment';
import { HashLocationStrategy, LocationStrategy  } from '@angular/common';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { CustomMatPaginatorIntl } from './Util/pipes/paginator-es';
import { EnlacesComponent } from './completar-registro/enlaces/enlaces.component';
import { ProgessSpinnerDialogComponent } from './progess-spinner-dialog/progess-spinner-dialog.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ConsultarDniComponent } from './consultar-dni/consultar-dni.component';
import { ConsultarQrComponent } from './consultar-qr/consultar-qr.component';
import { MatRadioModule } from '@angular/material/radio';


@NgModule({
  declarations: [
    AppComponent,
    PortalComponent,
    ConsultarAsociadoComponent,
    SolicitarRegistroComponent,
    LoginComponent,
    CompletarRegistroComponent,
    CompletarSolicitudComponent,
    MarcoLegalComponent,
    DialogAdjuntarComponent,
    EnlacesComponent,
    ProgessSpinnerDialogComponent,
    ConsultarDniComponent,
    ConsultarQrComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MenuModule,
    MatSidenavModule,
    MatToolbarModule,
    MatIconModule, 
    MatCardModule,
    HttpClientModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    FormsModule,
    MatButtonModule,
    MatInputModule,
    MatAutocompleteModule,    
    MatFormFieldModule,
    RecaptchaV3Module,
    MatCheckboxModule,
    MatMenuModule, 
    MatTableModule,
    DragDropModule,
    FlexLayoutModule,
    MatProgressSpinnerModule,
    MatRadioModule
  ],
  providers: [
    // { provide: MAT_DATE_LOCALE, useValue: 'en-GB'},
    { provide: MAT_DATE_LOCALE, useValue: 'es-ES'},
    { provide: APP_BASE_HREF, useValue: '/'},
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.recaptcha.siteKey
    },
    { provide: MatPaginatorIntl, useClass: CustomMatPaginatorIntl},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true
    },
    {provide : LocationStrategy , useClass: HashLocationStrategy}
  ],
  entryComponents: [
    DialogAdjuntarComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
