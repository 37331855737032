import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Configuracion } from './config';
import { Entidad } from '../models/Entidad';
import { variablesGlobales } from 'src/app/Util/variableGlobal';

@Injectable({
    providedIn: 'root'
})

export class EntidadService {
    public url:string = Configuracion.url2 + 'Entidad/';
    
    constructor(private _http: HttpClient) { }

    ConsultaLibre(tX_NUMERO:string){
        let bodyServicio = {"tX_NUMERO":tX_NUMERO, 
                            "tX_TIPODOCUMENTO":"1"}; 
        return this._http.post<any>(Configuracion.url4 + 'ciudadano/obtener', bodyServicio);
    }

    Listar(opc: number){
        let bodyServicio = {"NU_OPCION_BUSQUEDA":opc}; 
        //console.log(bodyServicio);
        return this._http.post<any>(this.url + 'Listar', bodyServicio);
    }

    Obtener(codigo: number, opc: number){
        let bodyServicio = {"NU_ID_ENTIDAD":codigo,
                            "NU_OPCION_BUSQUEDA": opc}; 
        return this._http.post<any>(this.url + 'Obtener', bodyServicio);
    }

    Actualizar(variable: Entidad){
        variable.nU_ID_USUARIO=variablesGlobales.usuario.codusu;
        
        return this._http.post<any>(this.url + 'Actualizar', variable);
    }

    ObtieneCargos(opc: number){
        let bodyServicio = {"NU_CODIGO_BUSQUEDA":opc}; 
        return this._http.post<any>(this.url + 'ObtieneCargos', bodyServicio);
    }
}