import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CompletarRegistroComponent } from './completar-registro/completar-registro.component';
import { ConsultarDniComponent } from './consultar-dni/consultar-dni.component';
import { ConsultarQrComponent } from './consultar-qr/consultar-qr.component';
import { LoginComponent } from './login/login.component';
import { PortalComponent } from './portal/portal.component';

const routes: Routes = [
  { path: 'portal', component: PortalComponent},
  { path: 'completa_solicitud/:token', component: CompletarRegistroComponent},
  { path: 'consultar_dni', component: ConsultarDniComponent},
  { path: 'consultar_qr/:token', component: ConsultarQrComponent},
  { path: 'login', component: LoginComponent},
  { path: 'menu', loadChildren: () => import('./menu/menu.module').then(m => m.MenuModule)},
  //{ path: '', redirectTo: 'portal', pathMatch: 'full' },
   // { path: '**', redirectTo: 'portal' },
  //{ path: '', redirectTo: 'completa_solicitud', pathMatch: 'full' },
    //{ path: '**', redirectTo: 'completa_solicitud' },
  //{ path: '', redirectTo: 'consultar_qr', pathMatch: 'full' },
  //{ path: '**', redirectTo: 'consultar_qr' },
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: '**', redirectTo: 'login' }
];

@NgModule({
  //imports: [RouterModule.forRoot(routes)],
  imports: [RouterModule.forRoot(routes, {
    onSameUrlNavigation: 'reload'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
