import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { Component, HostListener, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatRadioChange } from '@angular/material/radio';
import { AutenticaService } from 'src/app/@core/services/AutenticaService';
import { SolicitudPadronService } from 'src/app/@core/services/SolicitudPadronService';
import { variablesGlobales } from 'src/app/Util/variableGlobal';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-dialog-atender-documento',
  templateUrl: './dialog-atender-documento.component.html',
  styleUrls: ['./dialog-atender-documento.component.scss']
})
export class DialogAtenderDocumentoComponent implements OnInit {

  FrmAtenderDocumento!: FormGroup;
  codigo: number = 0;
  isVisibleObservacion: boolean = false;
  requiredObservacion: boolean=false;

  @ViewChild('autosize') autosize!: CdkTextareaAutosize;

  constructor(private _formBuilder: FormBuilder,
              private ObjAutenticaService: AutenticaService,
              private ObjSolicitudPadronService: SolicitudPadronService,
              private dialogRef: MatDialogRef<DialogAtenderDocumentoComponent>,
              @Inject(MAT_DIALOG_DATA) data: any,) {
                this.codigo=data.id;
              }

  ngOnInit(): void {
    this.FrmAtenderDocumento=this._formBuilder.group({
      rdoObservacion:[null],
      txtobservacion: [null]
    });
  }
  
  @HostListener('window:keyup.esc') onKeyUp() {
    this.Close();
  }

  fnMostrarObservacion(event: MatRadioChange){
    this.isVisibleObservacion=(event.value=="0")?true:false;

    if(event.value=="0"){
      this.requiredObservacion=true;
      this.FrmAtenderDocumento.controls["txtobservacion"].setValidators([Validators.required]);
    }
    else{
      this.requiredObservacion=false;
      this.FrmAtenderDocumento.controls["txtobservacion"].setValidators(null);
      this.FrmAtenderDocumento.controls["txtobservacion"].setValue(null);
    }
  }


  alertMsg(msg:string){
    Swal.fire({
      html:msg,
      icon:'info'
    })
  }

  ValidarCampos(){
    if (this.FrmAtenderDocumento.controls["rdoObservacion"].value === null) {
        this.alertMsg('Seleccione Opción');
        return false;
    }
    else{
      if(this.FrmAtenderDocumento.controls["rdoObservacion"].value === "0"){
        if (this.FrmAtenderDocumento.controls["txtobservacion"].value === null || 
          this.FrmAtenderDocumento.controls["txtobservacion"].value.length === 0 || 
          /^\s+$/.test(this.FrmAtenderDocumento.controls["txtobservacion"].value)) {
            this.alertMsg('Ingrese Motivo');
            return false;
        }
      }
    }

    return true;
  }

  Guardar(){
    if(this.ValidarCampos()){
      this.ObjAutenticaService.ValidarApp()
        .subscribe(
          data => { 
            if(data.isSuccess){
              if(data.tx_token.length>0){
                variablesGlobales.token=data.tx_token;
                
                this.ObjSolicitudPadronService.Procesar(this.codigo, 
                                                        parseInt(this.FrmAtenderDocumento.controls["rdoObservacion"].value),
                                                        this.FrmAtenderDocumento.controls["txtobservacion"].value).pipe()
                  .subscribe(
                    data => {
                      if (data.isSuccess){

                        Swal.fire({
                          title: data.tx_Mensaje,
                          icon: 'success'});
                          
                        this.dialogRef.close(data.isSuccess);
                      }
                      else{
                        Swal.fire({
                          title: data.tx_Mensaje,
                          icon: 'warning'}); 
                      } 
                    },
                    error => {
                      //console.log(error);
                      Swal.fire({
                        title:'El servicio solicitado no se encuentra disponible',
                        icon: 'error',
                      });
                    }
                  );
              }
              else{
                  //console.log("Autenticación inválida: "+data.tx_Mensaje);
              }
            }
          },
          error => {
            //console.log(error);
          }   
        );
    }
  }


  Close() {
    this.dialogRef.close();
  }
}
