import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { NavigationEnd, Router } from '@angular/router';
import { Documento } from 'src/app/@core/models/Documento';
import { AutenticaService } from 'src/app/@core/services/AutenticaService';
import { DocumentoService } from 'src/app/@core/services/DocumentoService';
import { variablesGlobales } from 'src/app/Util/variableGlobal';
import { DialogAtenderDocumentoComponent } from './dialog-atender-documento/dialog-atender-documento.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-gestiona-documento',
  templateUrl: './gestiona-documento.component.html',
  styleUrls: ['./gestiona-documento.component.scss']
})
export class GestionaDocumentoComponent implements OnInit, OnDestroy {

  FrmGestDocumento!: FormGroup;

  codigo:number=0;
  principal:number=1;
  detalle:number=0;
  tipo:number=0;
  navigationSubscription?: Subscription;

  Documento: Documento[]=[];
  displayedColumns: string[] = ['nU_ID_DOCUMENTO','fE_EMISION','tX_SIGLA','tX_DESCRIPCION','tX_NOTA','tX_ACCION'];
  dataSource = this.Documento;

  DocumentoTable : Documento[] = [];
  DocumentoTableDataSource = new MatTableDataSource(this.DocumentoTable);

  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;

  constructor(private _formBuilder: FormBuilder,
              private router: Router,
              private ObjAutenticaService: AutenticaService,
              private ObjDocumentoService: DocumentoService,
              public dialog: MatDialog) { }

  ngOnInit(): void {
    this.FrmGestDocumento=this._formBuilder.group({
      txtbuscar:[''],
    });

    this.LlenaGrilla();

    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd) {
        this.codigo=0;
        this.principal=1;
        this.detalle=0;
        this.tipo=0;
        this.Documento= [];
        this.DocumentoTableDataSource.data=this.Documento; 
        this.DocumentoTableDataSource.paginator=this.paginator;

        this.FrmGestDocumento.controls["txtbuscar"].setValue('');

        var element= document.getElementById("paginadorDocumento");
        if(element!=null){
          element.style.display='block';
        }
        
        this.LlenaGrilla();
      }
    });
  }

  ngOnDestroy() {
    if(this.navigationSubscription!=null){
      this.navigationSubscription.unsubscribe();
    }
  }

  LlenaGrilla(){
    this.ObjAutenticaService.ValidarApp()
      .subscribe(
        data => { 
          if(data.isSuccess){
            if(data.tx_token.length>0){
              variablesGlobales.token=data.tx_token;

              this.ObjDocumentoService.Listar()
                .subscribe(data => {
                  if (data.isSuccess){
                    this.Documento= data.data;
                    this.DocumentoTableDataSource.data=this.Documento; 
                  }                                         
                }); 

              this.DocumentoTableDataSource.paginator=this.paginator;
            }
            else{
              variablesGlobales.token='';
              variablesGlobales.usuario.codusu=0;
              variablesGlobales.usuarioOficinaCargo.codOficina=0;
              variablesGlobales.usuarioOficinaCargo.codCargo=0;
              variablesGlobales.usuarioRol.admBase=0;
              variablesGlobales.usuarioRol.admEquipo=0;
              variablesGlobales.usuarioRol.admDelegado=0;
              variablesGlobales.usuarioRol.admDeportista=0;
              variablesGlobales.usuarioRol.isVisibleData=0;
              variablesGlobales.menu={};
              variablesGlobales.codevento=0;
              this.router.navigate(['login']);
            }
          }
        },
        error => {
          //console.log(error);
          variablesGlobales.token='';
          variablesGlobales.usuario.codusu=0;
          variablesGlobales.usuarioOficinaCargo.codOficina=0;
          variablesGlobales.usuarioOficinaCargo.codCargo=0;
          variablesGlobales.usuarioRol.admBase=0;
          variablesGlobales.usuarioRol.admEquipo=0;
          variablesGlobales.usuarioRol.admDelegado=0;
          variablesGlobales.usuarioRol.admDeportista=0;
          variablesGlobales.usuarioRol.isVisibleData=0;
          variablesGlobales.menu={};
          variablesGlobales.codevento=0;
          this.router.navigate(['login']);
        }   
      );   
  }

  convertFormatDate(fecha: string){
    var parts=fecha.split("-");
    var dia =parts[2].substring(0,2);
    var mes =parts[1];
    var anio =parts[0];

    return dia + '/' + mes + '/' + anio; 
  }

  fnDetalle(iddocumento: number, idTipo: number){
    this.principal=0;
    this.detalle=1;
    this.tipo=idTipo;
    this.codigo=iddocumento;

    var element= document.getElementById("paginadorDocumento");
    if(element!=null){
      element.style.display='none';
    }
  }

  fnRetornoDetalle(objeto:any){
    this.principal=1;
    this.detalle=0;
    this.codigo=0;

    var element= document.getElementById("paginadorDocumento");
    if(element!=null){
      element.style.display='block';
    }

    if(objeto==1){
      this.LlenaGrilla();
    }
  }

  fnRecibir(ObjDocumento: Documento){
    /*Swal.fire({
      text: "¿Está seguro de recibir el expediente "+ObjDocumento.expediente+"?",
      icon: 'question',
      showCancelButton: true,
      cancelButtonText:'No',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí',
      reverseButtons: true
    }).then((result) => {
      if (result.value){
        //console.log("recibido");
      }
    });*/
  }

  fnAtender(iddocumento: number){
    const dialogRef = this.dialog.open(DialogAtenderDocumentoComponent, {
      data: {
        id: iddocumento,
      },
      disableClose: true 
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.LlenaGrilla();
      }
    });
  }
}
