<mat-sidenav-container>
    <mat-sidenav-content fxLayout="column">
      <div style="position: fixed;width: 100%;z-index: 60;">
        <mat-toolbar class="mat-toolbar_principal">         
          <span style="text-align: start;"> 
              <div style="display:flex; align-items:center;">
                <p class="titulo">{{txtTitulo}}</p>
              </div>
          </span>
        </mat-toolbar> 
        <mat-toolbar style="height: 5px; background-color: rgba(25, 115, 184);"></mat-toolbar> 
      </div>     
      <div class="pagina">
        <div class="div_centrado">
            <form [formGroup]="FrmConsultarONPE"> 
                <div style="display:flex; justify-content: center;">
                    <mat-card fxLayout="column" style="width: 335px;">  
                        <p>Consulta ONPE</p>
                        <mat-card-content fxLayout="column">
                            <div>
                                <mat-form-field class="fila">
                                    <mat-select placeholder="Tipo de Documento" formControlName="tipo_documento" required (selectionChange)="Limpiar()">
                                        <mat-option value="1">DNI - DOCUMENTO NACIONAL DE IDENTIDAD</mat-option>
                                        <!--<mat-option value="4">Carnet de Extranjería</mat-option>
                                        <mat-option value="7">Pasaporte</mat-option>-->
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div>
                                <mat-form-field class="fila">
                                    <input matInput aria-label="string" placeholder="Número" formControlName="numero" autocomplete="off" required (keyup)="fnIngNumDocumento($event)" [maxLength]="maxLength">
                                </mat-form-field> 
                            </div>
                        </mat-card-content>
                        <mat-card-actions style="text-align: center;margin-bottom: 5px;">
                            <button mat-button mat-raised-button class="btnConsultar" (click)="Consultar()">Consultar</button>
                        </mat-card-actions>
                    </mat-card>
                </div>
                <div style="margin-top: 40px;" *ngIf="isVisibleTexto">
                    <p>{{resultado}}</p>
                </div>
            </form>
        </div>
      </div>     
      <ng-content>        
      </ng-content>
      <div style="width: 100%;"> 
        <mat-toolbar class="fondo_pie" fxLayout="row" fxLayoutAlign="space-between start">
          <div class="contenido">  
            <div class="row">
              <div class="col">
                <!--<a href="https://www.gob.pe/osinfor" target="_blank" style="text-decoration: none;">
                  <strong class="formato">Osinfor Peru</strong>
                </a>-->
              </div>
              <div class="col">
                <p class="formatoVersion">versión</p>
              </div>
            </div>          
          </div>
        </mat-toolbar>
      </div>
    </mat-sidenav-content>    
</mat-sidenav-container>
    