import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatTableDataSource } from '@angular/material/table';
import { DomSanitizer } from '@angular/platform-browser';
import { Departamento } from 'src/app/@core/models/Departamento';
import { Distrito } from 'src/app/@core/models/Distrito';
import { Equipo } from 'src/app/@core/models/Equipo';
import { Evento } from 'src/app/@core/models/Evento';
import { Integrante } from 'src/app/@core/models/Integrante';
import { IntegranteRequisito } from 'src/app/@core/models/IntegranteRequisito';
import { Provincia } from 'src/app/@core/models/Provincia';
import { ArchivoService } from 'src/app/@core/services/ArchivoService';
import { AutenticaService } from 'src/app/@core/services/AutenticaService';
import { DepartamentoService } from 'src/app/@core/services/DepartamentoService';
import { DistritoService } from 'src/app/@core/services/DistritoService';
import { IntegranteService } from 'src/app/@core/services/IntegranteService';
import { ProvinciaService } from 'src/app/@core/services/ProvinciaService';
import { variablesGlobales } from 'src/app/Util/variableGlobal';
import Swal from 'sweetalert2';

export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};

@Component({
  selector: 'app-detalle-deportista',
  templateUrl: './detalle-deportista.component.html',
  styleUrls: ['./detalle-deportista.component.scss'],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }
  ]
})
export class DetalleDeportistaComponent implements OnInit {

  @Output() salida:any= new EventEmitter();

  FrmDetalleIntegrante!: FormGroup;
  @Input() ObjEvento!:Evento;
  @Input() ObjEquipo!:Equipo;
  @Input() codintegrante:number=0;
  txtTitulo!: string;
  ObjIntegrante!: Integrante;
  Departamento!: Departamento[];
  Provincia!: Provincia[];
  Distrito!: Distrito[];
  isSocio: boolean=false;
  src: any;
  isVisibleFoto: boolean=false;
  isVisibleData: number=0;

  IntegranteRequisito: IntegranteRequisito[]=[];
  displayedColumns: string[] = ['nU_ID_INTEGRANTEREQUISITO','tX_EXTENSION','tX_REQUISITO','tX_DESCRIPCION','fE_REGISTRO'];
  dataSource = this.IntegranteRequisito;

  IntegranteRequisitoTable: IntegranteRequisito[]=[];
  IntegranteRequisitoTableDataSource = new MatTableDataSource(this.IntegranteRequisitoTable);

  constructor(private _formBuilder: FormBuilder,
              private ObjAutenticaService: AutenticaService,
              private ObjIntegranteService: IntegranteService,
              private ObjDepartamentoService: DepartamentoService,
              private ObjProvinciaService: ProvinciaService,
              private ObjDistritoService: DistritoService,
              private ObjArchivoService: ArchivoService,
              private domSanitizer: DomSanitizer
              ) { }

  ngOnInit(): void {
    this.FrmDetalleIntegrante=this._formBuilder.group({ 
      txttipodocumento: [{value:null, disabled:true}],
      txtnumero: [null],
      txtnombre: [null],
      txtape_paterno: [null],
      txtape_materno: [null],
      FecNacimiento:[null],
      txtanio:[null],
      turno: [{value:null, disabled:true}],
      sexo: [{value:null, disabled:true}],
      txtemail: [null],
      txttelefono: [null],
      coddepartamento: [{value:null, disabled:true}],
      codprovincia: [{value:null, disabled:true}],
      coddistrito: [{value:null, disabled:true}],
      txtdireccion: [null]
    });

    this.txtTitulo=this.ObjEvento.tX_DENOMINACION!;

    this.isVisibleData=variablesGlobales.usuarioRol.isVisibleData;

    this.fnObtener();
  }

  fnObtener(){
    this.ObjAutenticaService.ValidarApp()
      .subscribe(
        data => { 
          if(data.isSuccess){
            if(data.tx_token.length>0){
              variablesGlobales.token=data.tx_token;

              this.ObjIntegranteService.Obtener(this.codintegrante).pipe()
                .subscribe(
                  data => {
                    //console.log(data);
                    if (data.isSuccess){
                      this.ObjIntegrante=data.integrante;
                      this.IntegranteRequisito=data.listaarchivosadjuntos;
                      this.IntegranteRequisitoTableDataSource.data=this.IntegranteRequisito;

                      for(let item of data.listaarchivosadjuntos){
                        if(item.nU_ID_REQUISITO===3){
                          this.isVisibleFoto=true;
                          this.fnObtenerSrc(item);
                        }
                      }

                      this.FrmDetalleIntegrante.controls["txttipodocumento"].setValue(this.ObjIntegrante.tX_ID_TIPODOCUMENTOIDENTIDAD);
                      this.FrmDetalleIntegrante.controls["txtnumero"].setValue(this.ObjIntegrante.tX_NUMERO);
                      this.FrmDetalleIntegrante.controls["txtnombre"].setValue(this.ObjIntegrante.tX_NOMBRE);
                      this.FrmDetalleIntegrante.controls["txtape_paterno"].setValue(this.ObjIntegrante.tX_APELLIDO_PATERNO);
                      this.FrmDetalleIntegrante.controls["txtape_materno"].setValue(this.ObjIntegrante.tX_APELLIDO_MATERNO);                    
                      this.FrmDetalleIntegrante.controls["sexo"].setValue(this.ObjIntegrante.tX_SEXO);
                      this.FrmDetalleIntegrante.controls["txtemail"].setValue(this.ObjIntegrante.tX_CORREO);
                      this.FrmDetalleIntegrante.controls["txttelefono"].setValue(this.ObjIntegrante.tX_TELEFONO);
                      this.FrmDetalleIntegrante.controls["txtdireccion"].setValue(this.ObjIntegrante.tX_DIRECCION);

                      if(this.ObjIntegrante.fE_NACIMIENTO!=null){
                        this.FrmDetalleIntegrante.controls["FecNacimiento"].setValue(this.convertFechaString(this.ObjIntegrante.fE_NACIMIENTO));
                      }

                      if(this.ObjIntegrante.nU_ANIO_EGRESO!>0){
                        this.isSocio=true;
                        this.FrmDetalleIntegrante.controls["txtanio"].setValue(this.ObjIntegrante.nU_ANIO_EGRESO);
                        this.FrmDetalleIntegrante.controls["turno"].setValue(this.ObjIntegrante.tX_TURNO_ASOCIADO);
                      }

                      if(this.ObjIntegrante.tX_UBIGEO!=null){
                        this.ObjDepartamentoService.Listar(0,'').pipe()    
                          .subscribe(
                            data => {
                              if(data.isSuccess){
                                this.Departamento= data.data;

                                if(this.Departamento.length>0){
                                  this.FrmDetalleIntegrante.controls["coddepartamento"].setValue(this.ObjIntegrante.tX_UBIGEO!.substring(0,2));

                                  this.ObjProvinciaService.Listar(1,this.ObjIntegrante.tX_UBIGEO!.substring(0,2)).pipe()    
                                    .subscribe(
                                      data => {
                                        if(data.isSuccess){
                                          this.Provincia= data.data;
                                          if(this.Provincia.length>0){
                                            this.FrmDetalleIntegrante.controls["codprovincia"].setValue(this.ObjIntegrante.tX_UBIGEO!.substring(0,4));

                                            this.ObjDistritoService.Listar(0,this.ObjIntegrante.tX_UBIGEO!.substring(0,4)).pipe()    
                                              .subscribe(
                                                data => {
                                                  if(data.isSuccess){
                                                    this.Distrito= data.data;
                                                    if(this.Distrito.length>0){
                                                      this.FrmDetalleIntegrante.controls["coddistrito"].setValue(this.ObjIntegrante.tX_UBIGEO);
                                                    } 
                                                  }                                  
                                                },
                                                error => {
                                                  //console.log(error);
                                                }
                                              );
                                          } 
                                        }                                  
                                      },
                                      error => {
                                        //console.log(error);
                                      }
                                    );
                                }
                              }                                  
                            },
                            error => {
                              //console.log(error);
                            }
                          );
                      }
                    }
                  },
                  error => {
                    //console.log(error);
                    Swal.fire({
                      title:'El servicio solicitado no se encuentra disponible',
                      icon: 'error',
                    });
                  }
                );
            }
            else{
                //console.log("Autenticación inválida: "+data.tx_Mensaje);
            }
          }
        },
        error => {
          //console.log(error);
        }   
      );
  }

  convertFechaString(fecha: any){
    var parts=fecha.split("/");
    var dia =parts[1];
    var mes =parts[0];
    var anio =parts[2].substring(0,4);
    var mydate =new Date(anio,mes-1,dia)

    return mydate.toISOString();
  }

  fnDescargar(obj: IntegranteRequisito){
    this.ObjAutenticaService.ValidarApp()
      .subscribe(
        data => { 
          if(data.isSuccess){
            if(data.tx_token.length>0){
              variablesGlobales.token=data.tx_token;

              this.ObjArchivoService.ObtenerTrama(obj.nU_ID_ARCHIVO+"."+obj.tX_EXTENSION).pipe()    
                .subscribe(
                  data => {
                    if(data.isSuccess){
                      obj.trama=data.tx_Mensaje;
                      this.fnObtenerArchivo(obj);
                    } 
                    else{
                      Swal.fire({
                        title:'Archivo no encontrado',
                        icon: 'info',
                      });
                    }                                  
                  },
                  error => {
                    //console.log(error);
                    Swal.fire({
                      title:'El servicio solicitado no se encuentra disponible',
                      icon: 'error',
                    });
                  }
                );
            }
            else{
                //console.log("Autenticación inválida: "+data.tx_Mensaje);
            }
          }
        },
        error => {
          //console.log(error);
        }   
      );
  }

  fnObtenerArchivo(obj: IntegranteRequisito){
    const byteCharacters= atob(obj.trama);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);
  
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
  
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    var nombre=obj.nU_ID_ARCHIVO+'.'+obj.tX_EXTENSION;

    const blob = new Blob(byteArrays, {type: 'application/'+obj.tX_EXTENSION});
    const blobUrl = URL.createObjectURL(blob);

    const link:any = document.createElement('a');
    link.href = blobUrl;
    link.download = nombre;
    link.click();
  }

  fnObtenerSrc(obj: IntegranteRequisito){
    this.ObjArchivoService.ObtenerTrama(obj.nU_ID_ARCHIVO+"."+obj.tX_EXTENSION).pipe()    
      .subscribe(
        data => {
          if(data.isSuccess){
            const byteCharacters= atob(data.tx_Mensaje);
            const byteArrays = [];

            for (let offset = 0; offset < byteCharacters.length; offset += 512) {
              const slice = byteCharacters.slice(offset, offset + 512);
          
              const byteNumbers = new Array(slice.length);
              for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
              }
          
              const byteArray = new Uint8Array(byteNumbers);
              byteArrays.push(byteArray);
            }

            const blob = new Blob(byteArrays, {type: 'application/'+obj.tX_EXTENSION});
            const reader = new FileReader();
            reader.onload = () => {
              this.src = this.domSanitizer.bypassSecurityTrustUrl(reader.result!.toString());
            };
            reader.readAsDataURL(new File([blob],obj.nU_ID_ARCHIVO+"."+obj.tX_EXTENSION,
              {type: 'application/'+obj.tX_EXTENSION}));
            }                                  
        },
        error => {
          //console.log(error);
          Swal.fire({
            title:'El servicio solicitado no se encuentra disponible',
            icon: 'error',
          });
        }
      );
  }

  convertFecha(fecha: string): string{
    var parts=fecha.split("-");
    var anio =parts[0];
    var mes =parts[1];
    var dia =parts[2].substring(0,2);
    var hora =parts[2].substring(3,11);

    return dia+"/"+mes+"/"+anio+" "+hora;
  }

  Retorno(){
    this.salida.emit(1);
  }
}
