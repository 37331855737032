
import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';

@Injectable()
export class CustomMatPaginatorIntl extends MatPaginatorIntl {
    constructor(){
        super();
    }

    itemsPerPageLabel = 'Resultados por página'; 
    nextPageLabel     = 'Página siguiente';
    previousPageLabel = 'Página Anterior';
    firstPageLabel = 'Primera página';
    lastPageLabel = 'Última página';

    getRangeLabel=(page:number,pagesize:number,length:number)=>{
        if(length===0||pagesize===0){
            //return '0 de ${length}';
            return '0 de ' + length;
        }

        length=Math.max(length,0);
        const startIndex=page*pagesize;
        const endIndex=startIndex<length?Math.min(startIndex+pagesize,length):startIndex+pagesize;
        //return '${startIndex + 1} - ${endIndex} de ${length}';
        return startIndex + 1 + ' - ' + endIndex + ' / ' + length;
    }
}