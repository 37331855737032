import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { Component, HostListener, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Base } from 'src/app/@core/models/Base';
import { Equipo } from 'src/app/@core/models/Equipo';
import { AutenticaService } from 'src/app/@core/services/AutenticaService';
import { BaseService } from 'src/app/@core/services/BaseService';
import { EquipoService } from 'src/app/@core/services/EquipoService';
import { variablesGlobales } from 'src/app/Util/variableGlobal';
import { map, startWith } from 'rxjs/operators';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-dialog-agregar-equipo',
  templateUrl: './dialog-agregar-equipo.component.html',
  styleUrls: ['./dialog-agregar-equipo.component.scss']
})
export class DialogAgregarEquipoComponent implements OnInit {

  FrmAgregarEquipo!: FormGroup;
  coddisciplina:number=0;
  Base: Base[]=[];
  filteredBase: any;
  ListaEquipo:Equipo[]=[];
  codigo:number=0;
  ObjEquipo!: Equipo;

  @ViewChild('autosize') autosize!: CdkTextareaAutosize;

  constructor(private _formBuilder: FormBuilder,
              private ObjAutenticaService: AutenticaService,
              private objBaseService: BaseService,
              private objEquipoService: EquipoService,
              private dialogRef: MatDialogRef<DialogAgregarEquipoComponent>,
              @Inject(MAT_DIALOG_DATA) data: any,
              ) {
                this.coddisciplina=data.coddisciplina;
                this.ListaEquipo=data.listaEquipo;
              }

  ngOnInit(): void {
    this.FrmAgregarEquipo=this._formBuilder.group({
      codbase: [null, Validators.required],
      txtdenominacion: [null, Validators.compose([Validators.required,Validators.maxLength(10)])],
      cboserie: [null, Validators.required]
    });   
  }

  @HostListener('window:keyup.esc') onKeyUp() {
    this.Close();
  }

  fnListarBase(){   
    this.ObjAutenticaService.ValidarApp()
      .subscribe(
        data => { 
          if(data.isSuccess){
            if(data.tx_token.length>0){
              variablesGlobales.token=data.tx_token;

              this.objBaseService.Listar(1).pipe()
                .subscribe(data => {
                  if(data.isSuccess){
                    this.Base=data.listabase;
                    this.filteredBase=this.FrmAgregarEquipo.controls["codbase"].valueChanges
                        .pipe(
                          startWith(''),
                          map(value => typeof value === 'string' ? value : value.nU_ANIO_PROMOCION+' - '+value.tX_TURNO),            
                          map(descripcion => descripcion ? this._filterBase(descripcion) : this.Base.slice()
                        )
                    );
                  }
                });
            }
            else{
                //console.log("Autenticación inválida: "+data.tx_Mensaje);
            }
          }
        },
        error => {
          //console.log(error);
        }   
      ); 
  }

  displayFnBase(base?: Base): string{
    return base ? base.nU_ANIO_PROMOCION+' - '+base.tX_TURNO: "";
  }

  private _filterBase(descripcion: string): Base[] {
    const filterValue = descripcion.toLowerCase();
    return this.Base.filter(option => (option.nU_ANIO_PROMOCION+' - '+option.tX_TURNO!.toLowerCase()).includes(filterValue));
  }

  fnLimpiar(){
    if(this.FrmAgregarEquipo.controls["codbase"].value===null ||
      this.FrmAgregarEquipo.controls["codbase"].value.nU_ID_BASE===undefined){
        this.FrmAgregarEquipo.controls["txtdenominacion"].setValue(null);
        this.FrmAgregarEquipo.controls["cboserie"].setValue(null);
    }
  }

  alertMsg(msg:string){
    Swal.fire({
      html:msg,
      icon:'info'
    })
  }

  ValidarCampos(){
    var band=0;

    if(this.FrmAgregarEquipo.controls["codbase"].value!==null &&
      this.FrmAgregarEquipo.controls["codbase"].value.nU_ID_BASE!==undefined){
        band=1;
    }
    if(band===0){
      this.alertMsg('Seleccione Base');
      return false;
    }
    else{
      band=0;
    }

    if (this.FrmAgregarEquipo.controls["txtdenominacion"].value === null || 
      this.FrmAgregarEquipo.controls["txtdenominacion"].value.length === 0 || 
      /^\s+$/.test(this.FrmAgregarEquipo.controls["txtdenominacion"].value)) {
        this.alertMsg('Ingrese Denominación');
        return false;
    }

    if (this.FrmAgregarEquipo.controls["cboserie"].value === null || 
      this.FrmAgregarEquipo.controls["cboserie"].value.length === 0) {
        this.alertMsg('Seleccione Serie');
        return false;
    }

    return true;
  }

  Guardar(){
    if(this.ValidarCampos()){
      let band=0;

      if(this.ListaEquipo.length>0){
        for(let item of this.ListaEquipo){
          if(item.nU_ID_BASE===this.FrmAgregarEquipo.controls["codbase"].value.nU_ID_BASE &&
            item.nU_ID_EVENTO===variablesGlobales.codevento &&
            item.nU_ID_DISCIPLINA===this.coddisciplina && 
            item.tX_DENOMINACION===this.FrmAgregarEquipo.controls["txtdenominacion"].value.trim() &&
            item.tX_SERIE===this.FrmAgregarEquipo.controls["cboserie"].value){
              band=1;
              break;     
          }
        }
      }

      if(band===1){
        Swal.fire({
          html:'El equipo ya existe en la lista',
          icon:'info' 
        })
      }
      else{ 
        this.ObjEquipo={nU_ID_EQUIPO:0};
        this.ObjEquipo.nU_ID_EQUIPO=this.codigo;
        this.ObjEquipo.nU_ID_BASE=this.FrmAgregarEquipo.controls["codbase"].value.nU_ID_BASE;
        this.ObjEquipo.nU_ID_EVENTO=variablesGlobales.codevento;
        this.ObjEquipo.nU_ID_DISCIPLINA=this.coddisciplina;
        this.ObjEquipo.tX_DENOMINACION=this.FrmAgregarEquipo.controls["txtdenominacion"].value.trim();
        this.ObjEquipo.tX_SERIE=this.FrmAgregarEquipo.controls["cboserie"].value;

        this.ObjAutenticaService.ValidarApp()
          .subscribe(
            data => { 
              if(data.isSuccess){
                if(data.tx_token.length>0){
                  variablesGlobales.token=data.tx_token;

                  this.objEquipoService.Actualizar(this.ObjEquipo).pipe()
                    .subscribe(
                      data => {
                        if (data.isSuccess){
                          Swal.fire({
                            title: data.tx_Mensaje,
                            icon: 'success'}); 
                          
                          this.dialogRef.close(data.isSuccess);
                        }
                        else{
                          Swal.fire({
                            title: data.tx_Mensaje,
                            icon: 'warning'}); 
                        } 
                      },
                      error => {
                        //console.log(error);
                        Swal.fire({
                          title:'El servicio solicitado no se encuentra disponible',
                          icon: 'error',
                        });
                      }
                    );
                }
                else{
                    //console.log("Autenticación inválida: "+data.tx_Mensaje);
                }
              }
            },
            error => {
              //console.log(error);
            }   
          );
      }
    }
  }

  Close() {
    this.dialogRef.close();
  }

  pulsar(event:any) {
    if (event.which === 13 && !event.shiftKey) {
      event.preventDefault();
      return false;
    }
    return true;
  }
}
