import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})

export class Functions {

    constructor() { }

    ValidateDateRange = function (fechaini:Date,fechafin:Date) {
        var valRetorno = true;
    
        if (fechafin < fechaini) {       
            valRetorno = false;
        }
    
        return valRetorno;
    };

    GetDate() {
        const fecha: Date=new Date();
        const dia: string = fecha.getDate().toString().padStart(2, '0');
        const mes: string = (fecha.getMonth() + 1).toString().padStart(2, '0');
        const anio: string = fecha.getFullYear().toString();

        var hora=fecha.getHours();
        var min=fecha.getMinutes();
        var seg=fecha.getSeconds();
        var ampm=(hora>=12)?'p.m.':'a.m.';
        hora=(hora>12)?hora-12:hora;

        return dia + '/' + mes + '/' + anio + ' ' +
            ((hora<10)?'0'+hora:hora) + ':' + ((min<10)?'0'+min:min) + ':' + ((seg<10)?'0'+seg:seg) + ' ' + ampm; 
    }

    formatNumberES(number: number, decimal: number = 0): string{
        const format: string = number.toFixed(decimal);
        var array=Array.from(format.substring(0,((format.indexOf(".")==-1)?format.length:format.indexOf("."))));

        var cad="";
        var x=0;

        for(let i=array.length-1;i>=0;i--){
            x++;           
            if(x==4){
                cad=array[i]+","+cad;
                x=1;
            }
            else cad=array[i]+cad;
            
        }

        cad=(format.indexOf(".")==-1)? cad : cad + format.substring(format.indexOf("."),format.length);

        return cad;
    }

    GetFecha(fecha: string): string{
        var parts=fecha.split("/");
        var mes =parseInt(parts[1]);

        var result="";

        switch(mes){
            case 1:
                result="ENE";
                break;
            case 2:
                result="FEB";
                break;
            case 3:
                result="MAR";
                break;
            case 4:
                result="ABR";
                break;
            case 5:
                result="MAY";
                break;
            case 6:
                result="JUN";
                break;
            case 7:
                result="JUL";
                break;
            case 8:
                result="AGO";
                break;
            case 9:
                result="SEP";
                break;
            case 10:
                result="OCT";
                break;
            case 11:
                result="NOV";
                break;
            case 12:
                result="DIC";
                break;
        }

        return parts[0]+" "+result+" "+parts[2];
    }
}