<div fxLayout="column" fxLayoutAlign="center">
    <form [formGroup]="FrmGestDirectivo">
        <div class="opcion">
            <mat-label class="Titulo">Bases Organizacionales</mat-label>
        </div>
        <mat-card fxLayout="column">
            <div class="opcion1">
                <mat-label class="titulo">Base Organizacional: {{ObjBase.nU_ANIO_PROMOCION+' '+ObjBase.tX_TURNO}}</mat-label>
            </div>
            <mat-card-content fxLayout="column">
                <mat-card-content fxLayout="row">
                    <fieldset class="borderFieldset">
                        <mat-form-field class="fila1"> 
                            <mat-select placeholder="Filtrar por" formControlName="cboFiltro" (selectionChange)="fnListarFiltro($event.value)">
                                <mat-option value="1">Cargos Vigentes</mat-option>
                                <mat-option value="2">Historial</mat-option>                             
                            </mat-select>
                        </mat-form-field>
                    </fieldset>            
                </mat-card-content>
            </mat-card-content>
            <mat-card-content fxLayout="column">
                <div style="margin-top: 18px;margin-bottom: 20px;">
                    <button mat-mini-fab color="btnStyle" class="tooltips_fab" (click)="Retorno()">
                        <mat-icon>arrow_back</mat-icon>
                        <span>Regresar</span>
                    </button>
                    <button mat-mini-fab color="btnStyle" class="tooltips_fab" style="margin-left: 18px;" *ngIf="admBase" (click)="fnNuevo('NUE',0)">
                        <mat-icon>add</mat-icon>
                        <span>Añadir</span>
                    </button>
                </div>
                <h3 class="SubTitulo">Relación de Directivos</h3>
                <div class="example-container">
                    <table mat-table #table [dataSource]="BaseDirectivoTableDataSource">
                        <ng-container *ngFor="let column of columns" [matColumnDef]="column.columnDef">
                            <th mat-header-cell *matHeaderCellDef [class]="column.class">{{column.header}}</th>
                            <td mat-cell *matCellDef="let row" [class]="column.class">
                                <div *ngIf="column.columnDef==='nU_ID_BASEDIRECTIVO'">
                                    <button mat-icon-button class="tooltips_cls" [matMenuTriggerFor]="menu">
                                        <mat-icon>menu</mat-icon>
                                    </button>
                                    <mat-menu #menu="matMenu">
                                        <button mat-menu-item (click)="fnNuevo('MOD',column.cell(row))">
                                            <mat-icon fontSet="material-icons-outlined">{{(admBase && row.nU_ID_DOCUMENTO_TERMINO==0)?'handshake':'visibility'}}</mat-icon>
                                            <span>{{(admBase && row.nU_ID_DOCUMENTO_TERMINO==0)?'Finaliza Cargo':'Detalle de Cargo'}}</span>
                                        </button>
                                        <button mat-menu-item *ngIf="row.nU_ID_DOCUMENTO_INICIO>0" (click)="fnDescargarDocumento(1,row)">
                                            <mat-icon fontSet="material-icons-outlined">download</mat-icon>
                                            <span>Documento Inicio</span>
                                        </button>
                                        <button mat-menu-item *ngIf="row.nU_ID_DOCUMENTO_TERMINO>0" (click)="fnDescargarDocumento(2,row)">
                                            <mat-icon fontSet="material-icons-outlined">download</mat-icon>
                                            <span>Documento Termino</span>
                                        </button>
                                        <button mat-menu-item *ngIf="admBase && row.nU_ID_DOCUMENTO_TERMINO===0" (click)="fnEditarEntidad('MOD',row.nU_ID_ENTIDAD)">
                                            <mat-icon fontSet="material-icons-outlined">edit</mat-icon>
                                            <span>Actualiza Datos de Persona</span>
                                        </button>
                                    </mat-menu>
                                </div>
                                <div *ngIf="column.columnDef!=='nU_ID_BASEDIRECTIVO'">{{column.cell(row)}}</div>
                            </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                </div>    
            </mat-card-content>
        </mat-card> 
    </form>      
</div>
<mat-paginator id="paginadorDirectivo" class="stylePaginator" [pageSizeOptions]="[10, 20, 50]" showFirstLastButtons></mat-paginator>
