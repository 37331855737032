import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { AutenticaService } from '../@core/services/AutenticaService';
import { EntidadService } from '../@core/services/EntidadService';
import { variablesGlobales } from '../Util/variableGlobal';

@Component({
  selector: 'app-portal',
  templateUrl: './portal.component.html',
  styleUrls: ['./portal.component.scss']
})
export class PortalComponent implements OnInit {

  txtTitulo!: string;
  constructor(private ObjEntidadService: EntidadService,
    private ObjAutenticaService: AutenticaService) { 
    this.ObjAutenticaService.ValidarApp()
                    .subscribe(
                      data => { 
                        if(data.isSuccess){
                          if(data.tx_token.length>0){
                            variablesGlobales.token=data.tx_token;

                            this.ObjEntidadService.Obtener(1,0).pipe()
                              .subscribe(
                                data => {
                                  if(data.isSuccess){
                                    this.txtTitulo=data.entidad.tX_DENOMINACION;
                                  }
                                },
                                error => {
                                  console.log(error);
                                  Swal.fire({
                                    title:'El servicio solicitado no se encuentra disponible',
                                    icon: 'error',
                                  });
                                }
                              );
                          }
                          else{
                              console.log("Autenticación inválida: "+data.tx_Mensaje);
                          }
                        }
                      },
                      error => {
                        console.log(error);
                      }   
                    );
  }

  ngOnInit(): void {
    
  }

}
