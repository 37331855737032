import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { EquipoPartido } from 'src/app/@core/models/EquipoPartido';
import { Evento } from 'src/app/@core/models/Evento';
import { Fecha } from 'src/app/@core/models/Fecha';
import { Partido } from 'src/app/@core/models/Partido';
import { PlanillaJuego } from 'src/app/@core/models/PlanillaJuego';
import { AutenticaService } from 'src/app/@core/services/AutenticaService';
import { EquipoPartidoService } from 'src/app/@core/services/EquipoPartidoService';
import { PlanillaJuegoService } from 'src/app/@core/services/PlanillaJuegoService';
import { variablesGlobales } from 'src/app/Util/variableGlobal';
import { DialogAgregarPlanillajuegoComponent } from './dialog-agregar-planillajuego/dialog-agregar-planillajuego.component';
import Swal from 'sweetalert2';
import { DialogQuitarPlanillajuegoComponent } from './dialog-quitar-planillajuego/dialog-quitar-planillajuego.component';
import { GolesInfraccionesComponent } from './goles-infracciones/goles-infracciones.component';
import { InfraccionesComponent } from './infracciones/infracciones.component';
import { DetallePlanillaJuegoService } from 'src/app/@core/services/DetallePlanillaJuegoService';
import { DetallePlanillaJuego } from 'src/app/@core/models/DetallePlanillaJuego';
import { DialogQuitarDetalleplanillajuegoComponent } from './goles-infracciones/dialog-quitar-detalleplanillajuego/dialog-quitar-detalleplanillajuego.component';
import { DialogAgregarWoComponent } from './dialog-agregar-wo/dialog-agregar-wo.component';
import { Console } from 'console';

@Component({
  selector: 'app-gestiona-planilla-juego',
  templateUrl: './gestiona-planilla-juego.component.html',
  styleUrls: ['./gestiona-planilla-juego.component.scss']
})
export class GestionaPlanillaJuegoComponent implements OnInit {

  @Output() salida:any= new EventEmitter();

  FrmGestPlanilla!: FormGroup;
  @Input() ObjEvento!:Evento;
  @Input() ObjFecha!:Fecha;
  @Input() ObjPartido!:Partido;
  @Input() coddisciplina:number=0;
  @Input() txtDisciplina!:string;
  txtTitulo!: string;
  txtEquipo?: string;
  EquipoPartido: EquipoPartido[]=[];
  isBotonAgregar: number=0;
  isVisibleWalkOver: number=0;
  permiteOperacion: number=0;
  isVisibleFutbol:number=1;
  isVisibleVoley:number=0;

  PlanillaJuego: PlanillaJuego[]=[];
  displayedColumns: string[] = [
    'nU_ID_PLANILLAJUEGO',
    'tX_ENTIDAD',
    'tX_TIPO_INTEGRANTEPARTIDO',
    'nU_CAMISETA',
    'nU_CARNET',
    'tX_ASIMILADO',
    'nU_CAPITAN',
    'nU_TA',
    'nU_TR',
    'nU_GL',
    'nU_AUTOGL'
  ];

  displayedColumnsVoley: string[] = [
    'nU_ID_PLANILLAJUEGO',
    'tX_ENTIDAD',
    'tX_TIPO_INTEGRANTEPARTIDO',
    'nU_CAMISETA',
    'nU_CARNET',
    'tX_ASIMILADO',
    'nU_CAPITAN',
    'nU_TA',
    'nU_TR'
  ];

  dataSource = this.PlanillaJuego;

  PlanillaJuegoTable : PlanillaJuego[] = [];
  PlanillaJuegoTableDataSource = new MatTableDataSource(this.PlanillaJuegoTable);

  @ViewChild(MatPaginator, { static: false }) paginator!: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort!: MatSort;

  constructor(private _formBuilder: FormBuilder,
              private router: Router,
              private ObjAutenticaService: AutenticaService,
              private ObjEquipoPartidoService: EquipoPartidoService,
              private ObjPlanillaJuegoService: PlanillaJuegoService,
              private ObjDetallePlanillaJuegoService: DetallePlanillaJuegoService,
              public dialog: MatDialog) { }

  ngOnInit(): void {

    if (this.coddisciplina==2 || this.coddisciplina==3) {
      this.isVisibleVoley=1;
      this.isVisibleFutbol=0;
    }
    else{
      this.isVisibleVoley=0;
      this.isVisibleFutbol=1;
    }
    
    this.FrmGestPlanilla=this._formBuilder.group({
      cboequipopartido:[null,Validators.required]
    });

    this.isBotonAgregar=variablesGlobales.usuarioRol.admEquipo;
    this.txtTitulo=this.ObjEvento.tX_DENOMINACION!;

    if(this.ObjPartido.nU_ID_ESTADO!=18){
      this.permiteOperacion=1;
    }

    this.fnListarEquipoPartido();
  }

  fnListarEquipoPartido(){
    this.ObjAutenticaService.ValidarApp()
      .subscribe(
        data => { 
          if(data.isSuccess){
            if(data.tx_token.length>0){
              variablesGlobales.token=data.tx_token;

              this.ObjEquipoPartidoService.Listar(0,this.ObjPartido.nU_ID_PARTIDO).pipe()
                .subscribe(data => {
                  // console.log(data);
                  if(data.isSuccess){
                    this.EquipoPartido=data.partido;
                  }
                });
            }
            else{
                variablesGlobales.token='';
                variablesGlobales.usuario.codusu=0;
                this.router.navigate(['login']);
            }
          }
        },
        error => {
          console.log(error);
          variablesGlobales.token='';
          variablesGlobales.usuario.codusu=0;
          this.router.navigate(['login']);
        }   
      );
  }

  fnListarPartido2()
  {
    this.fnListarPlanilla(this.FrmGestPlanilla.controls["cboequipopartido"].value);
  }

  fnListarPlanilla(codequipopartido: number){
    this.txtEquipo=this.EquipoPartido.find(element=> element.nU_ID_EQUIPOPARTIDO==codequipopartido)!.tX_DENOMINACION;
    this.isVisibleWalkOver=1;

    this.ObjAutenticaService.ValidarApp()
      .subscribe(
        data => { 
          if(data.isSuccess){
            if(data.tx_token.length>0){
              variablesGlobales.token=data.tx_token;

              this.ObjPlanillaJuegoService.Listar(0,codequipopartido)
                .subscribe(data => {
                  // console.log(data);console
                  if (data.isSuccess){
                    this.PlanillaJuego= data.planillaJuego;
                    this.PlanillaJuegoTableDataSource.data=this.PlanillaJuego; 
                  }                                         
                });

              this.PlanillaJuegoTableDataSource.paginator=this.paginator;
              this.PlanillaJuegoTableDataSource.sort = this.sort;
            }
            else{
                variablesGlobales.token='';
                variablesGlobales.usuario.codusu=0;
                this.router.navigate(['login']);
            }
          }
        },
        error => {
          console.log(error);
          variablesGlobales.token='';
          variablesGlobales.usuario.codusu=0;
          this.router.navigate(['login']);
        }   
      );
  }

  fnAgregarPlanilla(idplanillajuego: number){
    if (this.FrmGestPlanilla.controls["cboequipopartido"].value == null || 
      this.FrmGestPlanilla.controls["cboequipopartido"].value.length == 0) {
        this.alertMsg('Seleccione Equipo');
    }
    else{
      const dialogRef = this.dialog.open(DialogAgregarPlanillajuegoComponent, {
        data: {
          coddisciplina:this.coddisciplina,
          codfecha: this.ObjFecha.nU_ID_FECHA,
          codpartido: this.ObjPartido.nU_ID_PARTIDO,
          objEquipoPartido: this.EquipoPartido.find(element=> element.nU_ID_EQUIPOPARTIDO==this.FrmGestPlanilla.controls["cboequipopartido"].value),
          codplanillajuego: idplanillajuego
        },
        disableClose: true 
      });
  
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.fnListarPlanilla(this.FrmGestPlanilla.controls["cboequipopartido"].value);
        }
      });
    }  
  }

  alertMsg(msg:string){
    Swal.fire({
      html:msg,
      icon:'info'
    })
  }

  fnQuitar(idplanillajuego: number){
    const dialogRef = this.dialog.open(DialogQuitarPlanillajuegoComponent, {
      data: {
        codplanillajuego: idplanillajuego
      },
      disableClose: true 
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.fnListarPlanilla(this.FrmGestPlanilla.controls["cboequipopartido"].value);
      }
    });
  }

  fnGestGolesInfracciones(obj: PlanillaJuego, tipo: number){
    const dialogRef = this.dialog.open(GolesInfraccionesComponent, {
      data: {
        coddisciplina:this.coddisciplina,
        codfecha: this.ObjFecha.nU_ID_FECHA,
        codpartido: this.ObjPartido.nU_ID_PARTIDO,
        permiteOperacion: this.permiteOperacion,
        codplanillajuego: obj.nU_ID_PLANILLAJUEGO,
        txtentidad: obj.tX_ENTIDAD,
        tipo:tipo
      },
      disableClose: true 
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.fnListarPlanilla(this.FrmGestPlanilla.controls["cboequipopartido"].value);
      }
    });  
  }

  fnAgregarInfraccion(opc:number, obj: PlanillaJuego){
    const dialogRef = this.dialog.open(InfraccionesComponent, {
      data: {
        coddisciplina:this.coddisciplina,
        codfecha: this.ObjFecha.nU_ID_FECHA,
        codpartido: this.ObjPartido.nU_ID_PARTIDO,
        codplanillajuego: obj.nU_ID_PLANILLAJUEGO,
        txtentidad: obj.tX_ENTIDAD,
        opc: opc
      },
      disableClose: true 
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.fnListarPlanilla(this.FrmGestPlanilla.controls["cboequipopartido"].value);
      }
    });  
  }

  fnQuitarInfraccion(opc: number, idplanillajuego: number){
    this.ObjAutenticaService.ValidarApp()
      .subscribe(
        data => { 
          if(data.isSuccess){
            if(data.tx_token.length>0){
              variablesGlobales.token=data.tx_token;

              this.ObjDetallePlanillaJuegoService.Listar(0,idplanillajuego).pipe()
                .subscribe(data => {
                  if(data.isSuccess){
                    let DetallePlanillaJuego: DetallePlanillaJuego[]=data.partido;
                    DetallePlanillaJuego=DetallePlanillaJuego.filter((value,key)=>{
                      return value.nU_ID_ESTADO != 0 && value.nU_ID_PRODUCTO==opc;
                    });

                    let iddetalleplanillajuego=DetallePlanillaJuego[0].nU_ID;

                    const dialogRef = this.dialog.open(DialogQuitarDetalleplanillajuegoComponent, {
                      data: {
                        coddetalleplanillajuego: iddetalleplanillajuego,
                        opc: opc
                      },
                      disableClose: true 
                    });
                
                    dialogRef.afterClosed().subscribe(result => {
                      if (result) {
                        this.fnListarPlanilla(this.FrmGestPlanilla.controls["cboequipopartido"].value);
                      }
                    });
                  }                                         
                });
            }
            else{
                variablesGlobales.token='';
                variablesGlobales.usuario.codusu=0;
                this.router.navigate(['login']);
            }
          }
        },
        error => {
          console.log(error);
          variablesGlobales.token='';
          variablesGlobales.usuario.codusu=0;
          this.router.navigate(['login']);
        }   
      );
  }

  fnAgregarWO(){
    if (this.FrmGestPlanilla.controls["cboequipopartido"].value == null || 
      this.FrmGestPlanilla.controls["cboequipopartido"].value.length == 0) {
        this.alertMsg('Seleccione Equipo');
    }
    else{
      const dialogRef = this.dialog.open(DialogAgregarWoComponent, {
        data: {
          codequipopartido:this.FrmGestPlanilla.controls["cboequipopartido"].value
        },
        disableClose: true 
      });
  
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.fnListarPlanilla(this.FrmGestPlanilla.controls["cboequipopartido"].value);
        }
      });
    }  
  }

  getFormatFecha(opc: number, fecha: string){
    var parts=fecha.split("-");
    var anio =parts[0];
    var mes =parts[1];
    var dia =parts[2].substring(0,2);

    if(opc==1){
      parts=fecha.split("T");

      return dia+"/"+mes+"/"+anio+" "+parts[1].substring(0,8);
    }
    else{
      return dia+"/"+mes+"/"+anio;
    } 
  }

  Retorno(){
    this.salida.emit(1);
  }
}
