import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Rol } from 'src/app/@core/models/Rol';
import { UsuarioRol } from 'src/app/@core/models/UsuarioRol';
import { AutenticaService } from 'src/app/@core/services/AutenticaService';
import { RolService } from 'src/app/@core/services/RolService';
import { UsuarioRolService } from 'src/app/@core/services/UsuarioRolService';
import { variablesGlobales } from 'src/app/Util/variableGlobal';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-asigna-rol',
  templateUrl: './asigna-rol.component.html',
  styleUrls: ['./asigna-rol.component.scss']
})
export class AsignaRolComponent implements OnInit {

  FrmAsignaRol!: FormGroup;
  codigo: number=0;
  IsChecked: boolean=false;
  Rol: Rol[]=[];
  UsuarioRol: Rol[]=[];
  cantRol: number=0;
  cantUsuarioRol: number=0;
  chkArr: boolean[]=[];
  arrayRol: any[]=[];
  mensaje!: string;
  ObjUsuarioRol!: UsuarioRol;

  constructor(private _formBuilder: FormBuilder,
              private ObjAutenticaService: AutenticaService,
              private ObjRolService: RolService,
              private ObjUsuarioRolService: UsuarioRolService,
              private dialogRef: MatDialogRef<AsignaRolComponent>,
              @Inject(MAT_DIALOG_DATA) data: any) {
                this.codigo=data.codUsuario;
              }

  ngOnInit(): void {
    this.FrmAsignaRol=this._formBuilder.group({
    });

    this.fnListarRol();
  }
  
  fnListarRol(){ 
    var j,band;
    this.arrayRol=[];

    this.ObjAutenticaService.ValidarApp()
      .subscribe(
        data => { 
          if(data.isSuccess){
            if(data.tx_token.length>0){
              variablesGlobales.token=data.tx_token;

              this.ObjRolService.Listar(1).pipe()    
                .subscribe(
                  data1 => {
                    if(data1.isSuccess){
                      this.Rol= data1.listaRol;
                      this.cantRol=this.Rol.length;

                      this.ObjRolService.Listar(2,this.codigo).pipe()
                        .subscribe(
                          data2 => {
                            if(data2.isSuccess){
                              this.UsuarioRol=data2.listaRol;
                              this.cantUsuarioRol=this.UsuarioRol.length;

                              for(let i=0;i<this.cantRol;i++){
                                j=0,band=0;
                                while(j<this.cantUsuarioRol && band===0){
                                  if(this.Rol[i].nU_ID_ROL===this.UsuarioRol[j].nU_ID_ROL){
                                    this.chkArr[i]=true;
                                    this.arrayRol[i]=this.Rol[i].nU_ID_ROL;
                                    band=1; 
                                  } 
                                  j++; 
                                }
                                if(band===0) this.chkArr[i]=false;
                              }

                              this.fnMarcarPrincipal();
                            }
                          },
                          error => {
                            //console.log(error);
                            Swal.fire({
                              title:'El servicio solicitado no se encuentra disponible',
                              icon: 'error',
                            });
                          }
                        );
                    }                                  
                  },
                  error => {
                    //console.log(error);
                    Swal.fire({
                      title:'El servicio solicitado no se encuentra disponible',
                      icon: 'error',
                    });
                  }
                );
            }
            else{
                //console.log("Autenticación inválida: "+data.tx_Mensaje);
            }
          }
        },
        error => {
          //console.log(error);
        }   
      );
  }

  fnMarcarTodos(event:MatCheckboxChange){
    if(event.checked){
      this.chkArr = this.chkArr.map(m=>true);
      
      for(let i=0;i<this.cantRol;i++){
        this.arrayRol[i]=this.Rol[i].nU_ID_ROL;
      }
    }
    else{
      this.chkArr = this.chkArr.map(m=>false);
      this.arrayRol=[];
    }
  }

  fnMarcar(event:MatCheckboxChange,index:number,codrol:number){
    if(event.checked){
      this.chkArr[index]=true;
      this.arrayRol[index]=codrol;
    }
    else{
      this.chkArr[index]=false;
      delete this.arrayRol[index];
    }

    this.fnMarcarPrincipal();
  }

  fnMarcarPrincipal(){
    var band=0;

    for(let i=0;i<this.chkArr.length;i++){
      if(this.chkArr[i]){
        band++;
      }
    }

    if(band==this.cantRol){
      this.IsChecked =true;
    }
    else{
      this.IsChecked =false;
    }
  }

  Guardar(){
    var datos: any[]=[];
    var x=0,cant=0;
    this.mensaje="";

    for(let i=0;i<this.cantRol;i++){
      if(!this.chkArr[i]){
        this.ObjUsuarioRol={nU_ID_USUARIO_ROL:0};
        this.ObjUsuarioRol.nU_ID_USUARIO=this.codigo;
        this.ObjUsuarioRol.nU_ID_ROL=this.Rol[i].nU_ID_ROL;
        this.ObjUsuarioRol.nU_ID_ESTADO=0;
        this.ObjUsuarioRolService.Actualizar(this.ObjUsuarioRol).pipe()
          .subscribe(data => {

          });
      }
    }

    for(let i=0;i<this.arrayRol.length;i++){
      if(this.arrayRol[i]!==null && this.arrayRol[i]!==undefined){
        datos[x]=this.arrayRol[i];
        x++;
      }
    }

    for(let i=0;i<datos.length;i++){
      this.ObjUsuarioRol={nU_ID_USUARIO_ROL:0};
      this.ObjUsuarioRol.nU_ID_USUARIO=this.codigo;
      this.ObjUsuarioRol.nU_ID_ROL=datos[i];
      this.ObjUsuarioRol.nU_ID_ESTADO=1;
      this.ObjUsuarioRolService.Actualizar(this.ObjUsuarioRol).pipe()
        .subscribe(data => {
          if(data.isSuccess){
            this.mensaje+="&#8226;"+data.tx_Mensaje+"<br>";
            cant++;

            if(cant===datos.length){
              Swal.fire({
                html:'Se han asignado los siguientes roles:<br><br>'+this.mensaje,
                icon:'success'
              });
            }
          }
        });
    }

    this.Close();
  }

  Close() {
    this.dialogRef.close();
  }
}
