import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Base } from 'src/app/@core/models/Base';
import { AutenticaService } from 'src/app/@core/services/AutenticaService';
import { BaseService } from 'src/app/@core/services/BaseService';
import { variablesGlobales } from 'src/app/Util/variableGlobal';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-dialog-agregar-base',
  templateUrl: './dialog-agregar-base.component.html',
  styleUrls: ['./dialog-agregar-base.component.scss']
})
export class DialogAgregarBaseComponent implements OnInit {

  FrmAgregarBase!: FormGroup;
  ListaBase:Base[]=[];
  codigo:number=0;
  ObjBase!: Base;

  constructor(private _formBuilder: FormBuilder,
              private ObjAutenticaService: AutenticaService,
              private objBaseService: BaseService,
              private dialogRef: MatDialogRef<DialogAgregarBaseComponent>,
              @Inject(MAT_DIALOG_DATA) data: any,) {
                this.ListaBase=data.listaBase;
              }

  ngOnInit(): void {
    this.FrmAgregarBase=this._formBuilder.group({
      txtanio: [null, Validators.compose([Validators.required,Validators.minLength(4)])],
      cboturno: [null, Validators.required]
    }); 
  }

  @HostListener('window:keyup.esc') onKeyUp() {
    this.Close();
  }

  alertMsg(msg:string){
    Swal.fire({
      html:msg,
      icon:'info'
    })
  }

  ValidarCampos(){
    if (this.FrmAgregarBase.controls["txtanio"].value === null || 
      this.FrmAgregarBase.controls["txtanio"].value.length === 0 || 
      /^\s+$/.test(this.FrmAgregarBase.controls["txtanio"].value)) {
        this.alertMsg('Ingrese Año de Promoción');
        return false;
    }
    else{
      if(this.FrmAgregarBase.controls["txtanio"].hasError('minlength')){
        const minLength = this.FrmAgregarBase.controls["txtanio"].errors?.minlength.requiredLength;
        this.alertMsg('El año debe tener '+minLength+' dígitos');
        return false;
      }
      else{
        if(parseInt(this.FrmAgregarBase.controls["txtanio"].value) < 1979){
          this.alertMsg('El año debe ser mayo que 1978');
          return false;
        }
      }
    }

    if (this.FrmAgregarBase.controls["cboturno"].value === null || 
      this.FrmAgregarBase.controls["cboturno"].value.length === 0) {
        this.alertMsg('Seleccione Turno');
        return false;
    }

    return true;
  }

  Guardar(){
    if(this.ValidarCampos()){
      let band=0;

      if(this.ListaBase.length>0){
        for(let item of this.ListaBase){
          if(item.nU_ANIO_PROMOCION===parseInt(this.FrmAgregarBase.controls["txtanio"].value) &&
            item.tX_TURNO===this.FrmAgregarBase.controls["cboturno"].value){
              band=1;
              break;     
          }
        }
      }

      if(band===1){
        Swal.fire({
          html:'La Base Organizacional ya existe en la lista',
          icon:'info' 
        })
      }
      else{ 
        this.ObjBase={nU_ID_BASE:0};
        this.ObjBase.nU_ID_BASE=this.codigo;
        this.ObjBase.nU_ANIO_PROMOCION=parseInt(this.FrmAgregarBase.controls["txtanio"].value);
        this.ObjBase.tX_TURNO=this.FrmAgregarBase.controls["cboturno"].value;

        this.ObjAutenticaService.ValidarApp()
          .subscribe(
            data => { 
              if(data.isSuccess){
                if(data.tx_token.length>0){
                  variablesGlobales.token=data.tx_token;

                  this.objBaseService.Actualizar(this.ObjBase).pipe()
                    .subscribe(
                      data => {
                        if (data.isSuccess){
                          Swal.fire({
                            title: data.tx_Mensaje,
                            icon: 'success'}); 
                          
                          this.dialogRef.close(data.isSuccess);
                        }
                        else{
                          Swal.fire({
                            title: data.tx_Mensaje,
                            icon: 'warning'}); 
                        } 
                      },
                      error => {
                        //console.log(error);
                        Swal.fire({
                          title:'El servicio solicitado no se encuentra disponible',
                          icon: 'error',
                        });
                      }
                    );
                }
                else{
                    //console.log("Autenticación inválida: "+data.tx_Mensaje);
                }
              }
            },
            error => {
              //console.log(error);
            }   
          );
      }
    }
  }

  fnSoloNumeros(event:any) {
    event.target.value = event.target.value.replace(/[^\d]/g,'');
    return false;
  }

  Close() {
    this.dialogRef.close();
  }
}
