import { IfStmt } from '@angular/compiler';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { NavigationEnd, Router } from '@angular/router';
import { Usuario } from 'src/app/@core/models/Usuario';
import { AutenticaService } from 'src/app/@core/services/AutenticaService';
import { UsuarioService } from 'src/app/@core/services/UsuarioService';
import { variablesGlobales } from 'src/app/Util/variableGlobal';
import Swal from 'sweetalert2';
import { AsignaRolComponent } from './asigna-rol/asigna-rol.component';
import { RegistrarUsuarioComponent } from './registrar-usuario/registrar-usuario.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-gestiona-usuario',
  templateUrl: './gestiona-usuario.component.html',
  styleUrls: ['./gestiona-usuario.component.scss']
})
export class GestionaUsuarioComponent implements OnInit, OnDestroy {

  FrmGestUsuario!: FormGroup;
  navigationSubscription?: Subscription;

  Usuario: Usuario[]=[];
  displayedColumns: string[] = ['nU_ID_USUARIO','tX_NUMERO','tX_NOMBRE_COMPLETO','tX_LOGIN','tX_ESTADO','fE_REGISTRO','fE_MODIFICA'];
  dataSource = this.Usuario;

  UsuarioTable : Usuario[] = [];
  UsuarioTableDataSource = new MatTableDataSource(this.UsuarioTable);

  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;

  constructor(private _formBuilder: FormBuilder,
              private router: Router,
              private ObjAutenticaService: AutenticaService,
              private ObjUsuarioService: UsuarioService,
              public dialog: MatDialog) { }

  ngOnInit(): void {
    this.FrmGestUsuario=this._formBuilder.group({
      txtFiltro: [null],
    });

    this.LlenaGrilla();

    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd) {
        this.Usuario=[];
        this.UsuarioTableDataSource.data=this.Usuario; 
        this.UsuarioTableDataSource.paginator=this.paginator;

        this.FrmGestUsuario.controls["txtFiltro"].setValue(null);

        this.LlenaGrilla();
      }
    });
  }

  ngOnDestroy() {
    if(this.navigationSubscription!=null){
      this.navigationSubscription.unsubscribe();
    }
  }

  LlenaGrilla(){
    this.ObjAutenticaService.ValidarApp()
      .subscribe(
        data => { 
          if(data.isSuccess){
            if(data.tx_token.length>0){
              variablesGlobales.token=data.tx_token;

              this.ObjUsuarioService.Listar(1,this.FrmGestUsuario.controls["txtFiltro"].value)
                .subscribe(data => {
                  if (data.isSuccess){
                    this.Usuario= data.data;
                    this.UsuarioTableDataSource.data=this.Usuario; 
                  }                                         
                }); 

              this.UsuarioTableDataSource.paginator=this.paginator;
            }
            else{
              variablesGlobales.token='';
              variablesGlobales.usuario.codusu=0;
              variablesGlobales.usuarioOficinaCargo.codOficina=0;
              variablesGlobales.usuarioOficinaCargo.codCargo=0;
              variablesGlobales.usuarioRol.admBase=0;
              variablesGlobales.usuarioRol.admEquipo=0;
              variablesGlobales.usuarioRol.admDelegado=0;
              variablesGlobales.usuarioRol.admDeportista=0;
              variablesGlobales.usuarioRol.isVisibleData=0;
              variablesGlobales.menu={};
              variablesGlobales.codevento=0;
              this.router.navigate(['login']);
            }
          }
        },
        error => {
          //console.log(error);
          variablesGlobales.token='';
          variablesGlobales.usuario.codusu=0;
          variablesGlobales.usuarioOficinaCargo.codOficina=0;
          variablesGlobales.usuarioOficinaCargo.codCargo=0;
          variablesGlobales.usuarioRol.admBase=0;
          variablesGlobales.usuarioRol.admEquipo=0;
          variablesGlobales.usuarioRol.admDelegado=0;
          variablesGlobales.usuarioRol.admDeportista=0;
          variablesGlobales.usuarioRol.isVisibleData=0;
          variablesGlobales.menu={};
          variablesGlobales.codevento=0;
          this.router.navigate(['login']);
        }   
      ); 
  }

  fnNuevo(opc:string,idusuario:number){
    if(idusuario>0){
      this.ObjAutenticaService.ValidarApp()
        .subscribe(
          data => { 
            if(data.isSuccess){
              if(data.tx_token.length>0){
                variablesGlobales.token=data.tx_token;
                  
                this.ObjUsuarioService.ObtenerId(idusuario).pipe()
                  .subscribe(data => {
                    if(data.isSuccess){
                      if(data.data.nU_ID_ESTADO===2){
                        Swal.fire(
                          'Advertencia',
                          'El usuario se encuentra inactivo',
                          'warning'
                        );
                      }
                      else{
                        const dialogRef = this.dialog.open(RegistrarUsuarioComponent, {
                          data: {
                            codUsuario:idusuario,
                            op: opc
                          },
                          disableClose: true 
                        });
                    
                        dialogRef.afterClosed().subscribe(result => {
                          if (result) {
                            this.LlenaGrilla();
                          }
                        });
                      }
                    }       
                  });
              }
              else{
                variablesGlobales.token='';
                variablesGlobales.usuario.codusu=0;
                variablesGlobales.usuarioOficinaCargo.codOficina=0;
                variablesGlobales.usuarioOficinaCargo.codCargo=0;
                variablesGlobales.usuarioRol.admBase=0;
                variablesGlobales.usuarioRol.admEquipo=0;
                variablesGlobales.usuarioRol.admDelegado=0;
                variablesGlobales.usuarioRol.admDeportista=0;
                variablesGlobales.usuarioRol.isVisibleData=0;
                variablesGlobales.menu={};
                variablesGlobales.codevento=0;
                this.router.navigate(['login']);
              }
            }
          },
          error => {
            //console.log(error);
            variablesGlobales.token='';
            variablesGlobales.usuario.codusu=0;
            variablesGlobales.usuarioOficinaCargo.codOficina=0;
            variablesGlobales.usuarioOficinaCargo.codCargo=0;
            variablesGlobales.usuarioRol.admBase=0;
            variablesGlobales.usuarioRol.admEquipo=0;
            variablesGlobales.usuarioRol.admDelegado=0;
            variablesGlobales.usuarioRol.admDeportista=0;
            variablesGlobales.usuarioRol.isVisibleData=0;
            variablesGlobales.menu={};
            variablesGlobales.codevento=0;
            this.router.navigate(['login']);
          }   
        );
    }
    else{
      const dialogRef = this.dialog.open(RegistrarUsuarioComponent, {
        data: {
          codUsuario:idusuario,
          op: opc
        },
        disableClose: true 
      });
  
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.LlenaGrilla();
        }
      });
    }
  }

  convertFecha(fecha: string): string{
    var parts=fecha.split("-");
    var anio =parts[0];
    var mes =parts[1];
    var dia =parts[2].substring(0,2);
    var hora =parts[2].substring(3,11);

    return dia+"/"+mes+"/"+anio+" "+hora;
  }

  fnResetearClave(obj: Usuario){
    this.ObjAutenticaService.ValidarApp()
      .subscribe(
        data => { 
          if(data.isSuccess){
            if(data.tx_token.length>0){
              variablesGlobales.token=data.tx_token;
                
              this.ObjUsuarioService.ObtenerId(obj.nU_ID_USUARIO).pipe()
                .subscribe(data => {
                  if(data.isSuccess){
                    if(data.data.nU_ID_ESTADO===2){
                      Swal.fire(
                        'Advertencia',
                        'El usuario se encuentra inactivo',
                        'warning'
                      );
                    }
                    else{
                      Swal.fire({
                        text: "¿Está seguro de resetear la clave del usuario: "+obj.tX_NUMERO+"?",
                        icon: 'question',
                        showCancelButton: true,
                        cancelButtonText:'No',
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Sí',
                        reverseButtons: true
                      }).then((result) => {
                        if (result.value){
                          this.ObjUsuarioService.ReseteaClave(obj.nU_ID_USUARIO)
                            .subscribe(
                              data => {
                                if (data.isSuccess){
                                  Swal.fire({
                                    title: data.tx_Mensaje,
                                    icon: 'success'});
                                }
                                else{
                                  Swal.fire({
                                    title: data.tx_Mensaje,
                                    icon: 'warning'}); 
                                } 
                              },
                              error => {
                                //console.log(error);
                                Swal.fire({
                                  title:'El servicio solicitado no se encuentra disponible',
                                  icon: 'error',
                                });
                              }
                            );
                        }
                      });
                    }
                  }       
                });
            }
            else{
              variablesGlobales.token='';
              variablesGlobales.usuario.codusu=0;
              variablesGlobales.usuarioOficinaCargo.codOficina=0;
              variablesGlobales.usuarioOficinaCargo.codCargo=0;
              variablesGlobales.usuarioRol.admBase=0;
              variablesGlobales.usuarioRol.admEquipo=0;
              variablesGlobales.usuarioRol.admDelegado=0;
              variablesGlobales.usuarioRol.admDeportista=0;
              variablesGlobales.usuarioRol.isVisibleData=0;
              variablesGlobales.menu={};
              variablesGlobales.codevento=0;
              this.router.navigate(['login']);
            }
          }
        },
        error => {
          //console.log(error);
          variablesGlobales.token='';
          variablesGlobales.usuario.codusu=0;
          variablesGlobales.usuarioOficinaCargo.codOficina=0;
          variablesGlobales.usuarioOficinaCargo.codCargo=0;
          variablesGlobales.usuarioRol.admBase=0;
          variablesGlobales.usuarioRol.admEquipo=0;
          variablesGlobales.usuarioRol.admDelegado=0;
          variablesGlobales.usuarioRol.admDeportista=0;
          variablesGlobales.usuarioRol.isVisibleData=0;
          variablesGlobales.menu={};
          variablesGlobales.codevento=0;
          this.router.navigate(['login']);
        }   
      );
  }

  fnAsignarRol(idusuario:number){
    this.ObjAutenticaService.ValidarApp()
      .subscribe(
        data => { 
          if(data.isSuccess){
            if(data.tx_token.length>0){
              variablesGlobales.token=data.tx_token;
                
              this.ObjUsuarioService.ObtenerId(idusuario).pipe()
                .subscribe(data => {
                  if(data.isSuccess){
                    if(data.data.nU_ID_ESTADO===2){
                      Swal.fire(
                        'Advertencia',
                        'El usuario se encuentra inactivo',
                        'warning'
                      );
                    }
                    else{
                      const dialogRef = this.dialog.open(AsignaRolComponent, {
                        data: {
                          codUsuario:idusuario,
                        },
                        disableClose: true 
                      });
                  
                      dialogRef.afterClosed().subscribe(result => {
                        if (result) {
                          this.LlenaGrilla();
                        }
                      });
                    }
                  }       
                });
            }
            else{
              variablesGlobales.token='';
              variablesGlobales.usuario.codusu=0;
              variablesGlobales.usuarioOficinaCargo.codOficina=0;
              variablesGlobales.usuarioOficinaCargo.codCargo=0;
              variablesGlobales.usuarioRol.admBase=0;
              variablesGlobales.usuarioRol.admEquipo=0;
              variablesGlobales.usuarioRol.admDelegado=0;
              variablesGlobales.usuarioRol.admDeportista=0;
              variablesGlobales.usuarioRol.isVisibleData=0;
              variablesGlobales.menu={};
              variablesGlobales.codevento=0;
              this.router.navigate(['login']);
            }
          }
        },
        error => {
          //console.log(error);
          variablesGlobales.token='';
          variablesGlobales.usuario.codusu=0;
          variablesGlobales.usuarioOficinaCargo.codOficina=0;
          variablesGlobales.usuarioOficinaCargo.codCargo=0;
          variablesGlobales.usuarioRol.admBase=0;
          variablesGlobales.usuarioRol.admEquipo=0;
          variablesGlobales.usuarioRol.admDelegado=0;
          variablesGlobales.usuarioRol.admDeportista=0;
          variablesGlobales.usuarioRol.isVisibleData=0;
          variablesGlobales.menu={};
          variablesGlobales.codevento=0;
          this.router.navigate(['login']);
        }   
      );
  }

  fnCambiarEstado(obj:Usuario,estado:number){
    this.ObjAutenticaService.ValidarApp()
      .subscribe(
        data => { 
          if(data.isSuccess){
            if(data.tx_token.length>0){
              variablesGlobales.token=data.tx_token;
                
              this.ObjUsuarioService.ObtenerId(obj.nU_ID_USUARIO).pipe()
                .subscribe(data => {
                  if(data.isSuccess){
                    obj=data.data;

                    if(data.listaUOC.length>0){
                      obj.nU_ID_OFICINA=data.listaUOC[0].nU_ID_OFICINA;
                      obj.nU_ID_CARGO=data.listaUOC[0].nU_ID_CARGO;
                    }
          
                    if(obj.nU_ID_ESTADO==estado){
                      Swal.fire({
                        title: "El registro ya se encuentra "+((estado==2)?"inactivo":"activo"),
                        icon: 'info'});
                    }
                    else{
                      Swal.fire({
                        text: "¿Está seguro de "+((estado==2)?"anular":"activar")+" el registro?",
                        icon: 'question',
                        showCancelButton: true,
                        cancelButtonText:'No',
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Sí',
                        reverseButtons: true
                      }).then((result) => {
                        if (result.value){
                          obj.nU_ID_ESTADO=estado;
                          
                          this.ObjUsuarioService.Actualizar(obj).pipe()
                            .subscribe(
                              data => {
                                if (data.isSuccess){
                                  Swal.fire({
                                    title: data.tx_Mensaje,
                                    icon: 'success'}); 

                                  this.LlenaGrilla();
                                }
                                else{
                                  Swal.fire({
                                    title: data.tx_Mensaje,
                                    icon: 'warning'}); 
                                } 
                              },
                              error => {
                                //console.log(error);
                                Swal.fire({
                                  title:'El servicio solicitado no se encuentra disponible',
                                  icon: 'error',
                                });
                              },
                            );
                        }
                      });
                    }
                  }       
                });
            }
            else{
              variablesGlobales.token='';
              variablesGlobales.usuario.codusu=0;
              variablesGlobales.usuarioOficinaCargo.codOficina=0;
              variablesGlobales.usuarioOficinaCargo.codCargo=0;
              variablesGlobales.usuarioRol.admBase=0;
              variablesGlobales.usuarioRol.admEquipo=0;
              variablesGlobales.usuarioRol.admDelegado=0;
              variablesGlobales.usuarioRol.admDeportista=0;
              variablesGlobales.usuarioRol.isVisibleData=0;
              variablesGlobales.menu={};
              variablesGlobales.codevento=0;
              this.router.navigate(['login']);
            }
          }
        },
        error => {
          //console.log(error);
          variablesGlobales.token='';
          variablesGlobales.usuario.codusu=0;
          variablesGlobales.usuarioOficinaCargo.codOficina=0;
          variablesGlobales.usuarioOficinaCargo.codCargo=0;
          variablesGlobales.usuarioRol.admBase=0;
          variablesGlobales.usuarioRol.admEquipo=0;
          variablesGlobales.usuarioRol.admDelegado=0;
          variablesGlobales.usuarioRol.admDeportista=0;
          variablesGlobales.usuarioRol.isVisibleData=0;
          variablesGlobales.menu={};
          variablesGlobales.codevento=0;
          this.router.navigate(['login']);
        }   
      );
  }
}
