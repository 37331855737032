<mat-card-title class="Titulo" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>Buscar Documento
    <button mat-icon-button class="close-button" [mat-dialog-close]="true">
        <mat-icon class="close-icon" color="warn">close</mat-icon>
    </button>
</mat-card-title>
<div mat-dialog-content>
    <form [formGroup]="FrmBuscarDocumento"> 
        <mat-card fxLayout="column">  
            <mat-card-content fxLayout="row" style="margin-top: 8px;">
                <mat-form-field class="fila1"> 
                    <mat-select class="valueSelect" placeholder="Tipo de Documento" formControlName="cbotipodoc">
                        <mat-option class="opcionSelect" *ngFor="let elem of TipoDocumento" [value]="elem.nU_ID_TIPODOCUMENTO">
                            {{elem.tX_DESCRIPCION}}
                        </mat-option>
                    </mat-select>
                </mat-form-field> 
                <mat-form-field class="fila2">
                    <input matInput placeholder="Ingrese Documento" formControlName="txtdocumento" required>
                </mat-form-field>  
                <button mat-icon-button class="tooltips_fab" (click)="Buscar()">
                    <mat-icon fontSet="material-icons-outlined">search</mat-icon>
                    <span>Buscar número</span>
                </button>   
                <button mat-icon-button class="tooltips_fab" (click)="fnAgregarDocumento()">
                    <mat-icon fontSet="material-icons-outlined">add_box</mat-icon>
                    <span>Adicionar</span>
                </button>   
            </mat-card-content> 
            <mat-card-content fxLayout="column">
                <div class="example-container">
                    <table mat-table [dataSource]="DocumentoTableDataSource">
                        <ng-container matColumnDef="nU_ID_DOCUMENTO">
                            <th mat-header-cell *matHeaderCellDef></th>
                            <td mat-cell *matCellDef="let element">  
                                <button mat-icon-button class="tooltips_cls" (click)="fnObtener(element)">
                                    <mat-icon fontSet="material-icons-outlined">done</mat-icon>
                                    <span>Seleccionar</span>
                                </button>                        
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="fE_EMISION">
                            <th mat-header-cell *matHeaderCellDef>Fecha Emisión</th>
                            <td mat-cell *matCellDef="let element">{{convertFormatDate(element.fE_EMISION)}}</td>
                        </ng-container>
                        <ng-container matColumnDef="tX_SIGLA">
                            <th mat-header-cell *matHeaderCellDef>Documento</th>
                            <td mat-cell *matCellDef="let element">
                                <div class="btnDownload" (click)="fnDescargarDocumento(element)">{{element.tX_SIGLA}}</div>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="tX_DESCRIPCION">
                            <th mat-header-cell *matHeaderCellDef>Asunto</th>
                            <td mat-cell *matCellDef="let element">{{element.tX_DESCRIPCION}}</td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>  
                </div>
            </mat-card-content>
        </mat-card>
    </form>
</div>

