
<div fxLayout="column" fxLayoutAlign="center">
    <form [formGroup]="FrmDetalleIntegrante">
        <div class="opcion">
            <mat-label class="Titulo">{{txtTitulo}}</mat-label>
        </div>
        <mat-card fxLayout="column">
            <div class="opcion1">
                <mat-label class="titulo">Equipo: {{ObjEquipo.nU_ANIO_PROMOCION+' '+ObjEquipo.tX_TURNO+' - '+ObjEquipo.tX_DENOMINACION}}</mat-label>
            </div>
            <mat-card-content fxLayout="column">
                <div style="margin-top: 18px;margin-bottom: 20px;">
                    <button mat-mini-fab color="btnStyle" class="tooltips_fab" (click)="Retorno()">
                        <mat-icon>arrow_back</mat-icon>
                        <span>Regresar</span>
                    </button>
                </div>
                <div>
                    <div class="cardList">
                        <div class="cardListItem" style="margin-bottom: 20px;">
                            <fieldset class="borderFieldset"><legend class="estiloLegend">Datos Personales</legend>
                                <div class="cardList">
                                    <div class="cardListItem">
                                        <div class="center">
                                            <mat-card class="foto-card">
                                                <img mat-card-image *ngIf="isVisibleFoto" [src]="src" class="tamanioImagen">
                                                <div *ngIf="!isVisibleFoto" class="center">
                                                    <p style="text-align: center;">NO SE CARGÓ FOTO</p>
                                                </div>
                                            </mat-card>
                                        </div>
                                    </div>
                                    <div class="cardListItem">
                                        <div>
                                            <mat-form-field class="fila1"> 
                                                <mat-select class="valueSelect" placeholder="Tipo de Documento de identificación" formControlName="txttipodocumento">
                                                    <mat-option class="opcionSelect" value="1">DNI - DOCUMENTO NACIONAL DE IDENTIDAD</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                            <mat-form-field class="fila3">
                                                <input matInput placeholder="Número de Documento de Identificación" formControlName="txtnumero" readonly>
                                            </mat-form-field>
                                        </div>
                                        <div>
                                            <mat-form-field class="fila3">
                                                <textarea matInput cdkTextareaAutosize
                                                    #autosize="cdkTextareaAutosize" placeholder="Nombres" formControlName="txtnombre" class="CampoTextArea" readonly></textarea>
                                            </mat-form-field>
                                            <mat-form-field class="fila3">
                                                <textarea matInput cdkTextareaAutosize
                                                    #autosize="cdkTextareaAutosize" placeholder="Apellido Paterno" formControlName="txtape_paterno" class="CampoTextArea" readonly></textarea>
                                            </mat-form-field>
                                            <mat-form-field class="fila3">
                                                <textarea matInput cdkTextareaAutosize
                                                    #autosize="cdkTextareaAutosize" placeholder="Apellido Materno" formControlName="txtape_materno" class="CampoTextArea" readonly></textarea>
                                            </mat-form-field>
                                        </div>
                                        <div>
                                            <mat-form-field class="fila4" *ngIf="isVisibleData"> 
                                                <input matInput [matDatepicker]="FecNacimiento" placeholder="F. Nacimiento" formControlName="FecNacimiento" readonly>
                                                <mat-datepicker-toggle matSuffix [for]="FecNacimiento"></mat-datepicker-toggle>
                                                <mat-datepicker #FecNacimiento disabled></mat-datepicker>
                                            </mat-form-field>
                                            <mat-form-field class="fila5"> 
                                                <mat-select placeholder="Sexo" formControlName="sexo">
                                                    <mat-option value="F">Femenino</mat-option>
                                                    <mat-option value="M">Masculino</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                        <div *ngIf="isSocio">   
                                            <mat-form-field class="fila5">
                                                <input matInput placeholder="Año de Egreso" formControlName="txtanio" readonly>
                                            </mat-form-field>
                                            <mat-form-field class="fila5"> 
                                                <mat-select placeholder="Turno" formControlName="turno">
                                                    <mat-option value="Diurna">Diurna</mat-option>
                                                    <mat-option value="Nocturna">Nocturna</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                            </fieldset> 
                        </div>
                        <div class="cardListItem" style="margin-bottom: 20px;" *ngIf="isVisibleData">
                            <fieldset class="borderFieldset"><legend class="estiloLegend">Datos de Contacto</legend>
                                <div>
                                    <mat-form-field class="fila3">
                                        <input matInput placeholder="Correo Electrónico" formControlName="txtemail" readonly>
                                    </mat-form-field>
                                </div>
                                <div>
                                    <mat-form-field class="fila3">
                                        <input matInput placeholder="Teléfono" formControlName="txttelefono" readonly>
                                    </mat-form-field>
                                </div>
                                <div>
                                    <mat-form-field class="fila1"> 
                                        <mat-select class="valueSelect" placeholder="Departamento" formControlName="coddepartamento">
                                            <mat-option class="opcionSelect" *ngFor="let elem of Departamento" [value]="elem.tX_ID_DEPARTAMENTO">
                                                {{elem.tX_DEPARTAMENTO}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div>
                                    <mat-form-field class="fila1"> 
                                        <mat-select class="valueSelect" placeholder="Provincia" formControlName="codprovincia">
                                            <mat-option class="opcionSelect" *ngFor="let elem of Provincia" [value]="elem.tX_ID_PROVINCIA">
                                                {{elem.tX_PROVINCIA}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div>
                                    <mat-form-field class="fila1"> 
                                        <mat-select class="valueSelect" placeholder="Distrito" formControlName="coddistrito">
                                            <mat-option class="opcionSelect" *ngFor="let elem of Distrito" [value]="elem.tX_ID_DISTRITO">
                                                {{elem.tX_DISTRITO}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div>
                                    <mat-form-field class="fila2">
                                        <textarea matInput cdkTextareaAutosize
                                            #autosize="cdkTextareaAutosize" placeholder="Dirección" formControlName="txtdireccion" class="CampoTextArea"
                                            readonly></textarea>
                                    </mat-form-field>
                                </div>
                            </fieldset> 
                        </div>
                    </div>
                </div>
                <div>
                    <fieldset class="borderFieldset"><legend class="estiloLegend">Archivos</legend>
                        <div class="example-container">
                            <table mat-table [dataSource]="IntegranteRequisitoTableDataSource">
                                <ng-container matColumnDef="nU_ID_INTEGRANTEREQUISITO">
                                    <th mat-header-cell *matHeaderCellDef class="priority-1"></th>
                                    <td mat-cell *matCellDef="let element" class="priority-1"> 
                                        <button mat-icon-button class="tooltips_cls" *ngIf="isVisibleData || element.nU_ID_REQUISITO!=1" (click)="fnDescargar(element)">
                                            <mat-icon fontSet="material-icons-outlined">get_app</mat-icon>
                                            <span>Descargar</span>
                                        </button>                           
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="tX_EXTENSION">
                                    <th mat-header-cell *matHeaderCellDef class="priority-2">NOMBRE</th>
                                    <td mat-cell *matCellDef="let element" class="priority-2">{{element.nU_ID_ARCHIVO+'.'+element.tX_EXTENSION}}</td>
                                </ng-container>
                                <ng-container matColumnDef="tX_REQUISITO">
                                    <th mat-header-cell *matHeaderCellDef class="priority-3">REQUISITO</th>
                                    <td mat-cell *matCellDef="let element" class="priority-3">{{element.tX_REQUISITO}}</td>
                                </ng-container>
                                <ng-container matColumnDef="tX_DESCRIPCION">
                                    <th mat-header-cell *matHeaderCellDef class="priority-4">DESCRIPCIÓN</th>
                                    <td mat-cell *matCellDef="let element" class="priority-4">{{element.tX_DESCRIPCION}}</td>
                                </ng-container>
                                <ng-container matColumnDef="fE_REGISTRO">
                                    <th mat-header-cell *matHeaderCellDef class="priority-4">F. REGISTRO</th>
                                    <td mat-cell *matCellDef="let element" class="priority-4">{{convertFecha(element.fE_REGISTRO)}}</td>
                                </ng-container>
                                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                            </table>
                        </div>
                    </fieldset>
                </div>                                    
            </mat-card-content>
        </mat-card> 
    </form>      
</div>

