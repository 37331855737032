import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { Carnet } from '../@core/models/Carnet';
import { IntegranteRequisito } from '../@core/models/IntegranteRequisito';
import { ArchivoService } from '../@core/services/ArchivoService';
import { AutenticaService } from '../@core/services/AutenticaService';
import { DocumentoService } from '../@core/services/DocumentoService';
import { variablesGlobales } from '../Util/variableGlobal';

@Component({
  selector: 'app-consultar-qr',
  templateUrl: './consultar-qr.component.html',
  styleUrls: ['./consultar-qr.component.scss']
})
export class ConsultarQrComponent implements OnInit {

  FrmConsultarQR!: FormGroup;
  token: string;
  isVisibleFrm:boolean=false;
  txtTitulo!: string;
  ObjCarnet!: Carnet;
  IntegranteRequisito: IntegranteRequisito[]=[];
  isVisibleFoto: boolean=false;
  src: any;
  msjError: string="";

  @ViewChild('autosize') autosize!: CdkTextareaAutosize;

  constructor(private _formBuilder: FormBuilder,
              private route: ActivatedRoute,
              private ObjAutenticaService: AutenticaService,
              private ObjDocumentoService: DocumentoService,
              private ObjArchivoService: ArchivoService,
              private domSanitizer: DomSanitizer
              ) { 
              this.token=this.route.snapshot.paramMap.get("token")!;
            }

  ngOnInit(): void {
    this.FrmConsultarQR=this._formBuilder.group({
      txttipodoc:[null],
      txtnumero:[null],
      txtnombre:[null],
      //txtcarnet:[null],
      txtregistro:[null],
      txtfecemision:[null],
      //txtevento:[null],
      //txtcargo_condicion:[null],
      txtcondicion:[null],
      //txtequipo:[null],
      //txtdisciplina:[null],
      txtbase:[null],
      txtestado:[null]
    });

    this.ObjAutenticaService.ValidarApp()
      .subscribe(
        data => { 
          if(data.isSuccess){
            if(data.tx_token.length>0){
              variablesGlobales.token=data.tx_token;

              this.ObjDocumentoService.ValidaQR(3,this.token).pipe()
                .subscribe(
                  data => {
                    //console.log(data);
                    if(data.isSuccess){
                      this.isVisibleFrm=true;
                      this.ObjCarnet=data.carnet;
                      this.IntegranteRequisito=data.listaarchivosadjuntos;

                      this.txtTitulo=this.ObjCarnet.tX_DENOMINACION_EMISOR!;
                      this.FrmConsultarQR.controls["txttipodoc"].setValue(this.ObjCarnet.tX_TIPODOCUMENTO_IDENTIDAD);
                      this.FrmConsultarQR.controls["txtnumero"].setValue(this.ObjCarnet.tX_NUMERO);
                      this.FrmConsultarQR.controls["txtnombre"].setValue(this.ObjCarnet.tX_NOMBRE+" "+this.ObjCarnet.tX_APELLIDO_PATERNO+" "+this.ObjCarnet.tX_APELLIDO_MATERNO);
                      //this.FrmConsultarQR.controls["txtcarnet"].setValue(this.ObjCarnet.tX_SIGLA);
                      this.FrmConsultarQR.controls["txtregistro"].setValue(this.ObjCarnet.tX_SIGLA);
                      this.FrmConsultarQR.controls["txtfecemision"].setValue(this.ObjCarnet.tX_EMISION);
                      //this.FrmConsultarQR.controls["txtevento"].setValue(this.ObjCarnet.tX_DENOMINACION_EVENTO);
                      //this.FrmConsultarQR.controls["txtcargo_condicion"].setValue(this.ObjCarnet.tX_CARGO+" - "+this.ObjCarnet.tX_CONDICION);
                      this.FrmConsultarQR.controls["txtcondicion"].setValue(this.ObjCarnet.tX_CONDICION);
                      //this.FrmConsultarQR.controls["txtequipo"].setValue(this.ObjCarnet.tX_EQUIPO);
                      //this.FrmConsultarQR.controls["txtdisciplina"].setValue(this.ObjCarnet.tX_DISCIPLINA);
                      this.FrmConsultarQR.controls["txtbase"].setValue(this.ObjCarnet.tX_EQUIPO);
                      this.FrmConsultarQR.controls["txtestado"].setValue(this.ObjCarnet.tX_ESTADO);

                      if(this.IntegranteRequisito.length>0){
                        this.isVisibleFoto=true;
                        this.fnObtenerSrc(this.IntegranteRequisito[0]);
                      }
                    }
                    else{
                      this.msjError="Token no válido";
                    }
                  },
                  error => {
                    console.log(error);
                    Swal.fire({
                      title:'El servicio solicitado no se encuentra disponible',
                      icon: 'error',
                    });
                  }
                );
            }
            else{
                console.log("Autenticación inválida: "+data.tx_Mensaje);
            }
          }
        },
        error => {
          console.log(error);
        }   
      );
  }

  fnObtenerSrc(obj: IntegranteRequisito){
    this.ObjArchivoService.ObtenerTrama(obj.nU_ID_ARCHIVO+"."+obj.tX_EXTENSION).pipe()    
      .subscribe(
        data => {
          if(data.isSuccess){
            const byteCharacters= atob(data.tx_Mensaje);
            const byteArrays = [];

            for (let offset = 0; offset < byteCharacters.length; offset += 512) {
              const slice = byteCharacters.slice(offset, offset + 512);
          
              const byteNumbers = new Array(slice.length);
              for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
              }
          
              const byteArray = new Uint8Array(byteNumbers);
              byteArrays.push(byteArray);
            }

            const blob = new Blob(byteArrays, {type: 'application/'+obj.tX_EXTENSION});
            const reader = new FileReader();
            reader.onload = () => {
              this.src = this.domSanitizer.bypassSecurityTrustUrl(reader.result!.toString());
            };
            reader.readAsDataURL(new File([blob],obj.nU_ID_ARCHIVO+"."+obj.tX_EXTENSION,
              {type: 'application/'+obj.tX_EXTENSION}));
            }                                  
        },
        error => {
          //console.log(error);
          Swal.fire({
            title:'El servicio solicitado no se encuentra disponible',
            icon: 'error',
          });
        }
      );
  }
}
