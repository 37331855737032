import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})

export class ImageToBase64 {
    
    constructor() { }

    //Convert image url in assets to b64
    getBase64Image(url: string): Promise<any> {
        return new Promise((resolve) => {
        const image = new Image();
        const outputFormat = 'image/png';
        image.crossOrigin = 'Anonymous';
        image.onload = () => {
            let canvas = <HTMLCanvasElement>document.createElement('CANVAS');
            const context = canvas.getContext('2d');
            let dataURL;
            canvas.height = image.height;
            canvas.width = image.width;
            context!.drawImage(image, 0, 0);
            dataURL = canvas.toDataURL(outputFormat);
            resolve(dataURL);
        };
        image.src = url;
        });
    
    }

    GetDate() {
        const fecha: Date=new Date();
        const dia: string = fecha.getDate().toString().padStart(2, '0');
        const mes: string = (fecha.getMonth() + 1).toString().padStart(2, '0');
        const anio: string = fecha.getFullYear().toString();

        var hora=fecha.getHours();
        var min=fecha.getMinutes();
        var seg=fecha.getSeconds();
        var ampm=(hora>=12)?'p.m.':'a.m.';
        hora=(hora>12)?hora-12:hora;

        return dia + '/' + mes + '/' + anio + ' ' +
            ((hora<10)?'0'+hora:hora) + ':' + ((min<10)?'0'+min:min) + ':' + ((seg<10)?'0'+seg:seg) + ' ' + ampm; 
    }

}