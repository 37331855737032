<mat-sidenav-container>
  <mat-sidenav-content fxLayout="column">
    <div style="position: fixed;width: 100%;z-index: 60;">
      <mat-toolbar class="mat-toolbar_principal">         
        <span style="text-align: start;"> 
            <div style="display:flex; align-items:center;">
              <p class="titulo">{{txtTitulo}}</p>
            </div>
        </span>
      </mat-toolbar> 
      <mat-toolbar style="height: 5px; background-color: rgba(25, 115, 184);"></mat-toolbar> 
    </div>     
    <div class="pagina">
      <app-consultar-asociado></app-consultar-asociado>
    </div>     
    <ng-content>        
    </ng-content>
    <div style="width: 100%;"> 
      <mat-toolbar class="fondo_pie" fxLayout="row" fxLayoutAlign="space-between start">
        <div class="contenido">  
          <div class="row">
            <div class="col">
              <!--<a href="https://www.gob.pe/osinfor" target="_blank" style="text-decoration: none;">
                <strong class="formato">Osinfor Peru</strong>
              </a>-->
            </div>
            <div class="col">
              <p class="formatoVersion">versión</p>
            </div>
          </div>          
        </div>
      </mat-toolbar>
    </div>
  </mat-sidenav-content>    
</mat-sidenav-container>



