import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Usuario } from 'src/app/@core/models/Usuario';
import { AutenticaService } from 'src/app/@core/services/AutenticaService';
import { UsuarioService } from 'src/app/@core/services/UsuarioService';
import { variablesGlobales } from 'src/app/Util/variableGlobal';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-actualiza-clave',
  templateUrl: './actualiza-clave.component.html',
  styleUrls: ['./actualiza-clave.component.scss']
})
export class ActualizaClaveComponent implements OnInit, OnDestroy {

  FrmResetearPassword!: FormGroup;
  ObjUsuario!: Usuario;
  navigationSubscription?: Subscription;

  @ViewChild('autosize') autosize!: CdkTextareaAutosize;

  constructor(private _formBuilder: FormBuilder,
              private router: Router,
              private ObjAutenticaService: AutenticaService,
              private ObjUsuarioService: UsuarioService,
              ) { }

  ngOnInit(): void {
    this.FrmResetearPassword=this._formBuilder.group({
      usuario:[variablesGlobales.usuario.numdoc.trim()+" - "+variablesGlobales.usuario.nombreCompleto],
      clave_old:[null,Validators.compose([Validators.required,Validators.maxLength(20)])],
      clave:[null,Validators.compose([Validators.required,Validators.maxLength(20)])],
      clave_temp:[null,Validators.compose([Validators.required,Validators.maxLength(20)])],
    });

    this.Obtener();

    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd) {
        this.FrmResetearPassword.controls["clave_old"].setValue(null);
        this.FrmResetearPassword.controls["clave"].setValue(null);
        this.FrmResetearPassword.controls["clave_temp"].setValue(null);

        this.Obtener();
      }
    });
  }

  ngOnDestroy() {
    if(this.navigationSubscription!=null){
      this.navigationSubscription.unsubscribe();
    }
  }

  Obtener(){ 
    this.ObjAutenticaService.ValidarApp()
      .subscribe(
        data => { 
          if(data.isSuccess){
            if(data.tx_token.length>0){
              variablesGlobales.token=data.tx_token;

              this.ObjUsuarioService.ObtenerId(variablesGlobales.usuario.codusu).pipe()
                .subscribe(
                  data => {
                    if (data.isSuccess){
                      this.ObjUsuario=data.data;
                    }
                  },
                  error => {
                    //console.log(error);
                    Swal.fire({
                      title:'El servicio solicitado no se encuentra disponible',
                      icon: 'error',
                    });
                  }
                );  
            }
            else{
                //console.log("Autenticación inválida: "+data.tx_Mensaje);
            }
          }
        },
        error => {
          //console.log(error);
        }   
      );                              
  }

  alertMsg(msg:string){
    Swal.fire({
      html:msg,
      icon:'info'
    })
  }

  ValidarCampos(){
    if (this.FrmResetearPassword.controls["clave_old"].value === null || 
      this.FrmResetearPassword.controls["clave_old"].value.length === 0 || 
      /^\s+$/.test(this.FrmResetearPassword.controls["clave_old"].value)) {
        this.alertMsg('Ingrese clave actual');
        return false;
    }

    if (this.FrmResetearPassword.controls["clave"].value === null || 
      this.FrmResetearPassword.controls["clave"].value.length === 0 || 
      /^\s+$/.test(this.FrmResetearPassword.controls["clave"].value)) {
        this.alertMsg('Ingrese clave nueva');
        return false;
    }
    else if(this.FrmResetearPassword.controls["clave"].value.length < 8){
      this.alertMsg('Clave nueva debe tener mínino 8 dígitos');
      return false;
    }

    if (this.FrmResetearPassword.controls["clave_temp"].value === null || 
      this.FrmResetearPassword.controls["clave_temp"].value.length === 0 || 
      /^\s+$/.test(this.FrmResetearPassword.controls["clave_temp"].value)) {
        this.alertMsg('Confirme clave');
        return false;
    }
    else if(this.FrmResetearPassword.controls["clave_temp"].value.length < 8){
      this.alertMsg('Confirmar clave debe tener mínino 8 dígitos');
      return false;
    }

    if(this.FrmResetearPassword.controls["clave_old"].value===this.FrmResetearPassword.controls["clave"].value ){
      this.alertMsg('Clave nueva no puede ser igual a clave actual');
      return false;
    }

    if(this.FrmResetearPassword.controls["clave"].value!==this.FrmResetearPassword.controls["clave_temp"].value){
      this.alertMsg('Confirmar clave no coincide con clave nueva');
      return false;
    }

    return true;
  }

  Guardar(){
    if(this.ValidarCampos()){
      this.ObjAutenticaService.ValidarApp()
        .subscribe(
          data => { 
            if(data.isSuccess){
              if(data.tx_token.length>0){
                variablesGlobales.token=data.tx_token;

                this.ObjAutenticaService.ValidarUsuario(this.ObjUsuario.tX_LOGIN!,this.FrmResetearPassword.controls["clave_old"].value)
                  .subscribe(
                    data1=> {
                      if(data1.isSuccess){
                        if(data1.resultadovalidacion){
                          this.ObjUsuarioService.ReseteaClave(this.ObjUsuario.nU_ID_USUARIO, this.FrmResetearPassword.controls["clave"].value)
                          .subscribe(
                            data => {
                              if (data.isSuccess){
                                Swal.fire({
                                  title: data.tx_Mensaje,
                                  icon: 'success'});

                                this.Limpiar();
                              }
                              else{
                                Swal.fire({
                                  title: data.tx_Mensaje,
                                  icon: 'warning'}); 
                              } 
                            },
                            error => {
                              //console.log(error);
                              Swal.fire({
                                title:'El servicio solicitado no se encuentra disponible',
                                icon: 'error',
                              });
                            }
                          );
                        }
                        else{
                          Swal.fire(
                            'Advertencia',
                            'Clave actual incorrecta',
                            'warning'
                          );
                        }
                      }
                      else{
                        Swal.fire(
                          'Advertencia',
                          'Clave actual incorrecta',
                          'warning'
                        );
                      }
                    }  
                  );
              }
              else{
                variablesGlobales.token='';
                variablesGlobales.usuario.codusu=0;
                variablesGlobales.usuarioOficinaCargo.codOficina=0;
                variablesGlobales.usuarioOficinaCargo.codCargo=0;
                variablesGlobales.usuarioRol.admBase=0;
                variablesGlobales.usuarioRol.admEquipo=0;
                variablesGlobales.usuarioRol.admDelegado=0;
                variablesGlobales.usuarioRol.admDeportista=0;
                variablesGlobales.usuarioRol.isVisibleData=0;
                variablesGlobales.menu={};
                variablesGlobales.codevento=0;
                this.router.navigate(['login']);
              }
            }
          },
          error => {
            //console.log(error);
            variablesGlobales.token='';
            variablesGlobales.usuario.codusu=0;
            variablesGlobales.usuarioOficinaCargo.codOficina=0;
            variablesGlobales.usuarioOficinaCargo.codCargo=0;
            variablesGlobales.usuarioRol.admBase=0;
            variablesGlobales.usuarioRol.admEquipo=0;
            variablesGlobales.usuarioRol.admDelegado=0;
            variablesGlobales.usuarioRol.admDeportista=0;
            variablesGlobales.usuarioRol.isVisibleData=0;
            variablesGlobales.menu={};
            variablesGlobales.codevento=0;
            this.router.navigate(['login']);
          }   
        );
    }
  }

  Limpiar(){
    this.FrmResetearPassword.controls["clave_old"].setValue(null);
    this.FrmResetearPassword.controls["clave"].setValue(null);
    this.FrmResetearPassword.controls["clave_temp"].setValue(null);
  }

}
