import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { EquipoPartido } from 'src/app/@core/models/EquipoPartido';
import { PartidoSet } from 'src/app/@core/models/PartidoSet';
import { AutenticaService } from 'src/app/@core/services/AutenticaService';
import { PartidoSetService } from 'src/app/@core/services/PartidoSetService';
import { variablesGlobales } from 'src/app/Util/variableGlobal';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-dialog-agregar-partidoset',
  templateUrl: './dialog-agregar-partidoset.component.html',
  styleUrls: ['./dialog-agregar-partidoset.component.scss']
})
export class DialogAgregarPartidosetComponent implements OnInit {

  FrmAgregarPartidoSet!: FormGroup;
  coddisciplina:number=0;
  codfecha:number=0;
  codpartido:number=0;
  ObjEquipoPartido!: EquipoPartido;
  codpartidoset:number=0;
  titulo!: string;
  ObjPartidoSet!:PartidoSet;
  estado: number=1;

  constructor(private _formBuilder: FormBuilder,
              private ObjAutenticaService: AutenticaService,
              private router: Router,
              private ObjPartidoSetService: PartidoSetService,
              private dialogRef: MatDialogRef<DialogAgregarPartidosetComponent>,
              @Inject(MAT_DIALOG_DATA) data: any) {
                this.coddisciplina=data.coddisciplina;
                this.codfecha=data.codfecha;
                this.codpartido=data.codpartido;
                this.ObjEquipoPartido=data.objEquipoPartido;
                this.codpartidoset=data.codpartidoset;
              }

  ngOnInit(): void {
    this.FrmAgregarPartidoSet=this._formBuilder.group({
      cboset:[null,Validators.required],
      txtpuntos:[null,Validators.required]
    });

    this.titulo="Equipo: "+this.ObjEquipoPartido.tX_DENOMINACION;
  }

  @HostListener('window:keyup.esc') onKeyUp() {
    this.Close();
  }

  onKeyDownOnlyNumber (input: any, event:KeyboardEvent) {
    var specialKeys: string[] = [
      'Backspace',
      'Tab',
      'End',
      'Home',
      'ArrowLeft',
      'ArrowRight',
      'Del',
      'Delete',
    ];

    if (specialKeys.indexOf(event.key) !== -1 ||
        (event.key === 'v' && event.ctrlKey === true) ||
        (event.key === 'v' && event.metaKey === true)) {
        return;
    }

    var current: string = input.value;
    var regex=new RegExp(/^\d+$/g);
    var next = current.concat(event.key);

    if (next && !String(next).match(regex)) {
      event.preventDefault();
    }
  }

  handlePasteOnlyNumber(event:ClipboardEvent) {
    var input=event.clipboardData?.getData('text/plain');
    var regex=new RegExp(/^\d+$/g);
  
    if (regex.test(input!)) {
      return true;
    }
    return false;
  }

  Guardar(){
    if(this.ValidarCampos()){
      this.ObjPartidoSet={nU_ID_PARTIDOSET: this.codpartidoset};
      this.ObjPartidoSet.nU_ID_EVENTO=variablesGlobales.codevento;
      this.ObjPartidoSet.nU_ID_DISCIPLINA=this.coddisciplina;
      this.ObjPartidoSet.nU_ID_FECHA=this.codfecha;
      this.ObjPartidoSet.nU_ID_PARTIDO=this.codpartido;
      this.ObjPartidoSet.nU_ID_EQUIPOPARTIDO=this.ObjEquipoPartido.nU_ID_EQUIPOPARTIDO;
      this.ObjPartidoSet.nU_ID_EQUIPO=this.ObjEquipoPartido.nU_ID_EQUIPO;
      this.ObjPartidoSet.nU_SET=parseInt(this.FrmAgregarPartidoSet.controls["cboset"].value);
      this.ObjPartidoSet.nU_PUNTOS_AFAVOR=parseInt(this.FrmAgregarPartidoSet.controls["txtpuntos"].value);
      this.ObjPartidoSet.tX_OBSERVACION="";
      this.ObjPartidoSet.nU_ID_ESTADO=this.estado;

      this.ObjAutenticaService.ValidarApp()
        .subscribe(
          data => { 
            if(data.isSuccess){
              if(data.tx_token.length>0){
                variablesGlobales.token=data.tx_token;

                this.ObjPartidoSetService.Actualizar(this.ObjPartidoSet).pipe()
                  .subscribe(
                    data => {
                      if (data.isSuccess){
                        Swal.fire({
                          title: data.tx_Mensaje,
                          icon: 'success'}); 
                        
                        this.dialogRef.close(data.isSuccess);
                      }
                      else{
                        console.log(data.tx_mensaje);
                        Swal.fire(
                          'Advertencia',
                          'Atención: Error al procesar',
                          'warning'
                        );
                      } 
                    },
                    error => {
                      console.log(error);
                      Swal.fire(
                        'Error',
                        'El servicio solicitado no se encuentra disponible',
                        'error'
                      );
                    }
                  );
              }
              else{
                variablesGlobales.token='';
                variablesGlobales.usuario.codusu=0;
                this.router.navigate(['login']);
              }
            }
          },
          error => {
            console.log(error);
            variablesGlobales.token='';
            variablesGlobales.usuario.codusu=0;
            this.router.navigate(['login']);
          }   
        );
    }
  }

  ValidarCampos(){
    if (this.FrmAgregarPartidoSet.controls["cboset"].value == null || 
      this.FrmAgregarPartidoSet.controls["cboset"].value.length == 0) {
        this.alertMsg('Seleccione Set');
        return false;
    }

    if (this.FrmAgregarPartidoSet.controls["txtpuntos"].value == null || 
      this.FrmAgregarPartidoSet.controls["txtpuntos"].value.length == 0 || 
      /^\s+$/.test(this.FrmAgregarPartidoSet.controls["txtpuntos"].value)) {
        this.alertMsg('Ingrese Puntos');
        return false;
    }

    return true;
  }

  alertMsg(msg:string){
    Swal.fire({
      html:msg,
      icon:'info'
    })
  }

  Close() {
    this.dialogRef.close();
  }

}
