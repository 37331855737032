<div fxLayout="column" fxLayoutAlign="center">
    <form [formGroup]="FrmGestPartidoSet">
        <div class="opcion">
            <mat-label class="Titulo">{{txtTitulo}}</mat-label>
        </div>
        <mat-card fxLayout="column">
            <div class="opcion1">
                <mat-label class="titulo">
                    Fecha: {{ObjFecha.nU_SECUENCIA+' ['+getFormatFecha(0,ObjFecha.fE_PROGRAMADO!)+'] | '+txtDisciplina+' | Partido: '+ObjPartido.nU_SECUENCIA}}
                </mat-label>
            </div>
            <mat-card-content fxLayout="column">
                <div style="margin-top: 18px;margin-bottom: 25px;">
                    <button mat-mini-fab color="btnStyle" class="tooltips_fab" (click)="Retorno()">
                        <mat-icon>arrow_back</mat-icon>
                        <span>Regresar</span>
                    </button>
                    <button mat-mini-fab color="btnStyle" class="tooltips_fab" style="margin-left: 18px;" *ngIf="isBotonAgregar && permiteOperacion" (click)="fnAgregarResultado(0)">
                        <mat-icon>add</mat-icon>
                        <span>Añadir</span>
                    </button>
                </div>
                <div>
                    <mat-form-field class="Fila0"> 
                        <mat-select style="width: 110px;" placeholder="Filtrar Equipo" formControlName="cboequipopartido" required (selectionChange)="fnListarResultado($event.value)">
                            <mat-option *ngFor="let item of EquipoPartido" [value]="item.nU_ID_EQUIPOPARTIDO">
                                {{item.tX_DENOMINACION}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <h3 class="SubTitulo" style="margin-top: 20px;">Resultados {{(txtEquipo!=null)?'del Equipo: '+txtEquipo:''}}</h3>
                <div class="example-container">
                    <table mat-table [dataSource]="PartidoSetTableDataSource">
                        <ng-container matColumnDef="nU_ID_PARTIDOSET">
                            <th mat-header-cell *matHeaderCellDef></th>
                            <td mat-cell *matCellDef="let element">   
                                <button mat-icon-button class="tooltips_cls"
                                    *ngIf="isBotonAgregar && permiteOperacion"
                                    (click)="fnQuitar(element.nU_ID_PARTIDOSET)">
                                        <mat-icon fontSet="material-icons-outlined">delete</mat-icon>
                                        <span>Quitar</span>
                                </button>                    
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="nU_SET">
                            <th mat-header-cell *matHeaderCellDef>Set</th>
                            <td mat-cell *matCellDef="let element">{{element.nU_SET}}</td>
                        </ng-container>
                        <ng-container matColumnDef="nU_PUNTOS_AFAVOR">
                            <th mat-header-cell *matHeaderCellDef>A favor</th>
                            <td mat-cell *matCellDef="let element">{{element.nU_PUNTOS_AFAVOR}}</td>
                        </ng-container>
                        <ng-container matColumnDef="nU_PUNTOS_CONTRA">
                            <th mat-header-cell *matHeaderCellDef>En contra</th>
                            <td mat-cell *matCellDef="let element">{{element.nU_PUNTOS_CONTRA}}</td>
                        </ng-container>
                        <ng-container matColumnDef="nU_PUNTOS">
                            <th mat-header-cell *matHeaderCellDef>Score</th>
                            <td mat-cell *matCellDef="let element">{{element.nU_PUNTOS}}</td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                </div>
                <mat-paginator [pageSizeOptions]="[10, 20, 50]" showFirstLastButtons></mat-paginator> 
            </mat-card-content>
        </mat-card> 
    </form>      
</div>
