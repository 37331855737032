<div fxLayout="column" fxLayoutAlign="center">
    <form [formGroup]="FrmDetalleDocumento">
        <div class="opcion">
            <mat-label class="Titulo">Gestiona Documento</mat-label>
        </div>
        <mat-card fxLayout="column" style="margin-right: 10px;">
            <div class="opcion1">
                <button mat-icon-button class="tooltips_cls" (click)="Retorno()">
                    <mat-icon fontSet="material-icons-outlined">keyboard_backspace</mat-icon>
                    <span>Regresar</span>
                </button>
                <mat-label class="titulo">Detalle de Documento</mat-label>
                
                
                <button *ngIf="tipo" mat-icon-button class="tooltips_cls" (click)="fnAtender(codigo)">
                    <mat-icon fontSet="material-icons-outlined">fact_check</mat-icon>
                    <span>Atender</span>
                </button>
                <mat-label *ngIf="tipo" class="titulo">Atender documento</mat-label>
            </div>
            <mat-card-content fxLayout="column">
                <div>
                    <fieldset class="borderFieldset"><legend class="estiloLegend">Datos Personales</legend>
                        <div>
                            <mat-form-field class="fila1"> 
                                <mat-select class="valueSelect" placeholder="Tipo de Documento de identificación" formControlName="txttipodocumento">
                                    <mat-option class="opcionSelect" value="1">DNI - DOCUMENTO NACIONAL DE IDENTIDAD</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field class="fila3">
                                <input matInput placeholder="Número de Documento de Identificación" formControlName="txtnumero" readonly>
                            </mat-form-field>
                        </div>
                        <div>
                            <mat-form-field class="fila3">
                                <textarea matInput cdkTextareaAutosize
                                    #autosize="cdkTextareaAutosize" placeholder="Nombres" formControlName="txtnombre" class="CampoTextArea" readonly></textarea>
                            </mat-form-field>
                            <mat-form-field class="fila3">
                                <textarea matInput cdkTextareaAutosize
                                    #autosize="cdkTextareaAutosize" placeholder="Apellido Paterno" formControlName="txtape_paterno" class="CampoTextArea" readonly></textarea>
                            </mat-form-field>
                            <mat-form-field class="fila3">
                                <textarea matInput cdkTextareaAutosize
                                    #autosize="cdkTextareaAutosize" placeholder="Apellido Materno" formControlName="txtape_materno" class="CampoTextArea" readonly></textarea>
                            </mat-form-field>
                        </div>
                        <div>
                            <mat-form-field class="fila3">
                                <input matInput placeholder="Correo Electrónico" formControlName="txtemail" readonly>
                            </mat-form-field>
                            <mat-form-field class="fila3">
                                <input matInput placeholder="Teléfono" formControlName="txttelefono" readonly>
                            </mat-form-field>
                        </div>
                        <div>   
                            <mat-form-field class="fila4"> 
                                <input matInput [matDatepicker]="FecNacimiento" placeholder="Fecha de Nacimiento" formControlName="FecNacimiento" readonly>
                                <mat-datepicker-toggle matSuffix [for]="FecNacimiento"></mat-datepicker-toggle>
                                <mat-datepicker #FecNacimiento disabled></mat-datepicker>
                            </mat-form-field>
                            <mat-form-field class="fila5">
                                <input matInput placeholder="Año de Egreso" formControlName="txtanio" readonly maxLength="4">
                            </mat-form-field>
                            <mat-form-field class="fila5"> 
                                <mat-select placeholder="Turno" formControlName="turno">
                                    <mat-option value="Diurna">Diurna</mat-option>
                                    <mat-option value="Nocturna">Nocturna</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field> 
                                <mat-select placeholder="Sexo" formControlName="sexo">
                                    <mat-option value="F">Femenino</mat-option>
                                    <mat-option value="M">Masculino</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </fieldset> 
                </div><br>
                <div>
                    <fieldset class="borderFieldset"><legend class="estiloLegend">Datos de dirección</legend>
                        <div>
                            <mat-form-field class="fila1"> 
                                <mat-select class="valueSelect" placeholder="Departamento" formControlName="coddepartamento" (selectionChange)="fnListarProvincia($event.value)">
                                    <mat-option class="opcionSelect" *ngFor="let elem of Departamento" [value]="elem.tX_ID_DEPARTAMENTO">
                                        {{elem.tX_DEPARTAMENTO}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field class="fila1"> 
                                <mat-select class="valueSelect" placeholder="Provincia" formControlName="codprovincia" (selectionChange)="fnListarDistrito($event.value)">
                                    <mat-option class="opcionSelect" *ngFor="let elem of Provincia" [value]="elem.tX_ID_PROVINCIA">
                                        {{elem.tX_PROVINCIA}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field class="fila1"> 
                                <mat-select class="valueSelect" placeholder="Distrito" formControlName="coddistrito">
                                    <mat-option class="opcionSelect" *ngFor="let elem of Distrito" [value]="elem.tX_ID_DISTRITO">
                                        {{elem.tX_DISTRITO}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div>
                            <mat-form-field class="fila2">
                                <textarea matInput cdkTextareaAutosize
                                    #autosize="cdkTextareaAutosize" placeholder="Dirección" formControlName="txtdireccion" class="CampoTextArea" readonly></textarea>
                            </mat-form-field>
                        </div>
                    </fieldset> 
                </div><br>
                <div>
                    <fieldset class="borderFieldset"><legend class="estiloLegend">Nota/Observación</legend>
                        <div>
                            <mat-form-field class="fila6">
                                <textarea matInput cdkTextareaAutosize
                                    #autosize="cdkTextareaAutosize" formControlName="txtnota" class="CampoTextArea" readonly></textarea>
                            </mat-form-field>
                        </div>
                    </fieldset>
                </div><br>
                <div>
                    <fieldset class="borderFieldset"><legend class="estiloLegend">Archivos</legend>
                        <div class="example-container">
                            <table mat-table [dataSource]="ArchivoTableDataSource">
                                <ng-container matColumnDef="nU_ID_ARCHIVO">
                                    <th mat-header-cell *matHeaderCellDef class="priority-1"></th>
                                    <td mat-cell *matCellDef="let element" class="priority-1">
                                        <button mat-icon-button class="tooltips_cls" [matMenuTriggerFor]="menu">
                                            <mat-icon>menu</mat-icon>
                                        </button>
                                        <mat-menu #menu="matMenu">
                                            <button mat-menu-item (click)="fnDescargar(element)">
                                                <mat-icon fontSet="material-icons-outlined">get_app</mat-icon>
                                                <span>Descargar</span>
                                            </button>
                                        </mat-menu>                            
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="tX_NOMBRE_ARCHIVO">
                                    <th mat-header-cell *matHeaderCellDef class="priority-2">NOMBRE</th>
                                    <td mat-cell *matCellDef="let element" class="priority-2">{{element.tX_NOMBRE_ARCHIVO}}</td>
                                </ng-container>
                                <ng-container matColumnDef="tX_REQUISITO">
                                    <th mat-header-cell *matHeaderCellDef class="priority-3">REQUISITO</th>
                                    <td mat-cell *matCellDef="let element" class="priority-3">{{element.tX_REQUISITO}}</td>
                                </ng-container>
                                <ng-container matColumnDef="tX_DESCRIPCION">
                                    <th mat-header-cell *matHeaderCellDef class="priority-4">DESCRIPCIÓN</th>
                                    <td mat-cell *matCellDef="let element" class="priority-4">{{element.tX_DESCRIPCION}}</td>
                                </ng-container>
                                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                            </table>
                        </div>
                    </fieldset>
                </div>                                  
            </mat-card-content> 
        </mat-card>
    </form>
</div>

