<mat-card-title class="Titulo" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>Quitar Nota</mat-card-title>
<div mat-dialog-content>
    <form [formGroup]="FrmQuitarNotaPartido"> 
        <mat-card fxLayout="column">  
            <mat-card-content fxLayout="column">
                <div>
                    <mat-form-field class="Fila">
                        <textarea matInput placeholder="Motivo u observación" formControlName="txtmotivo" class="CampoTextArea"
                            autocomplete="off" rows="2" required></textarea>
                    </mat-form-field> 
                </div>
            </mat-card-content> 
        </mat-card>
    </form>
</div>
<div mat-dialog-actions style="text-align: right;">
    <button mat-button class="btnOpcion" (click)="Close()">
        <mat-icon fontSet="material-icons-outlined">cancel</mat-icon>
        <span>Cancelar</span>
    </button>
    <button mat-button class="btnOpcion" (click)="Guardar()">
        <mat-icon fontSet="material-icons-outlined">save</mat-icon>
        <span>Grabar</span>
    </button>
</div>