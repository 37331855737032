<div style="padding-top: 80px;">
    <button mat-button (click)="Retorno()">
        <span style="color: #1973b8;">🡨 Regresar</span>
    </button>
    <div class="div_centrado">
        <form [formGroup]="FrmRegistrarSolictud"> 
            <div style="display:flex; justify-content: center;">
                <mat-card fxLayout="column" style="width: 350px;">  
                    <p>Registro nuevo</p>
                    <mat-card-content fxLayout="column">
                        <div>
                            <mat-form-field class="fila">
                                <mat-select placeholder="Tipo de Documento de Identificación" formControlName="tipo_documento" required (selectionChange)="Limpiar()">
                                    <mat-option value="1">DNI - DOCUMENTO NACIONAL DE IDENTIDAD</mat-option>
                                    <!--<mat-option value="4">Carnet de Extranjería</mat-option>
                                    <mat-option value="7">Pasaporte</mat-option>-->
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div>
                            <mat-form-field class="fila">
                                <input matInput aria-label="string" placeholder="Número de Documento de Identificación" formControlName="numero"
                                    autocomplete="off" required (keyup)="fnIngNumDocumento($event)" [maxLength]="maxLength">
                            </mat-form-field> 
                        </div>
                        <div>
                            <mat-form-field class="fila">
                                <textarea matInput cdkTextareaAutosize
                                    #autosize="cdkTextareaAutosize" placeholder="Nombres" formControlName="nombre" class="FormatoTexto"
                                    autocomplete="off" required (keydown)="pulsar($event)" (keyup)="fnSoloLetras($event)"></textarea>
                            </mat-form-field> 
                        </div>
                        <div>
                            <mat-form-field class="fila">
                                <textarea matInput cdkTextareaAutosize
                                    #autosize="cdkTextareaAutosize" placeholder="Apellido Paterno" formControlName="ape_paterno" class="FormatoTexto"
                                    autocomplete="off" required (keydown)="pulsar($event)" (keyup)="fnSoloLetras($event)"></textarea>
                            </mat-form-field> 
                        </div>
                        <div>
                            <mat-form-field class="fila">
                                <textarea matInput cdkTextareaAutosize
                                    #autosize="cdkTextareaAutosize" placeholder="Apellido Materno" formControlName="ape_materno" class="FormatoTexto"
                                    autocomplete="off" required (keydown)="pulsar($event)" (keyup)="fnSoloLetras($event)"></textarea>
                            </mat-form-field> 
                        </div>
                        <div>
                            <mat-form-field class="fila">
                                <input matInput aria-label="string" placeholder="Correo Electrónico" formControlName="email" 
                                    autocomplete="off" required>
                            </mat-form-field> 
                        </div>
                    </mat-card-content>
                    <mat-card-actions style="text-align: center;margin-top: 15px;margin-bottom: 5px;">
                        <button mat-button mat-raised-button class="btnConsultar" (click)="Guardar()">Validar y Registrar</button>
                    </mat-card-actions>
                </mat-card>
            </div>
        </form>
    </div>
</div>

