import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Configuracion } from './config';
import { variablesGlobales } from 'src/app/Util/variableGlobal';
import { UsuarioRol } from '../models/UsuarioRol';

@Injectable({
    providedIn: 'root'
})

export class TorneoService {
    public url:string = Configuracion.url2 + 'Torneo/';
    
    constructor(private _http: HttpClient) { }


    Listar(NU_OPCION_BUSQUEDA: number){
        let bodyServicio = {"NU_OPCION_BUSQUEDA":NU_OPCION_BUSQUEDA,
                            "NU_CODIGO_USUARIO": variablesGlobales.usuario.codusu};         
        return this._http.post<any>(this.url + 'Listar', bodyServicio);
    }
}