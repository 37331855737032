
<div *ngIf="principal" fxLayout="column" fxLayoutAlign="center">
    <form [formGroup]="FrmGestIntegrante">
        <div class="opcion">
            <mat-label class="Titulo">{{txtTitulo}}</mat-label>
        </div>
        <mat-card fxLayout="column">
            <div class="opcion1">
                <mat-label class="titulo">Equipo: {{ObjEquipo.nU_ANIO_PROMOCION+' '+ObjEquipo.tX_TURNO+' - '+ObjEquipo.tX_DENOMINACION+', Disciplina: '+txtDisciplina}}</mat-label>
            </div>
            <mat-card-content fxLayout="column" *ngIf="opcDetalle===1">
                <mat-card-content fxLayout="row">
                    <fieldset class="borderFieldset">
                        <mat-form-field class="fila1"> 
                            <mat-select placeholder="Filtrar por" formControlName="cboFiltro" (selectionChange)="fnListarFiltro($event.value)">
                                <mat-option value="1">Cargos Vigentes</mat-option>
                                <mat-option value="2">Historial</mat-option>                             
                            </mat-select>
                        </mat-form-field>
                    </fieldset>            
                </mat-card-content>
            </mat-card-content>
            <mat-card-content fxLayout="column">
                <div style="margin-top: 18px;margin-bottom: 20px;">
                    <button mat-mini-fab color="btnStyle" class="tooltips_fab" (click)="Retorno()">
                        <mat-icon>arrow_back</mat-icon>
                        <span>Regresar</span>
                    </button>
                    <button mat-mini-fab color="btnStyle" class="tooltips_fab" style="margin-left: 18px;" *ngIf="isBotonAgregar" (click)="fnNuevo('NUE',0)">
                        <mat-icon>add</mat-icon>
                        <span>Añadir</span>
                    </button>
                    <button mat-mini-fab color="btnStyle" class="tooltips_fab" style="margin-left: 18px;" *ngIf="opcDetalle===2" (click)="fnImprimirLista()">
                        <mat-icon>print</mat-icon>
                        <span>Imprimir</span>
                    </button>
                    <button mat-mini-fab color="btnStyle" class="tooltips_fab" style="margin-left: 18px;" *ngIf="opcDetalle===2" (click)="fnImprimirListaMarcar()">
                        <mat-icon>print</mat-icon>
                        <span>Imprimir</span>
                    </button>
                    <button mat-mini-fab color="btnStyle" class="tooltips_fab" style="margin-left: 18px;" (click)="LlenaGrilla()">
                        <mat-icon>refresh</mat-icon>
                        <span>Refrescar Lista</span>
                    </button>
                </div>
                <h3 class="SubTitulo">Relación de {{opcDetalle===1?'Delegados':'Deportistas'}}</h3>
                <div class="example-container">
                    <table mat-table #table [dataSource]="IntegranteTableDataSource">
                        <ng-container *ngFor="let column of columns" [matColumnDef]="column.columnDef">
                            <th mat-header-cell *matHeaderCellDef [class]="column.class">{{column.header}}</th>
                            <td mat-cell *matCellDef="let row" [class]="column.class">
                                <div *ngIf="column.columnDef==='nU_ID_INTEGRANTE'">
                                    <button mat-icon-button class="tooltips_cls"  [matMenuTriggerFor]="menu">
                                        <mat-icon>menu</mat-icon>
                                    </button>
                                    <mat-menu #menu="matMenu">
                                        <button mat-menu-item *ngIf="opcDetalle===2" (click)="fnDetalle(column.cell(row))">
                                            <mat-icon fontSet="material-icons-outlined">visibility</mat-icon>
                                            <span>Ver</span>
                                        </button>
                                        <button mat-menu-item *ngIf="opcDetalle===2" (click)="fnImprimirFicha(column.cell(row))">
                                            <mat-icon fontSet="material-icons-outlined">print</mat-icon>
                                            <span>Ficha</span>
                                        </button>
                                        <button mat-menu-item *ngIf="isVisibleEmitirCarnet()" (click)="fnEmitirCarnet(row)">
                                            <mat-icon fontSet="material-symbols-outlined">badge</mat-icon>
                                            <span>Emitir Carnet</span>
                                        </button>
                                        <button mat-menu-item *ngIf="isVisibleImprimirCarnet()" (click)="fnImprimirCarnet(row)">
                                            <mat-icon fontSet="material-symbols-outlined">print</mat-icon>
                                            <span>Imprimir Carnet</span>
                                        </button>
                                        <!--<button mat-menu-item *ngIf="opcDetalle===1" (click)="fnCambiarEstado(row,3)">
                                            <mat-icon fontSet="material-icons-outlined">check_circle_outline</mat-icon>
                                            <span>Activar</span>
                                        </button>
                                        <button mat-menu-item *ngIf="opcDetalle===1" (click)="fnCambiarEstado(row,2)">
                                            <mat-icon fontSet="material-icons-outlined">cancel</mat-icon>
                                            <span>Anular</span>
                                        </button>-->  
                                        <button mat-menu-item *ngIf="opcDetalle===1" (click)="fnNuevo('MOD',column.cell(row))">
                                            <mat-icon fontSet="material-icons-outlined">{{(isVisibleObjeto && row.nU_ID_DOCUMENTO_TERMINO==0)?'handshake':'visibility'}}</mat-icon>
                                            <span>{{(isVisibleObjeto && row.nU_ID_DOCUMENTO_TERMINO==0)?'Finaliza Cargo':'Detalle de Cargo'}}</span>
                                        </button>
                                        <button mat-menu-item *ngIf="opcDetalle===2 && isBotonAgregar" (click)="fnNuevo('MOD',column.cell(row))">
                                            <mat-icon fontSet="material-icons-outlined">edit</mat-icon>
                                            <span>Actualiza Datos Deportista</span>
                                        </button>
                                        <!-- <button mat-menu-item *ngIf="(opcDetalle===1 && row.nU_ID_DOCUMENTO_TERMINO===0) || opcDetalle===2" (click)="fnEditarEntidad('MOD',row.nU_ID_ENTIDAD)"> -->
                                        <button mat-menu-item *ngIf="isBotonAgregar" (click)="fnEditarEntidad('MOD',row.nU_ID_ENTIDAD)">
                                            <mat-icon fontSet="material-icons-outlined">edit</mat-icon>
                                            <span>Actualiza Datos de Persona</span>
                                        </button>
                                        <button mat-menu-item *ngIf="opcDetalle===1 && row.nU_ID_DOCUMENTO_INICIO>0" (click)="fnDescargarDocumento(1,row)">
                                            <mat-icon fontSet="material-icons-outlined">download</mat-icon>
                                            <span>Documento Inicio</span>
                                        </button>
                                        <button mat-menu-item *ngIf="opcDetalle===1 && row.nU_ID_DOCUMENTO_TERMINO>0" (click)="fnDescargarDocumento(2,row)">
                                            <mat-icon fontSet="material-icons-outlined">download</mat-icon>
                                            <span>Documento Termino</span>
                                        </button>
                                        <button mat-menu-item *ngIf="isVisibleObjeto && opcDetalle===2" (click)="fnQuitar(row)">
                                            <mat-icon fontSet="material-icons-outlined">delete</mat-icon>
                                            <span>Quitar</span>
                                        </button>
                                    </mat-menu>
                                </div>
                                <div *ngIf="column.columnDef!=='nU_ID_INTEGRANTE'">{{column.cell(row)}}</div>
                            </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                </div>
            </mat-card-content>
        </mat-card> 
    </form>      
</div>
<mat-paginator id="paginadorIntegrante" class="stylePaginator" [pageSizeOptions]="[10, 20, 50]" showFirstLastButtons></mat-paginator>
<div *ngIf="detalle">
    <app-detalle-deportista
        [ObjEvento]="ObjEvento" 
        [ObjEquipo]="ObjEquipo"    
        [codintegrante]="codigo"   
        (salida)="fnRetornoDetalle($event)"
        >
    </app-detalle-deportista>
</div>
<div style="display: none;">
    <ngx-qrcode #parent
        [elementType]="elementType" 
        [value]="value"
        cssClass="aclass"
        [errorCorrectionLevel]="errorCorrectionLevel">
    </ngx-qrcode>
</div>

