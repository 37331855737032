import { Variable } from '@angular/compiler/src/render3/r3_ast';
import { AfterViewInit, Component, OnDestroy, OnInit, ɵgetComponentViewDefinitionFactory } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import Swal from 'sweetalert2';
import { AutenticaService } from '../@core/services/AutenticaService';
import { UsuarioService } from '../@core/services/UsuarioService';
import { variablesGlobales } from '../Util/variableGlobal';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, AfterViewInit, OnDestroy {

  FrmLogin!: FormGroup;
  titulo!: string;
  txtButton!: string;
  txtClave!: string;
  isReadOnlyLogin: boolean=false; 
  version: string="";
  logo:string="";
  isVisibleLogo: boolean=false;
  isVisibleTexto: boolean=false

  constructor(private _formBuilder: FormBuilder,
              private router: Router,
              private recaptchaV3Service: ReCaptchaV3Service,
              private usuarioService: UsuarioService,
              private ObjAutenticaService: AutenticaService,) {
                if (variablesGlobales.codsistema==1){
                  this.logo = "https://app.exasadj.org.pe/assets/img/exasadj/exasadj.jpg"
                  this.isVisibleLogo=true;
                }
                if (variablesGlobales.codsistema==2){
                  this.logo = "https://padron.exacemba.org.pe/assets/img/exacemba/exacemba.jpg"
                  this.isVisibleLogo=true;
                }
                if (variablesGlobales.codsistema==3){
                  this.logo = "https://deporte.rakler.pe/assets/img/exacom/exacom.jpg"
                  this.isVisibleTexto=true;
                }
                  
                this.CompruebaSesionActiva();
              }

  ngOnInit(): void {
    this.FrmLogin=this._formBuilder.group({
      login: [null, Validators.compose([Validators.required,Validators.maxLength(50)])],
      // clave: [null, Validators.compose([Validators.required,Validators.maxLength(20)])],
      clave: [null],
      clave_temp: [null],
    });

    this.titulo="Iniciar Sesión";
    this.txtButton="Ingresar";
    this.version=variablesGlobales.version;
  }

  ngAfterViewInit() {
    const element = document.getElementsByClassName('grecaptcha-badge')[0] as HTMLElement;
    if (element) {
      element.style.visibility = 'visible';
    }
  }

  ngOnDestroy() {
    const element = document.getElementsByClassName('grecaptcha-badge')[0] as HTMLElement;
    if (element) {
      element.style.visibility = 'hidden';
    }
  }

  CompruebaSesionActiva(){    
    if(variablesGlobales.usuario.codusu>0){            
      this.router.navigate(['/menu']);
    }
  }

  keyDownEnter(event:any) {
    if(event.keyCode == 13) {
      this.ValidarLogin();
    }
  }

  validarFormulario() {
    if ( !this.FrmLogin.valid ) {
        return false;
    }
    else{       
        return true;
    }  
  }

  alertMsg(msg:string){
    Swal.fire({
      html:msg,
      icon:'info'
    })
  }

  ValidarCampos(){
    if (this.FrmLogin.controls["clave"].value === null || 
      this.FrmLogin.controls["clave"].value.length === 0 || 
      /^\s+$/.test(this.FrmLogin.controls["clave"].value)) {
        this.alertMsg('Ingrese clave nueva');
        return false;
    }
    else if(this.FrmLogin.controls["clave"].value.length < 8){
      this.alertMsg('Clave nueva debe tener mínino 8 dígitos');
      return false;
    }

    if (this.FrmLogin.controls["clave_temp"].value === null || 
      this.FrmLogin.controls["clave_temp"].value.length === 0 || 
      /^\s+$/.test(this.FrmLogin.controls["clave_temp"].value)) {
        this.alertMsg('Confirme clave');
        return false;
    }
    else if(this.FrmLogin.controls["clave_temp"].value.length < 8){
      this.alertMsg('Confirmar clave debe tener mínino 8 dígitos');
      return false;
    }

    if(this.FrmLogin.controls["clave"].value===this.txtClave){
      this.alertMsg('Clave nueva no puede ser igual a clave actual');
      return false;
    }

    if(this.FrmLogin.controls["clave"].value!==this.FrmLogin.controls["clave_temp"].value){
      this.alertMsg('Confirmar clave no coincide con clave nueva');
      return false;
    }

    return true;
  }

  fnValidar(){

    if(this.isReadOnlyLogin){
      this.FrmLogin.controls["clave"].setValidators([Validators.required,Validators.maxLength(20)]);
      this.ActualizaClave();
    }
    else{
      this.FrmLogin.controls["clave"].setValidators(null);
      let clave = this.FrmLogin.controls["clave"].value;       
      if (clave == null){                
        this.ValidarDeportista();
      }
      else {        
        this.ValidarLogin();
      }
    }
  }

  ValidarDeportista(){  
    const validacion = this.validarFormulario();
   
    if (!validacion) {
      return false;
    }
    
    this.recaptchaV3Service.execute('login')
      .subscribe(token => {
        this.ObjAutenticaService.ValidarApp()
          .subscribe(
            data => { 
              if(data.isSuccess){
                if(data.tx_token.length>0){
                  variablesGlobales.token=data.tx_token;
                  let login = this.FrmLogin.controls["login"].value;
                  this.ObjAutenticaService.ValidarUsuario(login,'')
                  .subscribe(
                    dataValidaUsuario=> {
                      // console.log(dataValidaUsuario);
                      if(dataValidaUsuario.isSuccess){
                        if (dataValidaUsuario.resultadovalidacion){
                          this.usuarioService.ObtenerId(dataValidaUsuario.idUsuario)
                          .subscribe(
                            dataUsuario=>{
                              // console.log(dataUsuario);
                              if(dataUsuario.isSuccess){
                                variablesGlobales.usuario.codusu = dataUsuario.data.nU_ID_USUARIO;
                                variablesGlobales.usuario.numdoc = dataUsuario.data.tX_NUMERO;
                                variablesGlobales.usuario.nombre = dataUsuario.data.tX_NOMBRE;
                                variablesGlobales.usuario.apematerno = dataUsuario.data.tX_APELLIDO_PATERNO;
                                variablesGlobales.usuario.apematerno = dataUsuario.data.tX_APELLIDO_MATERNO;
                                variablesGlobales.usuario.nombreCompleto = dataUsuario.data.tX_NOMBRE
                                                                            +" " +dataUsuario.data.tX_APELLIDO_PATERNO
                                                                            +" " + dataUsuario.data.tX_APELLIDO_MATERNO;
                                variablesGlobales.usuario.codentidad=dataUsuario.data.nU_ID_ENTIDAD;

                                this.router.navigate(['/menu']);                                
                              }
                            }
                          );
                        }
                        else{
                          Swal.fire(
                            'Advertencia',
                            'Autentificación incorrecta de usuario',
                            'warning'
                          );
                        }
                      }
                      else{
                        Swal.fire(
                          'Advertencia',
                          'Autentificación incorrecta de usuario',
                          'warning'
                        );
                      }
                    }  
                  );
                  
                }
                else{
                  Swal.fire(
                    'Advertencia',
                    'Autentificación incorrecta a la aplicación',
                    'warning'
                  );
                }
              }
            },
            error => {
              //console.log(error);
              Swal.fire(
                'Error',
                'El servicio de autentificación no se encuentra disponible',
                'error'
              );
            }     
          );
      });

    return true;
  }

  ValidarLogin(){  
    const validacion = this.validarFormulario();
   
    if (!validacion) {
      return false;
    }

    this.recaptchaV3Service.execute('login')
      .subscribe(token => {
        this.ObjAutenticaService.ValidarApp()
          .subscribe(
            data => { 
              if(data.isSuccess){
                if(data.tx_token.length>0){
                  variablesGlobales.token=data.tx_token;
                  let login = this.FrmLogin.controls["login"].value;
                  let calve = this.FrmLogin.controls["clave"].value;
                  this.ObjAutenticaService.ValidarUsuario(login,calve)
                  .subscribe(
                    dataValidaUsuario=> {
                     // console.log(dataValidaUsuario);
                      if(dataValidaUsuario.isSuccess){
                        if (dataValidaUsuario.resultadovalidacion){
                          this.usuarioService.ObtenerId(dataValidaUsuario.idUsuario)
                          .subscribe(
                            dataUsuario=>{
                              if(dataUsuario.isSuccess){
                                variablesGlobales.usuario.codusu = dataUsuario.data.nU_ID_USUARIO;
                                variablesGlobales.usuario.numdoc = dataUsuario.data.tX_NUMERO;
                                variablesGlobales.usuario.nombre = dataUsuario.data.tX_NOMBRE;
                                variablesGlobales.usuario.apematerno = dataUsuario.data.tX_APELLIDO_PATERNO;
                                variablesGlobales.usuario.apematerno = dataUsuario.data.tX_APELLIDO_MATERNO;
                                variablesGlobales.usuario.nombreCompleto = dataUsuario.data.tX_NOMBRE
                                                                            +" " +dataUsuario.data.tX_APELLIDO_PATERNO
                                                                            +" " + dataUsuario.data.tX_APELLIDO_MATERNO;
                                variablesGlobales.usuario.codentidad=dataUsuario.data.nU_ID_ENTIDAD;

                                if(dataUsuario.listaUOC.length>0){
                                  variablesGlobales.usuarioOficinaCargo.codOficina=dataUsuario.listaUOC[0].nU_ID_OFICINA;
                                  variablesGlobales.usuarioOficinaCargo.nomOficina=dataUsuario.listaUOC[0].tX_OFICINA;
                                  variablesGlobales.usuarioOficinaCargo.nomCargo=dataUsuario.listaUOC[0].tX_CARGO;
                                  variablesGlobales.usuarioOficinaCargo.codCargo=dataUsuario.listaUOC[0].nU_ID_CARGO;
                                }
                                variablesGlobales.isDeportista=false;
                                console.log(variablesGlobales.isDeportista);
                                
                                if(dataUsuario.data.nU_FLAG===1){
                                  this.titulo="Actualice su clave para el acceso";
                                  this.isReadOnlyLogin=true;
                                  this.txtButton="Guardar";
                                  this.txtClave=this.FrmLogin.controls["clave"].value;
                                  this.FrmLogin.controls["clave"].setValue(null);
                                }
                                else if(dataUsuario.data.nU_FLAG===2){
                                  //console.log(variablesGlobales);
                                  this.router.navigate(['/menu']);
                                }
                              }
                            }
                          );
                        }
                        else{
                          Swal.fire(
                            'Advertencia',
                            'Autentificación incorrecta de usuario',
                            'warning'
                          );
                        }
                      }
                      else{
                        Swal.fire(
                          'Advertencia',
                          'Autentificación incorrecta de usuario',
                          'warning'
                        );
                      }
                    }  
                  );
                  
                }
                else{
                  Swal.fire(
                    'Advertencia',
                    'Autentificación incorrecta a la aplicación',
                    'warning'
                  );
                }
              }
            },
            error => {
              //console.log(error);
              Swal.fire(
                'Error',
                'El servicio de autentificación no se encuentra disponible',
                'error'
              );
            }     
          );
      });

    variablesGlobales.isDeportista=false;
    return true;
  }

  ActualizaClave(){
    if(this.ValidarCampos()){
      this.ObjAutenticaService.ValidarApp()
        .subscribe(
          data => { 
            if(data.isSuccess){
              if(data.tx_token.length>0){
                variablesGlobales.token=data.tx_token;

                this.usuarioService.ReseteaClave(variablesGlobales.usuario.codusu, this.FrmLogin.controls["clave"].value)
                  .subscribe(
                    data => {
                      if (data.isSuccess){
                        this.router.navigate(['/menu']);
                      }
                      else{
                        Swal.fire({
                          title: data.tx_Mensaje,
                          icon: 'warning'}); 
                      } 
                    },
                    error => {
                      //console.log(error);
                      Swal.fire({
                        title:'El servicio solicitado no se encuentra disponible',
                        icon: 'error',
                      });
                    }
                  );
              }
              else{
                Swal.fire(
                  'Advertencia',
                  'Autentificación incorrecta a la aplicación',
                  'warning'
                );
              }
            }
          },
          error => {
            //console.log(error);
            Swal.fire(
              'Error',
              'El servicio de autentificación no se encuentra disponible',
              'error'
            );
          } 
        );
    }
  }
}
