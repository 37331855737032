<mat-card-title class="Titulo" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>Atender Documento</mat-card-title>
<div mat-dialog-content>
    <form [formGroup]="FrmAtenderDocumento"> 
        <mat-card fxLayout="column">  
            <mat-card-content fxLayout="column">
                <div>
                    <label>Indique si se acepta o rechaza el documento</label><br>
                </div>
                <div style="margin-top: 20px;">
                    <mat-radio-group formControlName="rdoObservacion" (change)="fnMostrarObservacion($event)">
                        <mat-radio-button value="0">Rechazar</mat-radio-button>
                        <mat-radio-button value="1" style="margin-left: 20px;">Aceptar</mat-radio-button>
                    </mat-radio-group>
                </div>
                <div style="margin-top: 30px;" *ngIf="isVisibleObservacion">
                    <mat-form-field class="fila">
                        <textarea matInput cdkTextareaAutosize
                            #autosize="cdkTextareaAutosize" placeholder="Motivo de rechazo u observación" formControlName="txtobservacion" class="CampoTextArea" 
                            autocomplete="off" [required]="requiredObservacion"></textarea>
                    </mat-form-field>
                </div>
            </mat-card-content> 
        </mat-card>
    </form>
</div>
<div mat-dialog-actions style="text-align: right;margin-top: 20px;">
    <button mat-button class="btnOpcion" (click)="Close()">
        <mat-icon fontSet="material-icons-outlined">cancel</mat-icon>
        <span>Cancelar</span>
    </button>
    <button mat-button class="btnOpcion" (click)="Guardar()">
        <mat-icon fontSet="material-icons-outlined">save</mat-icon>
        <span>Guardar</span>
    </button>
</div>











