<mat-card-title class="Titulo" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>{{txtTitulo}}</mat-card-title>
<div mat-dialog-content>
    <form [formGroup]="FrmRegistrarUsuario"> 
        <mat-card fxLayout="column">  
            <mat-card-content fxLayout="column">
                <div>
                    <mat-form-field class="fila1">
                        <textarea matInput cdkTextareaAutosize
                            #autosize="cdkTextareaAutosize" placeholder="Seleccione Persona" formControlName="codentidad" 
                            class="CampoTextArea" [matAutocomplete]="auto" (focus)="fnListarEntidad()" (keydown)="pulsar($event)" (keyup)="fnLimpiar()" 
                            [readonly]="opc=='MOD'" required></textarea>
                        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFnEntidad" 
                            (optionSelected)="fnSelectEntidad($event.option.value)">
                            <mat-option class="opcionSelect" *ngFor="let entidad of filteredEntidad | async" [value]="entidad">                            
                                {{entidad.tX_NUMERO+' | '+entidad.tX_NOMBRE+' '+entidad.tX_APELLIDO_PATERNO+' '+entidad.tX_APELLIDO_MATERNO}}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                    <button mat-icon-button class="tooltips_fab" (click)="fnRegistrarEntidad('NUE',0)" [disabled]="opc=='MOD'">
                        <mat-icon>add_circle_outline</mat-icon>
                        <span>Añadir</span>
                    </button>
                </div>
                <div>
                    <mat-form-field class="fila2">
                        <textarea matInput cdkTextareaAutosize
                            #autosize="cdkTextareaAutosize" placeholder="Correo Electrónico" formControlName="txtemail" class="CampoTextArea" 
                            readonly></textarea>
                    </mat-form-field>
                </div>
                <div>
                    <div style="margin-top: 5px;">
                        <label>¿El usuario es personal administrativo?</label><br>
                    </div>
                    <div style="margin-top: 15px;">
                        <mat-radio-group formControlName="rdoAdministrativo" (change)="fnMostrarCampos($event)">
                            <mat-radio-button value="1">Sí</mat-radio-button>
                            <mat-radio-button value="0" style="margin-left: 20px;">No</mat-radio-button>
                        </mat-radio-group>
                    </div>
                </div> 
                <div *ngIf="isVisibleAdministrativo" style="margin-top: 15px;">
                    <mat-form-field class="fila3" > 
                        <mat-select class="valueSelect" placeholder="Oficina" formControlName="codoficina" required>
                            <mat-option class="opcionSelect" *ngFor="let elem of Oficina" [value]="elem.nU_ID_OFICINA">
                                {{elem.tX_DESCRIPCION}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div *ngIf="isVisibleAdministrativo">
                    <mat-form-field class="fila3"> 
                        <mat-select class="valueSelect" placeholder="Cargo" formControlName="codcargo" required>
                            <mat-option class="opcionSelect" *ngFor="let elem of Cargo" [value]="elem.nU_ID_CARGO">
                                {{elem.tX_DESCRIPCION}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </mat-card-content> 
        </mat-card>
    </form>
</div>
<div mat-dialog-actions style="text-align: right;">
    <button mat-button class="btnOpcion" (click)="Close()">
        <mat-icon fontSet="material-icons-outlined">cancel</mat-icon>
        <span>Cancelar</span>
    </button>
    <button mat-button class="btnOpcion" (click)="Guardar()">
        <mat-icon fontSet="material-icons-outlined">save</mat-icon>
        <span>Grabar</span>
    </button>
</div>
