import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Equipo } from 'src/app/@core/models/Equipo';
import { Partido } from 'src/app/@core/models/Partido';
import { AutenticaService } from 'src/app/@core/services/AutenticaService';
import { EquipoService } from 'src/app/@core/services/EquipoService';
import { PartidoService } from 'src/app/@core/services/PartidoService';
import { ReclamoService } from 'src/app/@core/services/ReclamoService';
import { variablesGlobales } from 'src/app/Util/variableGlobal';
import { BuscarDocumentoComponent } from 'src/app/menu/controles/buscar-documento/buscar-documento.component';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-dialog-registrar-reclamo',
  templateUrl: './dialog-registrar-reclamo.component.html',
  styleUrls: ['./dialog-registrar-reclamo.component.scss']
})
export class DialogRegistrarReclamoComponent implements OnInit {

  FrmRegistrarReclamo!: FormGroup;
  codpartido:number=0;
  ObjPartido!: Partido;
  Equipo: Equipo[]=[];

  constructor(private _formBuilder: FormBuilder,
              private ObjAutenticaService: AutenticaService,
              private router: Router,
              private ObjEquipoService: EquipoService,
              private ObjReclamoService: ReclamoService,
              public dialog: MatDialog,
              private dialogRef: MatDialogRef<DialogRegistrarReclamoComponent>,
              @Inject(MAT_DIALOG_DATA) data: any) {
                this.ObjPartido=data.objPartido;
                this.codpartido=this.ObjPartido.nU_ID_PARTIDO;
              }

  ngOnInit(): void {
    this.FrmRegistrarReclamo=this._formBuilder.group({
      cboequipo:[null,Validators.required],
      coddocumento:[null],
      txtdocumento:[null],
      txtmotivo:["",Validators.required]
    });

    this.cargaDatos();
  }

  @HostListener('window:keyup.esc') onKeyUp() {
    this.Close();
  }

  cargaDatos(){
    this.ObjAutenticaService.ValidarApp()
      .subscribe(
        data => { 
          if(data.isSuccess){
            if(data.tx_token.length>0){
              variablesGlobales.token=data.tx_token;

              this.ObjEquipoService.Obtener(this.ObjPartido.nU_ID_EQUIPO_LOCAL!).pipe()
                .subscribe(data => {
                  if(data.isSuccess){
                    this.Equipo.push(data.equipo);
                  }
                });
              
              this.ObjEquipoService.Obtener(this.ObjPartido.nU_ID_EQUIPO_VISITA!).pipe()
                .subscribe(data => {
                  if(data.isSuccess){
                    this.Equipo.push(data.equipo);
                  }
                });
            }
            else{
                variablesGlobales.token='';
                variablesGlobales.usuario.codusu=0;
                this.router.navigate(['login']);
            }
          }
        },
        error => {
          console.log(error);
          variablesGlobales.token='';
          variablesGlobales.usuario.codusu=0;
          this.router.navigate(['login']);
        }   
      );
  }

  fnBuscarDocumento(){
    const dialogRef = this.dialog.open(BuscarDocumentoComponent, {
      data: {
        op:1
      },
      disableClose: true 
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.FrmRegistrarReclamo.controls["coddocumento"].setValue(result.obj.nU_ID_DOCUMENTO);
        this.FrmRegistrarReclamo.controls["txtdocumento"].setValue(result.obj.tX_SIGLA);
      }
    });
  }

  Guardar(){
    if(this.ValidarCampos()){
      this.ObjPartido={nU_ID_PARTIDO: this.codpartido};
      this.ObjPartido.nU_ID_RECLAMO=this.FrmRegistrarReclamo.controls["coddocumento"].value;
      this.ObjPartido.tX_OBSERVACION=this.FrmRegistrarReclamo.controls["txtmotivo"].value;

      if(this.FrmRegistrarReclamo.controls["cboequipo"].value>0){
        this.ObjPartido.nU_ID_EQUIPO_GANADOR=this.FrmRegistrarReclamo.controls["cboequipo"].value;
      }

      this.ObjAutenticaService.ValidarApp()
        .subscribe(
          data => { 
            if(data.isSuccess){
              if(data.tx_token.length>0){
                variablesGlobales.token=data.tx_token;

                this.ObjReclamoService.ActualizarPartido(this.ObjPartido).pipe()
                  .subscribe(
                    data => {
                      if (data.isSuccess){
                        Swal.fire({
                          title: data.tx_Mensaje,
                          icon: 'success'}); 
                        
                        this.dialogRef.close(data.isSuccess);
                      }
                      else{
                        console.log(data.tx_mensaje);
                        Swal.fire(
                          'Advertencia',
                          'Atención: Error al procesar',
                          'warning'
                        );
                      } 
                    },
                    error => {
                      console.log(error);
                      Swal.fire(
                        'Error',
                        'El servicio solicitado no se encuentra disponible',
                        'error'
                      );
                    }
                  );
              }
              else{
                variablesGlobales.token='';
                variablesGlobales.usuario.codusu=0;
                this.router.navigate(['login']);
              }
            }
          },
          error => {
            console.log(error);
            variablesGlobales.token='';
            variablesGlobales.usuario.codusu=0;
            this.router.navigate(['login']);
          }   
        );
    }
  }

  ValidarCampos(){
    if (this.FrmRegistrarReclamo.controls["cboequipo"].value == null || 
      this.FrmRegistrarReclamo.controls["cboequipo"].value.length == 0) {
        this.alertMsg('Seleccione Ganador');
        return false;
    }

    if(this.FrmRegistrarReclamo.controls["txtdocumento"].value === null ||
      this.FrmRegistrarReclamo.controls["txtdocumento"].value.length === 0 || 
      /^\s+$/.test(this.FrmRegistrarReclamo.controls["txtdocumento"].value)){
        this.alertMsg('Ingrese Documento');
        return false;
    }

    if (this.FrmRegistrarReclamo.controls["txtmotivo"].value == null || 
      this.FrmRegistrarReclamo.controls["txtmotivo"].value.length == 0 || 
      /^\s+$/.test(this.FrmRegistrarReclamo.controls["txtmotivo"].value)) {
        this.alertMsg('Ingrese Nota u observación');
        return false;
    }

    return true;
  }

  alertMsg(msg:string){
    Swal.fire({
      html:msg,
      icon:'info'
    })
  }

  Close() {
    this.dialogRef.close();
  }

}
