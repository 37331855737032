import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';
import html2canvas from 'html2canvas';
import { Integrante } from 'src/app/@core/models/Integrante';
import { IntegranteRequisito } from 'src/app/@core/models/IntegranteRequisito';
import { RptPDFCarnet } from 'src/app/@core/reports/RptPDFCarnet';

@Component({
  selector: 'app-rpt-carnet',
  templateUrl: './rpt-carnet.component.html',
  styleUrls: ['./rpt-carnet.component.scss']
})
export class RptCarnetComponent implements OnInit {

  data: any = {};
  ObjIntegrante!: Integrante;
  IntegranteRequisito: IntegranteRequisito[]=[];
  
  elementType = NgxQrcodeElementTypes.URL;
  errorCorrectionLevel = NgxQrcodeErrorCorrectionLevels.HIGH;
  srcCarnet: any;
  srcFoto: any;
  srcQR: any;
  lblevento!: string;
  lblsigla!: string;
  lblNombre!: string;
  lblEquipo!: string;
  condicion!: string;
  lblCargo!: string;
  lblnumero!: string;
  lblEmision!: string;
  lblDisciplina!: string;

  @ViewChild('imgCarnet') imgCarnet!: ElementRef;
  @ViewChild('canvas') canvas!: ElementRef;

  constructor(private pdfCarnet: RptPDFCarnet,
              private dialogRef: MatDialogRef<RptCarnetComponent>,
              @Inject(MAT_DIALOG_DATA) data: any) {
                this.data=data.parametros;
                //console.log(this.data);
              }

  ngOnInit(): void {
    this.srcCarnet=this.data.srcCarnet;
    this.srcFoto=this.data.srcFoto;
    this.srcQR=this.data.srcQR;
    this.condicion=this.data.condicion;
    this.lblevento=this.data.lblevento;
    this.lblsigla=this.data.lblsigla;
    this.lblNombre=this.data.lblNombre;
    this.lblEquipo=this.data.lblEquipo;
    //console.log(this.lblCargo);
    //console.log(this.lblnumero);
    this.lblCargo=this.data.lblCargo;
    this.lblnumero=this.data.lblnumero;
    this.lblDisciplina=this.data.lblDisciplina;
    this.lblEmision=this.data.lblEmision;
    
  }

  fnImprimir(){
    html2canvas(this.imgCarnet.nativeElement,
      {
        logging: true,
        allowTaint: false,
        useCORS: true,
      }).then(canvas => {
      this.canvas.nativeElement.src = canvas.toDataURL();
      this.pdfCarnet.getPdfData(canvas.toDataURL());
    });
  }
}
