<div *ngIf="principal" fxLayout="column" fxLayoutAlign="center">
    <form [formGroup]="FrmGestEquipo">
        <div class="opcion">
            <mat-label class="Titulo">{{txtTitulo}}</mat-label>
        </div>
        <mat-card fxLayout="column">
            <mat-card-content fxLayout="column">
                <mat-card-content fxLayout="row">
                    <fieldset class="borderFieldset">
                        <mat-form-field class="fila1"> 
                            <mat-select class="valueSelect" placeholder="Disciplina" formControlName="cbodisciplina" (selectionChange)="LlenaGrillas(1,$event.value)">
                                <mat-option class="opcionSelect" *ngFor="let elem of Disciplina" [value]="elem.nU_ID_DISCIPLINA" (click)="fnValorDisciplina(elem.tX_DISCIPLINA!)">
                                    {{elem.tX_DISCIPLINA}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </fieldset>            
                </mat-card-content>
            </mat-card-content>
            <mat-tab-group animationDuration="0ms" [selectedIndex]="tabIndex" (selectedIndexChange)="tabIndex=$event">
                <mat-tab label="Equipos" *ngIf="!isDeportista">
                    <div class="divTabContenedor">
                        <mat-card-content fxLayout="column" style="margin-top: 18px;margin-bottom: 20px;" *ngIf="admEquipo">
                            <button mat-mini-fab color="btnStyle" class="tooltips_fab" (click)="fnAgregarEquipo()">
                                <mat-icon>add</mat-icon>
                                <span>Añadir</span>
                            </button>
                        </mat-card-content>
                        <mat-card-content fxLayout="column">
                            <h3 class="SubTitulo">Relación de Equipos</h3>
                            <div class="example-container">
                                <table mat-table [dataSource]="EquipoTableDataSource" matSort #equipoSort="matSort">
                                    <ng-container matColumnDef="nU_ID_EQUIPO">
                                        <th mat-header-cell *matHeaderCellDef class="priority-equipo-1"></th>
                                        <td mat-cell *matCellDef="let element" class="priority-equipo-1">
                                            <button mat-icon-button class="tooltips_cls" [matMenuTriggerFor]="menu">
                                                <mat-icon>menu</mat-icon>
                                            </button>
                                            <mat-menu #menu="matMenu">
                                                <button mat-menu-item (click)="fnGestIntegrante(1,element)">
                                                    <mat-icon fontSet="material-icons-outlined">group</mat-icon>
                                                    <span>Delegados</span>
                                                </button>
                                                <button mat-menu-item (click)="fnGestIntegrante(2,element)">
                                                    <mat-icon fontSet="material-icons-outlined">groups</mat-icon>
                                                    <span>Deportistas</span>
                                                </button>
                                            </mat-menu>                            
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="nU_ANIO_PROMOCION">
                                        <th mat-header-cell *matHeaderCellDef class="priority-equipo-2">Equipos</th>
                                        <td mat-cell *matCellDef="let element" class="priority-equipo-2">{{element.nU_ANIO_PROMOCION}}</td>
                                    </ng-container>
                                    <ng-container matColumnDef="tX_TURNO">
                                        <th mat-header-cell *matHeaderCellDef class="priority-equipo-3">Turno</th>
                                        <td mat-cell *matCellDef="let element" class="priority-equipo-3">{{element.tX_TURNO}}</td>
                                    </ng-container>
                                    <ng-container matColumnDef="tX_DENOMINACION">
                                        <th mat-header-cell *matHeaderCellDef class="priority-equipo-4">Denominación</th>
                                        <td mat-cell *matCellDef="let element" class="priority-equipo-4">{{element.tX_DENOMINACION}}</td>
                                    </ng-container>
                                    <ng-container matColumnDef="tX_SERIE">
                                        <th mat-header-cell *matHeaderCellDef class="priority-equipo-5">Serie</th>
                                        <td mat-cell *matCellDef="let element" class="priority-equipo-5">{{element.tX_SERIE}}</td>
                                    </ng-container>
                                    <ng-container matColumnDef="tX_DELEGADO_TITULAR">
                                        <th mat-header-cell *matHeaderCellDef class="priority-equipo-6">Delegado Titular</th>
                                        <td mat-cell *matCellDef="let element" class="priority-equipo-6">{{(element.tX_DELEGADO_TITULAR!==null)?element.tX_DELEGADO_TITULAR:'—'}}</td>
                                    </ng-container>
                                    <ng-container matColumnDef="tX_DELEGADO_SUPLENTE">
                                        <th mat-header-cell *matHeaderCellDef class="priority-equipo-7">Delegado Suplente</th>
                                        <td mat-cell *matCellDef="let element" class="priority-equipo-7">{{(element.tX_DELEGADO_SUPLENTE!==null)?element.tX_DELEGADO_SUPLENTE:'—'}}</td>
                                    </ng-container>
                                    <tr mat-header-row *matHeaderRowDef="displayedColumnsEquipo"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumnsEquipo;"></tr>
                                </table>
                            </div> 
                            <mat-paginator #equipoPaginator
                                [pageIndex]="pageIndexEquipo"
                                [pageSize]="pageSizeEquipo" 
                                [pageSizeOptions]="pageSizeOptions" 
                                (page)="handleEvent('equipoPaginator', $event)"
                                showFirstLastButtons></mat-paginator> 
                        </mat-card-content>
                    </div>
                </mat-tab>
                <mat-tab label="Fechas">
                    <div class="divTabContenedor">
                        <mat-card-content fxLayout="column" style="margin-top: 18px;margin-bottom: 20px;" *ngIf="admEquipo">
                            <button mat-mini-fab color="btnStyle" class="tooltips_fab" (click)="fnAgregarFecha(0)">
                                <mat-icon>add</mat-icon>
                                <span>Añadir</span>
                            </button>
                        </mat-card-content>
                        <mat-card-content fxLayout="column">
                            <h3 class="SubTitulo">Relación de Fechas</h3>
                            <div class="example-container">
                                <table mat-table [dataSource]="FechaTableDataSource" matSort #fechaSort="matSort">
                                    <ng-container matColumnDef="nU_ID_FECHA">
                                        <th mat-header-cell *matHeaderCellDef class="priority-fecha-1"></th>
                                        <td mat-cell *matCellDef="let element" class="priority-fecha-1"> 
                                            <button mat-icon-button class="tooltips_cls" [matMenuTriggerFor]="menu">
                                                <mat-icon>menu</mat-icon>
                                            </button>
                                            <mat-menu #menu="matMenu">
                                                <button mat-menu-item *ngIf="admEquipo"
                                                     (click)="fnImpPartido(element)">
                                                    <mat-icon fontSet="material-icons-outlined">print</mat-icon>
                                                    <span>Imprimir planilla de juego</span>
                                                </button>
                                                <button mat-menu-item (click)="fnGestPartido(element)">
                                                    <mat-icon fontSet="material-icons-outlined">sports_soccer</mat-icon>
                                                    <span>Partidos</span>
                                                </button>
                                                <button mat-menu-item 
                                                    *ngIf="admEquipo &&
                                                        element.fE_INICIO==null && 
                                                        (element.nU_ID_ESTADO==1 || element.nU_ID_ESTADO==3 || element.nU_ID_ESTADO==16)" 
                                                    (click)="fnAgregarFecha(element.nU_ID_FECHA,1)">
                                                            <mat-icon fontSet="material-icons-outlined">do_not_disturb_on</mat-icon>
                                                            <span>Suspender</span>
                                                </button>
                                                <button mat-menu-item 
                                                    *ngIf="admEquipo && 
                                                        element.fE_INICIO==null && 
                                                        element.nU_ID_ESTADO==9" 
                                                    (click)="fnAgregarFecha(element.nU_ID_FECHA,2)">
                                                            <mat-icon fontSet="material-icons-outlined">event</mat-icon>
                                                            <span>Reprogramar</span>
                                                </button>
                                            </mat-menu>                         
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="nU_SECUENCIA">
                                        <th mat-header-cell *matHeaderCellDef class="priority-fecha-2">Nro.</th>
                                        <td mat-cell *matCellDef="let element" class="priority-fecha-2">{{element.nU_SECUENCIA}}</td>
                                    </ng-container>
                                    <ng-container matColumnDef="fE_PROGRAMADO">
                                        <th mat-header-cell *matHeaderCellDef class="priority-fecha-3">Estimado</th>
                                        <td mat-cell *matCellDef="let element" class="priority-fecha-3">{{getFormatFecha(0,element.fE_PROGRAMADO)}}</td>
                                    </ng-container>
                                    <ng-container matColumnDef="fE_INICIO">
                                        <th mat-header-cell *matHeaderCellDef class="priority-fecha-4">Inicio Real</th>
                                        <td mat-cell *matCellDef="let element" class="priority-fecha-4">{{(element.fE_INICIO!=null)?getFormatFecha(1,element.fE_INICIO):'—'}}</td>
                                    </ng-container>
                                    <ng-container matColumnDef="nU_ID_ESTADO">
                                        <th mat-header-cell *matHeaderCellDef class="priority-fecha-5">Estado</th>
                                        <td mat-cell *matCellDef="let element" class="priority-fecha-5">{{element.tX_ESTADO}}</td>
                                    </ng-container>
                                    <tr mat-header-row *matHeaderRowDef="displayedColumnsFecha"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumnsFecha;"></tr>
                                </table>
                            </div> 
                            <mat-paginator #fechaPaginator
                                [pageIndex]="pageIndexFecha"
                                [pageSize]="pageSizeFecha"
                                [pageSizeOptions]="pageSizeOptions" 
                                (page)="handleEvent('fechaPaginator', $event)"
                                showFirstLastButtons></mat-paginator>
                        </mat-card-content>
                    </div>
                </mat-tab>
                <mat-tab label="Estadística">
                    <div class="divTabContenedor">
                        <mat-card-content fxLayout="column" style="margin-top: 18px;margin-bottom: 20px;">
                            <div>
                                <mat-form-field class="fila1"> 
                                    <mat-select class="valueSelect" placeholder="Cuadros" formControlName="cboreporte" required 
                                        (selectionChange)="contruyeColumnasEstadistica($event.value,1)">
                                            <mat-option class="opcionSelect" *ngFor="let elem of ReporteFiltro" [value]="elem.value">{{elem.text}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field class="fila2" *ngIf="isVisibleSerie">
                                    <mat-select style="width: 104px;" placeholder="Serie" formControlName="cboserie" (selectionChange)="fnListarEstadistica($event.value,1)">
                                        <mat-option *ngFor="let elem of Serie" [value]="elem.tX_SERIE" >
                                            {{elem.tX_SERIE}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field> 
                                <mat-form-field class="fila2" *ngIf="isVisibleSerie">
                                    <mat-select style="width: 104px;" placeholder="Torneo" formControlName="cbotorneo" (selectionChange)="fnTorneoExange($event.value)">
                                        <mat-option *ngFor="let elem of Torneo" [value]="elem.nU_ID_TORNEO" >
                                            {{elem.tX_TORNEO}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field> 
                                <mat-form-field class="fila2" *ngIf="isVisibleSerie">
                                    <mat-select style="width: 104px;" placeholder="Tipo" formControlName="cbotipotorneo" (selectionChange)="fnTipoTorneoExange($event.value)">
                                        <mat-option *ngFor="let elem of TipoTorneo" [value]="elem.nU_ID_TIPOTORNEO" >
                                            {{elem.tX_TIPOTORNEO}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field> 
                            </div>
                        </mat-card-content>
                        <mat-card-content fxLayout="column">
                            <div class="example-container">
                                <table mat-table [dataSource]="EstadisticaTableDataSource" matSort #estadisticaSort="matSort">
                                    <ng-container *ngFor="let column of columnsEstadistica" [matColumnDef]="column.columnDef">
                                        <th mat-header-cell *matHeaderCellDef [class]="column.class">{{column.header}}</th>
                                        <td mat-cell *matCellDef="let row" [class]="column.class">
                                            <!--<button mat-icon-button class="tooltips_cls" *ngIf="isVisibleBotonDetalle && column.columnDef=='NU_ID_INTEGRANTE'" (click)="verEstadisticaDetalle(column.cell(row))">
                                                <mat-icon fontSet="material-icons-outlined">visibility</mat-icon>
                                                <span>Detalle</span>
                                            </button>-->
                                            <div>{{column.cell(row)}}</div>
                                        </td>
                                    </ng-container>
                                    <tr mat-header-row *matHeaderRowDef="displayedColumnsEstadistica"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumnsEstadistica;"></tr>
                                </table>
                            </div>
                            <mat-paginator #estadisticaPaginator
                                [pageIndex]="pageIndexEstadistica"
                                [pageSize]="pageSizeEstadistica"
                                [pageSizeOptions]="pageSizeOptions" 
                                (page)="handleEvent('estadisticaPaginator', $event)"
                                showFirstLastButtons></mat-paginator>
                        </mat-card-content>

                    </div>
                </mat-tab>
            </mat-tab-group>
        </mat-card> 
    </form>      
</div>
<div *ngIf="detalle">
    <app-gestiona-integrante
        [ObjEvento]="ObjEvento" 
        [opcDetalle]="opcDetalle" 
        [ObjEquipo]="ObjEquipo"    
        [coddisciplina]="codDisciplina" 
        [txtDisciplina]="txtDisciplina"  
        (salida)="fnRetornoGestIntegrante($event)"
        >
    </app-gestiona-integrante>
</div>
<div *ngIf="partido">
    <app-gestiona-partido
        [ObjEvento]="ObjEvento"  
        [ObjFecha]="ObjFecha"    
        [coddisciplina]="codDisciplina" 
        [txtDisciplina]="txtDisciplina"  
        (salida)="fnRetornoGestPartido($event)"
        >
    </app-gestiona-partido>
</div>