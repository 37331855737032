import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { EquipoPartido } from 'src/app/@core/models/EquipoPartido';
import { Evento } from 'src/app/@core/models/Evento';
import { Fecha } from 'src/app/@core/models/Fecha';
import { Partido } from 'src/app/@core/models/Partido';
import { PartidoSet } from 'src/app/@core/models/PartidoSet';
import { AutenticaService } from 'src/app/@core/services/AutenticaService';
import { EquipoPartidoService } from 'src/app/@core/services/EquipoPartidoService';
import { variablesGlobales } from 'src/app/Util/variableGlobal';
import Swal from 'sweetalert2';
import { DialogAgregarPartidosetComponent } from './dialog-agregar-partidoset/dialog-agregar-partidoset.component';
import { PartidoSetService } from 'src/app/@core/services/PartidoSetService';
import { DialogQuitarPartidosetComponent } from './dialog-quitar-partidoset/dialog-quitar-partidoset.component';

@Component({
  selector: 'app-gestiona-partido-set',
  templateUrl: './gestiona-partido-set.component.html',
  styleUrls: ['./gestiona-partido-set.component.scss']
})
export class GestionaPartidoSetComponent implements OnInit {

  @Output() salida:any= new EventEmitter();

  FrmGestPartidoSet!: FormGroup;
  @Input() ObjEvento!:Evento;
  @Input() ObjFecha!:Fecha;
  @Input() ObjPartido!:Partido;
  @Input() coddisciplina:number=0;
  @Input() txtDisciplina!:string;
  txtTitulo!: string;
  txtEquipo?: string;
  EquipoPartido: EquipoPartido[]=[];
  isBotonAgregar: number=0;
  permiteOperacion: number=0;

  PartidoSet: PartidoSet[]=[];
  displayedColumns: string[] = [
    'nU_ID_PARTIDOSET',
    'nU_SET',
    'nU_PUNTOS_AFAVOR',
    'nU_PUNTOS_CONTRA',
    'nU_PUNTOS'
  ];
  dataSource = this.PartidoSet;

  PartidoSetTable : PartidoSet[] = [];
  PartidoSetTableDataSource = new MatTableDataSource(this.PartidoSetTable);

  @ViewChild(MatPaginator, { static: false }) paginator!: MatPaginator;
  @ViewChild(MatSort, {static: false}) sort!: MatSort;

  constructor(private _formBuilder: FormBuilder,
              private router: Router,
              private ObjAutenticaService: AutenticaService,
              private ObjEquipoPartidoService: EquipoPartidoService,
              private ObjPartidoSetService: PartidoSetService,
              public dialog: MatDialog) { }

  ngOnInit(): void {
    this.FrmGestPartidoSet=this._formBuilder.group({
      cboequipopartido:[null,Validators.required]
    });

    this.isBotonAgregar=variablesGlobales.usuarioRol.admEquipo;
    this.txtTitulo=this.ObjEvento.tX_DENOMINACION!;

    if(this.ObjPartido.nU_ID_ESTADO!=18){
      this.permiteOperacion=1;
    }

    this.fnListarEquipoPartido();
  }

  fnListarEquipoPartido(){
    this.ObjAutenticaService.ValidarApp()
      .subscribe(
        data => { 
          if(data.isSuccess){
            if(data.tx_token.length>0){
              variablesGlobales.token=data.tx_token;

              this.ObjEquipoPartidoService.Listar(0,this.ObjPartido.nU_ID_PARTIDO).pipe()
                .subscribe(data => {
                  if(data.isSuccess){
                    this.EquipoPartido=data.partido;
                  }
                });
            }
            else{
                variablesGlobales.token='';
                variablesGlobales.usuario.codusu=0;
                this.router.navigate(['login']);
            }
          }
        },
        error => {
          console.log(error);
          variablesGlobales.token='';
          variablesGlobales.usuario.codusu=0;
          this.router.navigate(['login']);
        }   
      );
  }

  fnListarResultado(codequipopartido: number){
    this.txtEquipo=this.EquipoPartido.find(element=> element.nU_ID_EQUIPOPARTIDO==codequipopartido)!.tX_DENOMINACION;

    this.ObjAutenticaService.ValidarApp()
      .subscribe(
        data => { 
          if(data.isSuccess){
            if(data.tx_token.length>0){
              variablesGlobales.token=data.tx_token;

              this.ObjPartidoSetService.Listar(0,codequipopartido)
                .subscribe(data => {
                  if (data.isSuccess){
                    this.PartidoSet= data.partidoSet;
                    this.PartidoSetTableDataSource.data=this.PartidoSet; 
                  }                                         
                });

              this.PartidoSetTableDataSource.paginator=this.paginator;
              this.PartidoSetTableDataSource.sort = this.sort;
            }
            else{
                variablesGlobales.token='';
                variablesGlobales.usuario.codusu=0;
                this.router.navigate(['login']);
            }
          }
        },
        error => {
          console.log(error);
          variablesGlobales.token='';
          variablesGlobales.usuario.codusu=0;
          this.router.navigate(['login']);
        }   
      );
  }

  fnAgregarResultado(idpartidoset: number){
    if (this.FrmGestPartidoSet.controls["cboequipopartido"].value == null || 
      this.FrmGestPartidoSet.controls["cboequipopartido"].value.length == 0) {
        this.alertMsg('Seleccione Equipo');
    }
    else{
      const dialogRef = this.dialog.open(DialogAgregarPartidosetComponent, {
        data: {
          coddisciplina:this.coddisciplina,
          codfecha: this.ObjFecha.nU_ID_FECHA,
          codpartido: this.ObjPartido.nU_ID_PARTIDO,
          objEquipoPartido: this.EquipoPartido.find(element=> element.nU_ID_EQUIPOPARTIDO==this.FrmGestPartidoSet.controls["cboequipopartido"].value),
          codpartidoset: idpartidoset
        },
        disableClose: true 
      });
  
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.fnListarResultado(this.FrmGestPartidoSet.controls["cboequipopartido"].value);
        }
      });
    }  
  }

  alertMsg(msg:string){
    Swal.fire({
      html:msg,
      icon:'info'
    })
  }

  fnQuitar(idpartidoset: number){
    const dialogRef = this.dialog.open(DialogQuitarPartidosetComponent, {
      data: {
        codpartidoset: idpartidoset
      },
      disableClose: true 
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.fnListarResultado(this.FrmGestPartidoSet.controls["cboequipopartido"].value);
      }
    });
  }

  getFormatFecha(opc: number, fecha: string){
    var parts=fecha.split("-");
    var anio =parts[0];
    var mes =parts[1];
    var dia =parts[2].substring(0,2);

    if(opc==1){
      parts=fecha.split("T");

      return dia+"/"+mes+"/"+anio+" "+parts[1].substring(0,8);
    }
    else{
      return dia+"/"+mes+"/"+anio;
    } 
  }

  Retorno(){
    this.salida.emit(1);
  }
}
