// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  recaptcha: {
    // siteKey: '6LdDdkEhAAAAAHRItYK_p4ecgK6Gi_AzMEjd-FQM',  //Clave sitio web. reCAPTCHA deporte.rakler.pe
    // siteKey: '6Le_lXsfAAAAALqjUx26S32vIv-IBfySWobrprph', //Clave sitio web. reCAPTCHA v3. (https://www.google.com/recaptcha)// localhost
    //siteKey: '6LfBs3sfAAAAADFrTyWv5R0dUIURkDIHIZLmr1BM', //Clave sitio web. reCAPTCHA v3. (https://www.google.com/recaptcha)// demo.exacemba.org.pe
    //siteKey: '6LfBs3sfAAAAADFrTyWv5R0dUIURkDIHIZLmr1BM', //Clave sitio web. reCAPTCHA v3. (https://www.google.com/recaptcha)// test-padron.exacemba.org.pe
    // siteKey: '6LfBs3sfAAAAADFrTyWv5R0dUIURkDIHIZLmr1BM', //Clave sitio web. reCAPTCHA v3. (https://www.google.com/recaptcha)// padron.exacemba.org.pe



    //siteKey: '6Ld4EkEhAAAAADiI7XdJWqtQkKog-3D2nS3-T_vY', //Clave sitio web. reCAPTCHA test.exasadj.org.pe
    siteKey: '6Ld4EkEhAAAAADiI7XdJWqtQkKog-3D2nS3-T_vY', //Clave sitio web. reCAPTCHA app.exasadj.org.pe


    // siteKey: '6LeUcrEZAAAAALlCfWcpRIbbkABMr-299s5WizLd', //Clave sitio web. reCAPTCHA deporte.rakler.pe

  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
