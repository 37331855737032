import { Component, HostListener } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { variablesGlobales } from './Util/variableGlobal';

@Component({
  selector: 'app-root',
  /*templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']*/
  template: '<router-outlet></router-outlet>'
})
export class AppComponent {
  title = 'EXASADJ v.2.0.';
  userActivity: any;
  userInactive: Subject<any> = new Subject();
  
  ngOnInit() {}

  constructor(private router:Router,
              private dialogRef: MatDialog){
    this.userInactive.subscribe(()=>{
      this.dialogRef.closeAll();
      variablesGlobales.token='';
      variablesGlobales.usuario.codusu=0;
      variablesGlobales.usuarioOficinaCargo.codOficina=0;
      variablesGlobales.usuarioOficinaCargo.codCargo=0;
      variablesGlobales.usuarioRol.admBase=0;
      variablesGlobales.usuarioRol.admEquipo=0;
      variablesGlobales.usuarioRol.admDelegado=0;
      variablesGlobales.usuarioRol.admDeportista=0;
      variablesGlobales.usuarioRol.isVisibleData=0;
      variablesGlobales.menu={};
      variablesGlobales.codevento=0;
      this.router.navigate(['login']);
    });
  }

  @HostListener('window:mousemove') refreshUserState() {
    clearTimeout(this.userActivity);
    this.setTimeout();
  }

  setTimeout() {
    this.userActivity = setTimeout(() => this.userInactive.next(undefined), variablesGlobales.time_Out*1000);
  }
}
