<div *ngIf="principal" class="div_centrado">
    <div style="display:flex; justify-content: center;">
        <mat-card fxLayout="column" style="width:100%; max-width:700px;">  
            <p>Marco Legal Estatutario</p>    
            <mat-label style="text-align: justify;">
                Asociación EXACEMBA al futuro, informa al USUARIO que la finalidad por la que recopila sus datos personales es:
            </mat-label>
            <ul>
                <li>
                    Ejecutar, gestionar y administrar la relación que se genere como consecuencia de la suscripción del padrón de socios
                    de la Asociación EXACEMBA al futuro.
                </li>     
                <li>
                    Usar, brindar y/o transferir esta información para dar cumplimiento a las obligaciones y/o requerimientos que se 
                    generen en virtud de las normas vigentes en el ordenamiento jurídico peruano, incluyendo, las del sistema de 
                    prevención de lavado de activos y financiamiento del terrorismo y normas prudenciales.
                </li>
                <li>
                    Usar, brindar y/o transferir esta información a las autoridades y terceros autorizados por ley.
                </li>
                <li>
                    Cumplir con fines estadísticos e históricos para la Asociación EXACEMBA al futuro.
                </li>
                <li>
                    Evaluar cualquier solicitud que efectúe o pudiere efectuar.
                </li>
                <li>
                    Efectuar encuestas sobre los servicios de la Asociación EXACEMBA al futuro.
                </li>
            </ul>
            <mat-label style="text-align: justify;">
                De igual forma, el USUARIO declara estar informado que puede ejercer sus derechos de acceso, actualización, 
                inclusión, rectificación, supresión y oposición, respecto de sus datos personales en los términos previstos
                en la Ley N° 29733 – Ley de Protección de Datos Personales, y su Reglamento aprobado por el Decreto Supremo
                N° 003-2013-JUS. Para ejercer estos derechos o cualquier otro que la ley establezca, el USUARIO deberá presentar
                una solicitud escrita en en el Jr. Oscar Zevallos N° 282-A, referencia: a espaldas de la panadería La Moderna,
                distrito de Callería.
            </mat-label>
            <mat-label style="margin-top: 15px; text-align: justify;">
                LÍMITES AL SUMINISTRO Y DIVULGACIÓN DE LOS DATOS PERSONALES PROPORCIONADOS
            </mat-label>
            <mat-label style="margin-top: 10px; text-align: justify;">
                El tratamiento de los datos personales de los usuarios se encuentra limitado exclusivamente al cumplimiento
                de las finalidades previstas en este apartado.
            </mat-label>
            <div style="margin-top: 20px;">
                <mat-checkbox [formControl]="chkAcepto" (change)="fnMarcarCheck($event,1)">Acepto</mat-checkbox> 
                <mat-checkbox [formControl]="chkNoAcepto" (change)="fnMarcarCheck($event,2)" style="margin-left: 50px;">No Acepto</mat-checkbox>
            </div>          
            <mat-card-actions style="text-align: right;margin-top: 15px;margin-bottom: 5px;">
                <button mat-button mat-raised-button class="btnConsultar" (click)="fnSiguiente()">Siguiente</button>
            </mat-card-actions>
        </mat-card>
    </div>
</div>
<div>
    <app-completar-solicitud *ngIf="solicitud" [token]="token"></app-completar-solicitud>
</div>
