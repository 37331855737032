import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { PlanillaJuego } from 'src/app/@core/models/PlanillaJuego';
import { AutenticaService } from 'src/app/@core/services/AutenticaService';
import { PlanillaJuegoService } from 'src/app/@core/services/PlanillaJuegoService';
import { variablesGlobales } from 'src/app/Util/variableGlobal';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-dialog-quitar-planillajuego',
  templateUrl: './dialog-quitar-planillajuego.component.html',
  styleUrls: ['./dialog-quitar-planillajuego.component.scss']
})
export class DialogQuitarPlanillajuegoComponent implements OnInit {

  FrmQuitarPlanillaJuego!: FormGroup;
  codplanillajuego:number=0;
  ObjPlanillaJuego!: PlanillaJuego;

  constructor(private _formBuilder: FormBuilder,
              private ObjAutenticaService: AutenticaService,
              private router: Router,
              private ObjPlanillaJuegoService: PlanillaJuegoService,
              private dialogRef: MatDialogRef<DialogQuitarPlanillajuegoComponent>,
              @Inject(MAT_DIALOG_DATA) data: any) {
                this.codplanillajuego=data.codplanillajuego;
              }

  ngOnInit(): void {
    this.FrmQuitarPlanillaJuego=this._formBuilder.group({
      txtmotivo:[null,Validators.required]
    });
  }

  @HostListener('window:keyup.esc') onKeyUp() {
    this.Close();
  }

  Guardar(){
    if (this.FrmQuitarPlanillaJuego.controls["txtmotivo"].value == null || 
      this.FrmQuitarPlanillaJuego.controls["txtmotivo"].value.length == 0 || 
      /^\s+$/.test(this.FrmQuitarPlanillaJuego.controls["txtmotivo"].value)) {
        this.alertMsg('Ingrese Motivo');
    }
    else{
      this.ObjPlanillaJuego={nU_ID_PLANILLAJUEGO: this.codplanillajuego};
      this.ObjPlanillaJuego.tX_OBSERVACION=this.FrmQuitarPlanillaJuego.controls["txtmotivo"].value;

      this.ObjAutenticaService.ValidarApp()
        .subscribe(
          data => { 
            if(data.isSuccess){
              if(data.tx_token.length>0){
                variablesGlobales.token=data.tx_token;

                this.ObjPlanillaJuegoService.Quitar(this.ObjPlanillaJuego).pipe()
                  .subscribe(
                    data => {
                      if (data.isSuccess){
                        Swal.fire({
                          title: data.tx_Mensaje,
                          icon: 'success'}); 
                        
                        this.dialogRef.close(data.isSuccess);
                      }
                      else{
                        console.log(data.tx_mensaje);
                        Swal.fire(
                          'Advertencia',
                          'Atención: Error al procesar',
                          'warning'
                        );
                      } 
                    },
                    error => {
                      console.log(error);
                      Swal.fire(
                        'Error',
                        'El servicio solicitado no se encuentra disponible',
                        'error'
                      );
                    }
                  );
              }
              else{
                variablesGlobales.token='';
                variablesGlobales.usuario.codusu=0;
                this.router.navigate(['login']);
              }
            }
          },
          error => {
            console.log(error);
            variablesGlobales.token='';
            variablesGlobales.usuario.codusu=0;
            this.router.navigate(['login']);
          }   
        );
    }
  }

  alertMsg(msg:string){
    Swal.fire({
      html:msg,
      icon:'info'
    })
  }

  Close() {
    this.dialogRef.close();
  }

}
