import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Archivo } from 'src/app/@core/models/Archivo';
import { Aux_Solicitud } from 'src/app/@core/models/Aux_Solicitud';
import { Departamento } from 'src/app/@core/models/Departamento';
import { Distrito } from 'src/app/@core/models/Distrito';
import { Documento } from 'src/app/@core/models/Documento';
import { Provincia } from 'src/app/@core/models/Provincia';
import { ArchivoService } from 'src/app/@core/services/ArchivoService';
import { AutenticaService } from 'src/app/@core/services/AutenticaService';
import { DepartamentoService } from 'src/app/@core/services/DepartamentoService';
import { DistritoService } from 'src/app/@core/services/DistritoService';
import { DocumentoService } from 'src/app/@core/services/DocumentoService';
import { ProvinciaService } from 'src/app/@core/services/ProvinciaService';
import { variablesGlobales } from 'src/app/Util/variableGlobal';
import Swal from 'sweetalert2';
import { DialogAtenderDocumentoComponent } from '../dialog-atender-documento/dialog-atender-documento.component';

export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};

@Component({
  selector: 'app-detalle-documento',
  templateUrl: './detalle-documento.component.html',
  styleUrls: ['./detalle-documento.component.scss'],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }
  ]
})
export class DetalleDocumentoComponent implements OnInit {

  @Output() salida:any= new EventEmitter();

  FrmDetalleDocumento!: FormGroup;
  @Input() codigo:number=0;
  @Input() tipo:number=0;
  ObjDocumento!: Documento;
  ObjAux_Solicitud!: Aux_Solicitud;
  DocumentoAdjunto!: Documento[];
  Departamento!: Departamento[];
  Provincia!: Provincia[];
  Distrito!: Distrito[];

  Archivo: Archivo[]=[];
  displayedColumns: string[] = ['nU_ID_ARCHIVO','tX_NOMBRE_ARCHIVO','tX_REQUISITO','tX_DESCRIPCION'];
  dataSource = this.Archivo;

  ArchivoTable: Archivo[]=[];
  ArchivoTableDataSource = new MatTableDataSource(this.ArchivoTable);

  @ViewChild('autosize') autosize!: CdkTextareaAutosize;

  constructor(private _formBuilder: FormBuilder,
              private ObjAutenticaService: AutenticaService,
              private ObjDocumentoService: DocumentoService,
              private ObjDepartamentoService: DepartamentoService,
              private ObjProvinciaService: ProvinciaService,
              private ObjDistritoService: DistritoService,
              public dialog: MatDialog,
              private ObjArchivoService: ArchivoService) { }

  ngOnInit(): void {
    this.FrmDetalleDocumento=this._formBuilder.group({
      coddepartamento: [{value:null, disabled:true}],
      codprovincia: [{value:null, disabled:true}],
      coddistrito: [{value:null, disabled:true}],
      txtdireccion: [null],
      txttipodocumento: [{value:null, disabled:true}],
      txtnumero: [null],
      txtnombre: [null],
      txtape_paterno: [null],
      txtape_materno: [null],
      txtemail: [null],
      txttelefono: [null],
      FecNacimiento:[null],
      txtanio:[null],
      turno: [{value:null, disabled:true}],
      sexo: [{value:null, disabled:true}],
      txtnota: [null]
    });

    this.ObjAutenticaService.ValidarApp()
      .subscribe(
        data => { 
          if(data.isSuccess){
            if(data.tx_token.length>0){
              variablesGlobales.token=data.tx_token;

              this.ObjDocumentoService.Obtener(this.codigo).pipe()
                .subscribe(
                  data => {
                    ////console.log(data);
                    if (data.isSuccess){
                      this.ObjDocumento=data.documento;
                      this.ObjAux_Solicitud=data.aux_solicitud;
                      this.DocumentoAdjunto=data.listadocumentoadjuntos;
                      this.Archivo=data.listaarchivo;
                      this.ArchivoTableDataSource.data=this.Archivo;

                      this.FrmDetalleDocumento.controls["txttipodocumento"].setValue(this.ObjAux_Solicitud.tX_ID_TIPODOCUMENTOIDENTIDAD);
                      this.FrmDetalleDocumento.controls["txtnumero"].setValue(this.ObjAux_Solicitud.tX_NUMERO);
                      this.FrmDetalleDocumento.controls["txtnombre"].setValue(this.ObjAux_Solicitud.tX_NOMBRE);
                      this.FrmDetalleDocumento.controls["txtape_paterno"].setValue(this.ObjAux_Solicitud.tX_APELLIDO_PATERNO);
                      this.FrmDetalleDocumento.controls["txtape_materno"].setValue(this.ObjAux_Solicitud.tX_APELLIDO_MATERNO);
                      this.FrmDetalleDocumento.controls["txtemail"].setValue(this.ObjAux_Solicitud.tX_CORREO);
                      this.FrmDetalleDocumento.controls["txttelefono"].setValue(this.ObjAux_Solicitud.tX_NUMERO_TELEFONO);
                      this.FrmDetalleDocumento.controls["FecNacimiento"].setValue(this.convertFechaString(this.ObjAux_Solicitud.fE_NACIMIENTO));
                      this.FrmDetalleDocumento.controls["txtanio"].setValue(this.ObjAux_Solicitud.nU_ANIO_EGRESO);
                      this.FrmDetalleDocumento.controls["turno"].setValue(this.ObjAux_Solicitud.tX_TURNO);
                      this.FrmDetalleDocumento.controls["sexo"].setValue(this.ObjAux_Solicitud.tX_SEXO);
                      this.FrmDetalleDocumento.controls["txtdireccion"].setValue(this.ObjAux_Solicitud.tX_DIRECCION);
                      this.FrmDetalleDocumento.controls["txtnota"].setValue(this.ObjDocumento.tX_OBSERVACION);

                      this.fnListarDepartamento(this.ObjAux_Solicitud.tX_UBIGEO!);
                    }
                  },
                  error => {
                    //console.log(error);
                    Swal.fire({
                      title:'El servicio solicitado no se encuentra disponible',
                      icon: 'error',
                    });
                  }
                );
            }
            else{
                //console.log("Autenticación inválida: "+data.tx_Mensaje);
            }
          }
        },
        error => {
          //console.log(error);
        }   
      );
  }

  fnListarDepartamento(codubigeo: string){
    this.ObjDepartamentoService.Listar(0,'').pipe()    
      .subscribe(
        data => {
          if(data.isSuccess){
            this.Departamento= data.data;
            if(this.Departamento.length>0){
              this.FrmDetalleDocumento.controls["coddepartamento"].setValue(codubigeo.substring(0,2));
              this.fnListarProvincia(codubigeo);
            }
          }                                  
        },
        error => {
          //console.log(error);
        }
      );
  }

  fnListarProvincia(codubigeo: string){
    this.ObjProvinciaService.Listar(1,codubigeo.substring(0,2)).pipe()    
      .subscribe(
        data => {
          if(data.isSuccess){
            this.Provincia= data.data;
            if(this.Provincia.length>0){
              this.FrmDetalleDocumento.controls["codprovincia"].setValue(codubigeo.substring(0,4));
              this.fnListarDistrito(codubigeo);
            } 
          }                                  
        },
        error => {
          //console.log(error);
        }
      );
  }

  fnListarDistrito(codubigeo: string){
    this.ObjDistritoService.Listar(0,codubigeo.substring(0,4)).pipe()    
      .subscribe(
        data => {
          if(data.isSuccess){
            this.Distrito= data.data;
            if(this.Distrito.length>0){
              this.FrmDetalleDocumento.controls["coddistrito"].setValue(codubigeo);
            } 
          }                                  
        },
        error => {
          //console.log(error);
        }
      );
  }

  fnDescargar(obj: Archivo){
    this.ObjAutenticaService.ValidarApp()
      .subscribe(
        data => { 
          if(data.isSuccess){
            if(data.tx_token.length>0){
              variablesGlobales.token=data.tx_token;

              this.ObjArchivoService.ObtenerTrama(obj.tX_NOMBRE_ARCHIVO!).pipe()    
                .subscribe(
                  data => {
                    if(data.isSuccess){
                      obj.trama=data.tx_Mensaje;
                      this.fnObtenerArchivo(obj);
                    }  
                    else{
                      Swal.fire({
                        title:'Archivo no encontrado',
                        icon: 'info',
                      });
                    }                                 
                  },
                  error => {
                    //console.log(error);
                    Swal.fire({
                      title:'El servicio solicitado no se encuentra disponible',
                      icon: 'error',
                    });
                  }
                );
            }
            else{
                //console.log("Autenticación inválida: "+data.tx_Mensaje);
            }
          }
        },
        error => {
          //console.log(error);
        }   
      );
  }

  fnObtenerArchivo(obj: Archivo){
    const byteCharacters= atob(obj.trama);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);
  
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
  
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, {type: 'application/'+obj.tX_NOMBRE_ARCHIVO!.split('.').pop()});
    const blobUrl = URL.createObjectURL(blob);

    const link:any = document.createElement('a');
    link.href = blobUrl;
    link.download = obj.tX_NOMBRE_ARCHIVO;
    link.click();
  }

  convertFechaString(fecha: any){
    var parts=fecha.split("/");
    var dia =parts[1];
    var mes =parts[0];
    var anio =parts[2].substring(0,4);
    var mydate =new Date(anio,mes-1,dia)

    return mydate.toISOString();
  }

  Retorno(){
    this.salida.emit(1);
  }

  fnAtender(iddocumento: number){
    //console.log(iddocumento);
    const dialogRef = this.dialog.open(DialogAtenderDocumentoComponent, {
      data: {
        id: iddocumento,
      },
      disableClose: true 
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        //this.LlenaGrilla();
        this.salida.emit(1);
      }
    });
  }
}
