import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Configuracion } from './config';
import { variablesGlobales } from 'src/app/Util/variableGlobal';
import { BaseDirectivo } from '../models/BaseDirectivo';

@Injectable({
    providedIn: 'root'
})

export class BaseDirectivoService {
    public url:string = Configuracion.url2 + 'BaseDirectivo/';
    
    constructor(private _http: HttpClient) { }

    Actualizar(variable: BaseDirectivo){
        variable.nU_ID_USUARIO=variablesGlobales.usuario.codusu;
        
        return this._http.post<any>(this.url + 'Actualizar', variable);
    }

    Listar(opc: number, codigo: number){
        let bodyServicio = {"NU_OPCION_BUSQUEDA":opc,
                            "NU_CODIGO_BUSQUEDA":codigo}; 
        return this._http.post<any>(this.url + 'Listar', bodyServicio);
    }

    Obtener(codigo: number){
        let bodyServicio = {"NU_ID_BASEDIRECTIVO":codigo}; 
        return this._http.post<any>(this.url + 'Obtener', bodyServicio);
    }
}