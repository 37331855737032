
<mat-card-title class="Titulo" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>Agregar Documento</mat-card-title>
<div mat-dialog-content>
    <form [formGroup]="FrmAgregarDocumento"> 
        <mat-card fxLayout="column">  
            <mat-card-content fxLayout="column">
                <div>
                    <mat-form-field class="fila1"> 
                        <mat-select class="valueSelect" placeholder="Tipo de Documento" formControlName="cbotipodoc">
                            <mat-option class="opcionSelect" *ngFor="let elem of TipoDocumento" [value]="elem.nU_ID_TIPODOCUMENTO">
                                {{elem.tX_DESCRIPCION}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field> 
                    <mat-form-field class="fila2"> 
                        <input matInput [matDatepicker]="FecEmision" placeholder="Fecha de Emisión" autocomplete="off" formControlName="FecEmision" required>
                        <mat-datepicker-toggle matSuffix [for]="FecEmision" ></mat-datepicker-toggle>
                        <mat-datepicker #FecEmision></mat-datepicker>
                    </mat-form-field> 
                </div>
                <div>
                    <mat-form-field class="fila3">
                        <textarea matInput cdkTextareaAutosize
                            #autosize="cdkTextareaAutosize" placeholder="Asunto" formControlName="txtasunto" class="CampoTextArea"
                            (keydown)="pulsar($event)" required></textarea>
                    </mat-form-field>
                </div>
                <div>
                    <input type="file" formControlName="archivo" style="font-size: 11.5px; color: #09C;" 
                        (change)="ngChangeFila($event)" accept=".pdf, .jpg, .jpeg, .png, .PDF, .JPG, .JPEG, .PNG">
                </div>
            </mat-card-content>
        </mat-card>
    </form>
</div>
<div mat-dialog-actions style="text-align: right;">
    <button mat-button class="btnOpcion" (click)="Close()">
        <mat-icon fontSet="material-icons-outlined">cancel</mat-icon>
        <span>Cancelar</span>
    </button>
    <button mat-button class="btnOpcion" (click)="Guardar()">
        <mat-icon fontSet="material-icons-outlined">save</mat-icon>
        <span>Grabar</span>
    </button>
</div>












