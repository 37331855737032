import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { Departamento } from 'src/app/@core/models/Departamento';
import { Distrito } from 'src/app/@core/models/Distrito';
import { Provincia } from 'src/app/@core/models/Provincia';
import { Socio } from 'src/app/@core/models/Socio';
import { AutenticaService } from 'src/app/@core/services/AutenticaService';
import { DepartamentoService } from 'src/app/@core/services/DepartamentoService';
import { DistritoService } from 'src/app/@core/services/DistritoService';
import { ProvinciaService } from 'src/app/@core/services/ProvinciaService';
import { SocioService } from 'src/app/@core/services/SocioService';
import { variablesGlobales } from 'src/app/Util/variableGlobal';
import Swal from 'sweetalert2';
import * as EmailValidator from 'email-validator';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { Subscription } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';

export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};

@Component({
  selector: 'app-actualizar-datos',
  templateUrl: './actualizar-datos.component.html',
  styleUrls: ['./actualizar-datos.component.scss'],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }
  ]
})
export class ActualizarDatosComponent implements OnInit, OnDestroy {

  FrmActualizarInfo!: FormGroup;

  ObjSocio!: Socio;
  Departamento!: Departamento[];
  Provincia!: Provincia[];
  Distrito!: Distrito[];
  isEditable: boolean=true;
  isRequired: boolean=false;
  isReadOnly: boolean=true;
  navigationSubscription?: Subscription;

  @ViewChild('autosize') autosize!: CdkTextareaAutosize;

  constructor(private _formBuilder: FormBuilder,
              private router: Router,
              private ObjAutenticaService: AutenticaService,
              private ObjDepartamentoService: DepartamentoService,
              private ObjProvinciaService: ProvinciaService,
              private ObjDistritoService: DistritoService,
              private ObjSocioService: SocioService) { }

  ngOnInit(): void {
    this.FrmActualizarInfo=this._formBuilder.group({ 
      txttipodocumento: [{value:null, disabled:true}],
      txtnumero: [null],
      txtnombre: [null],
      txtape_paterno: [null],
      txtape_materno: [null],
      FecNacimiento:[null],
      txtanio:[null],
      turno: [{value:null, disabled:true}],
      sexo: [{value:null, disabled:true}],
      txtemail: [null],
      txttelefono: [null],
      coddepartamento: [{value:null, disabled:true}],
      codprovincia: [{value:null, disabled:true}],
      coddistrito: [{value:null, disabled:true}],
      txtdireccion: [null]
    });

    this.fnObtener();

    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd) {     
        this.isEditable=true;
        this.isRequired=false;
        this.isReadOnly=true;

        this.FrmActualizarInfo.controls["txttipodocumento"].setValue(null);
        this.FrmActualizarInfo.controls["txtnumero"].setValue(null);
        this.FrmActualizarInfo.controls["txtnombre"].setValue(null);
        this.FrmActualizarInfo.controls["txtape_paterno"].setValue(null);
        this.FrmActualizarInfo.controls["txtape_materno"].setValue(null);
        this.FrmActualizarInfo.controls["FecNacimiento"].setValue(null);
        this.FrmActualizarInfo.controls["txtanio"].setValue(null);
        this.FrmActualizarInfo.controls["turno"].setValue(null);
        this.FrmActualizarInfo.controls["sexo"].setValue(null);
        this.FrmActualizarInfo.controls["txtemail"].setValue(null);
        this.FrmActualizarInfo.controls["txttelefono"].setValue(null);
        this.FrmActualizarInfo.controls["coddepartamento"].setValue(null);
        this.FrmActualizarInfo.controls["coddepartamento"].disable();
        this.FrmActualizarInfo.controls["codprovincia"].setValue(null);
        this.FrmActualizarInfo.controls["codprovincia"].disable();
        this.FrmActualizarInfo.controls["coddistrito"].setValue(null);
        this.FrmActualizarInfo.controls["coddistrito"].disable();
        this.FrmActualizarInfo.controls["txtdireccion"].setValue(null);

        this.fnObtener();
      }
    });
  }

  ngOnDestroy() {
    if(this.navigationSubscription!=null){
      this.navigationSubscription.unsubscribe();
    }
  }

  fnObtener(){
    this.ObjAutenticaService.ValidarApp()
      .subscribe(
        data => { 
          if(data.isSuccess){
            if(data.tx_token.length>0){
              variablesGlobales.token=data.tx_token;

              this.fnListarDepartamento();

              this.ObjSocioService.Obtener(variablesGlobales.usuario.codusu,1).pipe()
                .subscribe(
                  data => {
                    if (data.isSuccess){
                      this.ObjSocio=data.socio;

                      this.FrmActualizarInfo.controls["txttipodocumento"].setValue(this.ObjSocio.tX_ID_TIPODOCUMENTOIDENTIDAD);
                      this.FrmActualizarInfo.controls["txtnumero"].setValue(this.ObjSocio.tX_NUMERO);
                      this.FrmActualizarInfo.controls["txtnombre"].setValue(this.ObjSocio.tX_NOMBRE);
                      this.FrmActualizarInfo.controls["txtape_paterno"].setValue(this.ObjSocio.tX_APELLIDO_PATERNO);
                      this.FrmActualizarInfo.controls["txtape_materno"].setValue(this.ObjSocio.tX_APELLIDO_MATERNO);
                      this.FrmActualizarInfo.controls["FecNacimiento"].setValue(this.convertFechaString(this.ObjSocio.fE_NACIMIENTO));
                      this.FrmActualizarInfo.controls["txtanio"].setValue(this.ObjSocio.nU_ANIO_EGRESO);
                      this.FrmActualizarInfo.controls["turno"].setValue(this.ObjSocio.tX_TURNO);
                      this.FrmActualizarInfo.controls["sexo"].setValue(this.ObjSocio.tX_SEXO);
                      this.FrmActualizarInfo.controls["txtemail"].setValue(this.ObjSocio.tX_CORREO);
                      this.FrmActualizarInfo.controls["txttelefono"].setValue(this.ObjSocio.tX_TELEFONO);
                      this.FrmActualizarInfo.controls["txtdireccion"].setValue(this.ObjSocio.tX_DIRECCION);

                      this.ObjDepartamentoService.Listar(0,'').pipe()    
                        .subscribe(
                          data => {
                            if(data.isSuccess){
                              this.Departamento= data.data;
                              if(this.Departamento.length>0){
                                this.FrmActualizarInfo.controls["coddepartamento"].setValue(this.ObjSocio.tX_UBIGEO!.substring(0,2));

                                this.ObjProvinciaService.Listar(1,this.ObjSocio.tX_UBIGEO!.substring(0,2)).pipe()    
                                  .subscribe(
                                    data => {
                                      if(data.isSuccess){
                                        this.Provincia= data.data;
                                        if(this.Provincia.length>0){
                                          this.FrmActualizarInfo.controls["codprovincia"].setValue(this.ObjSocio.tX_UBIGEO!.substring(0,4));

                                          this.ObjDistritoService.Listar(0,this.ObjSocio.tX_UBIGEO!.substring(0,4)).pipe()    
                                            .subscribe(
                                              data => {
                                                if(data.isSuccess){
                                                  this.Distrito= data.data;
                                                  if(this.Distrito.length>0){
                                                    this.FrmActualizarInfo.controls["coddistrito"].setValue(this.ObjSocio.tX_UBIGEO);
                                                  } 
                                                }                                  
                                              },
                                              error => {
                                                //console.log(error);
                                              }
                                            );
                                        } 
                                      }                                  
                                    },
                                    error => {
                                      //console.log(error);
                                    }
                                  );
                              }
                            }                                  
                          },
                          error => {
                            //console.log(error);
                          }
                        );
                    }
                  },
                  error => {
                    //console.log(error);
                    Swal.fire({
                      title:'El servicio solicitado no se encuentra disponible',
                      icon: 'error',
                    });
                  }
                );
            }
            else{
                //console.log("Autenticación inválida: "+data.tx_Mensaje);
            }
          }
        },
        error => {
          //console.log(error);
        }   
      );
  }

  fnListarDepartamento(){
    this.ObjDepartamentoService.Listar(0,'').pipe()    
      .subscribe(
        data => {
          if(data.isSuccess){
            this.Departamento= data.data;
          }                                  
        },
        error => {
          //console.log(error);
          Swal.fire({
            title:'El servicio solicitado no se encuentra disponible',
            icon: 'error',
          });
        }
      );
  }

  fnListarProvincia(coddepartamento:string){
    this.FrmActualizarInfo.patchValue({
      codprovincia: null,
      coddistrito: null,
    });

    this.ObjAutenticaService.ValidarApp()
      .subscribe(
        data => { 
          if(data.isSuccess){
            if(data.tx_token.length>0){
              variablesGlobales.token=data.tx_token;

              this.ObjProvinciaService.Listar(1,coddepartamento).pipe()    
                .subscribe(
                  data => {
                    if(data.isSuccess){
                      this.Provincia= data.data;
                      if(this.Provincia.length>0) this.fnListarDistrito(this.Provincia[0].tX_ID_PROVINCIA);
                    }                                  
                  },
                  error => {
                    //console.log(error);
                    Swal.fire({
                      title:'El servicio solicitado no se encuentra disponible',
                      icon: 'error',
                    });
                  }
                );
            }
            else{
                //console.log("Autenticación inválida: "+data.tx_Mensaje);
            }
          }
        },
        error => {
          //console.log(error);
        }   
      );
  }

  fnListarDistrito(codprovincia:string){
    this.FrmActualizarInfo.patchValue({
      coddistrito: null,
    });

    this.ObjAutenticaService.ValidarApp()
      .subscribe(
        data => { 
          if(data.isSuccess){
            if(data.tx_token.length>0){
              variablesGlobales.token=data.tx_token;

              this.ObjDistritoService.Listar(0,codprovincia).pipe()    
                .subscribe(
                  data => {
                    if(data.isSuccess){
                      this.Distrito= data.data;
                    }                                  
                  },
                  error => {
                    //console.log(error);
                    Swal.fire({
                      title:'El servicio solicitado no se encuentra disponible',
                      icon: 'error',
                    });
                  }
                );
            }
            else{
                //console.log("Autenticación inválida: "+data.tx_Mensaje);
            }
          }
        },
        error => {
          //console.log(error);
        }   
      );
  }

  convertFechaString(fecha: any){
    var parts=fecha.split("/");
    var dia =parts[1];
    var mes =parts[0];
    var anio =parts[2].substring(0,4);
    var mydate =new Date(anio,mes-1,dia)

    return mydate.toISOString();
  }

  pulsar(event:any) {
    if (event.which === 13 && !event.shiftKey) {
      event.preventDefault();
      return false;
    }
    return true;
  }

  fnSoloNumeros(event:any) {
    event.target.value = event.target.value.replace(/[^\d]/g,'');
    return false;
  }

  alertMsg(msg:string){
    Swal.fire({
      html:msg,
      icon:'info'
    })
  }

  ValidarCampos(){
    if (this.FrmActualizarInfo.controls["txtemail"].value === null || 
      this.FrmActualizarInfo.controls["txtemail"].value.length === 0 || 
      /^\s+$/.test(this.FrmActualizarInfo.controls["txtemail"].value)) {
        this.alertMsg('Ingrese Correo Electrónico');
        return false;
    }
    else{
      if(!EmailValidator.validate(this.FrmActualizarInfo.controls["txtemail"].value.trim())){
        this.alertMsg('Formato de correo incorrecto');
        return false;
      }       
    }

    if (this.FrmActualizarInfo.controls["txttelefono"].value === null || 
      this.FrmActualizarInfo.controls["txttelefono"].value.length === 0 || 
      /^\s+$/.test(this.FrmActualizarInfo.controls["txttelefono"].value)) {
        this.alertMsg('Ingrese Teléfono');
        return false;
    }

    if (this.FrmActualizarInfo.controls["coddepartamento"].value === null || 
      this.FrmActualizarInfo.controls["coddepartamento"].value.length === 0) {
        this.alertMsg('Ingrese Departamento');
        return false;
    }

    if (this.FrmActualizarInfo.controls["codprovincia"].value === null || 
      this.FrmActualizarInfo.controls["codprovincia"].value.length === 0) {
        this.alertMsg('Ingrese Provincia');
        return false;
    }

    if (this.FrmActualizarInfo.controls["coddistrito"].value === null || 
      this.FrmActualizarInfo.controls["coddistrito"].value.length === 0) {
        this.alertMsg('Ingrese Distrito');
        return false;
    }

    if (this.FrmActualizarInfo.controls["txtdireccion"].value === null || 
      this.FrmActualizarInfo.controls["txtdireccion"].value.length === 0 || 
      /^\s+$/.test(this.FrmActualizarInfo.controls["txtdireccion"].value)) {
        this.alertMsg('Ingrese Dirección');
        return false;
    }
    
    return true;
  }

  Guardar(){
    if(this.isEditable){
      this.isEditable=false;
      this.isRequired=true;
      this.isReadOnly=false;
      this.FrmActualizarInfo.controls["coddepartamento"].enable();
      this.FrmActualizarInfo.controls["codprovincia"].enable();
      this.FrmActualizarInfo.controls["coddistrito"].enable();
      this.FrmActualizarInfo.controls["txtemail"].setValidators([Validators.required]);
      this.FrmActualizarInfo.controls["txttelefono"].setValidators([Validators.required]);
      this.FrmActualizarInfo.controls["coddepartamento"].setValidators([Validators.required]);
      this.FrmActualizarInfo.controls["codprovincia"].setValidators([Validators.required]);
      this.FrmActualizarInfo.controls["coddistrito"].setValidators([Validators.required]);
      this.FrmActualizarInfo.controls["txtdireccion"].setValidators([Validators.required]);
    }
    else{
      if(this.ValidarCampos()){
        this.ObjSocio.tX_UBIGEO=this.FrmActualizarInfo.controls["coddistrito"].value;
        this.ObjSocio.tX_DIRECCION=this.FrmActualizarInfo.controls["txtdireccion"].value.trim();
        this.ObjSocio.tX_CORREO=this.FrmActualizarInfo.controls["txtemail"].value.trim();
        this.ObjSocio.tX_TELEFONO=this.FrmActualizarInfo.controls["txttelefono"].value.trim();

        this.ObjAutenticaService.ValidarApp()
          .subscribe(
            data => { 
              if(data.isSuccess){
                if(data.tx_token.length>0){
                  variablesGlobales.token=data.tx_token;

                  this.ObjSocioService.Actualizar(this.ObjSocio).pipe()
                    .subscribe(
                      data => {
                        if (data.isSuccess){
                          Swal.fire({
                            title: data.tx_Mensaje,
                            icon: 'success'}); 

                          this.Cancelar();
                        }
                        else{
                          Swal.fire({
                            title: data.tx_Mensaje,
                            icon: 'warning'}); 
                        } 
                      },
                      error => {
                        //console.log(error);
                        Swal.fire({
                          title:'El servicio solicitado no se encuentra disponible',
                          icon: 'error',
                        });
                      }
                    );
                }
                else{
                    //console.log("Autenticación inválida: "+data.tx_Mensaje);
                }
              }
            },
            error => {
              //console.log(error);
            }   
          );
      }
    }
  }

  Cancelar(){
    this.isEditable=true;
    this.isRequired=false;
    this.isReadOnly=true;
    this.FrmActualizarInfo.controls["coddepartamento"].disable();
    this.FrmActualizarInfo.controls["codprovincia"].disable();
    this.FrmActualizarInfo.controls["coddistrito"].disable();
    this.FrmActualizarInfo.controls["txtemail"].setValidators(null);
    this.FrmActualizarInfo.controls["txttelefono"].setValidators(null);
    this.FrmActualizarInfo.controls["coddepartamento"].setValidators(null);
    this.FrmActualizarInfo.controls["codprovincia"].setValidators(null);
    this.FrmActualizarInfo.controls["coddistrito"].setValidators(null);
    this.FrmActualizarInfo.controls["txtdireccion"].setValidators(null);

    this.fnObtener();
  }
}
