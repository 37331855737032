import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Archivo } from 'src/app/@core/models/Archivo';
import { Documento } from 'src/app/@core/models/Documento';
import { TipoDocumento } from 'src/app/@core/models/TipoDocumento';
import { ArchivoService } from 'src/app/@core/services/ArchivoService';
import { AutenticaService } from 'src/app/@core/services/AutenticaService';
import { DocumentoService } from 'src/app/@core/services/DocumentoService';
import { TipoDocumentoService } from 'src/app/@core/services/TipoDocumentoService';
import { variablesGlobales } from 'src/app/Util/variableGlobal';
import Swal from 'sweetalert2';
import { AgregarDocumentoComponent } from '../agregar-documento/agregar-documento.component';

@Component({
  selector: 'app-buscar-documento',
  templateUrl: './buscar-documento.component.html',
  styleUrls: ['./buscar-documento.component.scss']
})
export class BuscarDocumentoComponent implements OnInit {

  FrmBuscarDocumento!: FormGroup;
  opc!: number;
  ObjArchivo!: Archivo;
  TipoDocumento!: TipoDocumento[];

  Documento: Documento[]=[];
  displayedColumns: string[] = ['nU_ID_DOCUMENTO','fE_EMISION','tX_SIGLA','tX_DESCRIPCION'];
  dataSource = this.Documento;

  DocumentoTable : Documento[] = [];
  DocumentoTableDataSource = new MatTableDataSource(this.DocumentoTable);

  parametros:any={ 
    opc: 0,
    obj: null
  };

  constructor(private _formBuilder: FormBuilder,
              private router: Router,
              private ObjAutenticaService: AutenticaService,
              private ObjDocumentoService: DocumentoService,
              private ObjArchivoService: ArchivoService,
              private ObjTipoDocumentoService: TipoDocumentoService,
              public dialog: MatDialog,
              private dialogRef: MatDialogRef<BuscarDocumentoComponent>,
              @Inject(MAT_DIALOG_DATA) data: any,) {
                this.opc=data.op;
              }

  ngOnInit(): void {
    this.FrmBuscarDocumento=this._formBuilder.group({
      cbotipodoc:[null],
      txtdocumento:[null],
    }); 

    this.fnListarTipoDocumento();
  }

  @HostListener('window:keyup.esc') onKeyUp() {
    this.Close();
  }

  fnListarTipoDocumento(){
    this.ObjAutenticaService.ValidarApp()
      .subscribe(
        data => { 
          if(data.isSuccess){
            if(data.tx_token.length>0){
              variablesGlobales.token=data.tx_token;

              this.ObjTipoDocumentoService.Listar(1)
                .subscribe(data => {
                  if (data.isSuccess){
                    this.TipoDocumento= data.data;

                    if(this.TipoDocumento.length>0){
                      this.FrmBuscarDocumento.controls["cbotipodoc"].setValue(this.TipoDocumento[0].nU_ID_TIPODOCUMENTO);

                      this.LlenaGrilla();
                    }
                  }                                         
                });
            }
            else{
              variablesGlobales.token='';
              variablesGlobales.usuario.codusu=0;
              variablesGlobales.usuarioOficinaCargo.codOficina=0;
              variablesGlobales.usuarioOficinaCargo.codCargo=0;
              variablesGlobales.usuarioRol.admBase=0;
              variablesGlobales.usuarioRol.admEquipo=0;
              variablesGlobales.usuarioRol.admDelegado=0;
              variablesGlobales.usuarioRol.admDeportista=0;
              variablesGlobales.usuarioRol.isVisibleData=0;
              variablesGlobales.menu={};
              variablesGlobales.codevento=0;
              this.router.navigate(['login']);
            }
          }
        },
        error => {
          //console.log(error);
          variablesGlobales.token='';
          variablesGlobales.usuario.codusu=0;
          variablesGlobales.usuarioOficinaCargo.codOficina=0;
          variablesGlobales.usuarioOficinaCargo.codCargo=0;
          variablesGlobales.usuarioRol.admBase=0;
          variablesGlobales.usuarioRol.admEquipo=0;
          variablesGlobales.usuarioRol.admDelegado=0;
          variablesGlobales.usuarioRol.admDeportista=0;
          variablesGlobales.usuarioRol.isVisibleData=0;
          variablesGlobales.menu={};
          variablesGlobales.codevento=0;
          this.router.navigate(['login']);
        }   
      ); 
  }

  LlenaGrilla(){
    this.ObjAutenticaService.ValidarApp()
      .subscribe(
        data => { 
          if(data.isSuccess){
            if(data.tx_token.length>0){
              variablesGlobales.token=data.tx_token;

              this.ObjDocumentoService.ListarDocumento(1,this.FrmBuscarDocumento.controls["cbotipodoc"].value,
                                                      this.FrmBuscarDocumento.controls["txtdocumento"].value)
                .subscribe(data => {
                  if (data.isSuccess){
                    this.Documento= data.documento;
                    this.DocumentoTableDataSource.data=this.Documento; 
                  }                                         
                });
            }
            else{
              variablesGlobales.token='';
              variablesGlobales.usuario.codusu=0;
              variablesGlobales.usuarioOficinaCargo.codOficina=0;
              variablesGlobales.usuarioOficinaCargo.codCargo=0;
              variablesGlobales.usuarioRol.admBase=0;
              variablesGlobales.usuarioRol.admEquipo=0;
              variablesGlobales.usuarioRol.admDelegado=0;
              variablesGlobales.usuarioRol.admDeportista=0;
              variablesGlobales.usuarioRol.isVisibleData=0;
              variablesGlobales.menu={};
              variablesGlobales.codevento=0;
              this.router.navigate(['login']);
            }
          }
        },
        error => {
          //console.log(error);
          variablesGlobales.token='';
          variablesGlobales.usuario.codusu=0;
          variablesGlobales.usuarioOficinaCargo.codOficina=0;
          variablesGlobales.usuarioOficinaCargo.codCargo=0;
          variablesGlobales.usuarioRol.admBase=0;
          variablesGlobales.usuarioRol.admEquipo=0;
          variablesGlobales.usuarioRol.admDelegado=0;
          variablesGlobales.usuarioRol.admDeportista=0;
          variablesGlobales.usuarioRol.isVisibleData=0;
          variablesGlobales.menu={};
          variablesGlobales.codevento=0;
          this.router.navigate(['login']);
        }   
      ); 
  }

  Buscar(){
    this.LlenaGrilla();
  }

  fnObtener(obj: Documento){
    this.parametros.opc=this.opc;
    this.parametros.obj=obj
    this.dialogRef.close(this.parametros);
  }

  convertFormatDate(fecha: string){
    var parts=fecha.split("-");
    var dia =parts[2].substring(0,2);
    var mes =parts[1];
    var anio =parts[0];

    return dia + '/' + mes + '/' + anio; 
  }

  fnAgregarDocumento(){
    const dialogRef = this.dialog.open(AgregarDocumentoComponent, {
      data: {},
      disableClose: true 
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.LlenaGrilla();
      }
    });
  }

  fnDescargarDocumento(obj: Documento){
    this.ObjAutenticaService.ValidarApp()
      .subscribe(
        data => { 
          if(data.isSuccess){
            if(data.tx_token.length>0){
              variablesGlobales.token=data.tx_token;

              this.ObjDocumentoService.Obtener(obj.nU_ID_DOCUMENTO!).pipe()
                .subscribe(
                  data => {
                    //console.log(data);
                    if (data.isSuccess){
                      this.ObjArchivo={nU_ID_ARCHIVO:0};

                      for(let item of data.listaarchivo){
                        if(item.nU_ID_REQUISITO==2){
                          this.ObjArchivo=item;
                          break;
                        }
                      }

                      if(this.ObjArchivo.nU_ID_ARCHIVO>0){
                        this.ObjArchivoService.ObtenerTrama(this.ObjArchivo.tX_NOMBRE_ARCHIVO!).pipe()    
                          .subscribe(
                            data1 => {
                              if(data1.isSuccess){
                                this.ObjArchivo.trama=data1.tx_Mensaje;
                                this.fnObtenerArchivo(this.ObjArchivo);
                              }
                              else{
                                Swal.fire({
                                  title:'Archivo no encontrado',
                                  icon: 'info',
                                });
                              }                                 
                            },
                            error => {
                              //console.log(error);
                              Swal.fire({
                                title:'El servicio solicitado no se encuentra disponible',
                                icon: 'error',
                              });
                            }
                          );
                      }
                      else{
                        Swal.fire({
                          title:'No existe archivo disponible para descargar',
                          icon: 'info',
                        });
                      }
                    }
                  },
                  error => {
                    //console.log(error);
                    Swal.fire({
                      title:'El servicio solicitado no se encuentra disponible',
                      icon: 'error',
                    });
                  }
                );
            }
            else{
                //console.log("Autenticación inválida: "+data.tx_Mensaje);
            }
          }
        },
        error => {
          //console.log(error);
        }   
      ); 
  }

  fnObtenerArchivo(obj: Archivo){
    const byteCharacters= atob(obj.trama);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);
  
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
  
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    var nombre=(obj.nU_ID_ARCHIVO==0)?obj.tX_NOMBRE:obj.tX_NOMBRE_ARCHIVO;

    const blob = new Blob(byteArrays, {type: 'application/'+nombre!.split('.').pop()});
    const blobUrl = URL.createObjectURL(blob);

    const link:any = document.createElement('a');
    link.href = blobUrl;
    link.download = nombre;
    link.click();
  }

  Close() {
    this.dialogRef.close();
  }
}
