
<mat-card-title class="Titulo" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>{{txtTitulo}}</mat-card-title>
<div mat-dialog-content>
    <form [formGroup]="FrmRegistrarEntidad"> 
        <mat-card fxLayout="column">  
            <mat-card-content fxLayout="column">
                <div class="opcion1">
                    <mat-label class="titulo">Datos Personales</mat-label>
                </div>
                <div>
                    <mat-form-field class="fila1"> 
                        <mat-select class="valueSelect" placeholder="Tipo de Documento de identificación" formControlName="cbotipodocumento" (selectionChange)="Limpiar()">
                            <mat-option class="opcionSelect" value="1">DNI - DOCUMENTO NACIONAL DE IDENTIDAD</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field class="fila2">
                        <input matInput placeholder="Nro. Documento de Identificación" formControlName="txtnumero" autocomplete="off" required
                            (keyup)="fnIngNumDocumento($event)" [maxLength]="maxLength" [readonly]="isReadOnly">
                    </mat-form-field>
                    <button mat-icon-button class="tooltips_fab" *ngIf="isVisibleButtonBusqueda" (click)="Buscar()">
                        <mat-icon fontSet="material-icons-outlined">search</mat-icon>
                        <span>Buscar</span>
                    </button> 
                </div>
                <div>
                    <mat-form-field class="fila2">
                        <textarea matInput cdkTextareaAutosize
                            #autosize="cdkTextareaAutosize" placeholder="Nombres" formControlName="txtnombre" class="FormatoTexto" 
                            (keydown)="pulsar($event)" (keyup)="fnSoloLetras($event)" autocomplete="off" required [readonly]="isReadOnly"></textarea>
                    </mat-form-field>
                    <mat-form-field class="fila2">
                        <textarea matInput cdkTextareaAutosize
                            #autosize="cdkTextareaAutosize" placeholder="Apellido Paterno" formControlName="txtape_paterno" class="FormatoTexto"
                            (keydown)="pulsar($event)" (keyup)="fnSoloLetras($event)" autocomplete="off" required [readonly]="isReadOnly"></textarea>
                    </mat-form-field>
                    <mat-form-field class="fila2">
                        <textarea matInput cdkTextareaAutosize
                            #autosize="cdkTextareaAutosize" placeholder="Apellido Materno" formControlName="txtape_materno" class="FormatoTexto"
                            (keydown)="pulsar($event)" (keyup)="fnSoloLetras($event)" autocomplete="off" required [readonly]="isReadOnly"></textarea>
                    </mat-form-field>
                </div>
                <div style="margin-bottom: 10px;">
                    <mat-form-field class="fila2">
                        <textarea matInput cdkTextareaAutosize
                            #autosize="cdkTextareaAutosize" placeholder="Correo Electrónico" formControlName="txtemail" class="CampoTextArea" 
                            (keydown)="pulsar($event)" autocomplete="off"></textarea>
                    </mat-form-field>
                    <mat-form-field> 
                        <mat-select placeholder="Sexo" formControlName="cbosexo" required>
                            <mat-option value="F">Femenino</mat-option>
                            <mat-option value="M">Masculino</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <button mat-icon-button class="tooltips_fab" [matMenuTriggerFor]="menu" *ngIf="isVisibleButton">
                        <mat-icon fontSet="material-icons-outlined">playlist_add</mat-icon>
                        <span>Agregar</span>
                    </button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item *ngIf="!isVisibleFNacimiento" (click)="fnMostrarComponente(1)">
                            <mat-icon fontSet="material-icons-outlined">today</mat-icon>
                            <span>F. Nacimiento</span>
                        </button>
                        <button mat-menu-item *ngIf="!isVisibleTelefono" (click)="fnMostrarComponente(2)">
                            <mat-icon fontSet="material-icons-outlined">call</mat-icon>
                            <span>Teléfono</span>
                        </button>
                        <button mat-menu-item *ngIf="!isVisibleDomicilio" (click)="fnMostrarComponente(3)">
                            <mat-icon fontSet="material-icons-outlined">home</mat-icon>
                            <span>Domicilio</span>
                        </button>
                        <button mat-menu-item *ngIf="!isVisibleArchivo && opc==='NUE'" (click)="fnMostrarComponente(4)">
                            <mat-icon fontSet="material-icons-outlined">attach_file</mat-icon>
                            <span>Archivos</span>
                        </button>
                    </mat-menu>
                </div>
                <div class="opcion1" *ngIf="isVisibleCabecera">
                    <mat-label class="titulo">Datos Confidenciales y/o de Contacto</mat-label>
                </div>
                <div>
                    <mat-form-field class="fila3" *ngIf="isVisibleFNacimiento"> 
                        <input matInput [matDatepicker]="FecNacimiento" placeholder="Fecha de Nacimiento" formControlName="FecNacimiento" required>
                        <mat-datepicker-toggle matSuffix [for]="FecNacimiento"></mat-datepicker-toggle>
                        <mat-datepicker #FecNacimiento></mat-datepicker>
                    </mat-form-field>
                    <button mat-icon-button class="tooltips_fab" *ngIf="isVisibleFNacimiento" (click)="fnOcultarComponente(1)">
                        <mat-icon fontSet="material-icons-outlined">close</mat-icon>
                        <span>Quitar</span>
                    </button>
                    <mat-form-field class="fila5" *ngIf="isVisibleTelefono">
                        <textarea matInput cdkTextareaAutosize
                            #autosize="cdkTextareaAutosize" placeholder="Teléfono" formControlName="txttelefono" class="CampoTextArea" 
                            (keydown)="pulsar($event)" (keyup)="fnSoloNumeros($event)" autocomplete="off" required></textarea>
                    </mat-form-field>
                    <button mat-icon-button class="tooltips_fab" *ngIf="isVisibleTelefono" (click)="fnOcultarComponente(2)">
                        <mat-icon fontSet="material-icons-outlined">close</mat-icon>
                        <span>Quitar</span>
                    </button>
                </div>
                <fieldset class="borderFieldset" *ngIf="isVisibleDomicilio">
                    <legend class="estiloLegend">
                        <button mat-icon-button class="tooltips_fab" (click)="fnOcultarComponente(3)">
                            <mat-icon fontSet="material-icons-outlined">close</mat-icon>
                            <span>Quitar</span>
                        </button>
                    </legend>
                    <div>                
                        <mat-form-field class="fila1"> 
                            <mat-select class="valueSelect" placeholder="Departamento" formControlName="coddepartamento" 
                                (selectionChange)="fnListarProvincia($event.value)" required>
                                <mat-option class="opcionSelect" *ngFor="let elem of Departamento" [value]="elem.tX_ID_DEPARTAMENTO">
                                    {{elem.tX_DEPARTAMENTO}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field class="fila1"> 
                            <mat-select class="valueSelect" placeholder="Provincia" formControlName="codprovincia" 
                                (selectionChange)="fnListarDistrito($event.value)" required>
                                <mat-option class="opcionSelect" *ngFor="let elem of Provincia" [value]="elem.tX_ID_PROVINCIA">
                                    {{elem.tX_PROVINCIA}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field class="fila1"> 
                            <mat-select class="valueSelect" placeholder="Distrito" formControlName="coddistrito" required>
                                <mat-option class="opcionSelect" *ngFor="let elem of Distrito" [value]="elem.tX_ID_DISTRITO">
                                    {{elem.tX_DISTRITO}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div>
                        <mat-form-field class="fila4">
                            <textarea matInput cdkTextareaAutosize
                                #autosize="cdkTextareaAutosize" placeholder="Dirección" formControlName="txtdireccion" class="CampoTextArea"
                                (keydown)="pulsar($event)" autocomplete="off" required></textarea>
                        </mat-form-field>
                    </div> 
                </fieldset> 
                <fieldset class="borderFieldset" style="margin-top: 25px;" *ngIf="isVisibleArchivo">
                    <legend class="estiloLegend">
                        <button mat-icon-button class="tooltips_fab" (click)="fnOcultarComponente(4)">
                            <mat-icon fontSet="material-icons-outlined">close</mat-icon>
                            <span>Quitar</span>
                        </button>
                    </legend>
                    <div class="opcion1" style="margin-top: 15px;">
                        <mat-label class="titulo">Archivos</mat-label>
                    </div> 
                    <div style="margin-top: 10px;margin-bottom: 20px;">
                        <button mat-icon-button class="tooltips_fab" (click)="fnAgregar()">
                            <mat-icon fontSet="material-icons-outlined">attach_file</mat-icon>
                            <span>Añadir</span>
                        </button>
                    </div>
                    <div class="example-container">
                        <table mat-table [dataSource]="ArchivoTableDataSource">
                            <ng-container matColumnDef="nU_ID_ARCHIVO">
                                <th mat-header-cell *matHeaderCellDef></th>
                                <td mat-cell *matCellDef="let element">
                                    <button mat-icon-button class="tooltips_cls" [matMenuTriggerFor]="menu">
                                        <mat-icon>menu</mat-icon>
                                    </button>
                                    <mat-menu #menu="matMenu">
                                        <button mat-menu-item (click)="fnQuitar(element)">
                                            <mat-icon fontSet="material-icons-outlined">delete</mat-icon>
                                            <span>Quitar</span>
                                        </button>
                                        <button mat-menu-item (click)="fnDescargar(element)">
                                            <mat-icon fontSet="material-icons-outlined">get_app</mat-icon>
                                            <span>Descargar</span>
                                        </button>
                                    </mat-menu>                            
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="tX_NOMBRE">
                                <th mat-header-cell *matHeaderCellDef>NOMBRE</th>
                                <td mat-cell *matCellDef="let element">
                                    {{(element.nU_ID_ARCHIVO==0)?element.tX_NOMBRE:element.tX_NOMBRE_ARCHIVO}}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="tX_REQUISITO">
                                <th mat-header-cell *matHeaderCellDef>REQUISITO</th>
                                <td mat-cell *matCellDef="let element">{{element.tX_REQUISITO}}</td>
                            </ng-container>
                            <ng-container matColumnDef="tX_DESCRIPCION">
                                <th mat-header-cell *matHeaderCellDef>DESCRIPCIÓN</th>
                                <td mat-cell *matCellDef="let element">{{element.tX_DESCRIPCION}}</td>
                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        </table>
                    </div>
                </fieldset>      
            </mat-card-content> 
        </mat-card>
    </form>
</div>
<div mat-dialog-actions style="text-align: right;">
    <button mat-button class="btnOpcion" (click)="Close()">
        <mat-icon fontSet="material-icons-outlined">cancel</mat-icon>
        <span>Cancelar</span>
    </button>
    <button mat-button class="btnOpcion" (click)="Guardar()">
        <mat-icon fontSet="material-icons-outlined">save</mat-icon>
        <span>Grabar</span>
    </button>
</div>
