export let variablesGlobales: any={
    autentica:{
        tX_LOGIN:'EXACEMBA_SIG',
        tX_CLAVE: '3X4CEM8A.sig',
        tX_IP:'',
        tX_NOMBRE_EQUIPO:''
    },
    token:'',
    usuario:{
        codusu:0, //2: Paola en producción EXASADJ, 3: Tony en producción EXACEMBA
        numdoc:'',
        nombre:'',
        apepaterno:'',
        apematerno:'',
        nombreCompleto:'',
        codentidad:0        
    },
    isDeportista:true,//debe iniciar en true
    usuarioOficinaCargo:{
        codOficina:0,
        nomOficina:'',
        nomCargo:'',
        codCargo:0
    },
    usuarioRol:{
        // admBase:1,
        // admEquipo:1,
        // admDelegado:1,
        // admDeportista:1,
        // isVisibleData:1,
        // admFecha:1,
        // admMesa:1,
        // admCaja:1,
        // admCarnet:1
        admBase:0,
        admEquipo:0,
        admDelegado:0,
        admDeportista:0,
        isVisibleData:0,
        admFecha:0,
        admMesa:0,
        admCaja:0,
        admCarnet:0
    },
    time_Out: 1800, // tiempo en segundos para el cierre de sesión.
    menu:{},
    codevento:0,//0
    institucion: '',
    aplicacion: 'SIG-EXACEMBA-FRONT-WEB',
    codsistema: 1, //1: EXASADJ, 2: EXACEMBA, 3: EXACOM
    version: "Versión 2.0.1"
}