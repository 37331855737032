
<div *ngIf="principal" fxLayout="column" fxLayoutAlign="center">
    <form [formGroup]="FrmGestAsociado">
        <div class="opcion">
            <mat-label class="Titulo">Bases Organizacionales</mat-label>
        </div>
        <mat-card fxLayout="column">
            <div class="opcion1">
                <mat-label class="titulo">Base Organizacional: {{ObjBase.nU_ANIO_PROMOCION+' '+ObjBase.tX_TURNO}}</mat-label>
            </div>
            <mat-card-content fxLayout="column">
                <div style="margin-top: 18px;margin-bottom: 20px;">
                    <button mat-mini-fab color="btnStyle" class="tooltips_fab" (click)="Retorno()">
                        <mat-icon>arrow_back</mat-icon>
                        <span>Regresar</span>
                    </button>
                    <button mat-mini-fab color="btnStyle" class="tooltips_fab" style="margin-left: 18px;" *ngIf="admBase" (click)="fnNuevo('NUE',0)">
                        <mat-icon>add</mat-icon>
                        <span>Añadir</span>
                    </button>
                    <button mat-mini-fab color="btnStyle" class="tooltips_fab" style="margin-left: 18px;" (click)="LlenaGrilla()">
                        <mat-icon>refresh</mat-icon>
                        <span>Refrescar Lista</span>
                    </button>
                </div>
                <h3 class="SubTitulo">Relación de Asociados</h3>
                <div class="example-container">
                    <table mat-table #table [dataSource]="AsociadoTableDataSource">
                        <ng-container *ngFor="let column of columns" [matColumnDef]="column.columnDef">
                            <th mat-header-cell *matHeaderCellDef [class]="column.class">{{column.header}}</th>
                            <td mat-cell *matCellDef="let row" [class]="column.class">
                                <div *ngIf="column.columnDef=='nU_ID_ASOCIADO' && (admBase || isBotonEmitirCarnet || isBotonImprimirCarnet)">
                                    <button mat-icon-button class="tooltips_cls"  [matMenuTriggerFor]="menu">
                                        <mat-icon>menu</mat-icon>
                                    </button>
                                    <mat-menu #menu="matMenu">
                                        <button mat-menu-item *ngIf="isBotonEmitirCarnet" (click)="fnEmitirCarnet(row)">
                                            <mat-icon fontSet="material-symbols-outlined">badge</mat-icon>
                                            <span>Emitir Carnet</span>
                                        </button>
                                        <button mat-menu-item *ngIf="isBotonImprimirCarnet" (click)="fnImprimirCarnet(row)">
                                            <mat-icon fontSet="material-symbols-outlined">print</mat-icon>
                                            <span>Imprimir Carnet</span>
                                        </button>
                                        <button mat-menu-item *ngIf="admBase" (click)="fnNuevo('MOD',column.cell(row))">
                                            <mat-icon fontSet="material-icons-outlined">edit</mat-icon>
                                            <span>Actualiza Datos de Asociado</span>
                                        </button>
                                        <button mat-menu-item *ngIf="admBase" (click)="fnEditarEntidad('MOD',row.nU_ID_ENTIDAD)">
                                            <mat-icon fontSet="material-icons-outlined">edit</mat-icon>
                                            <span>Actualiza Datos de Persona</span>
                                        </button>
                                    </mat-menu>
                                </div>
                                <div *ngIf="column.columnDef!=='nU_ID_ASOCIADO'">{{column.cell(row)}}</div>
                            </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                </div>
            </mat-card-content>
        </mat-card> 
    </form>      
</div>
<mat-paginator id="paginadorAsociado" class="stylePaginator" [pageSizeOptions]="[10, 20, 50]" showFirstLastButtons></mat-paginator>
<div style="display: none;">
    <ngx-qrcode #parent
        [elementType]="elementType" 
        [value]="value"
        cssClass="aclass"
        [errorCorrectionLevel]="errorCorrectionLevel">
    </ngx-qrcode>
</div>