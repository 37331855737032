import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Departamento } from 'src/app/@core/models/Departamento';
import { Distrito } from 'src/app/@core/models/Distrito';
import { Provincia } from 'src/app/@core/models/Provincia';
import { Socio } from 'src/app/@core/models/Socio';
import { RptPDFFichaInscripcion } from 'src/app/@core/reports/RptPDFFichaInscripcion';
import { AutenticaService } from 'src/app/@core/services/AutenticaService';
import { DepartamentoService } from 'src/app/@core/services/DepartamentoService';
import { DistritoService } from 'src/app/@core/services/DistritoService';
import { ProvinciaService } from 'src/app/@core/services/ProvinciaService';
import { SocioService } from 'src/app/@core/services/SocioService';
import { variablesGlobales } from 'src/app/Util/variableGlobal';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-ficha-inscripcion',
  templateUrl: './ficha-inscripcion.component.html',
  styleUrls: ['./ficha-inscripcion.component.scss']
})
export class FichaInscripcionComponent implements OnInit, OnDestroy {

  FrmConsultaFicha!: FormGroup;

  codigo:number=0;
  ObjSocio!: Socio;
  Departamento!: Departamento[];
  Provincia!: Provincia[];
  Distrito!: Distrito[];
  navigationSubscription?: Subscription;

  Socio: Socio[]=[];
  displayedColumns: string[] = ['nU_ID_ASOCIADO','tX_NOMBRE','nU_ANIO_EGRESO','tX_TURNO'];
  dataSource = this.Socio;

  SocioTable : Socio[] = [];
  SocioTableDataSource = new MatTableDataSource(this.SocioTable);

  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;

  constructor(private _formBuilder: FormBuilder,
              private router: Router,
              private ObjAutenticaService: AutenticaService,
              private ObjSocioService: SocioService,
              private pdfFicha: RptPDFFichaInscripcion,
              private ObjDepartamentoService: DepartamentoService,
              private ObjProvinciaService: ProvinciaService,
              private ObjDistritoService: DistritoService) { }

  ngOnInit(): void {
    this.FrmConsultaFicha=this._formBuilder.group({

    });

    this.LlenaGrilla();

    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      if (e instanceof NavigationEnd) {
        this.Socio=[];
        this.SocioTableDataSource.data=this.Socio; 
        this.SocioTableDataSource.paginator=this.paginator;   

        this.LlenaGrilla();
      }
    });
  }

  ngOnDestroy() {
    if(this.navigationSubscription!=null){
      this.navigationSubscription.unsubscribe();
    }
  }

  LlenaGrilla(){
    this.ObjAutenticaService.ValidarApp()
      .subscribe(
        data => { 
          if(data.isSuccess){
            if(data.tx_token.length>0){
              variablesGlobales.token=data.tx_token;

              this.ObjSocioService.Listar(1)
                .subscribe(data => {
                  if (data.isSuccess){
                    this.Socio= data.data;
                    this.SocioTableDataSource.data=this.Socio; 
                  }                                         
                }); 

              this.SocioTableDataSource.paginator=this.paginator;
            }
            else{
                variablesGlobales.token='';
                variablesGlobales.usuario.codusu=0;
                this.router.navigate(['login']);
            }
          }
        },
        error => {
          //console.log(error);
          variablesGlobales.token='';
          variablesGlobales.usuario.codusu=0;
          this.router.navigate(['login']);
        }   
      );   
  }

  Imprimir(idsocio: number){
    this.ObjAutenticaService.ValidarApp()
      .subscribe(
        data => { 
          if(data.isSuccess){
            if(data.tx_token.length>0){
              variablesGlobales.token=data.tx_token;

              this.ObjSocioService.Obtener(idsocio,2).pipe()
                .subscribe(
                  data => {
                    if (data.isSuccess){
                      this.ObjSocio=data.socio;

                      var txtdepartamento: string="", txtprovincia: string="", txtdistrito: string="";

                      this.ObjDepartamentoService.Listar(0,'').pipe()    
                        .subscribe(
                          data => {
                            if(data.isSuccess){
                              this.Departamento= data.data;

                              if(this.Departamento.length>0){
                                txtdepartamento=this.Departamento.find(m=>m.tX_ID_DEPARTAMENTO==this.ObjSocio.tX_UBIGEO!.substring(0,2))?.tX_DEPARTAMENTO!;

                                this.ObjProvinciaService.Listar(1,this.ObjSocio.tX_UBIGEO!.substring(0,2)).pipe()    
                                  .subscribe(
                                    data => {
                                      if(data.isSuccess){
                                        this.Provincia= data.data;

                                        if(this.Provincia.length>0){
                                          txtprovincia=this.Provincia.find(m=>m.tX_ID_PROVINCIA==this.ObjSocio.tX_UBIGEO!.substring(0,4))?.tX_PROVINCIA!;

                                          this.ObjDistritoService.Listar(0,this.ObjSocio.tX_UBIGEO!.substring(0,4)).pipe()    
                                            .subscribe(
                                              data => {
                                                if(data.isSuccess){
                                                  this.Distrito= data.data;
                                                  txtdistrito=this.Distrito.find(m=>m.tX_ID_DISTRITO==this.ObjSocio.tX_UBIGEO)?.tX_DISTRITO!;

                                                  //this.pdfFicha.getPdfData(this.ObjSocio,txtdepartamento,txtprovincia,txtdistrito);
                                                }                                  
                                              },
                                              error => {
                                                //console.log(error);
                                              }
                                            );
                                        } 
                                      }                                  
                                    },
                                    error => {
                                      //console.log(error);
                                    }
                                  );
                              }
                            }                                  
                          },
                          error => {
                            //console.log(error);
                          }
                        );
                    }
                  },
                  error => {
                    //console.log(error);
                    Swal.fire({
                      title:'El servicio solicitado no se encuentra disponible',
                      icon: 'error',
                    });
                  }
                );
            }
            else{
                //console.log("Autenticación inválida: "+data.tx_Mensaje);
            }
          }
        },
        error => {
          //console.log(error);
        }   
      );
  }
}
