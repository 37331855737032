import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { Component, HostListener, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatRadioChange } from '@angular/material/radio';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Archivo } from 'src/app/@core/models/Archivo';
import { Requisito } from 'src/app/@core/models/Requisito';
import { ArchivoService } from 'src/app/@core/services/ArchivoService';
import { AutenticaService } from 'src/app/@core/services/AutenticaService';
import { RequisitoService } from 'src/app/@core/services/RequisitoService';
import { variablesGlobales } from 'src/app/Util/variableGlobal';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-dialog-adjuntar',
  templateUrl: './dialog-adjuntar.component.html',
  styleUrls: ['./dialog-adjuntar.component.scss']
})
export class DialogAdjuntarComponent implements OnInit {

  FrmAgregarRequisito!: FormGroup;
  Requisito:Requisito[]=[];
  origen!: string;
  codentidad?: number;
  ListaArchivo:Archivo[]=[];
  oFileCurrent: any;
  txtrequisito!: string;
  ObjArchivo?: Archivo;
  isVisibleOpcion: boolean=false;
  isVisibleAgregarArchivo: boolean=false;
  isVisibleArchivoExistente: boolean=false;

  archivo:any={
    codarchivo: 0,
    nomarchivo: null,
    codrequisito: 0,
    requisito: null,
    descripcion: null,
    extension: null,
    file:null,
    trama:null
  }

  Archivo: Archivo[]=[];
  ArchivoQuitar: Archivo[]=[];
  displayedColumns: string[] = ['nU_ID_ARCHIVO','tX_NOMBRE_ARCHIVO','tX_REQUISITO','tX_DESCRIPCION'];
  dataSource = this.Archivo;

  ArchivoTable: Archivo[]=[];
  ArchivoTableDataSource = new MatTableDataSource(this.ArchivoTable);

  @ViewChild('autosize') autosize!: CdkTextareaAutosize;

  constructor(private _formBuilder: FormBuilder,
              private router: Router,
              private objRequisitoService: RequisitoService,
              private ObjAutenticaService: AutenticaService,
              private ObjArchivoService: ArchivoService,
              private dialogRef: MatDialogRef<DialogAdjuntarComponent>,
              @Inject(MAT_DIALOG_DATA) data: any,
              ) {
                this.origen=data.origen;
                this.ListaArchivo=data.listaArchivo;

                if(this.origen=="FrmRegistrarIntegrante" || this.origen=="FrmRegistrarAsociado"){
                  this.codentidad=data.codentidad;
                }
              }

  ngOnInit(): void {
    this.FrmAgregarRequisito=this._formBuilder.group({
      codopcion: [null, Validators.required],
      codrequisito: [null, Validators.required],
      txtdescripcion: [null, Validators.required],
      archivo:[null]
    });

    if(this.origen=="FrmRegistrarIntegrante" || this.origen=="FrmRegistrarAsociado"){
      this.isVisibleOpcion=true;
    }
    else{
      this.isVisibleAgregarArchivo=true;
    }

    this.fnListarRequisito();
  }

  @HostListener('window:keyup.esc') onKeyUp() {
    this.Close();
  }

  fnListarRequisito(){
    this.ObjAutenticaService.ValidarApp()
      .subscribe(
        data => { 
          if(data.isSuccess){
            if(data.tx_token.length>0){
              variablesGlobales.token=data.tx_token;

              this.objRequisitoService.Listar().pipe()    
                .subscribe(
                  data => {
                    if(data.isSuccess){
                      this.Requisito=data.data;

                      /*if(this.origen=="FrmRegistrarEntidad" || 
                        this.origen=="FrmRegistrarIntegrante" || 
                        this.origen=="FrmRegistrarDirectivo"){
                          this.Requisito=this.Requisito.filter(m => m.nU_ID_REQUISITO != 2);
                      }*/
                    }                                  
                  },
                  error => {
                    //console.log(error);
                    Swal.fire({
                      title:'El servicio solicitado no se encuentra disponible',
                      icon: 'error',
                    });
                  }
                );
            }
            else{
                //console.log("Autenticación inválida: "+data.tx_Mensaje);
            }
          }
        },
        error => {
          //console.log(error);
        }   
      );
  }

  fnHabilitarCampos(opc: string){
    if(opc=="1"){
      this.isVisibleAgregarArchivo=true;
      this.isVisibleArchivoExistente=false;
      this.ObjArchivo=undefined;
      this.Archivo=[];
      this.ArchivoTableDataSource.data=this.Archivo;
    }
    else{
      this.isVisibleAgregarArchivo=false;
      this.isVisibleArchivoExistente=true;
      this.FrmAgregarRequisito.controls["codrequisito"].setValue(null);
      this.FrmAgregarRequisito.controls["txtdescripcion"].setValue(null);
      this.FrmAgregarRequisito.controls["archivo"].setValue(null);
      this.oFileCurrent=null;

      this.fnListarArchivo();
    }
  }

  fnListarArchivo(){
    this.ObjAutenticaService.ValidarApp()
      .subscribe(
        data => { 
          if(data.isSuccess){
            if(data.tx_token.length>0){
              variablesGlobales.token=data.tx_token;

              this.ObjArchivoService.Listar(2,this.codentidad!,0).pipe()
                .subscribe(
                  data => {
                    if (data.isSuccess){
                      this.Archivo=data.listaarchivo;
                      this.ArchivoTableDataSource.data=this.Archivo;
                    }
                    else{
                      console.log(data.tx_mensaje);
                      Swal.fire(
                        'Advertencia',
                        'Atención: Error al procesar',
                        'warning'
                      );
                    } 
                  },
                  error => {
                    console.log(error);
                    Swal.fire(
                      'Error',
                      'El servicio solicitado no se encuentra disponible',
                      'error'
                    );
                  }
                );
            }
            else{
              variablesGlobales.token='';
              variablesGlobales.usuario.codusu=0;
              this.router.navigate(['login']);
            }
          }
        },
        error => {
          console.log(error);
          variablesGlobales.token='';
          variablesGlobales.usuario.codusu=0;
          this.router.navigate(['login']);
        }   
      );
  }

  ngChangeFila(event: any){
    const validExts = new Array(".pdf", ".jpg", ".jpeg", ".png", ".PDF", ".JPG", ".JPEG", ".PNG");
    const oFile = event.target.files[0];
    if(oFile == undefined){
      this.oFileCurrent=null;
      return;
    }
      
    const fileExt = oFile.name.substring(oFile.name.lastIndexOf('.'));
    if (validExts.indexOf(fileExt) < 0) {
      alert("No permitido, Solo se permiten archivos con extensión " + validExts.toString());
      this.FrmAgregarRequisito.controls["archivo"].setValue(null);
      return;
    }
    var converted = oFile.size/(1024*1024);
    if(converted>5){
      alert("El archivo no debe superar los 5 MB");
      this.FrmAgregarRequisito.controls["archivo"].setValue(null);
      return;
    }

    this.oFileCurrent = oFile;
    this.archivo.nomarchivo=this.oFileCurrent.name;
    this.archivo.extension=this.oFileCurrent.name.split('.').pop();
    this.archivo.file=this.oFileCurrent;
    
    this.getBase64(this.oFileCurrent).then(
      (data:any) =>{     
        this.archivo.trama = data.toString().split('base64,').pop();
      } 
    );
  }

  getBase64(file:any) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  alertMsg(msg:string){
    Swal.fire({
      html:msg,
      icon:'info'
    })
  }

  ValidarCampos(){
    if(this.origen=="FrmRegistrarIntegrante" || this.origen=="FrmRegistrarAsociado"){
      if (this.FrmAgregarRequisito.controls["codopcion"].value === null || 
        this.FrmAgregarRequisito.controls["codopcion"].value.length === 0) {
          this.alertMsg('Seleccione opción');
          return false;
      }
    }

    if(this.isVisibleAgregarArchivo){
      if (this.FrmAgregarRequisito.controls["codrequisito"].value === null || 
        this.FrmAgregarRequisito.controls["codrequisito"].value.length === 0) {
          this.alertMsg('Seleccione Requisito');
          return false;
      }

      if (this.FrmAgregarRequisito.controls["txtdescripcion"].value === null || 
        this.FrmAgregarRequisito.controls["txtdescripcion"].value.length === 0 || 
        /^\s+$/.test(this.FrmAgregarRequisito.controls["txtdescripcion"].value)) {
          this.alertMsg('Ingrese Descripción');
          return false;
      }

      if(this.oFileCurrent==null){
        this.alertMsg('Seleccione archivo');
        return false;
      }
    }

    if(this.isVisibleArchivoExistente && this.ObjArchivo==undefined){
      this.alertMsg('No se ha seleccionado archivo');
      return false;
    }

    return true;
  }

  Agregar(){
    if(this.ValidarCampos()){
      let band=0;

      if(this.isVisibleAgregarArchivo){
        if(this.ListaArchivo.length>0){
          for(let item of this.ListaArchivo){
            if(item.nU_ID_REQUISITO===this.FrmAgregarRequisito.controls["codrequisito"].value || item.tX_NOMBRE === this.archivo.nomarchivo){
              if(item.nU_ID_REQUISITO===this.FrmAgregarRequisito.controls["codrequisito"].value){
                band=1;
              }
              else{
                band=2;
              }
              break;     
            }
          }
        }

        if(band===1){
          Swal.fire({
            html:'El requisito ya existe en la lista',
            icon:'info' 
          })
        }
        else if(band===2){
          Swal.fire({
            html:'El archivo ya existe en la lista',
            icon:'info' 
          })
        }
        else{
          this.archivo.codrequisito=this.FrmAgregarRequisito.controls["codrequisito"].value;
          this.archivo.requisito=this.txtrequisito;
          this.archivo.descripcion=this.FrmAgregarRequisito.controls["txtdescripcion"].value;
          this.dialogRef.close(this.archivo);
        }
      }
      
      if(this.isVisibleArchivoExistente){
        if(this.ListaArchivo.length>0){
          for(let item of this.ListaArchivo){
            if(item.nU_ID_ARCHIVO==this.ObjArchivo?.nU_ID_ARCHIVO){
              band=1;
              break;
            }    
          }
        }

        if(band==1){
          Swal.fire({
            html:'El archivo ya existe en la lista',
            icon:'info' 
          })
        }
        else{
          this.archivo.codarchivo=this.ObjArchivo?.nU_ID_ARCHIVO;
          this.archivo.nomarchivo=this.ObjArchivo?.tX_NOMBRE_ARCHIVO;
          this.archivo.codrequisito=this.ObjArchivo?.nU_ID_REQUISITO;
          this.archivo.requisito=this.ObjArchivo?.tX_REQUISITO;
          this.archivo.descripcion=this.ObjArchivo?.tX_DESCRIPCION;
          this.archivo.extension=this.ObjArchivo?.tX_EXTENSION;
          this.dialogRef.close(this.archivo);
        }
      }
    }
  }

  radioSelected(event:MatRadioChange, obj:Archivo){
    this.ObjArchivo=obj;
  }

  Close() {
    this.dialogRef.close();
  }

  pulsar(event:any) {
    if (event.which === 13 && !event.shiftKey) {
      event.preventDefault();
      return false;
    }
    return true;
  }
}
