<mat-card-title class="Titulo" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>Registro de reclamo</mat-card-title>
<div mat-dialog-content>
    <form [formGroup]="FrmRegistrarReclamo"> 
        <mat-card fxLayout="column">  
            <mat-card-content fxLayout="column">
                <div>
                    <mat-form-field class="Fila0"> 
                        <mat-select style="width: 100px;" placeholder="Ganador" formControlName="cboequipo" required>
                            <mat-option [value]="0">NINGUNO</mat-option>
                            <mat-option *ngFor="let item of Equipo" [value]="item.nU_ID_EQUIPO">
                                {{item.tX_DENOMINACION}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field class="Fila1">
                        <input type="hidden" formControlName="coddocumento">
                        <textarea matInput cdkTextareaAutosize placeholder="Documento" formControlName="txtdocumento" 
                            class="CampoTextArea1" required readonly></textarea>
                    </mat-form-field>
                    <button mat-icon-button (click)="fnBuscarDocumento()">
                        <mat-icon fontSet="material-icons-outlined">find_in_page</mat-icon>
                    </button>
                </div>
                <div>
                    <mat-form-field class="Fila2">
                        <textarea matInput placeholder="Nota u observación" formControlName="txtmotivo" class="CampoTextArea2"
                            autocomplete="off" rows="2" required></textarea>
                    </mat-form-field> 
                </div>
            </mat-card-content> 
        </mat-card>
    </form>
</div>
<div mat-dialog-actions style="text-align: right;">
    <button mat-button class="btnOpcion" (click)="Close()">
        <mat-icon fontSet="material-icons-outlined">cancel</mat-icon>
        <span>Cancelar</span>
    </button>
    <button mat-button class="btnOpcion" (click)="Guardar()">
        <mat-icon fontSet="material-icons-outlined">save</mat-icon>
        <span>Grabar</span>
    </button>
</div>