<mat-card-title class="Titulo" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>{{tx_titulo}}
    <button mat-icon-button class="close-button" [mat-dialog-close]="true">
        <mat-icon class="close-icon" color="warn">close</mat-icon>
    </button>
</mat-card-title>
<div mat-dialog-content>
    <form [formGroup]="FrmGestGolesInfracciones"> 
        <mat-card fxLayout="column">  
            <mat-card-content fxLayout="column">
                <div>
                    <mat-label class="Info">Deportista: {{txtentidad}}</mat-label>
                </div>
                <div style="margin-top: 20px;" *ngIf="isBotonAgregar && permiteOperacion">
                    <mat-form-field class="Fila0">
                        <mat-select style="width: 150px;" placeholder="Tiempo" formControlName="cbotiempo" required>
                            <mat-option value="1">1er</mat-option>
                            <mat-option value="2">2do</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <button mat-button mat-raised-button class="btn" *ngIf="isBotonAgregar && permiteOperacion" (click)="Guardar()">Agregar</button> 
            </mat-card-content> 
            <mat-card-content fxLayout="column">
                <div class="example-container">
                    <table mat-table [dataSource]="DetallePlanillaJuegoTableDataSource">
                        <ng-container matColumnDef="nU_ID">
                            <th mat-header-cell *matHeaderCellDef></th>
                            <td mat-cell *matCellDef="let element">      
                                <button mat-icon-button class="tooltips_cls"
                                    *ngIf="isBotonAgregar && permiteOperacion"
                                    (click)="fnQuitar(2, element.nU_ID)">
                                        <mat-icon fontSet="material-icons-outlined">delete</mat-icon>
                                        <span>Quitar</span>
                                </button>                    
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="nU_CANTIDAD">
                            <th mat-header-cell *matHeaderCellDef>Gol</th>
                            <td mat-cell *matCellDef="let element">{{element.nU_CANTIDAD}}</td>
                        </ng-container>
                        <ng-container matColumnDef="tX_TIEMPO">
                            <th mat-header-cell *matHeaderCellDef>Tiempo</th>
                            <td mat-cell *matCellDef="let element">{{element.tX_TIEMPO}}</td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                </div>
            </mat-card-content>
        </mat-card>
    </form>
</div>