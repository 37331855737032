
<mat-card-title class="Titulo" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>{{titulo}}</mat-card-title>
<div mat-dialog-content>
    <form [formGroup]="FrmAgregarFecha"> 
        <mat-card fxLayout="column">  
            <mat-card-content fxLayout="column">
                <div>
                    <mat-form-field class="Fila0">
                        <input matInput placeholder="Nro. Secuencia" formControlName="txtnumero" autocomplete="off" required
                            #inputNumero (keydown)="onKeyDownOnlyNumber(inputNumero,$event)" (paste)="handlePasteOnlyNumber($event)" [readonly]="isReadOnly">
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field class="Fila1"> 
                        <input matInput [matDatepicker]="FecProgramada" placeholder="Fecha Programada" formControlName="FecProgramada" required [readonly]="isReadOnlyFecha">
                        <mat-datepicker-toggle matSuffix [for]="FecProgramada" [disabled]="isReadOnlyFecha"></mat-datepicker-toggle>
                        <mat-datepicker #FecProgramada></mat-datepicker>
                    </mat-form-field>
                </div>
                <div *ngIf="isVisibleMotivo">
                    <mat-form-field class="Fila2">
                        <textarea matInput placeholder="Motivo u observación" formControlName="txtmotivo" class="CampoTextArea"
                            autocomplete="off" rows="2" required></textarea>
                    </mat-form-field> 
                </div>
            </mat-card-content> 
        </mat-card>
    </form>
</div>
<div mat-dialog-actions style="text-align: right;">
    <button mat-button class="btnOpcion" (click)="Close()">
        <mat-icon fontSet="material-icons-outlined">cancel</mat-icon>
        <span>Cancelar</span>
    </button>
    <button mat-button class="btnOpcion" (click)="Guardar()">
        <mat-icon fontSet="material-icons-outlined">save</mat-icon>
        <span>Grabar</span>
    </button>
</div>