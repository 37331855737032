import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Configuracion } from './config';
import { variablesGlobales } from 'src/app/Util/variableGlobal';
import { Asociado } from '../models/Asociado';

@Injectable({
    providedIn: 'root'
})

export class AsociadoService {
    public url:string = Configuracion.url2 + 'Asociado/';
    
    constructor(private _http: HttpClient) { }

    Listar(opc: number, codigo: number){
        let bodyServicio = {"NU_OPCION_BUSQUEDA":opc,
                            "NU_CODIGO_BUSQUEDA":codigo,
                            "NU_CODIGO_USUARIO":variablesGlobales.usuario.codusu,
                            "TX_IP":"",
                            "TX_NOMBRE_EQUIPO":"",
                            "TX_APLICACION":variablesGlobales.aplicacion}; 

        return this._http.post<any>(this.url + 'Listar', bodyServicio);
    }

    GeneraCarnet(opc: number, codigo: number){
        let bodyServicio = {"NU_OPCION_BUSQUEDA":opc,
                            "NU_CODIGO_BUSQUEDA":codigo,
                            "NU_CODIGO_USUARIO":variablesGlobales.usuario.codusu,
                            "TX_IP":"",
                            "TX_NOMBRE_EQUIPO":"",
                            "TX_APLICACION":variablesGlobales.aplicacion}; 

        return this._http.post<any>(this.url + 'GeneraCarnet', bodyServicio);
    }

    Actualizar(ObjAsociado: Asociado){
        let bodyServicio: any = ObjAsociado;
        bodyServicio["NU_ID_USUARIO"] = variablesGlobales.usuario.codusu;
        bodyServicio["TX_IP"] = "";
        bodyServicio["TX_NOMBRE_EQUIPO"] = "";
        bodyServicio["TX_APLICACION"] = variablesGlobales.aplicacion;

        return this._http.post<any>(this.url + 'Actualizar', bodyServicio);
    }

    Obtener(codigo: number){
        let bodyServicio = {"NU_CODIGO_BUSQUEDA":codigo,
                            "NU_CODIGO_USUARIO":variablesGlobales.usuario.codusu,
                            "TX_IP":"",
                            "TX_NOMBRE_EQUIPO":"",
                            "TX_APLICACION":variablesGlobales.aplicacion}; 

        return this._http.post<any>(this.url + 'Obtener', bodyServicio);
    }
}