import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Entidad } from 'src/app/@core/models/Entidad';
import { AutenticaService } from 'src/app/@core/services/AutenticaService';
import { EntidadService } from 'src/app/@core/services/EntidadService';
import { variablesGlobales } from 'src/app/Util/variableGlobal';
import { map, startWith } from 'rxjs/operators';
import { RegistrarEntidadComponent } from 'src/app/menu/controles/registrar-entidad/registrar-entidad.component';
import { Oficina } from 'src/app/@core/models/Oficina';
import { OficinaService } from 'src/app/@core/services/OficinaService';
import { CargoService } from 'src/app/@core/services/CargoService';
import Swal from 'sweetalert2';
import { Cargo } from 'src/app/@core/models/Cargo';
import { Usuario } from 'src/app/@core/models/Usuario';
import { UsuarioService } from 'src/app/@core/services/UsuarioService';
import { MatRadioChange } from '@angular/material/radio';
import { Console } from 'console';

@Component({
  selector: 'app-registrar-usuario',
  templateUrl: './registrar-usuario.component.html',
  styleUrls: ['./registrar-usuario.component.scss']
})
export class RegistrarUsuarioComponent implements OnInit {

  FrmRegistrarUsuario!: FormGroup;
  codigo:number=0;
  opc!:string;
  Entidad: Entidad[]=[];
  filteredEntidad: any;
  txtTitulo!: string;
  Oficina!: Oficina[];
  Cargo!: Cargo[];
  isVisibleAdministrativo: boolean=false;
  ObjUsuario!: Usuario;
  estado: number=0;

  @ViewChild('autosize') autosize!: CdkTextareaAutosize;

  constructor(private _formBuilder: FormBuilder,
              private ObjAutenticaService: AutenticaService,
              private ObjEntidadService: EntidadService,
              private ObjOficinaService: OficinaService,
              private ObjCargoService: CargoService,
              private ObjUsuarioService: UsuarioService,
              public dialog: MatDialog,
              private dialogRef: MatDialogRef<RegistrarUsuarioComponent>,
              @Inject(MAT_DIALOG_DATA) data: any,) { 
                this.codigo=data.codUsuario;
                this.opc=data.op;
              }

  ngOnInit(): void {
    this.FrmRegistrarUsuario=this._formBuilder.group({
      codentidad:[null, Validators.compose([Validators.required])],
      txtemail:[null],
      rdoAdministrativo:[null, Validators.compose([Validators.required])],
      codoficina:[null, Validators.compose([Validators.required])],
      codcargo:[null, Validators.compose([Validators.required])]
    });

    if(this.opc==='MOD') this.Obtener();
    else{
      this.txtTitulo="Registrar Usuario";
    }

    this.fnListarOficina();
    this.fnListarCargo();
  }

  fnMostrarCampos(event: MatRadioChange){
    if(event.value=="1"){ 
      this.isVisibleAdministrativo=true;
    }
    else{
      this.isVisibleAdministrativo=false;
      this.FrmRegistrarUsuario.controls["codoficina"].setValue(null);
      this.FrmRegistrarUsuario.controls["codcargo"].setValue(null);
    }
  }

  Obtener(){ 
    this.ObjAutenticaService.ValidarApp()
      .subscribe(
        data => { 
          if(data.isSuccess){
            if(data.tx_token.length>0){
              variablesGlobales.token=data.tx_token;

              this.ObjUsuarioService.ObtenerId(this.codigo).pipe()
                .subscribe(
                  data => {
                    if (data.isSuccess){
                      this.ObjUsuario=data.data;
                      console.log(data.data);
                      this.txtTitulo="Editar Usuario";
                      this.fnObtenerEntidad(this.ObjUsuario.nU_ID_ENTIDAD!);
                      //this.FrmRegistrarUsuario.controls["txtemail"].setValue(this.ObjUsuario.tX_LOGIN);
                      this.estado=this.ObjUsuario.nU_ID_ESTADO!;

                      if(data.listaUOC.length>0){
                        this.FrmRegistrarUsuario.controls["rdoAdministrativo"].setValue("1");
                        this.isVisibleAdministrativo=true;
                        this.FrmRegistrarUsuario.controls["codoficina"].setValue(data.listaUOC[0].nU_ID_OFICINA);
                        this.FrmRegistrarUsuario.controls["codcargo"].setValue(data.listaUOC[0].nU_ID_CARGO);
                      }
                      else{
                        this.FrmRegistrarUsuario.controls["rdoAdministrativo"].setValue("0");
                      }
                    }
                  },
                  error => {
                    //console.log(error);
                    Swal.fire({
                      title:'El servicio solicitado no se encuentra disponible',
                      icon: 'error',
                    });
                  }
                );  
            }
            else{
                //console.log("Autenticación inválida: "+data.tx_Mensaje);
            }
          }
        },
        error => {
          //console.log(error);
        }   
      );                              
  }

  fnListarOficina(){ 
    this.ObjAutenticaService.ValidarApp()
      .subscribe(
        data => { 
          if(data.isSuccess){
            if(data.tx_token.length>0){
              variablesGlobales.token=data.tx_token;

              this.ObjOficinaService.Listar(1).pipe()    
                .subscribe(
                  data => {
                    if(data.isSuccess){
                      this.Oficina= data.oficina;
                    }                                  
                  },
                  error => {
                    //console.log(error);
                    Swal.fire({
                      title:'El servicio solicitado no se encuentra disponible',
                      icon: 'error',
                    });
                  }
                );
            }
            else{
                //console.log("Autenticación inválida: "+data.tx_Mensaje);
            }
          }
        },
        error => {
          //console.log(error);
        }   
      );
  }

  fnListarCargo(){ 
    this.ObjAutenticaService.ValidarApp()
      .subscribe(
        data => { 
          if(data.isSuccess){
            if(data.tx_token.length>0){
              variablesGlobales.token=data.tx_token;

              this.ObjCargoService.Listar(1).pipe()    
                .subscribe(
                  data => {
                    if(data.isSuccess){
                      this.Cargo= data.cargo;
                    }                                  
                  },
                  error => {
                    //console.log(error);
                    Swal.fire({
                      title:'El servicio solicitado no se encuentra disponible',
                      icon: 'error',
                    });
                  }
                );
            }
            else{
                //console.log("Autenticación inválida: "+data.tx_Mensaje);
            }
          }
        },
        error => {
          //console.log(error);
        }   
      );
  }

  fnObtenerEntidad(identidad:number){
    this.ObjEntidadService.Obtener(identidad,0).pipe()
      .subscribe(data => {
        if (data.isSuccess){
          this.FrmRegistrarUsuario.controls["codentidad"].setValue(data.entidad);
          this.fnSelectEntidad(data.entidad);
        }
      });
  }

  fnListarEntidad(){   
    this.ObjAutenticaService.ValidarApp()
      .subscribe(
        data => { 
          if(data.isSuccess){
            if(data.tx_token.length>0){
              variablesGlobales.token=data.tx_token;

              this.ObjEntidadService.Listar(1).pipe()
                .subscribe(data => {
                  if(data.isSuccess){
                    this.Entidad=data.entidad;
                    this.filteredEntidad=this.FrmRegistrarUsuario.controls["codentidad"].valueChanges
                        .pipe(
                          startWith(''),
                          map(value => typeof value === 'string' ? value : value.tX_NUMERO+' | '+value.tX_NOMBRE+' '+value.tX_APELLIDO_PATERNO+' '+value.tX_APELLIDO_MATERNO),            
                          map(nombre => nombre ? this._filterEntidad(nombre) : this.Entidad.slice()
                        )
                    );
                  }
                });
            }
            else{
                //console.log("Autenticación inválida: "+data.tx_Mensaje);
            }
          }
        },
        error => {
          //console.log(error);
        }   
      ); 
  }

  displayFnEntidad(entidad?: Entidad): string{
    return entidad ? entidad.tX_NUMERO+' | '+entidad.tX_NOMBRE+' '+entidad.tX_APELLIDO_PATERNO+' '+entidad.tX_APELLIDO_MATERNO: "";
  }

  private _filterEntidad(nombre: string): Entidad[] {
    const filterValue = nombre.toLowerCase();
    return this.Entidad.filter(option => (option.tX_NUMERO+' | '+
                            option.tX_NOMBRE!.toLowerCase()+' '+
                            option.tX_APELLIDO_PATERNO!.toLowerCase()+' '+
                            option.tX_APELLIDO_MATERNO!.toLowerCase()).includes(filterValue));
  }

  fnRegistrarEntidad(opc:string,identidad:number){
    const dialogRef = this.dialog.open(RegistrarEntidadComponent, {
      data: {
        codEntidad:identidad,
        op: opc
      },
      disableClose: true 
    });
  }

  fnLimpiar(){
    if(this.FrmRegistrarUsuario.controls["codentidad"].value===null ||
       this.FrmRegistrarUsuario.controls["codentidad"].value.nU_ID_ENTIDAD===undefined){
        this.FrmRegistrarUsuario.controls["txtemail"].setValue(null);
        this.FrmRegistrarUsuario.controls["rdoAdministrativo"].setValue(null);
        this.isVisibleAdministrativo=false;
        this.FrmRegistrarUsuario.controls["codoficina"].setValue(null);
        this.FrmRegistrarUsuario.controls["codcargo"].setValue(null);
    }
  }

  fnSelectEntidad(obj:Entidad){
    this.FrmRegistrarUsuario.controls["txtemail"].setValue(obj.tX_CORREO);
  }

  alertMsg(msg:string){
    Swal.fire({
      html:msg,
      icon:'info'
    })
  }

  ValidarCampos(){
    var band=0;

    if(this.FrmRegistrarUsuario.controls["codentidad"].value!==null &&
      this.FrmRegistrarUsuario.controls["codentidad"].value.nU_ID_ENTIDAD!==undefined){
      band=1;
    }
    if(band===0){
      this.alertMsg('Seleccione Persona');
      return false;
    }

    if (this.FrmRegistrarUsuario.controls["txtemail"].value === null || 
      this.FrmRegistrarUsuario.controls["txtemail"].value.length === 0 || 
      /^\s+$/.test(this.FrmRegistrarUsuario.controls["txtemail"].value)) {
        this.alertMsg('Ingrese Correo Electrónico');
        return false;
    }

    if (this.FrmRegistrarUsuario.controls["rdoAdministrativo"].value === null) {
      this.alertMsg('Seleccione Opción');
      return false;
    }

    if(this.isVisibleAdministrativo){
      if (this.FrmRegistrarUsuario.controls["codoficina"].value === null || 
        this.FrmRegistrarUsuario.controls["codoficina"].value.length === 0) {
          this.alertMsg('Seleccione Oficina');
          return false;
      }

      if (this.FrmRegistrarUsuario.controls["codcargo"].value === null || 
        this.FrmRegistrarUsuario.controls["codcargo"].value.length === 0) {
          this.alertMsg('Seleccione Cargo');
          return false;
      }
    }
    
    return true;
  }

  Guardar(){
    if(this.ValidarCampos()){
      this.ObjUsuario={nU_ID_USUARIO:0};
      this.ObjUsuario.nU_ID_USUARIO=this.codigo;
      this.ObjUsuario.nU_ID_ENTIDAD=this.FrmRegistrarUsuario.controls["codentidad"].value.nU_ID_ENTIDAD;
      this.ObjUsuario.tX_LOGIN=this.FrmRegistrarUsuario.controls["txtemail"].value.trim();
      this.ObjUsuario.nU_ID_ESTADO=this.estado;

      if(this.isVisibleAdministrativo){
        this.ObjUsuario.nU_ID_OFICINA=this.FrmRegistrarUsuario.controls["codoficina"].value;
        this.ObjUsuario.nU_ID_CARGO=this.FrmRegistrarUsuario.controls["codcargo"].value;
      }

      this.ObjAutenticaService.ValidarApp()
        .subscribe(
          data => { 
            if(data.isSuccess){
              if(data.tx_token.length>0){
                variablesGlobales.token=data.tx_token;

                this.ObjUsuarioService.Actualizar(this.ObjUsuario).pipe()
                  .subscribe(
                    data => {
                      if (data.isSuccess){
                        Swal.fire({
                          title: data.tx_Mensaje,
                          icon: 'success'}); 
                        
                        this.dialogRef.close(data.isSuccess);
                      }
                      else{
                        Swal.fire({
                          title: data.tx_Mensaje,
                          icon: 'warning'}); 
                      } 
                    },
                    error => {
                      //console.log(error);
                      Swal.fire({
                        title:'El servicio solicitado no se encuentra disponible',
                        icon: 'error',
                      });
                    }
                  );
              }
              else{
                  //console.log("Autenticación inválida: "+data.tx_Mensaje);
              }
            }
          },
          error => {
            //console.log(error);
          }   
        );
    }
  }

  Close() {
    this.dialogRef.close();
  }

  pulsar(event:any) {
    if (event.which === 13 && !event.shiftKey) {
      event.preventDefault();
      return false;
    }
    return true;
  }
}
