<mat-card-title class="Titulo" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>Vista Previa de Carnet
    <button mat-icon-button class="close-button" [mat-dialog-close]="true">
        <mat-icon class="close-icon" color="warn">close</mat-icon>
    </button>
</mat-card-title>
<div mat-dialog-content>
    <button mat-mini-fab color="btnStyle" class="tooltips_fab" style="margin-top: 30px; margin-bottom: 20px;" (click)="fnImprimir()">
        <mat-icon>print</mat-icon>
        <span>Imprimir</span>
    </button>
    <div *ngIf="codsistema==1">
        <div #imgCarnet style="position:relative;">
            <img [src]="srcCarnet"> 
            <div style="position:absolute; top:0; left:0;">
                <div [ngClass]="condicion=='ASIMILADO'?'styledivInstitucionAsimiladoEXASADJ':'styledivInstitucionAsociadoEXASADJ'">    
                    <div>{{lblinstitucion}}</div>
                </div> 
            </div>
            <div style="position:absolute; top:0; left:0;">
                <div [ngClass]="condicion=='ASIMILADO'?'styledivEventoAsimiladoEXASADJ':'styledivEventoAsociadoEXASADJ'">    
                    <div>{{lblevento}}</div>
                </div> 
            </div>
            <div style="position:absolute; top:0; left:0;">
                <div class="styledivFotoCapa1EXASADJ">    
                    <div [ngClass]="condicion=='ASIMILADO'?'styledivFotoCapa2AsimiladoEXASADJ':'styledivFotoCapa2AsociadoEXASADJ'">    
                        <img [src]="srcFoto" class="styleFotoEXASADJ"> 
                    </div> 
                </div>
            </div> 
            <!--<div style="position:absolute; top:0; left:0;" *ngIf="condicion=='ASIMILADO'">
                <div class="styledivCondicionEXASADJ">    
                    <div>{{capitalizeFirstLetter(condicion)}}</div>
                </div> 
            </div>-->
            <div style="position:absolute; top:0; left:0;">
                <div class="styledivNombreEXASADJ">     
                    <div [innerHTML]="lblNombre" [ngClass]="condicion=='ASIMILADO'?'styleNombreAsimiladoEXASADJ':'styleNombreAsociadoEXASADJ'"></div>
                    <div class="styleNumeroEXASADJ">DNI Nº : {{lblnumero}}</div>
                </div>
            </div>
            <div style="position:absolute; top:0; left:0;">
                <div [ngClass]="condicion=='ASIMILADO'?'styledivEquipoAsimiladoEXASADJ':'styledivEquipoAsociadoEXASADJ'">    
                    <div>{{lblEquipo}}</div>
                </div> 
            </div>
            <div style="position:absolute; top:0; left:0;">
                <div class="styledivCarnetEXASADJ">    
                    <div>{{lblsigla}}</div>
                </div> 
            </div>
            <div style="position:absolute; top:0; left:0;">
                <div class="styledivQREXASADJ">    
                    <img [src]="srcQR" class="styleQREXASADJ">
                </div>
            </div>
        </div>
        <img #canvas style="display: none;">
    </div>
    <div *ngIf="codsistema==2">
        <div #imgCarnet style="position:relative;">
            <img [src]="srcCarnet"> 
            <div style="position:absolute; top:0; left:0;">
                <div class="styledivCarnetEXACEMBA">    
                    <div>Nº {{lblsigla}}</div>
                </div> 
            </div>
            <div style="position:absolute; top:0; left:0;">   
                <div [ngClass]="condicion=='ASIMILADO'?'styledivFotoCapaAsimiladoEXACEMBA':'styledivFotoCapaAsociadoEXACEMBA'">    
                    <img [src]="srcFoto" class="styleFotoEXACEMBA"> 
                </div> 
            </div> 
            <div style="position:absolute; top:0; left:0;">
                <div class="styledivNombreEXACEMBA"> 
                    <div [innerHTML]="lblApellido" class="styleApellidoEXACEMBA"></div>    
                    <div [innerHTML]="lblNombre" class="styleNombreEXACEMBA"></div>
                    <div class="styleNumeroEXACEMBA">DNI Nº {{lblnumero}}</div>
                    <div class="styleEquipoEXACEMBA">{{lblEquipo}}</div>
                </div>
            </div>
            <div style="position:absolute; top:0; left:0;" *ngIf="condicion=='ASIMILADO'">
                <div class="styledivCondicionEXACEMBA">    
                    <div>{{condicion}}</div>
                </div> 
            </div>
            <div style="position:absolute; top:0; left:0;">
                <div class="styledivQREXACEMBA">    
                    <img [src]="srcQR" class="styleQREXACEMBA">
                </div>
            </div>
        </div>
        <img #canvas style="display: none;">
    </div>
</div>
