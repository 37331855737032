import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Configuracion } from './config';
import { variablesGlobales } from 'src/app/Util/variableGlobal';
import { UsuarioRol } from '../models/UsuarioRol';

@Injectable({
    providedIn: 'root'
})

export class TipoIntegrantePartidoService {
    public url:string = Configuracion.url2 + 'TipoIntegrantePartido/';
    
    constructor(private _http: HttpClient) { }

    Listar(){
        let bodyServicio = {"NU_OPCION_BUSQUEDA":0}; 
        return this._http.post<any>(this.url + 'Listar', bodyServicio);
    }
}