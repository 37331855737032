import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { Component, HostListener, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { map, startWith } from 'rxjs/operators';
import { EquipoPartido } from 'src/app/@core/models/EquipoPartido';
import { Integrante } from 'src/app/@core/models/Integrante';
import { PlanillaJuego } from 'src/app/@core/models/PlanillaJuego';
import { TipoIntegrantePartido } from 'src/app/@core/models/TipoIntegrantePartido';
import { AutenticaService } from 'src/app/@core/services/AutenticaService';
import { IntegranteService } from 'src/app/@core/services/IntegranteService';
import { PlanillaJuegoService } from 'src/app/@core/services/PlanillaJuegoService';
import { TipoIntegrantePartidoService } from 'src/app/@core/services/TipoIntegrantePartidoService';
import { variablesGlobales } from 'src/app/Util/variableGlobal';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-dialog-agregar-planillajuego',
  templateUrl: './dialog-agregar-planillajuego.component.html',
  styleUrls: ['./dialog-agregar-planillajuego.component.scss']
})
export class DialogAgregarPlanillajuegoComponent implements OnInit {

  FrmAgregarPlanillaJuego!: FormGroup;
  coddisciplina:number=0;
  codfecha:number=0;
  codpartido:number=0;
  ObjEquipoPartido!: EquipoPartido;
  codplanillajuego:number=0;
  titulo!: string;
  Integrante: Integrante[]=[];
  tipoList:TipoIntegrantePartido[]=[];
  filteredIntegrante: any;
  ObjPlanillaJuego!:PlanillaJuego;
  estado: number=1;
  isVisibleSaliente: boolean=false;
  isVisibleCamiseta: boolean=false;
  isVisibleCapitan: boolean=false;

  @ViewChild('autosize') autosize!: CdkTextareaAutosize;

  constructor(private _formBuilder: FormBuilder,
              private ObjAutenticaService: AutenticaService,
              private router: Router,
              private ObjIntegranteService: IntegranteService,
              private ObjPlanillaJuegoService: PlanillaJuegoService,
              private ObjTipoIntegrantePartidoService: TipoIntegrantePartidoService,
              private dialogRef: MatDialogRef<DialogAgregarPlanillajuegoComponent>,
              @Inject(MAT_DIALOG_DATA) data: any) {
                this.coddisciplina=data.coddisciplina;
                this.codfecha=data.codfecha;
                this.codpartido=data.codpartido;
                this.ObjEquipoPartido=data.objEquipoPartido;
                this.codplanillajuego=data.codplanillajuego;
              }

  ngOnInit(): void {
    this.FrmAgregarPlanillaJuego=this._formBuilder.group({
      codintegrante:[null,Validators.required],
      txtnrocarnet:[null,Validators.required],
      txtnrocamiseta:[null,Validators.required],
      cbocapitan:["0",Validators.required],
      cbotipo:[null,Validators.required],
      codintegrante_saliente:[null,Validators.required],
      txttiempo:[null,Validators.required]
    });
    
    this.fnListaTipo();
    this.FrmAgregarPlanillaJuego.controls["cbotipo"].setValue(0);
    
    this.isVisibleCamiseta=true;
    this.titulo="Registrar deportista del Equipo: "+this.ObjEquipoPartido.tX_DENOMINACION;
  }

  

  @HostListener('window:keyup.esc') onKeyUp() {
    this.Close();
  }

  fnListaTipo(){
    // console.log("x");
    this.ObjAutenticaService.ValidarApp()
        .subscribe(
          data => { 
            if(data.isSuccess){
              if(data.tx_token.length>0){
                variablesGlobales.token=data.tx_token;
  
                this.ObjTipoIntegrantePartidoService.Listar().pipe()
                  .subscribe(data => {
                    // console.log(data);
                    if(data.isSuccess){
                      this.tipoList= data.tipoIntegrantePartido;
                      }                    
                  });
              }
              else{
                  variablesGlobales.token='';
                  variablesGlobales.usuario.codusu=0;
                  this.router.navigate(['login']);
              }
            }
          },
          error => {
            console.log(error);
            variablesGlobales.token='';
            variablesGlobales.usuario.codusu=0;
            this.router.navigate(['login']);
          }   
        );
  }

  fnListarIntegrante(opc: number){
    this.ObjAutenticaService.ValidarApp()
      .subscribe(
        data => { 
          if(data.isSuccess){
            if(data.tx_token.length>0){
              variablesGlobales.token=data.tx_token;

              this.ObjIntegranteService.Listar(3,this.ObjEquipoPartido.nU_ID_EQUIPO!).pipe()
                .subscribe(data => {
                  //console.log(data);
                  if(data.isSuccess){
                    this.Integrante= data.integrante;

                    if(opc==1){
                      this.filteredIntegrante=this.FrmAgregarPlanillaJuego.controls["codintegrante"].valueChanges
                        .pipe(
                          startWith(''),
                          map(value => typeof value === 'string' ? value : 
                                                                  ((value.tX_CARNET!=null && value.tX_CARNET!='')?
                                                                  value.tX_NUMERO+' | '+value.tX_CARNET.padStart(5,"0")+' | '+value.tX_NOMBRE_COMPLETO:
                                                                  value.tX_NUMERO+' | '+value.tX_NOMBRE_COMPLETO)),            
                          map(nombre => nombre ? this._filterIntegrante(nombre) : this.Integrante.slice())
                        );
                    }
                    else{
                      this.filteredIntegrante=this.FrmAgregarPlanillaJuego.controls["codintegrante_saliente"].valueChanges
                        .pipe(
                          startWith(''),
                          map(value => typeof value === 'string' ? value : 
                                                                  ((value.tX_CARNET!=null && value.tX_CARNET!='')?
                                                                  value.tX_NUMERO+' | '+value.tX_CARNET.padStart(5,"0")+' | '+value.tX_NOMBRE_COMPLETO:
                                                                  value.tX_NUMERO+' | '+value.tX_NOMBRE_COMPLETO)),            
                          map(nombre => nombre ? this._filterIntegrante(nombre) : this.Integrante.slice())
                        );
                    }
                  }
                });
            }
            else{
                variablesGlobales.token='';
                variablesGlobales.usuario.codusu=0;
                this.router.navigate(['login']);
            }
          }
        },
        error => {
          console.log(error);
          variablesGlobales.token='';
          variablesGlobales.usuario.codusu=0;
          this.router.navigate(['login']);
        }   
      );
  }

  displayFnIntegrante(integrante?: Integrante): string{
    return integrante ? ((integrante.tX_CARNET!=null && integrante.tX_CARNET!='')?
                          integrante.tX_NUMERO+' | '+integrante.tX_CARNET+' | '+integrante.tX_NOMBRE_COMPLETO:
                          integrante.tX_NUMERO+' | '+integrante.tX_NOMBRE_COMPLETO)
                      : "";
  }

  private _filterIntegrante(nombre: string): Integrante[] {
    const filterValue = nombre.toLowerCase();
    return this.Integrante.filter(option => ((option.tX_CARNET!=null && option.tX_CARNET!='')?
                                                option.tX_NUMERO+' | '+option.tX_CARNET+' | '+option.tX_NOMBRE_COMPLETO!.toLowerCase():
                                                option.tX_NUMERO+' | '+option.tX_NOMBRE_COMPLETO!.toLowerCase()
                                            ).includes(filterValue));
  }

  fnHabilitarSaliente(tipo: number){
    // console.log(tipo);
    
    switch(tipo){
      case 0:
      case 3:
        this.isVisibleCamiseta=true;
        this.isVisibleCapitan=true;
        this.isVisibleSaliente=false;
        this.FrmAgregarPlanillaJuego.controls["codintegrante_saliente"].setValue("");
        this.FrmAgregarPlanillaJuego.controls["txttiempo"].setValue(null);
        break;
      case 1:
        this.isVisibleCamiseta=true;
        this.isVisibleCapitan=true;
        this.isVisibleSaliente=true;
        // console.log(9999);
        break;
      case 2:
        this.isVisibleCamiseta=false;
        this.isVisibleCapitan=false;
        this.isVisibleSaliente=false;
        this.FrmAgregarPlanillaJuego.controls["txtnrocamiseta"].setValue(null);
        this.FrmAgregarPlanillaJuego.controls["cbocapitan"].setValue("0");
        this.FrmAgregarPlanillaJuego.controls["codintegrante_saliente"].setValue("");
        this.FrmAgregarPlanillaJuego.controls["txttiempo"].setValue(null);
        break;
    }

  }

  fnLimpiar(){
    if(this.FrmAgregarPlanillaJuego.controls["codintegrante"].value==null ||
       this.FrmAgregarPlanillaJuego.controls["codintegrante"].value.nU_ID_INTEGRANTE==undefined){
        this.FrmAgregarPlanillaJuego.controls["txtnrocarnet"].setValue(null); 
    }
  }

  fnObtenerCarnet(obj: Integrante){
    if(obj.tX_CARNET!=null && obj.tX_CARNET!=""){
      this.FrmAgregarPlanillaJuego.controls["txtnrocarnet"].setValue(obj.tX_CARNET); 
    }
  }

  onKeyDownOnlyNumber (input: any, event:KeyboardEvent) {
    var specialKeys: string[] = [
      'Backspace',
      'Tab',
      'End',
      'Home',
      'ArrowLeft',
      'ArrowRight',
      'Del',
      'Delete',
    ];

    if (specialKeys.indexOf(event.key) !== -1 ||
        (event.key === 'v' && event.ctrlKey === true) ||
        (event.key === 'v' && event.metaKey === true)) {
        return;
    }

    var current: string = input.value;
    var regex=new RegExp(/^\d+$/g);
    var next = current.concat(event.key);

    if (next && !String(next).match(regex)) {
      event.preventDefault();
    }
  }

  handlePasteOnlyNumber(event:ClipboardEvent) {
    var input=event.clipboardData?.getData('text/plain');
    var regex=new RegExp(/^\d+$/g);
  
    if (regex.test(input!)) {
      return true;
    }
    return false;
  }

  Guardar(){
    if(this.ValidarCampos()){
      this.ObjPlanillaJuego={nU_ID_PLANILLAJUEGO: this.codplanillajuego};
      this.ObjPlanillaJuego.nU_ID_EVENTO=variablesGlobales.codevento;
      this.ObjPlanillaJuego.nU_ID_DISCIPLINA=this.coddisciplina;
      this.ObjPlanillaJuego.nU_ID_FECHA=this.codfecha;
      this.ObjPlanillaJuego.nU_ID_PARTIDO=this.codpartido;
      this.ObjPlanillaJuego.nU_ID_EQUIPOPARTIDO=this.ObjEquipoPartido.nU_ID_EQUIPOPARTIDO;
      this.ObjPlanillaJuego.nU_ID_EQUIPO=this.ObjEquipoPartido.nU_ID_EQUIPO;
      this.ObjPlanillaJuego.nU_ID_INTEGRANTE=this.FrmAgregarPlanillaJuego.controls["codintegrante"].value.nU_ID_INTEGRANTE;
      this.ObjPlanillaJuego.nU_ID_TIPO=parseInt(this.FrmAgregarPlanillaJuego.controls["cbotipo"].value);
      this.ObjPlanillaJuego.nU_CARNET=parseInt(this.FrmAgregarPlanillaJuego.controls["txtnrocarnet"].value);
      this.ObjPlanillaJuego.nU_CAPITAN=parseInt(this.FrmAgregarPlanillaJuego.controls["cbocapitan"].value);
      this.ObjPlanillaJuego.tX_OBSERVACION="";
      this.ObjPlanillaJuego.nU_ID_ESTADO=this.estado;

      if(this.isVisibleCamiseta){
        this.ObjPlanillaJuego.nU_CAMISETA=parseInt(this.FrmAgregarPlanillaJuego.controls["txtnrocamiseta"].value);
      }

      if(this.isVisibleSaliente){
        this.ObjPlanillaJuego.nU_ID_INTEGRANTE_SALIENTE=this.FrmAgregarPlanillaJuego.controls["codintegrante_saliente"].value.nU_ID_INTEGRANTE;
        this.ObjPlanillaJuego.nU_TIEMPO_CAMBIO=parseInt(this.FrmAgregarPlanillaJuego.controls["txttiempo"].value);
      }

      this.ObjAutenticaService.ValidarApp()
        .subscribe(
          data => { 
            if(data.isSuccess){
              if(data.tx_token.length>0){
                variablesGlobales.token=data.tx_token;

                this.ObjPlanillaJuegoService.Actualizar(this.ObjPlanillaJuego).pipe()
                  .subscribe(
                    data => {
                      if (data.isSuccess){
                        if (data.tx_Mensaje.substring(1,0)=="1"){
                          Swal.fire({
                            title: data.tx_Mensaje,
                            icon: 'success'}); 
                            this.dialogRef.close(data.isSuccess);
                        }
                        else
                        {
                          Swal.fire(
                            'Advertencia',
                            'Atención: '+data.tx_Mensaje.substring(2),
                            'warning'
                          );
                        } 
                      }
                      else{
                        console.log(data.tx_mensaje);
                        Swal.fire(
                          'Advertencia',
                          'Atención: Error al procesar',
                          'warning'
                        );
                      } 
                    },
                    error => {
                      console.log(error);
                      Swal.fire(
                        'Error',
                        'El servicio solicitado no se encuentra disponible',
                        'error'
                      );
                    }
                  );
              }
              else{
                variablesGlobales.token='';
                variablesGlobales.usuario.codusu=0;
                this.router.navigate(['login']);
              }
            }
          },
          error => {
            console.log(error);
            variablesGlobales.token='';
            variablesGlobales.usuario.codusu=0;
            this.router.navigate(['login']);
          }   
        );
    }
  }

  ValidarCampos(){
    if(this.FrmAgregarPlanillaJuego.controls["codintegrante"].value==null ||
      this.FrmAgregarPlanillaJuego.controls["codintegrante"].value.nU_ID_INTEGRANTE==undefined){
        this.alertMsg('Seleccione Deportista');
        return false;
    }

    if (this.FrmAgregarPlanillaJuego.controls["txtnrocarnet"].value == null || 
      this.FrmAgregarPlanillaJuego.controls["txtnrocarnet"].value.length == 0 || 
      /^\s+$/.test(this.FrmAgregarPlanillaJuego.controls["txtnrocarnet"].value)) {
        this.alertMsg('Ingrese Nro. Carnet');
        return false;
    }

    if(this.isVisibleCamiseta){
      if (this.FrmAgregarPlanillaJuego.controls["txtnrocamiseta"].value == null || 
        this.FrmAgregarPlanillaJuego.controls["txtnrocamiseta"].value.length == 0 || 
        /^\s+$/.test(this.FrmAgregarPlanillaJuego.controls["txtnrocamiseta"].value)) {
          this.alertMsg('Ingrese Nro. Camiseta');
          return false;
      }
    }

    if (this.FrmAgregarPlanillaJuego.controls["cbotipo"].value == null || 
      this.FrmAgregarPlanillaJuego.controls["cbotipo"].value.length == 0) {
        this.alertMsg('Seleccione En calidad');
        return false;
    }

    if(this.isVisibleSaliente){
      if(this.FrmAgregarPlanillaJuego.controls["codintegrante_saliente"].value==null ||
        this.FrmAgregarPlanillaJuego.controls["codintegrante_saliente"].value.nU_ID_INTEGRANTE==undefined){
          this.alertMsg('Seleccione Saliente');
          return false;
      }
      else{
        if(this.FrmAgregarPlanillaJuego.controls["codintegrante"].value.nU_ID_INTEGRANTE==
          this.FrmAgregarPlanillaJuego.controls["codintegrante_saliente"].value.nU_ID_INTEGRANTE){
            this.alertMsg('Deportista no puede ser igual a Saliente');
            return false;
        }
      }

      if (this.FrmAgregarPlanillaJuego.controls["txttiempo"].value == null || 
        this.FrmAgregarPlanillaJuego.controls["txttiempo"].value.length == 0 || 
        /^\s+$/.test(this.FrmAgregarPlanillaJuego.controls["txttiempo"].value)) {
          this.alertMsg('Ingrese Tiempo transcurrido del juego');
          return false;
      }
    }

    return true;
  }

  alertMsg(msg:string){
    Swal.fire({
      html:msg,
      icon:'info'
    })
  }

  pulsar(event:any) {
    if (event.which === 13 && !event.shiftKey) {
      event.preventDefault();
      return false;
    }
    return true;
  }

  Close() {
    this.dialogRef.close();
  }

}
