<mat-card-title class="Titulo" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>Vista Previa de Carnet
    <button mat-icon-button class="close-button" [mat-dialog-close]="true">
        <mat-icon class="close-icon" color="warn">close</mat-icon>
    </button>
</mat-card-title>
<div mat-dialog-content>
    <button mat-mini-fab color="btnStyle" class="tooltips_fab" style="margin-top: 30px; margin-bottom: 20px;" (click)="fnImprimir()">
        <mat-icon>print</mat-icon>
        <span>Imprimir</span>
    </button>
    <div #imgCarnet style="position:relative;">
        <img [src]="srcCarnet"> 
        <div style="position:absolute; top:0; left:0;">
            <div class="styledivEvento">    
                <div [ngClass]="condicion=='ASOCIADO'?'styleEventoAsociado':'styleEventoAsimilado'">{{lblevento}}</div>
            </div> 
        </div>
        <div style="position:absolute; top:0; left:0;">
            <div class="styledivSigla">    
                <div class="styleSigla">{{lblsigla}}</div>
            </div> 
        </div>
        <div style="position:absolute; top:0; left:0;">
            <img [src]="srcFoto" [ngClass]="condicion=='ASOCIADO'?'styleFotoAsociado':'styleFotoAsimilado'"> 
        </div> 
        <div style="position:absolute; top:0; left:0;">
            <div class="styledivNombre">     
                <div [innerHTML]="lblNombre" class="styleNombre"></div>
                <div class="styleNumero">{{lblnumero}}</div>
            </div>
        </div>
        <div style="position:absolute; top:0; left:0;">
            <div class="styledivPromo">    
                <div>
                    <div class="styleCargo">{{lblCargo}}</div><br>
                    <div class="styleDisciplina">{{lblDisciplina}}</div><br>
                    <!--<div class="stylePromo">PROMO</div><br>--> 
                    <div class="styleAnio">{{lblEquipo}}</div><br>
                    <div class="styleEmision">{{lblEmision}}</div>
                </div>
            </div>
        </div>
        <div style="position:absolute; top:0; left:0;">
            <div class="styledivQR"> 
                <!--<div class="cropped">
                    <img [src]="srcQR" class="styleQR"> 
                </div>-->    
                <img [src]="srcQR" class="styleQR"> 
            </div>
        </div>
    </div>
    <img #canvas style="display: none;">
</div>
