import { ComponentFactoryResolver, Injectable, Injector } from "@angular/core";

@Injectable({
    providedIn: 'root'
})

export class RenderService {
    constructor(
        private resolver: ComponentFactoryResolver,
        private injector: Injector
    ) { }

    create(component: any, data?: any) {
        const factory = this.resolver.resolveComponentFactory(component);
        const componentRef:any = factory.create(this.injector);
        if (data) {
            for (const key in data) {
                componentRef.instance[key] = data[key];
            }
        }
        
        componentRef.changeDetectorRef.detectChanges();
        // Obtenemos el html
        return componentRef.location.nativeElement.innerHTML;
    }
}