
<div fxLayout="column" fxLayoutAlign="center">
    <form [formGroup]="FrmGestUsuario">
        <div class="opcion">
            <mat-label class="Titulo">Gestiona Usuario</mat-label>
        </div>
        <mat-card fxLayout="column">
            <mat-card-content fxLayout="column" style="margin-top: 18px;margin-bottom: 20px;">
                <mat-card-content fxLayout="row">
                    <fieldset class="borderFieldset">
                        <mat-form-field class="fila1"> 
                            <input matInput placeholder="Ingresar dato" formControlName="txtFiltro">
                        </mat-form-field>
                    </fieldset>            
                </mat-card-content>
                <button mat-mini-fab color="btnStyle" class="tooltips_fab"(click)="LlenaGrilla()">
                    <mat-icon>search</mat-icon>
                    <span>Buscar</span>
                </button>
                <button mat-mini-fab color="btnStyle" class="tooltips_fab" style="margin-left: 18px;" (click)="fnNuevo('NUE',0)">
                    <mat-icon>add</mat-icon>
                    <span>Añadir</span>
                </button>
            </mat-card-content>
            <mat-card-content fxLayout="column">
                <h3 class="SubTitulo">Lista de Usuarios</h3>
                <div class="example-container">
                    <table mat-table [dataSource]="UsuarioTableDataSource">
                        <ng-container matColumnDef="nU_ID_USUARIO">
                            <th mat-header-cell *matHeaderCellDef class="priority-1"></th>
                            <td mat-cell *matCellDef="let element" class="priority-1">
                                <button mat-icon-button class="tooltips_cls" [matMenuTriggerFor]="menu">
                                    <mat-icon>menu</mat-icon>
                                </button>
                                <mat-menu #menu="matMenu">
                                    <button mat-menu-item (click)="fnNuevo('MOD',element.nU_ID_USUARIO)">
                                        <mat-icon fontSet="material-icons-outlined">edit</mat-icon>
                                        <span>Editar</span>
                                    </button>
                                    <button mat-menu-item (click)="fnResetearClave(element)">
                                        <mat-icon fontSet="material-icons-outlined">lock_reset</mat-icon>
                                        <span>Resetear Clave</span>
                                    </button>
                                    <button mat-menu-item (click)="fnAsignarRol(element.nU_ID_USUARIO)">
                                        <mat-icon fontSet="material-icons-outlined">manage_accounts</mat-icon>
                                        <span>Asignar Rol</span>
                                    </button>
                                    <button mat-menu-item *ngIf="element.nU_ID_ESTADO===2" (click)="fnCambiarEstado(element,3)">
                                        <mat-icon fontSet="material-icons-outlined">check_circle_outline</mat-icon>
                                        <span>Activar</span>
                                    </button>
                                    <button mat-menu-item *ngIf="element.nU_ID_ESTADO===1 || element.nU_ID_ESTADO===3" (click)="fnCambiarEstado(element,2)">
                                        <mat-icon fontSet="material-icons-outlined">cancel</mat-icon>
                                        <span>Anular</span>
                                    </button>
                                </mat-menu>                            
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="tX_NUMERO">
                            <th mat-header-cell *matHeaderCellDef class="priority-2">DNI</th>
                            <td mat-cell *matCellDef="let element" class="priority-2">{{element.tX_NUMERO}}</td>
                        </ng-container>
                        <ng-container matColumnDef="tX_NOMBRE_COMPLETO">
                            <th mat-header-cell *matHeaderCellDef class="priority-3">Nombre</th>
                            <td mat-cell *matCellDef="let element" class="priority-3">{{element.tX_NOMBRE_COMPLETO}}</td>
                        </ng-container>
                        <ng-container matColumnDef="tX_LOGIN">
                            <th mat-header-cell *matHeaderCellDef class="priority-4">Login</th>
                            <td mat-cell *matCellDef="let element" class="priority-4">{{element.tX_LOGIN}}</td>
                        </ng-container>
                        <ng-container matColumnDef="tX_ESTADO">
                            <th mat-header-cell *matHeaderCellDef class="priority-5">Estado</th>
                            <td mat-cell *matCellDef="let element" class="priority-5">{{element.tX_ESTADO}}</td>
                        </ng-container>
                        <ng-container matColumnDef="fE_REGISTRO">
                            <th mat-header-cell *matHeaderCellDef class="priority-6">F. Creación</th>
                            <td mat-cell *matCellDef="let element" class="priority-6">{{convertFecha(element.fE_REGISTRO)}}</td>
                        </ng-container>
                        <ng-container matColumnDef="fE_MODIFICA">
                            <th mat-header-cell *matHeaderCellDef class="priority-7">Última Actualización</th>
                            <td mat-cell *matCellDef="let element" class="priority-7">
                                {{(element.fE_MODIFICA!=='0001-01-01T00:00:00')?convertFecha(element.fE_MODIFICA):''}}
                            </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                </div>  
            </mat-card-content>
        </mat-card> 
    </form>      
</div>
<mat-paginator id="paginadorUsuario" class="stylePaginator" [pageSizeOptions]="[10, 20, 50]" showFirstLastButtons></mat-paginator>