<div fxLayout="column" fxLayoutAlign="center">
    <form [formGroup]="FrmActualizarInfo">
        <mat-card fxLayout="column" style="margin-right: 10px;">
            <div class="opcion">
                <mat-label class="subtitulo">Datos Personales</mat-label>
            </div>
            <mat-card-content fxLayout="column">
                <div>
                    <mat-form-field class="fila1"> 
                        <mat-select class="valueSelect" placeholder="Tipo de Documento de identificación" formControlName="txttipodocumento">
                            <mat-option class="opcionSelect" value="1">DNI - DOCUMENTO NACIONAL DE IDENTIDAD</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field class="fila3">
                        <input matInput placeholder="Número de Documento de Identificación" formControlName="txtnumero" readonly>
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field class="fila3">
                        <textarea matInput cdkTextareaAutosize
                            #autosize="cdkTextareaAutosize" placeholder="Nombres" formControlName="txtnombre" class="CampoTextArea" readonly></textarea>
                    </mat-form-field>
                    <mat-form-field class="fila3">
                        <textarea matInput cdkTextareaAutosize
                            #autosize="cdkTextareaAutosize" placeholder="Apellido Paterno" formControlName="txtape_paterno" class="CampoTextArea" readonly></textarea>
                    </mat-form-field>
                    <mat-form-field class="fila3">
                        <textarea matInput cdkTextareaAutosize
                            #autosize="cdkTextareaAutosize" placeholder="Apellido Materno" formControlName="txtape_materno" class="CampoTextArea" readonly></textarea>
                    </mat-form-field>
                </div>
                <div>   
                    <mat-form-field class="fila4"> 
                        <input matInput [matDatepicker]="FecNacimiento" placeholder="Fecha de Nacimiento" formControlName="FecNacimiento" readonly>
                        <mat-datepicker-toggle matSuffix [for]="FecNacimiento"></mat-datepicker-toggle>
                        <mat-datepicker #FecNacimiento disabled></mat-datepicker>
                    </mat-form-field>
                    <mat-form-field class="fila5">
                        <input matInput placeholder="Año de Egreso" formControlName="txtanio" readonly maxLength="4">
                    </mat-form-field>
                    <mat-form-field class="fila5"> 
                        <mat-select placeholder="Turno" formControlName="turno">
                            <mat-option value="Diurna">Diurna</mat-option>
                            <mat-option value="Nocturna">Nocturna</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field> 
                        <mat-select placeholder="Sexo" formControlName="sexo">
                            <mat-option value="F">Femenino</mat-option>
                            <mat-option value="M">Masculino</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>                  
            </mat-card-content> 
            <div class="opcion">
                <button mat-icon-button class="tooltips_cls" (click)="Guardar()">
                    <mat-icon fontSet="material-icons-outlined">{{isEditable?'edit':'save'}}</mat-icon>
                    <span>{{isEditable?'Editar':'Grabar'}}</span>
                </button>
                <mat-label style="margin-left: 5px;" class="subtitulo">{{isEditable?'Editar Información':'Guardar'}}</mat-label>
                <button *ngIf="!isEditable" mat-icon-button class="tooltips_cls" style="margin-left: 65px;" (click)="Cancelar()">
                    <mat-icon fontSet="material-icons-outlined">cancel</mat-icon>
                    <span>Cancelar</span>
                </button>
                <mat-label *ngIf="!isEditable" style="margin-left: 5px;" class="subtitulo">Cancelar</mat-label>
            </div>
            <mat-card-content fxLayout="column">
                <div>
                    <mat-form-field class="fila3">
                        <input matInput placeholder="Correo Electrónico" formControlName="txtemail" [required]="isRequired" [readonly]="isReadOnly">
                    </mat-form-field>
                    <mat-form-field class="fila3">
                        <input matInput placeholder="Teléfono" formControlName="txttelefono" autocomplete="off" [required]="isRequired" 
                            (keyup)="fnSoloNumeros($event)" [readonly]="isReadOnly">
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field class="fila1"> 
                        <mat-select class="valueSelect" placeholder="Departamento" formControlName="coddepartamento" 
                            (selectionChange)="fnListarProvincia($event.value)" [required]="isRequired">
                            <mat-option class="opcionSelect" *ngFor="let elem of Departamento" [value]="elem.tX_ID_DEPARTAMENTO">
                                {{elem.tX_DEPARTAMENTO}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field class="fila1"> 
                        <mat-select class="valueSelect" placeholder="Provincia" formControlName="codprovincia" (selectionChange)="fnListarDistrito($event.value)"
                            [required]="isRequired">
                            <mat-option class="opcionSelect" *ngFor="let elem of Provincia" [value]="elem.tX_ID_PROVINCIA">
                                {{elem.tX_PROVINCIA}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field class="fila1"> 
                        <mat-select class="valueSelect" placeholder="Distrito" formControlName="coddistrito" [required]="isRequired">
                            <mat-option class="opcionSelect" *ngFor="let elem of Distrito" [value]="elem.tX_ID_DISTRITO">
                                {{elem.tX_DISTRITO}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field class="fila2">
                        <textarea matInput cdkTextareaAutosize
                            #autosize="cdkTextareaAutosize" placeholder="Dirección" formControlName="txtdireccion" class="CampoTextArea"
                            (keydown)="pulsar($event)" [required]="isRequired" [readonly]="isReadOnly"></textarea>
                    </mat-form-field>
                </div>                          
            </mat-card-content> 
        </mat-card>
    </form>
</div> 
