import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Configuracion } from './config';
import { variablesGlobales } from 'src/app/Util/variableGlobal';
import { AsociadoRequisito } from '../models/AsociadoRequisito';

@Injectable({
    providedIn: 'root'
})

export class AsociadoRequisitoService {
    public url:string = Configuracion.url2 + 'AsociadoRequisito/';
    
    constructor(private _http: HttpClient) { }

    Actualizar(variable: AsociadoRequisito){
        variable.nU_ID_USUARIO=variablesGlobales.usuario.codusu;

        return this._http.post<any>(this.url + 'Actualizar', variable);
    }
}