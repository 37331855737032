import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { AutenticaService } from 'src/app/@core/services/AutenticaService';
import { SolicitudPadronService } from 'src/app/@core/services/SolicitudPadronService';
import { variablesGlobales } from 'src/app/Util/variableGlobal';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-marco-legal',
  templateUrl: './marco-legal.component.html',
  styleUrls: ['./marco-legal.component.scss']
})
export class MarcoLegalComponent implements OnInit {

  @Input() token!:string;
  principal:number=1;
  solicitud:number=0;
  chkAcepto=new FormControl();
  chkNoAcepto=new FormControl();
  opc:number=0;

  constructor(private ObjAutenticaService: AutenticaService,
    private ObjSolicitudPadronService: SolicitudPadronService,) { }

  ngOnInit(): void {
  }

  fnMarcarCheck(event:MatCheckboxChange,opc:number){
    if(event.checked){
      switch(opc){
        case 1:
          this.opc=1;
          if(this.chkNoAcepto.value){
            this.chkNoAcepto.setValue(null);
          }
          break;
        case 2:
          this.opc=2;
          if(this.chkAcepto.value){
            this.chkAcepto.setValue(null);
          }
          break;
      }
    }
    else{
      this.opc=0;
    }
  }

  fnSiguiente(){
    if(this.opc==0){
      Swal.fire({
        html: 'Elija una de las opciones',
        icon: 'info'});
    }
    else{
      if(this.opc==1){
        this.principal=0;
        this.solicitud=1;
      }
    }
  }
}
