import { variable } from '@angular/compiler/src/output/output_ast';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';
import html2canvas from 'html2canvas';
import { RptPDFCarnet2023 } from 'src/app/@core/reports/RptPDFCarnet2023';
import { variablesGlobales } from 'src/app/Util/variableGlobal';

@Component({
  selector: 'app-rpt-carnet2023',
  templateUrl: './rpt-carnet2023.component.html',
  styleUrls: ['./rpt-carnet2023.component.scss']
})
export class RptCarnet2023Component implements OnInit {

  data: any = {};
  
  codsistema: number=0;
  elementType = NgxQrcodeElementTypes.URL;
  errorCorrectionLevel = NgxQrcodeErrorCorrectionLevels.HIGH;
  srcCarnet: any;
  srcFoto: any;
  srcQR: any;
  lblinstitucion!: string;
  lblevento!: string;
  lblsigla!: string;
  lblNombre!: string;
  lblApellido!: string;
  lblEquipo!: string;
  condicion!: string;
  lblCargo!: string;
  lblnumero!: string;
  lblEmision!: string;
  lblDisciplina!: string;

  @ViewChild('imgCarnet') imgCarnet!: ElementRef;
  @ViewChild('canvas') canvas!: ElementRef;

  constructor(private pdfCarnet: RptPDFCarnet2023,
              private dialogRef: MatDialogRef<RptCarnet2023Component>,
              @Inject(MAT_DIALOG_DATA) data: any) {
                this.data=data.parametros;
                //console.log(this.data);
              }

  ngOnInit(): void {
    this.codsistema=variablesGlobales.codsistema;
    this.srcCarnet=this.data.srcCarnet;
    this.srcFoto=this.data.srcFoto;
    this.srcQR=this.data.srcQR;
    this.condicion=this.data.condicion;
    this.lblinstitucion=variablesGlobales.institucion.split(" ")[1];
    this.lblevento=this.data.lblevento;
    this.lblsigla=this.data.lblsigla;
    this.lblEquipo=this.data.lblEquipo;
    //console.log(this.lblCargo);
    //console.log(this.lblnumero);
    this.lblCargo=this.data.lblCargo;
    this.lblnumero=this.data.lblnumero;
    this.lblDisciplina=this.data.lblDisciplina;
    this.lblEmision=this.data.lblEmision;

    if(this.codsistema==2){
      let parts=this.data.lblNombre.split('<br>');
      this.lblNombre=parts[0];
      this.lblApellido=parts[1];
    }
    else{
      this.lblNombre=this.data.lblNombre;
    }
  }

  capitalizeFirstLetter(text: string) {
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
  }

  fnImprimir(){
    html2canvas(this.imgCarnet.nativeElement,
      {
        logging: true,
        allowTaint: false,
        useCORS: true,
      }).then(canvas => {
      this.canvas.nativeElement.src = canvas.toDataURL();
      this.pdfCarnet.getPdfData(canvas.toDataURL());
    });
  }

}
