
<mat-card-title class="Titulo" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>{{titulo}}</mat-card-title>
<div mat-dialog-content>
    <form [formGroup]="FrmAgregarPartido"> 
        <mat-card fxLayout="column">  
            <mat-card-content fxLayout="column">
                <div>
                    <mat-form-field class="Fila0">
                        <input matInput placeholder="Nro. Secuencia" formControlName="txtnumero" autocomplete="off" required
                            #inputNumero (keydown)="onKeyDownOnlyNumber(inputNumero,$event)" (paste)="handlePasteOnlyNumber($event)">
                    </mat-form-field>                    
                    <mat-form-field class="Fila0">
                        <mat-select style="width: 104px;" placeholder="Serie" formControlName="cboserie" (selectionChange)="fnListarEquipo($event.value)">
                            <mat-option *ngFor="let elem of Serie" [value]="elem.tX_SERIE" >
                                {{elem.tX_SERIE}}
                            </mat-option>
                        </mat-select>                        
                    </mat-form-field>
                </div>
                <div style="margin-top: 10px;">
                    <mat-label class="Info">Fecha</mat-label>
                </div>
                <div>
                    <mat-form-field class="Fila5">
                        <input matInput placeholder="Número" formControlName="txtFecha" autocomplete="off" required
                            #inputNumero (keydown)="onKeyDownOnlyNumber(inputNumero,$event)" (paste)="handlePasteOnlyNumber($event)">
                    </mat-form-field>   
                </div>
                <div>
                    <mat-form-field class="Fila1"> 
                        <mat-select class="valueSelect" placeholder="Torneo" formControlName="cboTornero" required
                            (selectionChange)="ValidarSeleccion(0,$event.value)">
                            <mat-option class="opcionSelect" *ngFor="let item of Torneo" [value]="item.nU_ID_TORNEO">
                                {{item.tX_TORNEO}}
                            </mat-option>
                        </mat-select>
                        <!-- <mat-hint>Equipo Local</mat-hint> -->
                    </mat-form-field>                    
                    <mat-form-field class="Fila1"> 
                        <mat-select class="valueSelect" placeholder="Tipo" formControlName="cboTipoTornero" required
                            (selectionChange)="ValidarSeleccion(1,$event.value)">
                            <mat-option class="opcionSelect" *ngFor="let item of TipoTorneo" [value]="item.nU_ID_TIPOTORNEO">
                                {{item.tX_TIPOTORNEO}}
                            </mat-option>
                        </mat-select>
                        <!-- <mat-hint>Equipo Visita</mat-hint> -->
                    </mat-form-field>
                </div>
                <div style="margin-top: 10px;">
                    <mat-label class="Info">Equipos</mat-label>
                </div>
                <div>
                    <mat-form-field class="Fila1"> 
                        <mat-select class="valueSelect" formControlName="cboequipo_local" required
                            (selectionChange)="ValidarSeleccion(0,$event.value)">
                            <mat-option class="opcionSelect" *ngFor="let item of EquipoLocal" [value]="item.nU_ID_EQUIPO">
                                {{item.tX_DENOMINACION}}
                            </mat-option>
                        </mat-select>
                        <mat-hint>Equipo Local</mat-hint>
                    </mat-form-field>
                    <mat-label class="Info">vs.</mat-label>
                    <mat-form-field class="Fila1"> 
                        <mat-select class="valueSelect" formControlName="cboequipo_visita" required
                            (selectionChange)="ValidarSeleccion(1,$event.value)">
                            <mat-option class="opcionSelect" *ngFor="let item of EquipoLocal" [value]="item.nU_ID_EQUIPO">
                                {{item.tX_DENOMINACION}}
                            </mat-option>
                        </mat-select>
                        <mat-hint>Equipo Visita</mat-hint>
                    </mat-form-field>
                </div>
                <div style="margin-top: 20px;">
                    <mat-form-field class="Fila2"> 
                        <input matInput [matDatepicker]="FecProgramada" placeholder="Fecha" formControlName="FecProgramada" readonly>
                        <mat-datepicker-toggle matSuffix [for]="FecProgramada" disabled></mat-datepicker-toggle>
                        <mat-datepicker #FecProgramada></mat-datepicker>
                    </mat-form-field>
                    <mat-form-field class="Fila3"><!--appearance="outline">-->
                        <!--<mat-label>Hora Programada</mat-label>-->
                        <input type="time" matInput placeholder="Hora Programada" formControlName="txthora" required/>
                    </mat-form-field>
                </div>
                <div *ngIf="isVisibleMotivo">
                    <mat-form-field class="Fila4">
                        <textarea matInput placeholder="Nota u observación" formControlName="txtmotivo" class="CampoTextArea"
                            autocomplete="off" rows="2" required></textarea>
                    </mat-form-field> 
                </div>
            </mat-card-content> 
        </mat-card>
    </form>
</div>
<div mat-dialog-actions style="text-align: right;">
    <button mat-button class="btnOpcion" (click)="Close()">
        <mat-icon fontSet="material-icons-outlined">cancel</mat-icon>
        <span>Cancelar</span>
    </button>
    <button mat-button class="btnOpcion" (click)="Guardar()">
        <mat-icon fontSet="material-icons-outlined">save</mat-icon>
        <span>Grabar</span>
    </button>
</div>