import { Component, OnInit } from '@angular/core';
import { Configuracion } from 'src/app/@core/services/config';

@Component({
  selector: 'app-enlaces',
  templateUrl: './enlaces.component.html',
  styleUrls: ['./enlaces.component.scss']
})
export class EnlacesComponent implements OnInit {
  
  solicitud:string= Configuracion.iniciosolicitud;
  portalprincipal:string= Configuracion.portalprincipal;

  constructor() {

   }

  ngOnInit(): void {
  }

}
