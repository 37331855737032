import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Fecha } from 'src/app/@core/models/Fecha';
import { AutenticaService } from 'src/app/@core/services/AutenticaService';
import { FechaService } from 'src/app/@core/services/FechaService';
import { variablesGlobales } from 'src/app/Util/variableGlobal';
import Swal from 'sweetalert2';

export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};

@Component({
  selector: 'app-dialog-agregar-fecha',
  templateUrl: './dialog-agregar-fecha.component.html',
  styleUrls: ['./dialog-agregar-fecha.component.scss'],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }
  ]
})
export class DialogAgregarFechaComponent implements OnInit {

  FrmAgregarFecha!: FormGroup;
  coddisciplina:number=0;
  codfecha:number=0;
  opc?: number;
  titulo!: string;
  ObjFecha!: Fecha;
  estado: number=1;
  isVisibleMotivo: boolean=false;
  isReadOnly: boolean=false;
  isReadOnlyFecha: boolean=false;

  constructor(private _formBuilder: FormBuilder,
              private ObjAutenticaService: AutenticaService,
              private router: Router,
              private ObjFechaService: FechaService,
              private dialogRef: MatDialogRef<DialogAgregarFechaComponent>,
              @Inject(MAT_DIALOG_DATA) data: any) { 
                this.coddisciplina=data.coddisciplina;
                this.codfecha=data.codfecha;
                this.opc=data.opc;
              }

  ngOnInit(): void {
    this.FrmAgregarFecha=this._formBuilder.group({
      txtnumero:[null,Validators.required],
      FecProgramada:[null,Validators.compose([Validators.required, Validators.maxLength(10)])],
      txtmotivo:["",Validators.required]
    });

    this.titulo="Registrar Fecha";

    this.cargaDatos();
  }

  @HostListener('window:keyup.esc') onKeyUp() {
    this.Close();
  }

  cargaDatos(){
    this.ObjAutenticaService.ValidarApp()
      .subscribe(
        data => { 
          if(data.isSuccess){
            if(data.tx_token.length>0){
              variablesGlobales.token=data.tx_token;

              if(this.codfecha>0){
                if(this.opc==1){
                  this.titulo="Suspender Fecha";
                  this.isReadOnlyFecha=true;
                  this.isVisibleMotivo=true;
                }
                else{
                  this.titulo="Reprogramar Fecha";
                }   

                this.ObjFechaService.Obtener(this.codfecha).pipe()
                  .subscribe(data => {
                    if(data.isSuccess){
                      this.ObjFecha=data.fecha;
                      this.isReadOnly=true;

                      this.FrmAgregarFecha.controls["txtnumero"].setValue(this.ObjFecha.nU_SECUENCIA?.toString());
                      this.FrmAgregarFecha.controls["FecProgramada"].setValue(this.convertFechatoStringData(this.ObjFecha.fE_PROGRAMADO));
                      this.estado=this.ObjFecha.nU_ID_ESTADO!;
                    }
                  });
              }
            }
            else{
                variablesGlobales.token='';
                variablesGlobales.usuario.codusu=0;
                this.router.navigate(['login']);
            }
          }
        },
        error => {
          console.log(error);
          variablesGlobales.token='';
          variablesGlobales.usuario.codusu=0;
          this.router.navigate(['login']);
        }   
      );
  }

  onKeyDownOnlyNumber (input: any, event:KeyboardEvent) {
    var specialKeys: string[] = [
      'Backspace',
      'Tab',
      'End',
      'Home',
      'ArrowLeft',
      'ArrowRight',
      'Del',
      'Delete',
    ];

    if (specialKeys.indexOf(event.key) !== -1 ||
        (event.key === 'v' && event.ctrlKey === true) ||
        (event.key === 'v' && event.metaKey === true)) {
        return;
    }

    var current: string = input.value;
    var regex=new RegExp(/^\d+$/g);
    var next = current.concat(event.key);

    if (next && !String(next).match(regex)) {
      event.preventDefault();
    }
  }

  handlePasteOnlyNumber(event:ClipboardEvent) {
    var input=event.clipboardData?.getData('text/plain');
    var regex=new RegExp(/^\d+$/g);
  
    if (regex.test(input!)) {
      return true;
    }
    return false;
  }

  convertFechatoStringData(fecha: any){
    var parts=fecha.split("-");
    var anio =parts[0];
    var mes =parts[1];
    var dia =parts[2].substring(0,2);
    var mydate =new Date(anio,mes-1,dia)

    return mydate.toISOString();
  }

  Guardar(){
    if(this.ValidarCampos()){
      this.ObjFecha={nU_ID_FECHA: this.codfecha};
      this.ObjFecha.nU_ID_ESTADO=this.estado;

      if(this.codfecha>0){
        if(this.opc==1){
          this.ObjFecha.tX_OBSERVACION=this.FrmAgregarFecha.controls["txtmotivo"].value;

          this.Procesar(2, this.ObjFecha);
        }
        else{
          this.ObjFecha.fE_PROGRAMADO=this.FrmAgregarFecha.controls["FecProgramada"].value;

          this.Procesar(3, this.ObjFecha);
        }
      }
      else{
        this.ObjFecha.nU_ID_EVENTO=variablesGlobales.codevento;
        this.ObjFecha.nU_ID_DISCIPLINA=this.coddisciplina;
        this.ObjFecha.nU_SECUENCIA=parseInt(this.FrmAgregarFecha.controls["txtnumero"].value);
        this.ObjFecha.fE_PROGRAMADO=this.FrmAgregarFecha.controls["FecProgramada"].value;
        this.ObjFecha.tX_OBSERVACION=this.FrmAgregarFecha.controls["txtmotivo"].value;

        this.Procesar(1, this.ObjFecha);
      }
    }
  }

  Procesar(opc: number, obj: Fecha){
    this.ObjAutenticaService.ValidarApp()
      .subscribe(
        data => { 
          if(data.isSuccess){
            if(data.tx_token.length>0){
              variablesGlobales.token=data.tx_token;

              if(opc==1){
                this.ObjFechaService.Actualizar(obj).pipe()
                  .subscribe(
                    data => {
                      if (data.isSuccess){
                        Swal.fire({
                          title: data.tx_Mensaje,
                          icon: 'success'}); 
                        
                        this.dialogRef.close(data.isSuccess);
                      }
                      else{
                        console.log(data.tx_mensaje);
                        Swal.fire(
                          'Advertencia',
                          'Atención: Error al procesar',
                          'warning'
                        );
                      } 
                    },
                    error => {
                      console.log(error);
                      Swal.fire(
                        'Error',
                        'El servicio solicitado no se encuentra disponible',
                        'error'
                      );
                    }
                  );
              }
              else if(opc==2){
                this.ObjFechaService.Suspender(obj).pipe()
                  .subscribe(
                    data => {
                      if (data.isSuccess){
                        Swal.fire({
                          title: data.tx_Mensaje,
                          icon: 'success'}); 
                        
                        this.dialogRef.close(data.isSuccess);
                      }
                      else{
                        console.log(data.tx_mensaje);
                        Swal.fire(
                          'Advertencia',
                          'Atención: Error al procesar',
                          'warning'
                        );
                      } 
                    },
                    error => {
                      console.log(error);
                      Swal.fire(
                        'Error',
                        'El servicio solicitado no se encuentra disponible',
                        'error'
                      );
                    }
                  );
              }
              else if(opc==3){
                this.ObjFechaService.Reprogramar(obj).pipe()
                  .subscribe(
                    data => {
                      if (data.isSuccess){
                        Swal.fire({
                          title: data.tx_Mensaje,
                          icon: 'success'}); 
                        
                        this.dialogRef.close(data.isSuccess);
                      }
                      else{
                        console.log(data.tx_mensaje);
                        Swal.fire(
                          'Advertencia',
                          'Atención: Error al procesar',
                          'warning'
                        );
                      } 
                    },
                    error => {
                      console.log(error);
                      Swal.fire(
                        'Error',
                        'El servicio solicitado no se encuentra disponible',
                        'error'
                      );
                    }
                  );
              }  
            }
            else{
              variablesGlobales.token='';
              variablesGlobales.usuario.codusu=0;
              this.router.navigate(['login']);
            }
          }
        },
        error => {
          console.log(error);
          variablesGlobales.token='';
          variablesGlobales.usuario.codusu=0;
          this.router.navigate(['login']);
        }   
      );
  }

  ValidarCampos(){
    if (this.FrmAgregarFecha.controls["txtnumero"].value == null || 
      this.FrmAgregarFecha.controls["txtnumero"].value.length == 0 || 
      /^\s+$/.test(this.FrmAgregarFecha.controls["txtnumero"].value)) {
        this.alertMsg('Ingrese Nro. Secuencia');
        return false;
    }

    if (this.FrmAgregarFecha.controls["FecProgramada"].value == null || 
      this.FrmAgregarFecha.controls["FecProgramada"].value.length == 0 || 
      /^\s+$/.test(this.FrmAgregarFecha.controls["FecProgramada"].value)) {
        this.alertMsg('Ingrese Fecha Programada o establezca el formato correcto');
        return false;
    }

    if(this.isVisibleMotivo){
      if (this.FrmAgregarFecha.controls["txtmotivo"].value == null || 
        this.FrmAgregarFecha.controls["txtmotivo"].value.length == 0 || 
        /^\s+$/.test(this.FrmAgregarFecha.controls["txtmotivo"].value)) {
          this.alertMsg('Ingrese Motivo de reprogramación');
          return false;
      }
    }

    return true;
  }

  alertMsg(msg:string){
    Swal.fire({
      html:msg,
      icon:'info'
    })
  }

  Close() {
    this.dialogRef.close();
  }

}
